import { ToastContainer } from '@montugroup/design-system';
import { CssBaseline } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { GoogleOAuthProvider } from '@react-oauth/google';
import enGB from 'date-fns/locale/en-GB';
import { createContext, useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';

import settings from '@/constants/constants';
import { PreferencesProvider } from '@/context/Preferences';
import { configure as configureBraze } from '@/services/braze.service';

import '@montugroup/design-system/Toast/style.css';
import './App.scss';

import type { Card } from './components/braze/components/ContentCard';
import CircleLoader from './components/common/circleLoader';
import AlertSnackbarProvider from './components/error/AlertSnackbarProvider';
import { FF_SHOW_MAINTENANCE_SCREEN } from './constants/featureFlags';
import { ErrorManagementProvider } from './context/ErrorManagement';
import { PatientReferralProvider } from './context/PatientReferral';
import useFeatureFlags from './hooks/useFeatureFlags';
import useUser from './hooks/user/useUser';
import useTrackingProviders from './hooks/useTrackingProviders';
import Maintenance from './pages/maintenance/Maintenance';
import { AuthenticatedSnowplow } from './providers/authenticated-snowplow';
import Routes from './routes/routes';
import AuthService from './services/authentication.service';
import { Logger } from './utils/logger';

const logger = new Logger('App');

export const BrazeContentCardContext = createContext<Card[]>([]);

function App() {
  const { data } = useUser();
  const { flags, isReady, setUser } = useFeatureFlags();
  const [brazeContentCards, setBrazeContentCards] = useState<Card[]>([]);
  useTrackingProviders(settings);

  if (!data && AuthService.getUser()?.user) {
    logger.warn('useUser data missing while localStorage data was found');
  }
  const user = data ?? AuthService.getUser()?.user;
  const email = user?.email || '';

  useEffect(() => {
    if (isReady && user) {
      setUser(user);
    }
  }, [isReady, setUser, user]);

  useEffect(() => {
    configureBraze({
      userEmail: email,
      contentCardsSubscriber: ({ cards }) => setBrazeContentCards(cards as Card[])
    });
  }, [email]);

  logger.debug('email: ', email);

  if (!isReady) {
    return <CircleLoader />;
  }

  return (
    <GoogleOAuthProvider clientId={settings.GoogleAuthId}>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={enGB}>
        <CssBaseline />
        <ToastContainer
          // Allowing unmasking of toast text in session replays, if any toast text is found to be PII the implementation of this will need to be changed
          toastClassName="dd-privacy-allow"
          position="top-right"
          autoClose={7500}
          hideProgressBar
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnHover
        />
        <AlertSnackbarProvider>
          <ErrorManagementProvider initialErrors={[]}>
            <BrazeContentCardContext.Provider value={brazeContentCards}>
              {flags[FF_SHOW_MAINTENANCE_SCREEN] ? (
                <Maintenance />
              ) : (
                <PreferencesProvider>
                  <PatientReferralProvider>
                    <BrowserRouter>
                      <AuthenticatedSnowplow>
                        <Routes />
                      </AuthenticatedSnowplow>
                    </BrowserRouter>
                  </PatientReferralProvider>
                </PreferencesProvider>
              )}
            </BrazeContentCardContext.Provider>
          </ErrorManagementProvider>
        </AlertSnackbarProvider>
      </LocalizationProvider>
    </GoogleOAuthProvider>
  );
}

export default App;
