import type { PaginationModel } from '@montugroup/design-system';
import { keepPreviousData, useQuery } from '@tanstack/react-query';

import { getPharmacistDetails } from '@/services/pharmacist.service';
import type { PharmacyInventoryHistoryParams } from '@/services/pharmacy.service';
import { PharmacyService } from '@/services/pharmacy.service';
import type { TableSortOrder } from '@/types';

const useInventoryHistory = (paginationModel: PaginationModel, sortingOrder: TableSortOrder, search: string) =>
  useQuery({
    queryKey: ['inventoryHistory', paginationModel, sortingOrder, search],

    queryFn: async () => {
      const params: PharmacyInventoryHistoryParams = {
        limit: paginationModel.pageSize,
        offset: paginationModel.page * paginationModel.pageSize,
        sort: sortingOrder.name,
        sortOrder: sortingOrder.reverse ? 'DESC' : 'ASC',
        filter: search
      };

      const { data: pharmacistDetails } = await getPharmacistDetails();
      const currentPharmacyId = pharmacistDetails.pharmacy_id;

      const response = await PharmacyService.getInventoryHistory(currentPharmacyId, params);

      if (!response?.data?.status) {
        throw new Error(response?.data?.message || 'Error fetching inventory history');
      }

      return response?.data;
    },
    meta: {
      error: {
        showToast: true
      }
    },

    staleTime: 5000,
    placeholderData: keepPreviousData
  });

export default useInventoryHistory;
