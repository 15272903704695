import React from 'react';
import PropTypes from 'prop-types';
const formInput = ({
  id,
  name,
  type,
  placeholder,
  onChange,
  className,
  value,
  info,
  error,
  readOnly,
  label,
  maxlength,
  defaultValue,
  noLabel,
  rows,
  showViewMode,
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  return (
    <div className={`form-group ${className}`}>
      {!noLabel && (
        <label className="float-left mr-2 label" htmlFor={id}>
          {label}
        </label>
      )}
      {rows ? (
        <textarea
          rows={rows}
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxlength}
          readOnly={readOnly}
          className={showViewMode ? 'form-control has-prefix input-field-invisible' : 'form-control has-prefix'}
          style={error ? { border: '1px solid red' } : {}}
          {...props}
        />
      ) : (
        <input
          id={id}
          name={name}
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          defaultValue={defaultValue}
          maxLength={maxlength}
          readOnly={readOnly}
          className={showViewMode ? 'form-control has-prefix input-field-invisible' : 'form-control has-prefix'}
          style={error ? { border: '1px solid red' } : {}}
          {...props}
        />
      )}
      {error && <div>{error}</div>}
      {info && <small className="form-text text-muted ml-1">{info}</small>}
    </div>
  );
};
formInput.defaultProps = {
  type: 'text',
  className: '',
};
formInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'number', 'password', 'email']),
  className: PropTypes.string,
  value: PropTypes.any,
};
export default formInput;
