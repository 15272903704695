import Brand from '@/utils/brand';

import ResetPassword from './resetPassword';
import ResetPasswordRebrand from './ResetPasswordRebrand';

// TODO: RouteProps to be removed once we remove the withFeatureFlags from routes
type RouteProps = {
  [key: string]: any;
};

export default function ProfileResetPasswordContainer(props: RouteProps) {
  return Brand.isRebrand() ? <ResetPasswordRebrand {...props} /> : <ResetPassword {...props} />;
}
