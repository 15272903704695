/* Eslint isn't detecting that we are using component="button" on the Link */
/* eslint-disable jsx-a11y/anchor-is-valid */

import { Box, Link } from '@mui/material';

import envSettings from '@/constants/constants';

const phoneNum = envSettings.support.phone.display;

export type PageLoadErrorAlertContentProps = {
  onClickRetry: () => void;
};

export function PageLoadErrorAlertContent({ onClickRetry }: PageLoadErrorAlertContentProps) {
  return (
    <Box>
      Oops! Something went wrong. Please{' '}
      <Link component="button" color="inherit" onClick={onClickRetry}>
        refresh
      </Link>{' '}
      the page. If the problem persists, please call our support team on{' '}
      <Link color="inherit" href={`tel:${phoneNum}`}>
        {phoneNum}
      </Link>
      .
    </Box>
  );
}

export default PageLoadErrorAlertContent;
