import { toast } from '@montugroup/design-system';
import moment from 'moment';
import { useState } from 'react';

import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import { OrderService } from '@/services/order.service';

interface Order {
  order: number;
  products: number[];
}

interface PaidOrder {
  id: number;
  orders: Order[];
}

interface UnpaidOrders {
  allOrderIds: number[];
  autoCancelOrderIds: number[];
  autoEditOrderIds: number[];
}

interface SplitOrder {
  paid_orders: PaidOrder[];
  unpaid_orders: UnpaidOrders;
}

interface SplitModalArgs {
  productId: number;
  returnDate: Date | null;
  reasoningToggle: boolean;
  outOfStock: boolean;
}

export const splitModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: '35px',
    border: 0
  }
};

const useSplitProductsModal = () => {
  const [showSplitModal, setShowSplitModal] = useState(false);
  const [splitOOS, setSplitOOS] = useState(false);
  const [splitOrders, setSplitOrders] = useState<SplitOrder | null>();

  const splitOrderConfirm = (orders: SplitOrder) => {
    setSplitOOS(true);
    setSplitOrders(orders);
    setShowSplitModal(false);
  };

  const splitOrderCancel = () => {
    setSplitOOS(false);
    setSplitOrders(null);
    setShowSplitModal(false);
  };

  const splitModal = async ({ productId, returnDate, reasoningToggle, outOfStock }: SplitModalArgs) => {
    if (!productId) {
      return;
    }
    const stockReturnDate = returnDate ? moment(returnDate).startOf('day') : moment().startOf('day');
    const currentDate = moment().startOf('day');
    if ((outOfStock && stockReturnDate.diff(currentDate, 'days') >= 3) || reasoningToggle) {
      setShowSplitModal(true);
      const response = await OrderService.findOutOfStockOrders(productId);
      const orders: SplitOrder = response.data;
      const paidOrders = orders.paid_orders;
      const unpaidOrders = orders.unpaid_orders.allOrderIds;
      if (paidOrders.length === 0 && unpaidOrders.length === 0) {
        setShowSplitModal(false);
        return;
      }

      let message = `This will split ${paidOrders.length} order(s) and edit ${unpaidOrders.length} order(s), do you want to continue?`;
      if (paidOrders.length > 0 && unpaidOrders.length === 0) {
        message = `This will split ${paidOrders.length} order(s), are you sure you want to split these orders?`;
      }
      if (paidOrders.length === 0 && unpaidOrders.length > 0) {
        message = `This will edit ${unpaidOrders.length} order(s), are you sure you want to edit these orders?`;
      }

      toast(
        <ToastConfirmModal onConfirm={() => splitOrderConfirm(orders)} onCancel={() => splitOrderCancel()}>
          <p>{message}</p>
        </ToastConfirmModal>,
        toastOptions
      );
    } else {
      splitOrderCancel();
    }
  };

  return {
    showSplitModal,
    splitOrders,
    splitOOS,
    splitModal
  };
};

export default useSplitProductsModal;
