import { useEffect, useState } from 'react';

// Returns the previous result count if data is being loaded, this is used to stop pagination breaking on the Table component.

function useTableCountWithLoading(isLoading: boolean, count = 0) {
  const [previousCount, setPreviousCount] = useState(0);

  useEffect(() => {
    if (!isLoading) {
      setPreviousCount(count || 0);
    }
  }, [count, isLoading]);

  if (!count && isLoading) {
    return previousCount;
  }
  return count;
}

export default useTableCountWithLoading;
