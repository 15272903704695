import React from 'react';

function healthCheck() {
  return (
    <React.Fragment>
      <p>OK</p>
    </React.Fragment>
  );
}

export default healthCheck;
