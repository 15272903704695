import { FilterList as FilterListIcon } from '@mui/icons-material';
import {
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Popover,
  Stack,
  useMediaQuery,
  useTheme
} from '@mui/material';
import * as React from 'react';
import { useCallback, useEffect, useState } from 'react';

import { OrderService } from '@/services/order.service';
import type { OrderStatus } from '@/types';

type Props = {
  selectedStatuses: OrderStatus[];
  onSelect: (statuses: OrderStatus[]) => void;
  allowedStatuses?: string[];
  buttonSize?: 'small' | 'medium' | 'large';
};

function FilterByOrderStatusSelect({ selectedStatuses, onSelect, allowedStatuses, buttonSize }: Props) {
  const [anchorElement, setAnchorElement] = useState<HTMLButtonElement | null>(null);
  const [statuses, setStatuses] = useState<OrderStatus[]>([]);
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('sm'));
  const sizeOfButton = buttonSize || (isLargeView ? 'large' : 'small');

  const onFilterButtonClick: React.MouseEventHandler<HTMLButtonElement> = (e) =>
    setAnchorElement((current) => (current ? null : e.currentTarget));

  const onClose = () => setAnchorElement(null);

  const onChange = (status: OrderStatus) => {
    const updatedStatuses = selectedStatuses.some((s) => s.id === status.id)
      ? selectedStatuses.filter((s) => s.id !== status.id)
      : [...selectedStatuses, status];
    onSelect(updatedStatuses);
  };

  const fetchStatuses = useCallback(async () => {
    const orderStatus = await OrderService.getOrderStatus();
    setStatuses(
      orderStatus.data.filter((status) => !allowedStatuses?.length || allowedStatuses?.includes(status.name))
    );
  }, [allowedStatuses]);

  useEffect(() => {
    fetchStatuses();
  }, [fetchStatuses]);

  return (
    <>
      <Stack direction="row" justifyContent="flex-end" position="relative">
        <Stack>
          <Button
            data-testid="order-status-filter"
            startIcon={<FilterListIcon />}
            size={sizeOfButton}
            onClick={onFilterButtonClick}
            variant="contained"
            color="secondary"
          >
            Filter by Status
          </Button>
        </Stack>
      </Stack>
      <Popover
        id="order-status-filters-popover"
        open={!!anchorElement}
        anchorEl={anchorElement}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
      >
        <Card raised sx={{ maxHeight: '50vh', overflowY: 'scroll' }}>
          <CardContent>
            <Stack spacing={2} alignContent="center">
              <FormGroup>
                {statuses.map((status) => (
                  <FormControlLabel
                    key={`order-status-filter-${status.id}`}
                    control={
                      <Checkbox
                        color="secondary"
                        value={status.id}
                        onChange={() => onChange(status)}
                        checked={selectedStatuses.some((s) => s.id === status.id)}
                      />
                    }
                    label={status.name}
                  />
                ))}
              </FormGroup>
            </Stack>
          </CardContent>
        </Card>
      </Popover>
    </>
  );
}

export default FilterByOrderStatusSelect;
