import { Typography } from '@mui/material';

import Section from './Section';

export type ProductDescriptionProps = {
  product: {
    description: string;
  };
};

export function ProductDescription({ product: { description } }: ProductDescriptionProps) {
  return (
    <Section>
      <Section.Title>Description</Section.Title>
      <Section.Content>
        <Typography>{description}</Typography>
      </Section.Content>
    </Section>
  );
}

export default ProductDescription;
