interface IAltLogoProps {
  width?: string;
  height?: string;
  fillColor?: string;
  className?: string;
}

export default function AltLogo({ width = '179', height = '100%', fillColor = '#ffffff', className }: IAltLogoProps) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 179 23"
      fill="none"
    >
      <path d="M28.4359 0.1875H25.0244V22.8104H28.4359V0.1875Z" fill={fillColor} />
      <path
        d="M37.9246 2.04297H34.5449V6.59834H31.6533V9.55317H34.5449V22.8114H37.9246V9.55317H42.3787V6.59834H37.9246V2.04297Z"
        fill={fillColor}
      />
      <path
        d="M9.19734 0.185547L0 22.8085H3.64233C6.52064 22.7675 9.06204 21.3747 10.6113 19.256H5.09607L5.42502 18.4506L7.41199 13.5387H7.40934L9.00103 9.64255C9.74913 7.82143 10.3646 6.24911 11.0808 4.42799H11.1127C11.7945 6.25168 12.4126 7.82143 13.1607 9.64255L14.2961 12.423L14.7497 13.5387H14.7444L15.71 15.9267C13.6143 16.4012 11.8077 17.6068 10.6033 19.256H17.063L18.5167 22.8085H22.1882L12.967 0.185547H9.19734Z"
        fill={fillColor}
      />
    </svg>
  );
}
