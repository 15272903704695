// Everything in this file is tech debt.
// These are temporary work arounds as Eng + UX work move to a more consistent use of the design system for theming.
// This should be deprecated as soon as possible.

import type { PartnerKey } from '@/hooks/portal-partner-brand/usePortalPartnerBrand';

export type BrandName = (typeof PartnerKey)[keyof typeof PartnerKey];

export const getAccordionHeaderColors = (brand: BrandName) => {
  if (brand === 'uMeds') {
    return {
      background: '#F6F4EC',
      borderTopColor: '#e8e4d1'
    };
  }
  return {
    background: '#E8E4D1',
    borderTopColor: '#E8E4D1' // 'rgba(71, 188, 143, 0.50)',
  };
};
