import { styled } from '@mui/material';

export const Heading = styled('h3')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontWeight: 'bold',
  marginLeft: 0,
  marginTop: '1.25rem',
  marginBottom: '1.25rem'
}));

export const SecondaryText = styled('span')(({ theme }) => ({
  fontSize: '1.2rem',
  fontFamily: theme.typography.fontFamily
}));

// TODO: Classify these colors
export const skyBlue = '#ceedff';
export const teal = '#194457';
export const deepRed = '#c53b00';
export const lightPurple = '#498cce';
export const pastelYellow = '#f3eee1';
export const deepPink = '#ffc8b9';
export const lightBlue = '#2085cb';
export const grassGreen = '#008000';
export const lightGray = 'cfd2d9';
export const faintPurple = '#E9CFE9';
export const lightGreen = '#B5F3D1';
export const redOrange = '#FFC8B9';
export const statusGray = '#E7E7E7';
