import React from 'react';

const Drawer = (props) => {
  const [state, setState] = React.useState({
    width: '100%',
    position: 'right',
    show: false,
  });
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  React.useEffect(() => {
    let newState = state;
    newState.show = props.show;
    if (props.width) {
      newState.width = props.width;
    }
    if (props.position) {
      newState.position = props.position;
    } // use only 'left' or 'right'
    setState(newState);
    forceUpdate();
  }, [props.show]);

  const onClose = () => {
    setState({ ...state, width: 0 });
    if (props.onClose) {
      props.onClose();
    }
  };

  return (
    <div
      className="drawer h-100"
      style={{
        width: state.show ? state.width : 0,
        [state.position]: 0,
      }}
    >
      <div className="content">
        {props.showDefaultClose && (
          <div className="btn btn-primary float-right" onClick={onClose}>
            x
          </div>
        )}
        {props.children}
      </div>
    </div>
  );
};

export default Drawer;
