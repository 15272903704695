import { List, ListItem } from '@mui/material';

type ListTableCellProps = {
  items: {
    key: string;
    value: string | number;
  }[];
};

function ListTableCell({ items }: ListTableCellProps) {
  return (
    <List dense disablePadding>
      {items.map(({ key, value }) => (
        <ListItem key={key} disableGutters disablePadding>
          {value}
        </ListItem>
      ))}
    </List>
  );
}

export default ListTableCell;
