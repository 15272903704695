import React from 'react';
import CircleLoader from '../common/circleLoader';
import FilterableTable from 'rc-data-table';
import moment from 'moment';
import { INVENTORY_STATUS } from '@/constants/inventory';
import { getEachInventoryHistory } from '@/services/pharmacist.service';
import { useParams } from 'react-router-dom';
import { getProductEdit } from '@/services/product.service';

const SingleProductOverview = () => {
  const [history, setHistory] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    getInventoryProducts();
  }, []);
  const [productName, setProductName] = React.useState([]);
  const { id } = useParams();

  const getInventoryProducts = async () => {
    await getEachInventoryHistory(id).then((data) => setHistory(data.data));
    getProductEdit(id).then((data) => setProductName(data.data.products[0].name));
    setLoading(false);
  };

  const getTableFields = [
    {
      name: 'createdDate',
      displayName: 'Date',
      inputFilterable: false,
      sortable: true,
      tdClassName: 'sub-text text-center',
      thClassName: 'text-center',
      sortFieldName: 'createdDate',
      render: (e) => {
        return moment(new Date(e.value)).format('DD-MMM-YYYY');
      },
    },
    {
      name: 'orderId',
      displayName: 'Order ID',
      inputFilterable: true,
      sortable: false,
      tdClassName: 'sub-text text-center',
      thClassName: 'text-center',
      render: (e) => {
        if (e.record.orderId === null) {
          return '-';
        }
        return e.record.orderId;
      },
    },
    {
      name: 'adjustedBy',
      displayName: 'Adjusted By',
      inputFilterable: false,
      sortable: false,
      tdClassName: 'sub-text text-center',
      thClassName: 'text-center',
    },
    {
      name: 'adjustmentType',
      displayName: 'Adjusted Type',
      inputFilterable: false,
      sortable: false,
      tdClassName: 'sub-text text-center',
      thClassName: 'text-center',
      render: (e) => {
        if (e.record.adjustmentType === INVENTORY_STATUS.INCREMENT) {
          return 'Increase';
        }
        return 'Decrease';
      },
    },
    {
      name: 'quantity',
      displayName: 'Quantity Change',
      inputFilterable: false,
      sortable: false,
      tdClassName: 'sub-text text-center',
      thClassName: 'text-center',
      render: (e) => {
        if (e.record.adjustmentType === INVENTORY_STATUS.INCREMENT) {
          return e.value;
        }
        return `-${e.value}`;
      },
    },
    {
      name: 'reference',
      displayName: 'Reference',
      inputFilterable: false,
      sortable: false,
      tdClassName: 'sub-text text-center',
      thClassName: 'text-center',
      render: (e) => {
        return e.value.substring(0, 50);
      },
    },
  ];
  return (
    <div className="container-fluid">
      <div>
        {loading ? (
          <CircleLoader />
        ) : (
          <div>
            <h3>Product Overview - {history.length > 0 ? history[0].productName : productName}</h3>
            <FilterableTable
              className="table-responsive"
              topPagerVisible={false}
              data={history}
              loading={loading}
              fields={getTableFields}
              currentPage={0}
              serverSort={() => {}}
              pageSizes={false}
              initialSort={'productName'}
              initialSortDir={true}
              filterPlaceholder="Search"
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default SingleProductOverview;
