import PatientProfile from '@/pages/profile/PatientProfile';
import Brand from '@/utils/brand';

import ProfileDetails from './profileDetails';

// TODO: RouteProps to be removed once we remove the withFeatureFlags from routes
type RouteProps = {
  [key: string]: any;
};

export default function ProfileDetailsContainer(props: RouteProps) {
  if (Brand.isRebrand()) {
    return <PatientProfile {...props} />;
  }

  return <ProfileDetails {...props} />;
}
