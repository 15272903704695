import { Box, Checkbox, FormControlLabel, FormGroup, List, ListItem, ListItemButton, Typography } from '@mui/material';
import React from 'react';

import type { ProductFormulation, Supplier } from '@/components/products/detail/types';

interface Availability {
  id: number;
  name: string;
}

interface FilterProps {
  onFilterChange: (id: number, filterType: string, name?: string, filterValue?: boolean | string) => void;
}

interface TypeFilterProps {
  filtersArr: ProductFormulation[];
  filter: number[];
  onFilterChange: FilterProps['onFilterChange'];
}

interface BrandFilterProps {
  filtersArr: Supplier[];
  filter: number[];
  onFilterChange: FilterProps['onFilterChange'];
}

interface AvailabilityFilterProps {
  filtersArr: Availability[];
  filter: boolean;
  onFilterChange: FilterProps['onFilterChange'];
}

interface ProductFilterProps {
  title: string;
  children: React.ReactNode;
}

const isChecked = (id: number, filterOption: number[]): boolean => !!filterOption.find((x) => x === id);

function ProductFilterList({ title, children }: ProductFilterProps) {
  return (
    <Box>
      <Typography fontWeight="bold" color="primary" marginTop="1.5rem">
        {title}
      </Typography>

      <List sx={{ width: '100%' }}>
        <FormGroup>{children}</FormGroup>
      </List>
    </Box>
  );
}

function ListItemComponent(props: {
  id: number;
  name: string;
  onFilterChange: FilterProps['onFilterChange'];
  filterType: string;
  filter: number[] | boolean;
}) {
  const { id, name, onFilterChange, filterType, filter } = props;

  return (
    <ListItem key={id} disablePadding>
      <ListItemButton sx={{ padding: '0' }} dense>
        <FormControlLabel
          control={
            <Checkbox
              data-testid={name}
              key={id}
              checked={typeof filter === 'boolean' ? filter : isChecked(id, filter)}
              tabIndex={-1}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onFilterChange(id, filterType, name, e.target.checked);
              }}
              sx={{
                color: (theme) => theme.palette.secondary.main,
                '&.Mui-checked': {
                  color: (theme) => theme.palette.secondary.main
                }
              }}
            />
          }
          label={name}
          sx={{ width: '100%', marginBottom: '0', paddingTop: '0.1rem', paddingBottom: '0.1rem' }}
          componentsProps={{ typography: { variant: 'body2' } }}
        />
      </ListItemButton>
    </ListItem>
  );
}

export function TypeFilterList({ filter, filtersArr, onFilterChange }: TypeFilterProps) {
  return (
    <ProductFilterList title="Type">
      {filtersArr.map((ele) => (
        <ListItemComponent
          id={ele.id}
          name={ele.name}
          onFilterChange={onFilterChange}
          filterType="formulation"
          filter={filter as number[]}
          key={ele.id}
        />
      ))}
    </ProductFilterList>
  );
}

export function BrandFilterList({ filter, filtersArr, onFilterChange }: BrandFilterProps) {
  return (
    <ProductFilterList title="Brand">
      {filtersArr.map((ele) => (
        <ListItemComponent
          id={ele.id}
          name={ele.supplier_name}
          onFilterChange={onFilterChange}
          filterType="brand"
          filter={filter as number[]}
          key={ele.id}
        />
      ))}
    </ProductFilterList>
  );
}

export function AvailabilityFilterList({ filter, filtersArr, onFilterChange }: AvailabilityFilterProps) {
  return (
    <ProductFilterList title="Availability">
      {filtersArr.map((ele) => (
        <ListItemComponent
          id={ele.id}
          name={ele.name}
          onFilterChange={onFilterChange}
          filterType="exclude_oos"
          filter={filter as boolean}
          key={ele.id}
        />
      ))}
    </ProductFilterList>
  );
}
