interface MontuIconProps {
  width?: string;
  className?: string;
  height?: string;
}

export default function MontuIcon({ width = '400', height = '400', className }: MontuIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="20 0 100 348"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M248.752 17C269.939 32.2745 287.189 52.3584 299.081 75.5972C310.972 98.836 317.165 124.565 317.149 150.663C317.149 194.418 299.747 236.382 268.771 267.323C237.796 298.264 195.783 315.648 151.976 315.651C151.195 315.651 150.415 315.651 149.634 315.587H149.519C142.091 315.587 134.677 314.993 127.344 313.811C103.801 310.258 81.3042 301.664 61.3958 288.618C41.4873 275.572 24.6369 258.381 12 238.225C24.872 270.607 47.1815 298.382 76.0397 317.955C104.898 337.527 138.976 347.994 173.858 348C220.043 348 264.336 329.675 296.994 297.055C329.652 264.436 348 220.195 348 174.065C348.007 141.193 338.688 108.992 321.122 81.1935C303.557 53.3952 278.464 31.1376 248.752 17Z"
        fill="#1B4556"
      />
      <path
        d="M127.455 314.157C94.8892 308.884 65.2604 292.173 43.8738 267.014C22.4872 241.855 10.7391 209.892 10.7337 176.848C10.7337 139.957 25.3641 104.576 51.4066 78.4896C77.449 52.4032 112.77 37.7482 149.599 37.7482C175.486 37.7457 200.859 44.992 222.852 58.6693C244.845 72.3466 262.583 91.9101 274.064 115.151C284.601 133.037 290.15 153.43 290.128 174.199C290.133 186.49 288.191 198.703 284.376 210.385C276.897 240.502 259.58 267.25 235.18 286.375C210.78 305.501 180.697 315.906 149.715 315.936C150.494 315.936 151.273 316 152.053 316C195.801 315.997 237.757 298.586 268.69 267.598C299.623 236.61 317 194.583 317 150.762C317.016 124.624 310.832 98.8565 298.957 75.5825C287.081 52.3085 269.856 32.1942 248.698 16.8966C225.34 5.73746 199.782 -0.0362291 173.904 0.000171048C127.781 0.000171048 83.5491 18.3533 50.9355 51.0219C18.3219 83.6904 1.89919e-05 127.999 1.89919e-05 174.199C-0.0102602 196.203 4.15223 218.008 12.2671 238.456C24.8868 258.643 41.7128 275.86 61.5943 288.926C81.4757 301.992 103.943 310.599 127.455 314.157Z"
        fill="#46BA8E"
      />
      <path
        d="M285 210.648C277.364 233.96 262.557 254.264 242.692 268.662C222.827 283.061 198.92 290.818 174.383 290.826C143.513 290.809 113.914 278.538 92.0912 256.71C70.2686 234.882 58.0097 205.284 58.0097 174.423C58.0097 143.562 70.2686 113.964 92.0912 92.1356C113.914 70.3075 143.513 58.0359 174.383 58.0189C194.648 58.012 214.564 63.2987 232.155 73.3549C249.746 83.4112 264.403 97.8878 274.674 115.351C263.178 92.1254 245.417 72.5754 223.396 58.9074C201.374 45.2393 175.968 37.9975 150.047 38C113.169 38 77.8025 52.6455 51.726 78.7143C25.6495 104.783 11 140.14 11 177.006C11.0054 210.028 22.7689 241.97 44.1834 267.111C65.5979 292.253 95.2654 308.953 127.874 314.222C135.255 315.314 142.701 315.909 150.163 316C181.169 315.977 211.278 305.598 235.708 286.51C260.138 267.422 277.487 240.721 285 210.648Z"
        fill="#F7991C"
      />
    </svg>
  );
}
