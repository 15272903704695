import React from 'react';

type PaginationType = 'Prev' | 'Next' | 'PickNumber';
export type HandlePagination = (type: PaginationType, pageNumber: number) => Promise<void>;

function usePagination<DataType>({
  data,
  search
}: {
  data: DataType[];
  search?: null | string;
}): [HandlePagination, number, DataType[], number, number[]] {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [sliceData, setSliceData] = React.useState({
    start: 0,
    end: 10
  });

  const pageNumbers = [];
  const totalPageCount = Math.ceil(data.length / 10);

  for (let i = 1; i <= totalPageCount; i += 1) {
    pageNumbers.push(i);
  }

  // todo: Remove the switch and `type` parameter from handlePagination as they don't change the outcome of the function
  const handlePagination: HandlePagination = async (type, pageNumber) => {
    let addPageCount: number | string = '';
    let subtractPageCount: number | string = '';

    switch (type) {
      case 'Prev':
        addPageCount = parseInt(`${pageNumber}0`, 10);
        subtractPageCount = addPageCount - 10;
        setSliceData({
          start: subtractPageCount,
          end: addPageCount
        });
        setCurrentPage(pageNumber);
        break;

      case 'Next':
        addPageCount = parseInt(`${pageNumber}0`, 10);
        subtractPageCount = addPageCount - 10;
        setSliceData({
          start: subtractPageCount,
          end: addPageCount
        });
        setCurrentPage(pageNumber);
        break;
      case 'PickNumber':
        addPageCount = parseInt(`${pageNumber}0`, 10);
        subtractPageCount = addPageCount - 10;
        setSliceData({
          start: subtractPageCount,
          end: addPageCount
        });
        setCurrentPage(pageNumber);
        break;

      default:
        setSliceData({
          start: 0,
          end: 10
        });
        setCurrentPage(1);
    }
  };

  React.useEffect(() => {
    if (search) {
      handlePagination('PickNumber', 1);
    }
  }, [search]);

  const filteredData = data.slice(sliceData.start, sliceData.end);

  return [handlePagination, currentPage, filteredData, totalPageCount, pageNumbers];
}

export default usePagination;
