import axios from 'axios';
import type { ErrorInfo } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';

import { datadogRum } from '@datadog/browser-rum';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import App from './App';
import ErrorFallback from './ErrorFallback';
import { LaunchDarklyProvider } from './components/featureFlags/FFLoadingStateProvider';
import { FF_CIRCUIT_FRONTEND_ERRORBOUNDARY_LOG } from './constants/featureFlags';
import useFeatureFlags from './hooks/useFeatureFlags';
import TsrReactQueryProvider from './providers/ts-rest-react-query-provider';
import getQueryClient from './utils/getQueryClient';
import { Logger } from './utils/logger';
import withFeatureFlagProvider from './utils/withFeatureFlagProvider';

axios.defaults.headers.post['Content-Type'] = 'application/json';

const logError = (error: Error, info: ErrorInfo) => {
  const logger = new Logger('ErrorBoundary');
  logger.error({ message: error.message, componentStack: info.componentStack });

  const renderingError = new Error(error.message);
  renderingError.name = `ErrorBoundary`;
  renderingError.stack = info.componentStack === null ? undefined : info.componentStack;
  renderingError.cause = error;

  datadogRum.addError(renderingError);
};

const queryClient = getQueryClient();

function AppWrapper() {
  const { flags } = useFeatureFlags();

  const props = {
    FallbackComponent: ErrorFallback,
    onError: flags[FF_CIRCUIT_FRONTEND_ERRORBOUNDARY_LOG] ? logError : undefined
  };

  return (
    <ErrorBoundary {...props}>
      <QueryClientProvider client={queryClient}>
        <TsrReactQueryProvider>
          <App />
          <ReactQueryDevtools initialIsOpen={false} />
        </TsrReactQueryProvider>
      </QueryClientProvider>
    </ErrorBoundary>
  );
}

const LDApp = withFeatureFlagProvider(() => (
  <LaunchDarklyProvider>
    <AppWrapper />
  </LaunchDarklyProvider>
));

const root = createRoot(document.getElementById('root')!);

root.render(<LDApp />);
