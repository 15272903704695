import { useEffect } from 'react';

import { PatientReferralActionType, usePatientReferral } from '../../context/PatientReferral';
import { useCheckReferralCode } from '../../hooks/useCheckReferralCodeExists';

import PatientReferralModal from './patientReferralModal';
import PatientReferralNavBanner from './patientReferralNavBanner';

function PatientReferralView({ isNavBanner = false }: { isNavBanner?: boolean }) {
  const { open, promoCode, dispatch } = usePatientReferral();
  const { isSuccess, data, isLoading, isError } = useCheckReferralCode();

  useEffect(() => {
    if (isSuccess && data?.promoCode && !promoCode) {
      dispatch({ type: PatientReferralActionType.SET_PROMO_CODE, payload: data.promoCode });
    }
  }, [data, isSuccess]);

  if (isError) {
    return null;
  }

  return (
    <>
      <PatientReferralNavBanner isNavBanner={isNavBanner} />
      {open && <PatientReferralModal isReferralCodeCheckLoading={isLoading} open={open} />}
    </>
  );
}

export default PatientReferralView;
