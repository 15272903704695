import { Button } from '@montugroup/design-system';
import type { ButtonProps } from '@mui/material';
import { Box, styled } from '@mui/material';
import { useState } from 'react';

import DateRangeComp from '@/components/daterange/DateRangeComp';

const CalendarWrapper = styled(Box)(({ theme }) => ({
  position: 'absolute',
  zIndex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  marginTop: theme.spacing(10),
  right: theme.spacing(0)
}));

type ExportAllDateRangeButtonProps = {
  buttonProps?: ButtonProps;
  onExport: (fromDate: string, toDate: string) => void;
};

function ExportAllDateRangeButton({ buttonProps, onExport }: ExportAllDateRangeButtonProps) {
  const [showExportDateRangePicker, setShowExportDateRangePicker] = useState<boolean>(false);

  return (
    <Box sx={{ display: 'flex', position: 'relative' }}>
      <Button size="small" color="info" {...buttonProps} onClick={() => setShowExportDateRangePicker(true)}>
        Export
      </Button>
      {showExportDateRangePicker && (
        <CalendarWrapper>
          <DateRangeComp open closeCalendar={() => setShowExportDateRangePicker(false)} onChange={onExport} />
        </CalendarWrapper>
      )}
    </Box>
  );
}

export default ExportAllDateRangeButton;
