import type { TooltipProps } from '@mui/material';
import { Tooltip } from '@mui/material';
import { useState } from 'react';

export type ClickableTooltipProps = TooltipProps;

export function ClickableTooltip({ children, ...rest }: ClickableTooltipProps) {
  const [open, setOpen] = useState(false);

  const handleTooltipToggle = () => {
    setOpen((prev) => !prev);
  };

  return (
    <Tooltip
      onMouseOver={handleTooltipToggle}
      onClose={() => setOpen(false)}
      disableHoverListener={false}
      onClick={handleTooltipToggle}
      open={open}
      leaveTouchDelay={5000}
      {...rest}
    >
      {children}
    </Tooltip>
  );
}

export default ClickableTooltip;
