import React from 'react';
import './switch.scss';

const Switch = ({ isOn, handleToggle, disabled }) => {
  return (
    <>
      <input
        checked={isOn}
        onChange={handleToggle}
        className="react-switch-checkbox"
        id={'react-switch-new'}
        type="checkbox"
        disabled={disabled}
      />
      <label style={{ background: isOn && '#194457' }} className="react-switch-label mr-5" htmlFor={'react-switch-new'}>
        <span className={'react-switch-button'} />
      </label>
    </>
  );
};

export default Switch;
