import { useCallback, useState } from 'react';

import type { FormulationFilterProps } from '@/components/products/components/FormulationFilter/FormulationFilter';
import type { ProductFormulation } from '@/types';
import { ProductFormulationFilters, ProductFormulations } from '@/types';

type FormulationFilterSet = [typeof ProductFormulationFilters.ALL] | ProductFormulation[];

const allFormulations = Object.values(ProductFormulations);

const hasAllOption = (selection: FormulationFilterSet): selection is [typeof ProductFormulationFilters.ALL] =>
  selection[0] === ProductFormulationFilters.ALL;

export type UseFormulationFilterProps = {
  availableIds?: FormulationFilterProps['availableIds'];
  defaultValue?: ProductFormulation[];
};

export function useFormulationFilter({ availableIds, defaultValue }: UseFormulationFilterProps = {}) {
  const [selectedFormulations, setSelectedFormulations] = useState<FormulationFilterSet>(
    defaultValue || [ProductFormulationFilters.ALL]
  );

  const isAllSelected = hasAllOption(selectedFormulations);

  const filterFormulation: (formulation?: ProductFormulation) => boolean = useCallback(
    (formulation?: ProductFormulation) =>
      isAllSelected || Boolean(formulation && selectedFormulations.includes(formulation)),
    [isAllSelected, selectedFormulations]
  );

  const getFormulationFilterProps: () => FormulationFilterProps = useCallback(
    () => ({
      selectedIds: isAllSelected ? availableIds ?? allFormulations : selectedFormulations,
      onChange: setSelectedFormulations,
      availableIds
    }),
    [isAllSelected, selectedFormulations, availableIds]
  );

  return {
    filterFormulation,
    getFormulationFilterProps
  };
}

export default useFormulationFilter;
