interface UmedsIconProps {
  width?: string;
  height?: string;
  fillColor?: string;
  className?: string;
}

export default function UmedsIcon({ width = '215', height = '349', fillColor = '#FF9845', className }: UmedsIconProps) {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 215 349"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M298.872 161.695C298.872 237.648 259.213 279.495 187.176 279.495C115.138 279.495 76.128 237.666 76.128 161.695V115.861C76.128 112.861 73.6955 110.437 70.6864 110.437H5.44157C2.43249 110.437 0 112.861 0 115.861V162.988C0 281.147 68.2359 348.911 187.176 348.911C306.115 348.911 375 280.914 375 162.341V5.42395C375 2.42461 372.568 0 369.558 0H304.314C301.305 0 298.872 2.42461 298.872 5.42395V161.695Z"
        fill={fillColor}
      />
      <path
        d="M79.8037 0H0V74.4087H24.0006C76.0199 74.4087 88.0922 50.3421 88.0922 27.0479C88.0922 12.7876 82.038 0 79.8037 0Z"
        fill={fillColor}
      />
    </svg>
  );
}
