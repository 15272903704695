import { Chip, Typography } from '@mui/material';

interface IPrescriptionDosageChip {
  dosage: 'CBD' | 'THC';
  strength: string;
}

export function PrescriptionDosageChip({ dosage, strength }: IPrescriptionDosageChip) {
  return (
    <Chip
      label={
        <>
          {dosage}
          <Typography
            component="span"
            fontWeight={(theme) => theme.typography.fontWeightMedium}
            fontSize={(theme) => theme.typography.fontSize}
            paddingLeft={(theme) => theme.spacing(1)}
          >
            {strength}
          </Typography>
        </>
      }
      variant="filled"
      sx={{
        fontFamily: (theme) => theme.typography.body1.fontFamily,
        fontSize: (theme) => theme.typography.fontSize,
        backgroundColor: '#CEEDFF' // TODO: Change to theme color once design system is updated
      }}
    />
  );
}

export default PrescriptionDosageChip;
