import { Box, Skeleton, Stack } from '@mui/material';

import PageContentGrid from './PageContentGrid';

function PageSkeleton() {
  return (
    <>
      <Box sx={{ mb: 3, alignSelf: 'flex-start' }}>
        <Skeleton variant="rectangular" height="24px" width="130px" />
      </Box>
      <PageContentGrid>
        <Box sx={{ gridArea: 'image' }}>
          <Skeleton variant="rectangular" sx={{ pt: '100%' }} />
        </Box>
        <Box sx={{ gridArea: 'summary' }}>
          <Skeleton variant="text" sx={{ fontSize: '1.7rem', width: '140px' }} />
          <Skeleton variant="rectangular" height="40px" width="40%" />
        </Box>
        <Stack gap={6} sx={{ gridArea: 'details' }}>
          <Box>
            <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '130px' }} />
            <Skeleton variant="rectangular" height="150px" />
          </Box>
          <Box>
            <Skeleton variant="text" sx={{ fontSize: '1.5rem', width: '130px' }} />
            <Skeleton variant="rectangular" height="150px" />
          </Box>
        </Stack>
      </PageContentGrid>
    </>
  );
}

export default PageSkeleton;
