import { useState } from 'react';
import { useParams } from 'react-router-dom';

import useFeatureFlags from '@/hooks/useFeatureFlags';

import './inventory.scss';

import AdjustmentOverview from './adjustmentOverview';
import ProductOverview from './productOverview';

const tabs = {
  PRODUCT_OVERVIEW: 'productOverview',
  ADJUSTMENT_OVERVIEW: 'adjustmentOverview'
};

export default function Inventory() {
  const [activeTab, setActiveTab] = useState(tabs.PRODUCT_OVERVIEW);
  const { id } = useParams();

  const { flags } = useFeatureFlags();

  function toggleTab(tab: string) {
    setActiveTab(tab);
  }

  return (
    <>
      <div className="row">
        <div className="col-lg-12 text-center">
          <div className="btn-group" role="group">
            <button
              type="button"
              className={activeTab === tabs.PRODUCT_OVERVIEW ? 'btn btn-primary' : 'btn btn-outline-primary'}
              onClick={() => toggleTab(tabs.PRODUCT_OVERVIEW)}
            >
              Product Overview
            </button>
            <button
              type="button"
              className={activeTab === tabs.ADJUSTMENT_OVERVIEW ? 'btn btn-primary' : 'btn btn-outline-primary'}
              onClick={() => toggleTab(tabs.ADJUSTMENT_OVERVIEW)}
            >
              Adjustment Overview
            </button>
          </div>
        </div>
      </div>
      {activeTab === tabs.PRODUCT_OVERVIEW && <ProductOverview flags={flags} pharmacy_id={id} />}
      {activeTab === tabs.ADJUSTMENT_OVERVIEW && <AdjustmentOverview flags={flags} pharmacy_id={id} />}
    </>
  );
}
