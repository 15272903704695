import type { AxiosResponse } from 'axios';
import axios from 'axios';

import settings from '../constants/constants';

export interface ProductPayload {
  quantity: number;
  productId: number;
}

interface CheckoutRedirectUrlResponse {
  status?: number;
  orderCode?: string;
  checkoutRedirectUrl?: string;
  isPaid?: boolean;
  paidOrderAttributes?: {
    transactionType: string;
    userFirstName: string;
    userEmail: string;
    onBoardKey: string;
  };
  error?: string;
}

interface CheckoutRedirectUrlForInvoiceResponse extends CheckoutRedirectUrlResponse {
  isUnavailable?: boolean;
  userStatus: {
    isDischarged: boolean;
    isUserActive: boolean;
  };
}

interface PaymentStatusResponse {
  status: number;
  isPaid: boolean;
  transactionType: number;
  bankTransferReference?: string;
  error?: string;
}

interface PaymentStatusInvoiceResponse extends PaymentStatusResponse {
  user: {
    firstName: string;
    email: string;
  };
  onBoardKey?: string;
}

const ORDER_CODE_KEY = 'orderCode';
const INVOICE_TOKEN_KEY = 'invoiceToken';

const clearOrderCode = () => localStorage.removeItem(ORDER_CODE_KEY);

const clearInvoiceToken = () => localStorage.removeItem(INVOICE_TOKEN_KEY);

const setOrderCode = (orderCode: string) => {
  localStorage.setItem(ORDER_CODE_KEY, orderCode);
};

const setInvoiceToken = (token: string) => {
  localStorage.setItem(INVOICE_TOKEN_KEY, token);
};

const getOrderCode = () => localStorage.getItem(ORDER_CODE_KEY);

const getInvoiceToken = () => localStorage.getItem(INVOICE_TOKEN_KEY);

const doRequestWithErrorHandling = async (requestFunction: () => Promise<any>) => {
  try {
    return await requestFunction();
  } catch (error: any) {
    if (axios.isAxiosError(error) && error.response) {
      const { response } = error;
      return {
        status: response.status,
        error: response.data?.errors ? response.data.errors[0].message : 'An unexpected error occurred'
      };
    }
    return { status: 500 };
  }
};

const getCheckoutRedirectUrl = async (
  products: ProductPayload[],
  orderId?: number,
  orderCode?: string,
  inventoryReservationId?: string,
  uMedsMarketingOptIn?: boolean
): Promise<CheckoutRedirectUrlResponse> => {
  clearOrderCode();
  const getCheckoutRedirectUrlRequest = async (): Promise<CheckoutRedirectUrlResponse> => {
    const payload = {
      ...(orderId ? { orderId } : {}),
      ...(orderCode ? { orderCode } : {}),
      products,
      inventoryReservationId,
      uMedsMarketingOptIn
    };
    const response: AxiosResponse = await axios.put(`${settings.url}/order/process-checkout`, payload);

    const {
      data: { checkoutRedirectUrl, orderCode: responseOrderCode }
    } = response.data;

    setOrderCode(responseOrderCode);

    return { status: 200, checkoutRedirectUrl, orderCode: responseOrderCode };
  };

  return doRequestWithErrorHandling(getCheckoutRedirectUrlRequest);
};

const getCheckoutRedirectUrlForInvoice = async (token: string): Promise<CheckoutRedirectUrlForInvoiceResponse> => {
  clearOrderCode();
  clearInvoiceToken();

  const getCheckoutRedirectUrlForInvoiceRequest = async (): Promise<CheckoutRedirectUrlForInvoiceResponse> => {
    const response: AxiosResponse = await axios.get(`${settings.url}/public/patient/pay-by-invoice/${token}`);

    const {
      data: {
        checkoutRedirectUrl,
        orderCode: responseOrderCode,
        isPaid,
        paidOrderAttributes,
        userStatus,
        isUnavailable
      }
    } = response.data;

    if (isUnavailable) {
      return {
        userStatus,
        isUnavailable
      };
    }

    if ((!isPaid && !checkoutRedirectUrl) || !responseOrderCode) {
      return { status: 500, error: 'Internal error', userStatus };
    }

    setOrderCode(responseOrderCode);
    setInvoiceToken(token);

    return {
      status: 200,
      checkoutRedirectUrl,
      orderCode: responseOrderCode,
      isPaid,
      paidOrderAttributes,
      userStatus,
      isUnavailable
    };
  };

  return doRequestWithErrorHandling(getCheckoutRedirectUrlForInvoiceRequest);
};

const checkPaymentStatus = async (orderCode: string): Promise<PaymentStatusResponse> => {
  const checkPaymentStatusRequest = async (): Promise<PaymentStatusResponse> => {
    const {
      data: { data }
    }: AxiosResponse = await axios.get(`${settings.url}/order/payment/status/${orderCode}`);

    return {
      status: data.status,
      isPaid: data.isPaid,
      transactionType: data.transactionType,
      ...(data.OrderManagementShopify?.shopify_order_confirmation_number
        ? { bankTransferReference: data.OrderManagementShopify?.shopify_order_confirmation_number }
        : {})
    };
  };

  return doRequestWithErrorHandling(checkPaymentStatusRequest);
};

const checkPaymentStatusForInvoice = async (
  token: string,
  orderCode: string
): Promise<PaymentStatusInvoiceResponse> => {
  const checkPaymentStatusForInvoiceRequest = async (): Promise<PaymentStatusInvoiceResponse> => {
    const {
      data: { data }
    }: AxiosResponse = await axios.post(`${settings.url}/public/order/payment/status`, {
      token,
      orderCode
    });

    return {
      status: data.status,
      isPaid: data.isPaid,
      transactionType: data.transactionType,
      ...(data.OrderManagementShopify?.shopify_order_confirmation_number
        ? { bankTransferReference: data.OrderManagementShopify?.shopify_order_confirmation_number }
        : {}),
      user: data.user,
      onBoardKey: data.onBoardKey
    };
  };

  return doRequestWithErrorHandling(checkPaymentStatusForInvoiceRequest);
};

// eslint-disable-next-line import/prefer-default-export
export const PaymentService = {
  clearOrderCode,
  clearInvoiceToken,
  getOrderCode,
  getInvoiceToken,
  getCheckoutRedirectUrl,
  getCheckoutRedirectUrlForInvoice,
  checkPaymentStatus,
  checkPaymentStatusForInvoice
};
