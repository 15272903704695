/* eslint-disable no-undefined */
import { ToastConfirmModal, toastOptions } from '@/components/common/toastConfirm';
import settings from '@/constants/constants';
import { AuthService } from '@/services/authentication.service';
import { getCountries, getStates, GPUpdate } from '@/services/data.service';
import { OrderService } from '@/services/order.service';
import { getProducts } from '@/services/product.service';
import { Logger } from '@/utils/logger';
import withRouter from '@/utils/withRouter';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import { toast } from '@montugroup/design-system';
import axios from 'axios';
import moment from 'moment';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import FormInput from '../common/FormInput';
import Button from '../common/button';

const logger = new Logger('gpProfile');
const url = settings.url;

class Register extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clinic1: false,
      clinic2: false,
      loading: true,
      id: this.props.params.id,
      medical_liasion: {},
      ml_available: [],
      user: {},
      gp_data: {},
      defaultState: { value: '', label: 'Select' },
      states: [[], []],
      defaultCountry: { value: 'australia', label: 'Australia', id: 13 },
      countries: [],
      data: [],
      isAP: false,
      downloading: false,
      productsList: [],
      productsSelected: [],
      existingProducts: [],
      APForm: { apIngredients: [], apProducts: [] }
    };
    this.validator = new SimpleReactValidator();
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  addressSplitter = (address) => {
    let full_address = address ? address.split('$$$$') : [];
    return {
      address_split_0: full_address ? full_address[0] : '',
      address_split_1: full_address.length > 1 ? full_address[1] : ''
    };
  };

  async componentDidMount() {
    document.title = 'Doctor Profile - Montu Group | Making Medical Cannabis Accessible';
    await axios.get(url + '/ap/' + this.props.params.id).then((data) => this.setState({ APForm: data.data }));
    await axios.get(url + '/clinic/gp/view/edit/' + this.props.params.id).then(async (data) => {
      let ml = data.data.medical_liasion[0] !== undefined ? data.data.medical_liasion[0] : {};
      this.setState({
        medical_liasion: { label: ml.first_name, value: ml.id },
        gp_data: data.data.gp_data,
        user: data.data.user,
        ml_available: data.data.ml_available,
        loading: false,
        isAP: Boolean(data.data.gp_data.is_authorised_prescriber),
        clinic1: data.data.clinics[0] !== undefined ? data.data.clinics[0] : false,
        clinic2: data.data.clinics[1] !== undefined ? data.data.clinics[1] : false
      });
      this.setState((prevState) => ({
        clinic1: {
          ...prevState.clinic1,
          ...this.addressSplitter(prevState.clinic1.address),
          clinic_priority: 0
        }
      }));
      if (this.state.clinic2) {
        this.setState((prevState) => ({
          clinic2: {
            ...prevState.clinic2,
            ...this.addressSplitter(prevState.clinic2.address),
            clinic_priority: 1
          }
        }));
      }
    });
    const { defaultCountry } = this.state;
    const countryList = await getCountries();
    const productResult = await OrderService.getAllProducts();
    const selProductsRes = await getProducts(this.props.params.id);
    const stateList = await getStates(defaultCountry.id);
    const allProducts = productResult.data.map((pr) => ({ label: pr.name, value: pr.id, sku_code: pr.sku_code }));
    const allowedProducts = selProductsRes.data.map((pr) => ({
      label: pr.Product.name,
      value: pr.product_id,
      sku_code: pr.Product.sku_code
    }));
    this.setState({
      countries: countryList,
      productsSelected: allowedProducts,
      productsList: allProducts,
      existingProducts: allowedProducts,
      states: [stateList, stateList]
    });
  }

  handleUserInput = (e) => {
    let el = e.target;
    this.setState((prevState) => ({
      [el.attributes.from.value]: {
        ...prevState[el.attributes.from.value],
        [el.name]: el.value
      }
    }));
  };

  handleCountry = async (e) => {
    let { states } = this.state;
    states[e.sl] = await getStates(e.value);
    if (e.sl === 0) {
      this.setState((prevState) => ({
        clinic1: {
          ...prevState.clinic1,
          country: e.label,
          country_id: e.value
        },
        states: states
      }));
    } else {
      this.setState((prevState) => ({
        clinic2: {
          ...prevState.clinic2,
          country: e.label,
          country_id: e.value
        },
        states: states
      }));
    }
  };

  handleState = async (e) => {
    if (e.sl === 0) {
      this.setState((prevState) => ({
        clinic1: {
          ...prevState.clinic1,
          state: e.label,
          state_id: e.value
        }
      }));
    } else {
      this.setState((prevState) => ({
        clinic2: {
          ...prevState.clinic2,
          state: e.label,
          state_id: e.value
        }
      }));
    }
  };

  handleClose = async () => {
    this.setState({
      clinic2: undefined
    });
  };

  handleMedicalLiasion = (e) => {
    this.setState((prevState) => ({
      medical_liasion: {
        ...prevState.medical_liasion,
        value: e.value,
        label: e.label
      }
    }));
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ loading: true });
    let { user, medical_liasion, gp_data, clinic1, clinic2, isAP, productsSelected } = this.state,
      clinics = [clinic1, clinic2],
      index;
    for (index in clinics) {
      clinics[index] = {
        ...clinics[index],
        address: clinics[index].address_split_0 + '$$$$' + clinics[index].address_split_1
      };
    }
    await GPUpdate({
      user,
      medical_liasion,
      gp_data,
      clinics,
      isAP,
      products: productsSelected,
      existingProducts: this.state.existingProducts
    });
    toast.success('GP updated Successfully!');
    if (AuthService.isAdmin()) {
      this.props.navigate('/doctors');
    }
  };
  addClinic = async () => {
    this.profileValidator = new SimpleReactValidator();
    this.setState({
      clinic2: {
        clinic_priority: 1
      }
    });
  };
  handleCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.checked
    });
  };

  handleProductChange = (selected) => {
    this.setState({
      productsSelected: selected
    });
  };

  downloadFile = async (e, apDocument, oldFormat = false) => {
    e.preventDefault();
    if (!apDocument.id) {
      toast.error('AP document could not be downloaded.');
      logger.error('AP document could not be downloaded. no apDocument.id');
    }
    this.setState({
      downloading: true
    });
    await axios({
      url: 'ap/document/download',
      method: 'GET',
      params: {
        apId: apDocument.id,
        oldFormat
      },
      responseType: 'blob'
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', apDocument.ap_document_path);
      document.body.appendChild(link);
      link.click();
    });
    this.setState({
      downloading: false
    });
  };

  deletefile = async (e) => {
    e.preventDefault();
    const id = e.target.dataset.key;
    const approvalType = e.target.dataset.old ? 'old=true' : '';
    toast(
      <ToastConfirmModal
        onConfirm={async () =>
          await axios.delete(url + `/ap/${id}?${approvalType}`).then((response) => {
            if (response.data.status === 200) {
              toast.success('AP removed successfully');
              this.props.navigate('/doctors');
            } else {
              toast.success('AP could not be removed');
            }
            this.forceUpdate();
          })
        }
      >
        <p>Are you sure you want to delete this?</p>
      </ToastConfirmModal>,
      toastOptions
    );
  };

  render() {
    const {
      loading,
      states,
      user,
      ml_available,
      defaultState,
      id,
      gp_data,
      clinic1,
      clinic2,
      countries,
      defaultCountry,
      medical_liasion,
      productsList,
      productsSelected,
      downloading,
      APForm
    } = this.state;
    let CountryOp = [
      countries.map((country) => {
        return { label: country.name, value: country.id, sl: 0 };
      }),
      countries.map((country) => {
        return { label: country.name, value: country.id, sl: 1 };
      })
    ];
    let StateOp = [
      states[0].map((state) => {
        return { label: state.name, value: state.id, sl: 0 };
      }),
      states[1].map((state) => {
        return { label: state.name, value: state.id, sl: 1 };
      })
    ];
    let MLOp = ml_available.map((ml) => {
      return { label: ml.ml_name, value: ml.id };
    });
    return (
      <div className="container">
        <form>
          <fieldset>
            <div>
              <h3 className="text-uppercase mx-autop-2 mt-4">Doctor details</h3>
              <div className="text-left">
                <label>
                  <b>Email:</b> {user.email}
                </label>
              </div>
              <div className="form-row">
                <FormInput
                  type="text"
                  name="first_name"
                  value={user.first_name}
                  from="user"
                  className="col-md-6"
                  label="First Name"
                  defaultValue={user.first_name}
                  error={this.validator.message('Name', user.first_name, 'required')}
                  onChange={this.handleUserInput}
                />
                <FormInput
                  type="text"
                  name="last_name"
                  value={user.last_name}
                  from="user"
                  className="col-md-6"
                  defaultValue={user.last_name}
                  error={this.validator.message('Name', user.last_name, 'required')}
                  label="Last Name"
                  onChange={this.handleUserInput}
                />
              </div>
              <div className="form-row">
                <FormInput
                  type="text"
                  className="col-md-6"
                  label="Prescriber AHPRA Number"
                  name="ahpra_no"
                  from="gp_data"
                  defaultValue={gp_data.ahpra_no}
                  value={gp_data.ahpra_no}
                  onChange={this.handleUserInput}
                  error={this.validator.message(
                    'ahpra number',
                    gp_data.ahpra_no,
                    'required|regex:^[A-Za-z]{3}\\d{10}$'
                  )}
                />
                <FormInput
                  type="text"
                  placeholder="eg.0343244556"
                  name="phone"
                  className="col-md-6"
                  label="Prescriber Phone number"
                  value={user.phone}
                  from="user"
                  error={this.validator.message('phone number', user.phone, 'required|regex:^\\d{10}$')}
                  onChange={this.handleUserInput}
                />
              </div>
              <div className="row">
                <div className="col-12">
                  <label>Business Development Manager</label>
                  <Select
                    name="medicalLiasion"
                    className={`${
                      this.validator.message('MedicalLiasion', medical_liasion, 'required')
                        ? 'rounded dropdown-border'
                        : ''
                    }`}
                    options={MLOp}
                    value={medical_liasion}
                    onChange={this.handleMedicalLiasion}
                    isSearchable={true}
                    placeholder="Select BDM"
                  />
                  {this.validator.message('MedicalLiasion', medical_liasion, 'required')}
                </div>
              </div>
              <div className="mt-3 doctor-products-list">
                <label>Product Selection</label>
                <Select
                  isMulti
                  name="colors"
                  options={productsList}
                  onChange={this.handleProductChange}
                  value={productsSelected}
                />
              </div>
              <div className="mt-3">
                <h3>Primary Clinic</h3>
                <div className="form-row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <FormInput
                        type="text"
                        name="name"
                        label="Clinic Name"
                        from="clinic1"
                        clinic_type="primary"
                        value={clinic1.name}
                        onChange={this.handleUserInput}
                        error={this.validator.message('name', clinic1.name, 'required')}
                      />
                    </div>
                    <div className="form-row">
                      <FormInput
                        type="email"
                        name="email"
                        className="col-md-6"
                        clinic_type="primary"
                        label="Clinic Email"
                        from="clinic1"
                        value={clinic1.email}
                        onChange={this.handleUserInput}
                        error={this.validator.message('email', clinic1.email, 'required|email')}
                      />
                      <FormInput
                        type="text"
                        name="phone"
                        placeholder="eg.0343244556"
                        clinic_type="primary"
                        className="col-md-6"
                        from="clinic1"
                        label="Clinic Phone Number"
                        value={clinic1.phone}
                        onChange={this.handleUserInput}
                        error={this.validator.message('phone-number', clinic1.phone, 'required|regex:^\\d{10}$')}
                      />
                    </div>
                    <div className="form-group">
                      <label>Clinic Address</label>
                      <FormInput
                        type="text"
                        name="address_split_0"
                        info="Street Address"
                        clinic_type="primary"
                        from="clinic1"
                        value={clinic1.address_split_0}
                        onChange={this.handleUserInput}
                        error={this.validator.message('address', clinic1.address_split_0, 'required')}
                      />
                      <div className="form-group ">
                        <FormInput
                          type="text"
                          name="address_split_1"
                          info="Address Line2"
                          clinic_type="primary"
                          from="clinic1"
                          value={clinic1.address_split_1}
                          onChange={this.handleUserInput}
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 my-auto">
                          <FormInput
                            type="text"
                            name="city"
                            value={clinic1.city}
                            info="City"
                            from="clinic1"
                            clinic_type="primary"
                            onChange={this.handleUserInput}
                            error={this.validator.message('City', clinic1.city, 'required')}
                          />
                        </div>
                        <div className="form-group col-md-6 my-auto">
                          <Select
                            name="state"
                            className={`${
                              this.validator.message(
                                'State',
                                {
                                  id: clinic1.state_id,
                                  label: clinic1.state
                                } || defaultState,
                                'required'
                              )
                                ? 'rounded dropdown-border'
                                : ''
                            }`}
                            value={{ id: clinic1.state_id, label: clinic1.state } || defaultState}
                            onChange={this.handleState}
                            isSearchable={true}
                            placeholder="Select State"
                            options={StateOp[0]}
                            clinic_type="primary"
                          />
                          {this.validator.message(
                            'State',
                            { id: clinic1.state_id, label: clinic1.state } || defaultState,
                            'required'
                          )}
                          <small className="form-text text-muted ml-1">State / Province / Region</small>
                        </div>
                      </div>
                      <div className="form-row ">
                        <div className="form-group col-md-6 my-auto">
                          <FormInput
                            type="text"
                            name="zip_code"
                            value={clinic1.zip_code}
                            info="Postcode"
                            from="clinic1"
                            clinic_type="primary"
                            onChange={this.handleUserInput}
                            error={this.validator.message('zip-code', clinic1.zip_code, 'required')}
                          />
                        </div>
                        <div className="form-group col-md-6 my-auto">
                          <Select
                            name="country"
                            value={
                              {
                                id: clinic1.country_id,
                                label: clinic1.country
                              } || defaultCountry
                            }
                            onChange={this.handleCountry}
                            isSearchable
                            options={CountryOp[0]}
                            clinic_type="primary"
                          />
                          {this.validator.message(
                            'Country',
                            { id: clinic1.country_id, label: clinic1.country },
                            'required'
                          )}
                          <small className="form-text text-muted ml-1 text-left">Country</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {clinic2 ? (
                <div className=" mt-5">
                  <Button text="x" className="btn btn-primary btn-md float-right" onClick={this.handleClose} />
                  <h3 className="text-left">Secondary Clinic</h3>
                  <div className="form-row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <FormInput
                          type="text"
                          name="name"
                          clinic_type="secondary"
                          value={clinic2.name || ''}
                          from="clinic2"
                          label="Clinic Name"
                          onChange={this.handleUserInput}
                          error={this.validator.message('name', clinic2.name, 'required')}
                        />
                      </div>
                      <div className="form-row">
                        <FormInput
                          type="email"
                          name="email"
                          from="clinic2"
                          clinic_type="secondary"
                          value={clinic2.email || ''}
                          className="col-md-6"
                          label="Clinic Email"
                          onChange={this.handleUserInput}
                          error={this.validator.message('email', clinic2.email, 'required|email')}
                        />
                        <FormInput
                          type="text"
                          name="phone"
                          from="clinic2"
                          clinic_type="secondary"
                          placeholder="eg.0343244556"
                          value={clinic2.phone || ''}
                          className="col-md-6"
                          label="Clinic Phone Number"
                          onChange={this.handleUserInput}
                          error={this.validator.message('phone-number', clinic2.phone, 'required|regex:^\\d{10}$')}
                        />
                      </div>
                      <div className="form-group">
                        <FormInput
                          type="text"
                          name="address_split_0"
                          from="clinic2"
                          clinic_type="secondary"
                          value={clinic2.address_split_0 || ''}
                          label="Clinic Address"
                          info="Street Address"
                          onChange={this.handleUserInput}
                          error={this.validator.message('address', clinic2.address_split_0, 'required')}
                        />
                      </div>
                      <div className="form-group">
                        <FormInput
                          type="text"
                          name="address_split_1"
                          from="clinic2"
                          clinic_type="secondary"
                          value={clinic2.address_split_1 || ''}
                          placeholder=""
                          info="Address Line 2"
                          onChange={this.handleUserInput}
                        />
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 my-auto">
                          <FormInput
                            type="text"
                            name="city"
                            from="clinic2"
                            value={clinic2.city || ''}
                            clinic_type="secondary"
                            info="City"
                            onChange={this.handleUserInput}
                            error={this.validator.message('City', clinic2.city, 'required')}
                          />
                        </div>
                        <div className="form-group col-md-6 my-auto">
                          <Select
                            name="state"
                            className={`${
                              this.validator.message(
                                'State',
                                {
                                  id: clinic2.state_id,
                                  label: clinic2.state
                                } || defaultState,
                                'required'
                              )
                                ? 'rounded dropdown-border'
                                : ''
                            }`}
                            value={{ id: clinic2.state_id, label: clinic2.state } || defaultState}
                            onChange={this.handleState}
                            isSearchable={true}
                            placeholder="Select State"
                            options={StateOp[1]}
                            clinic_type="secondary"
                          />
                          <small className="form-text text-muted ml-1">State / Province / Region</small>
                          {this.validator.message(
                            'State',
                            { id: clinic2.state_id, label: clinic2.state } || defaultState,
                            'required'
                          )}
                        </div>
                      </div>
                      <div className="form-row">
                        <div className="form-group col-md-6 my-auto">
                          <FormInput
                            type="text"
                            name="zip_code"
                            clinic_type="secondary"
                            value={clinic2.zip_code || ''}
                            info="Postcode"
                            from="clinic2"
                            onChange={this.handleUserInput}
                            error={this.validator.message('zip-code', clinic2.zip_code, 'required')}
                          />
                        </div>
                        <div className="form-group col-md-6 my-auto">
                          <Select
                            name="country1"
                            value={
                              {
                                id: clinic2.country_id,
                                label: clinic2.country
                              } || defaultCountry
                            }
                            onChange={this.handleCountry}
                            isSearchable
                            options={CountryOp[1]}
                            clinic_type="secondary"
                          />
                          {this.validator.message(
                            'Country',
                            {
                              id: clinic2.country_id,
                              label: clinic2.country
                            } || defaultCountry,
                            'required'
                          )}
                          <small className="form-text text-muted ml-1">Country</small>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  <h5 className="float-left ml-1  mt-2 add-option" onClick={this.addClinic}>
                    + Add Secondary Clinic
                  </h5>
                </div>
              )}
            </div>

            {this.props.params.id && (
              <React.Fragment>
                <p className="mt-5">AP Product Approvals </p>
                {APForm.apProducts.length > 0 && (
                  <React.Fragment>
                    <table className="table table-bordered" key="0">
                      <thead>
                        <tr>
                          <th>Product</th>
                          <th>AP Document</th>
                          <th>AP MAP Number</th>
                          <th>Doctor</th>
                          <th>Uploaded Date</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Uploaded By</th>
                          {AuthService.isAdmin() && <th>Delete</th>}
                        </tr>
                      </thead>
                      {APForm.apProducts.map((apObj) => (
                        <tbody key={apObj.id}>
                          <tr>
                            <td>{apObj.Product.name}</td>
                            <td>
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '200px'
                                }}
                              >
                                <a
                                  href="/#"
                                  onClick={(e) => this.downloadFile(e, apObj, true)}
                                  disabled={downloading}
                                  data-key={apObj.ap_document_path}
                                >
                                  {apObj.ap_document_path}
                                </a>
                              </div>
                            </td>
                            <td>{apObj.ap_map_number}</td>
                            <td>
                              {apObj.GeneralPractitioner.Doctor?.first_name +
                                ' ' +
                                apObj.GeneralPractitioner.Doctor?.last_name}{' '}
                            </td>
                            <td>{moment(apObj.upload_date).format('DD-MMM-YYYY')}</td>
                            <td>{moment(apObj.start_date).format('DD-MMM-YYYY')}</td>
                            <td>{moment(apObj.end_date).format('DD-MMM-YYYY')}</td>
                            <td>{apObj.User?.first_name + ' ' + apObj.User?.last_name}</td>
                            {AuthService.isAdmin() && (
                              <td>
                                <button
                                  className="btn btn-primary btn-md d-block font-weight-bold"
                                  onClick={this.deletefile}
                                  disabled={downloading}
                                  data-key={apObj.id}
                                  data-old={true}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        </tbody>
                      ))}
                    </table>
                    <React.Fragment>
                      {downloading ? (
                        <small className="text-muted">Please wait while the file is downloading...</small>
                      ) : (
                        <small className="text-muted">Click the links to download the approval file</small>
                      )}
                    </React.Fragment>
                  </React.Fragment>
                )}
                <p className="mt-5">AP Category/Formulation Approvals </p>
                {APForm.apIngredients.length > 0 ? (
                  <React.Fragment>
                    <table className="table table-bordered" key="0">
                      <thead>
                        <tr>
                          <th>Category</th>
                          <th>Formulation</th>
                          <th width="100">AP Document</th>
                          <th>AP MAP Number</th>
                          <th>Doctor</th>
                          <th>Uploaded Date</th>
                          <th>Start Date</th>
                          <th>End Date</th>
                          <th>Uploaded By</th>
                          {AuthService.isAdmin() && <th>Delete</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {APForm.apIngredients.map((apObj) => (
                          <tr key={apObj.id}>
                            <td>{apObj.ProductIngredient.name}</td>
                            <td>{apObj.ProductFormulation.name}</td>
                            <td>
                              <div
                                style={{
                                  whiteSpace: 'nowrap',
                                  textOverflow: 'ellipsis',
                                  overflow: 'hidden',
                                  width: '200px'
                                }}
                              >
                                <a
                                  href="/#"
                                  onClick={(e) => this.downloadFile(e, apObj)}
                                  disabled={downloading}
                                  data-key={apObj.ap_document_path}
                                >
                                  {apObj.ap_document_path}
                                </a>
                              </div>
                            </td>
                            <td>{apObj.ap_map_number}</td>
                            <td>
                              {apObj.GeneralPractitioner.Doctor?.first_name +
                                ' ' +
                                apObj.GeneralPractitioner.Doctor?.last_name}
                            </td>
                            <td>{moment(apObj.upload_date).format('DD-MMM-YYYY')}</td>
                            <td>{moment(apObj.start_date).format('DD-MMM-YYYY')}</td>
                            <td>{moment(apObj.end_date).format('DD-MMM-YYYY')}</td>
                            <td>{apObj.User?.first_name + ' ' + apObj.User?.last_name}</td>
                            {AuthService.isAdmin() && (
                              <td>
                                <button
                                  className="btn btn-primary btn-md d-block font-weight-bold"
                                  onClick={this.deletefile}
                                  disabled={downloading}
                                  data-key={apObj.id}
                                >
                                  Delete
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <React.Fragment>
                      {downloading ? (
                        <small className="text-muted">Please wait while the file is downloading...</small>
                      ) : (
                        <small className="text-muted">Click the links to download the approval file</small>
                      )}
                    </React.Fragment>
                  </React.Fragment>
                ) : (
                  <div className="mb-1 d-flex flex-wrap d-inline-block">
                    <div className="m-1 p-2 sas-border">
                      <p className="text-gray">Approval Pending</p>
                    </div>
                  </div>
                )}
              </React.Fragment>
            )}
            {this.props.params.id ? (
              <React.Fragment>
                <div>
                  <small>
                    <Link to={`/ap-approval/${this.props.params.id}`}>Upload approval files</Link>
                  </small>
                </div>
              </React.Fragment>
            ) : null}
            <div>
              <Button
                text={this.props.params.id ? 'Update' : 'Register'}
                type="submit"
                className="btn btn-primary p-2 float-right"
                loading={loading}
                onClick={this.handleSubmit}
              />
            </div>
          </fieldset>
        </form>
      </div>
    );
  }
}

export default withRouter(Register);
