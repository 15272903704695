function EwayIcon({ size = 24, color = 'currentColor' }) {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 122 121"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      data-testid="eway-icon"
    >
      <g transform="translate(0.000000,121.000000) scale(0.100000,-0.100000)" fill={color}>
        <path d="M491 1064 c-80 -21 -149 -62 -211 -124 -186 -186 -186 -474 0 -660 186 -186 474 -186 660 0 186 186 186 474 0 660 -120 120 -290 167 -449 124z m249 -98 c94 -48 129 -122 137 -293 l6 -113 -213 0 -213 0 6 -40 c7 -53 48 -105 94 -121 47 -15 151 -7 226 19 l57 19 0 -68 0 -68 -57 -18 c-81 -25 -241 -25 -305 0 -62 24 -120 90 -144 164 -27 81 -27 274 0 355 31 94 94 155 183 178 58 14 182 6 223 -14z" />
        <path d="M541 843 c-46 -24 -69 -58 -80 -115 l-8 -48 143 0 c161 0 151 -6 133 78 -6 29 -20 59 -34 72 -33 31 -108 37 -154 13z" />
      </g>
    </svg>
  );
}

export default EwayIcon;
