import { useEffect, useMemo } from 'react';

type Data = Record<string, string | undefined>;

function getMetadata(name: string): [string | undefined, Element | null] {
  if (!document) {
    return [undefined, null];
  }
  const isOg = name.startsWith('og:');
  const metaTag = document?.querySelector(`meta[${isOg ? 'property' : 'name'}="${name}"]`);

  if (metaTag) {
    const metaContent = metaTag?.getAttribute('content') || '';
    return [metaContent, metaTag];
  }
  return [undefined, null];
}

function setMetadata(name: string, content: string) {
  const [, metaTag] = getMetadata(name);

  if (metaTag) {
    metaTag.setAttribute('content', content);
  } else {
    const isOg = name.startsWith('og:');
    const newMetaTag = document.createElement('meta');
    newMetaTag.setAttribute(isOg ? 'property' : 'name', name);
    newMetaTag.setAttribute('content', content);
    document.head.appendChild(newMetaTag);
  }
}

const usePageMetadata = (data: Data) => {
  const { title, ...metadata } = data;
  const metadataProvided = metadata && Object.keys(metadata)?.length > 0;

  // save initial title value
  const prevTitle = useMemo(() => document?.title, []);

  // save initial metadata values
  const prevMetadata: Data = useMemo(() => {
    if (!metadataProvided) {
      return {};
    }
    const prevMetadata: Data = {};
    for (const [name] of Object.entries(metadata)) {
      const [prevContent] = getMetadata(name);
      prevMetadata[name] = prevContent;
    }
    return prevMetadata;
  }, []);

  // update title and handle revert after unmount
  useEffect(() => {
    if (document && title) {
      document.title = title;
    }

    return () => {
      document.title = prevTitle;
    };
  }, [title]);

  // update/create meta tags
  useEffect(() => {
    if (metadataProvided) {
      for (const [name, content] of Object.entries(metadata)) {
        if (content) {
          setMetadata(name, content);
        }
      }
    }
  }, [metadata]);

  // revert/remove meta tags after unmount
  useEffect(() => {
    return () => {
      for (const [name, prevContent] of Object.entries(prevMetadata)) {
        if (prevContent !== undefined) {
          setMetadata(name, prevContent);
        } else {
          const [, metaTag] = getMetadata(name);
          if (metaTag) {
            metaTag.remove();
          }
        }
      }
    };
  }, []);
};

export default usePageMetadata;
