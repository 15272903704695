import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

import tagImage from '../../assets/images/product_tag_concession.png';

export type ConcessionProductCatalogueTagProps = Omit<BoxProps, 'component' | 'src' | 'alt'>;

export function ConcessionProductCatalogueTag({ ...rest }: ConcessionProductCatalogueTagProps) {
  return <Box component="img" src={tagImage} alt="Concession" {...rest} />;
}

export default ConcessionProductCatalogueTag;
