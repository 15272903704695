/* eslint-disable no-undefined */
/* eslint-disable class-methods-use-this */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/prefer-default-export */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */

import type { StatusType } from '@datadog/browser-logs';
import { datadogLogs } from '@datadog/browser-logs';
import type { Debugger } from 'debug';
import debug from 'debug';

import settings from '@/constants/constants';

/*
  Usage:-
    logs will show in development only, in production logs will be disabled
    to turn on in production set localStorage.debug = 'MG*';

  import { Logger } from 'utils/logger';
  const logger = new Logger('MyComponent');
  ...
  ...
  logger.info(`hello world ${someValue}`);

*/

const NAMESPACE = 'MG';
const ENABLE_DDOG = process.env.NODE_ENV !== 'development';

if (process.env.NODE_ENV === 'development') {
  debug.enable(`${NAMESPACE}*`);
}

if (ENABLE_DDOG && settings.datadog) {
  datadogLogs.init({
    clientToken: settings.datadog.clientToken ?? '',
    site: settings.datadog.site,
    // Testing this off, so we only get our logged errors
    // forwardErrorsToLogs: true,
    sessionSampleRate: settings.datadog.sessionSampleRate,
    env: settings.datadog.env,
    silentMultipleInit: true,
    service: 'circuit-frontend'
  });
}

export class Logger {
  _debug: Debugger;

  _info: Debugger;

  _warn: Debugger;

  _error: Debugger;

  prefix?: string;

  enableDDogBrowserLogs: boolean;

  static getUserIdFunc: (() => string | undefined) | undefined;

  constructor(prefix?: string | undefined) {
    if (prefix) {
      this._debug = debug(`${NAMESPACE}:${prefix}`);
      this._info = debug(`${NAMESPACE}:INFO:${prefix}`);
      this._warn = debug(`${NAMESPACE}:WARN:${prefix}`);
      this._error = debug(`${NAMESPACE}:ERROR:${prefix}`);
    } else {
      this._debug = debug(`${NAMESPACE}`);
      this._info = debug(`${NAMESPACE}:INFO`);
      this._warn = debug(`${NAMESPACE}:WARN`);
      this._error = debug(`${NAMESPACE}:ERROR`);
    }

    this.enableDDogBrowserLogs = process.env.NODE_ENV !== 'development';

    this.prefix = prefix;

    this._debug.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.debug.apply(console, arguments as any);
    };
    this._info.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.info.apply(console, arguments as any);
    };
    this._warn.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.warn.apply(console, arguments as any);
    };
    this._error.log = function () {
      // eslint-disable-next-line prefer-spread, prefer-rest-params, no-console
      console.error.apply(console, arguments as any);
    };
  }

  debug(...args: any[]) {
    this.wrapLog(this._debug, 'debug', ...args);
  }

  info(...args: any[]) {
    this.wrapLog(this._info, 'info', ...args);
  }

  warn(...args: any[]) {
    this.wrapLog(this._warn, 'warn', ...args);
  }

  error(...args: any[]) {
    this.wrapLog(this._error, 'error', ...args);
  }

  static getUserId(): string | undefined {
    if (Logger.getUserIdFunc) {
      return Logger.getUserIdFunc();
    }
    return undefined;
  }

  sendToDatadog(logLevel: string, ...args: any[]): void {
    try {
      const first = args?.[0];

      const firstMsg = typeof first === 'string' || first instanceof String ? (first as string) : JSON.stringify(first);

      const msg = [NAMESPACE];

      if (this.prefix) {
        msg.push(this.prefix);
      }

      msg.push(firstMsg);

      const messageContext = {
        args,
        user_id: Logger.getUserId() || 'logged_out'
      };

      // find the first error in the args, and send this to ddog as an 'error'
      const err = args.find((x) => x instanceof Error);

      datadogLogs.logger.log(msg.join(':'), messageContext, logLevel as StatusType, err);
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error('failed to send to datadog: ', err);
    }
  }

  wrapLog = (logFunc: any, logLevel: string, ...args: any[]) => {
    logFunc(...args);
    if (ENABLE_DDOG && logLevel !== 'debug') {
      this.sendToDatadog(logLevel, ...args);
    }
  };
}
