import React from 'react';

interface ExtraProps {
  icon?: string;
  iconPosition?: 'left' | 'right';
  text: string;
  loading?: boolean;
}

type IconButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & ExtraProps;

function IconButton(props: IconButtonProps) {
  const { type = 'submit', loading, disabled, icon, iconPosition = 'left', text, ...otherProps } = props;

  if (type === 'button') {
    return (
      <button type="button" {...otherProps} disabled={loading || disabled}>
        {icon && iconPosition === 'left' ? <i className={`${icon} mr-2`} aria-hidden="true" /> : null}
        {loading ? <div className="lds-dual-ring" /> : text}
        {icon && iconPosition === 'right' ? <i className={`${icon} ml-2`} aria-hidden="true" /> : null}
      </button>
    );
  }

  return (
    <button type="submit" {...otherProps} disabled={loading || disabled}>
      {icon && iconPosition === 'left' ? <i className={`${icon} mr-2`} aria-hidden="true" /> : null}
      {loading ? <div className="lds-dual-ring" /> : text}
      {icon && iconPosition === 'right' ? <i className={`${icon} ml-2`} aria-hidden="true" /> : null}
    </button>
  );
}

export default IconButton;
