import { useQuery } from '@tanstack/react-query';

import { getPrescriptionsWithPrescribedProducts } from './api';

export function useQueryPrescriptions() {
  return useQuery({
    queryKey: ['medicine-information'],
    queryFn: async () => {
      const { data } = await getPrescriptionsWithPrescribedProducts();
      return data;
    }
  });
}

export default useQueryPrescriptions;
