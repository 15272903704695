import settings from '@/constants/constants';
import type { TableSortOrder } from '@/types';
import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

export type UsePharmacyReportParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  date?: Date;
};

const url = settings.url;

function useGetPharmacyReport({ page, pageSize, sortingOrder, date }: UsePharmacyReportParams) {
  const { isLoading, data, isError } = useQuery({
    queryKey: ['viewPharmacyReport', page, pageSize, sortingOrder, date],
    queryFn: async () => {
      try {
        const response = await axios.get(`${url}/order/pharmacy/report`, {
          params: { page, pageSize, sortingOrder, date }
        });

        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch pharmacy update history: ${(e as { message: string }).message}`);
      }
    }
  });
  return { data, isLoading, isError };
}
export default useGetPharmacyReport;
