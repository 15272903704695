import { Box, Card, CardContent, CardMedia, Typography } from '@mui/material';

import FormulationChip from '@/components/products/components/FormulationChip/FormulationChip';
import StrainChip from '@/components/products/components/StrainChip/StrainChip';
import type { ProductFormulation, ProductStrain, ProductStrainKey } from '@/types';

import ConcessionChip from '../ConcessionChip/ConcessionChip';

export interface ProductTileProps {
  name: string;
  imageUrl: string;
  thcStrength?: string;
  cbdStrength?: string;
  formulation?: ProductFormulation;
  strainName?: ProductStrain;
  strainKey?: ProductStrainKey;
  isConcession?: boolean;
}

function ProductTile(props: ProductTileProps) {
  const {
    name: displayName,
    imageUrl,
    formulation,
    strainName,
    strainKey,
    thcStrength,
    cbdStrength,
    isConcession
  } = props;

  return (
    <Card
      variant="outlined"
      sx={{
        display: 'flex',
        flexDirection: { xs: 'row', sm: 'column' },
        alignItems: 'left',
        minHeight: { xs: 128, sm: 283 }
      }}
    >
      <Box
        sx={{
          pt: { xs: 1, sm: 4 },
          pb: { xs: 1, sm: 0 },
          px: { xs: 0, sm: 8 },
          width: { xs: '30%', sm: '100%' },
          height: { xs: 'auto', sm: 132 }
        }}
      >
        <CardMedia
          component="img"
          src={imageUrl}
          alt={`${displayName} preview`}
          sx={{
            width: '100%',
            height: '100%',
            objectFit: 'contain'
          }}
        />
      </Box>
      <CardContent
        sx={{
          pt: { sm: 2 },
          display: 'flex',
          flexDirection: 'column',
          width: { xs: '70%', sm: '100%' },
          gap: 2,
          justifyContent: 'center'
        }}
      >
        <Box
          display="flex"
          gap={2}
          flexWrap="wrap"
          sx={{
            order: { xs: 2, sm: 1 }
          }}
        >
          {formulation ? <FormulationChip formulation={formulation} size="small" /> : null}
          {strainName && strainKey ? <StrainChip strainName={strainName} strainKey={strainKey} size="small" /> : null}
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          sx={{
            order: { xs: 1, sm: 2 }
          }}
        >
          <Typography variant="body1" fontWeight="bold" flexWrap="wrap">
            {displayName}
          </Typography>
          <Typography variant="body2" display="flex" flexWrap="wrap">
            {Boolean(thcStrength) && (
              <>
                <Typography variant="body2" component="span" fontWeight="bold">
                  THC:
                </Typography>
                &nbsp;{thcStrength}
              </>
            )}
            {Boolean(thcStrength && cbdStrength) && <>&nbsp;|&nbsp;</>}
            {Boolean(cbdStrength) && (
              <>
                <Typography variant="body2" component="span" fontWeight="bold">
                  CBD:
                </Typography>
                &nbsp;{cbdStrength}
              </>
            )}
          </Typography>
        </Box>
        <Box sx={{ order: { xs: 3 } }}>{isConcession ? <ConcessionChip size="small" /> : null}</Box>
      </CardContent>
    </Card>
  );
}

export default ProductTile;
