import { toast } from '@montugroup/design-system';
import { Badge, Box, Button, styled, useMediaQuery, useTheme } from '@mui/material';

import FilterByOrderStatusSelect from '@/components/common/FilterByOrderStatusSelect';
import { OrderStatus } from '@/components/order/order.types';
import settings from '@/constants/constants';
import { FF_PHARMACY_FILTER_ORDERS_BY_STATUS } from '@/constants/featureFlags';
import { PharmacistOrderTableView, usePharmacistOrders } from '@/context/pharmacist/orders/PharmacistOrders';
import { usePharmacistConfirmDispenseModal } from '@/context/pharmacist/orders/PharmacistOrdersModals';
import useDownload from '@/hooks/useDownload';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { Logger } from '@/utils/logger';

const logger = new Logger('pharmacistOrder');

const ActionsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'center',
  [theme.breakpoints.up('sm')]: {
    justifyContent: 'flex-end'
  }
}));

function PharmacistOrdersActions() {
  const {
    activeView,
    selectedOrders,
    toggleSelectAllOrders,
    dispenseOrders,
    loading,
    statusFilter,
    setStatusFilter,
    areAnyOrdersSelected
  } = usePharmacistOrders();
  const { showConfirmDispenseModal } = usePharmacistConfirmDispenseModal();
  const { isDownloading, downloadFile } = useDownload();
  const { flags } = useFeatureFlags();
  const theme = useTheme();
  const isLargeView = useMediaQuery(theme.breakpoints.up('sm'));

  const generateExport = async () => {
    const fileName = `${new Date().getTime()}-pharmacy-orders`;

    await downloadFile(
      fileName,
      `${settings.url}/excel/pharmacist/exportOrders`,
      {
        method: 'POST',
        responseType: 'blob'
      },
      '.xlsx'
    );
  };

  const handleDispenseOrders = async () => {
    const { status, message } = await dispenseOrders();
    if (status) {
      toast.success(message);
    } else {
      toast.error(message);
      logger.error('dispenseOrders', message);
    }
  };

  const isStatusSelected = (orderStatus: OrderStatus) => {
    if (statusFilter.length !== 1) {
      return false;
    }
    return statusFilter[0].name === orderStatus;
  };

  return (
    <ActionsContainer>
      {isStatusSelected(OrderStatus.AwaitingDispensing) && (
        <div>
          <Button
            variant="contained"
            size={isLargeView ? 'large' : 'small'}
            color={areAnyOrdersSelected() ? 'secondary' : 'primary'}
            onClick={toggleSelectAllOrders}
            disabled={loading}
          >
            {areAnyOrdersSelected() ? 'Deselect page' : 'Select page'}
          </Button>
        </div>
      )}
      {activeView !== PharmacistOrderTableView.PROCESSING_DISPENSE && (
        <div>
          <Button
            variant="contained"
            size={isLargeView ? 'large' : 'small'}
            onClick={generateExport}
            disabled={isDownloading || loading}
          >
            Export all
          </Button>
        </div>
      )}
      {activeView === PharmacistOrderTableView.OPEN_ORDERS && (
        <div>
          <Badge badgeContent={selectedOrders.length} color="primary">
            <Button
              variant="contained"
              size={isLargeView ? 'large' : 'small'}
              onClick={handleDispenseOrders}
              disabled={loading}
            >
              Process dispense
            </Button>
          </Badge>
        </div>
      )}
      {activeView === PharmacistOrderTableView.PROCESSING_DISPENSE && (
        <Button
          variant="contained"
          size={isLargeView ? 'large' : 'small'}
          onClick={showConfirmDispenseModal}
          disabled={loading}
        >
          Confirm dispense
        </Button>
      )}
      {flags[FF_PHARMACY_FILTER_ORDERS_BY_STATUS] && (
        <FilterByOrderStatusSelect
          allowedStatuses={[
            OrderStatus.AwaitingDispensing,
            OrderStatus.Pending,
            OrderStatus.Test,
            OrderStatus.IntervalTime,
            OrderStatus.PharmacyPartiallyOutOfStock,
            OrderStatus.UnableToDispense
          ]}
          selectedStatuses={statusFilter}
          onSelect={(value) => setStatusFilter(value)}
        />
      )}
    </ActionsContainer>
  );
}

export default PharmacistOrdersActions;
