import { toast } from '@montugroup/design-system';
import axios from 'axios';
import { useCallback, useState } from 'react';

interface FileUploadData {
  data: [];
  fileName?: string;
  message?: string;
}

interface FileUploadResponse {
  data?: FileUploadData;
  error?: string;
}

const useFileUploadData = (uploadUrl: string, maxFileSizeInBytes: number, allowedExtensions: string[]) => {
  const [uploading, setUploading] = useState<boolean>(false);

  const fileUpload = useCallback(
    async (file: File): Promise<FileUploadResponse> => {
      const fileName = file.name;
      const fileSize = file.size;
      const fileExtension = fileName.split('.').pop()?.toLowerCase();

      if (!fileExtension || !allowedExtensions.includes(fileExtension.toLowerCase())) {
        toast.error('This File type is not allowed');
        return { error: 'This File type is not allowed' };
      }

      if (fileSize > maxFileSizeInBytes) {
        toast.error(`File Size exceeded the Limit ${maxFileSizeInBytes}`);
        return { error: 'File Size exceeded the Limit' };
      }

      const formData = new FormData();
      formData.append('file', file);

      setUploading(true);

      try {
        const response = await axios.post(uploadUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });

        toast.success('File uploaded successfully');

        return { data: response.data };
      } catch (err) {
        toast.error('File upload failed');
        return { error: `File upload failed - ${err}` };
      } finally {
        setUploading(false);
      }
    },
    [uploadUrl, allowedExtensions, maxFileSizeInBytes]
  );

  return {
    uploading,
    fileUpload
  };
};

export default useFileUploadData;
