import { Box, CircularProgress, styled, Typography } from '@mui/material';

import EnquiryDialog from './EnquiryDialog';

type EnquiryLoadingDialogProps = {
  open: boolean;
  onClose?: () => void;
};

const Container = styled(Box)`
  text-align: center;
`;

export default function EnquiryLoadingDialog({ open, onClose }: EnquiryLoadingDialogProps) {
  return (
    <EnquiryDialog open={open} onClose={onClose}>
      <Container>
        <>
          <Typography variant="h5" marginBottom="3.75rem">
            We&apos;re checking to see the status of this product and your options
          </Typography>
          <CircularProgress size={100} thickness={5} />
          <Typography variant="body1" marginTop="1rem">
            This will take a couple of seconds!
          </Typography>
        </>
      </Container>
    </EnquiryDialog>
  );
}
