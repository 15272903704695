import { useQuery } from '@tanstack/react-query';

import { PharmacyService } from '@/services/pharmacy.service';
import type { TableSortOrder } from '@/types';

export type UseSearchPharmacyParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
};

function useSearchPharmacy({ page, pageSize, sortingOrder, filter }: UseSearchPharmacyParams) {
  const { isLoading, data, isError, isSuccess, error } = useQuery({
    queryKey: ['searchPharmacy', page, pageSize, sortingOrder, filter],
    queryFn: async () => {
      try {
        const response = await PharmacyService.searchPharmacy({ page, pageSize, sortingOrder, filter });
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch pharmacy: ${(e as { message: string }).message}`);
      }
    }
  });

  return { isLoading, data, isError, isSuccess, error };
}

export default useSearchPharmacy;
