import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Box, Skeleton, Stack } from '@mui/material';

import { Accordion, ConsultItem, ConsultItemHeader, MainContainer } from './HistoryConsultationCard';

export function HistoryConsultationCardSkeleton({ isExpanded }: { isExpanded?: boolean }) {
  return (
    <MainContainer maxWidth="md">
      <Accordion disableGutters expanded={isExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="black" />}
          aria-controls="consultation-content"
          id="consultation-content"
        >
          <Box>
            <Skeleton variant="text" width={100} height={24} />
            <ConsultItemHeader>
              <Skeleton variant="text" width={200} height={24} />
            </ConsultItemHeader>
          </Box>
        </AccordionSummary>
        <Stack gap={(theme) => theme.spacing(3)} marginBottom={(theme) => theme.spacing(3)}>
          <ConsultItem>
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="text" width={200} height={24} />
          </ConsultItem>
          <ConsultItem>
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="text" width={200} height={24} />
          </ConsultItem>
          <ConsultItem>
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="text" width={200} height={24} />
          </ConsultItem>
          <ConsultItem>
            <Skeleton variant="text" width={150} height={24} />
            <Skeleton variant="rectangular" width={100} height={24} />
          </ConsultItem>
        </Stack>
      </Accordion>
    </MainContainer>
  );
}

export default HistoryConsultationCardSkeleton;
