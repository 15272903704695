/**
 * Generates a URL for a doctor's booking page on Cal.com.
 *
 * @param calcomUsername - The Cal.com username of the doctor.
 * @param consultationSlug - The slug of the consultation.
 * @return The URL of the doctor's booking page.
 */
const buildDoctorBookingLink = (calcomUsername: string | undefined, consultationSlug: string | undefined): string => {
  if (!calcomUsername || !consultationSlug) {
    return '';
  }
  // return `${settings.calendarConfig.calOrigin}/${calcomUsername}/${consultationSlug}`;
  return `/${calcomUsername}/${consultationSlug}`;
};

export default buildDoctorBookingLink;

/**
 * Determines whether a patient can book a consultation with a new doctor.
 * If their current doctor is not available within the set timeframe (default 7 days).
 * and only if they have no open orders
 * @param currentDoctorIsUnavailable Whether the patient's current doctor is unavailable
 * @param [openOrdersCount] The number of open orders the patient has
 * @return Whether the patient can book a consultation with a new doctor
 */
export function canPatientBookNewDoctor(currentDoctorIsUnavailable: boolean, openOrdersCount?: number): boolean {
  if (openOrdersCount && openOrdersCount > 0) {
    return false;
  }

  return currentDoctorIsUnavailable;
}
