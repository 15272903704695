import type { TypographyProps } from '@mui/material';
import { Typography, useTheme } from '@mui/material';
import React from 'react';

interface PageHeadingProps extends TypographyProps {
  children: React.ReactNode;
}

export default function PageHeading({ children, ...typographyProps }: PageHeadingProps) {
  const theme = useTheme();

  return (
    <Typography variant="h5" color="primary" mb={theme.spacing(6)} {...typographyProps}>
      {children}
    </Typography>
  );
}
