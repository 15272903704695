import { teal } from '@/components/products/detail/common';
import useWindowWidth from '@/hooks/useWindowWidth';
import { productCatalog } from '@/services/product.service';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import filter from '../../assets/images/filter.png';
import Button from '../../components/common/button';
import Drawer from '../../components/common/drawer';
import FormInput from '../../components/common/FormInput';
import ProductImageTile from '../../components/products/ProductImageTile';
import constants from '../../constants/constants';

const filterTypes = [{ name: 'Type' }, { name: 'Brand' }, { name: 'Availability' }];

const ProductCatalog = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const screenWidth = useWindowWidth();
  const device = screenWidth < constants.screenResolution.medium ? 'sm' : 'lg';

  const [showDrawer, setDrawer] = React.useState(false);
  const [selectedFilter, setSelectedFilter] = React.useState('');
  const [data, setData] = React.useState({
    formulations: [],
    brands: [],
    loading: true
  });
  const [products, setProducts] = React.useState([]);
  const [filters, setFilters] = React.useState({
    formulation: [],
    brand: [],
    exclude_oos: false,
    search: '',
    ...(location.state?.filters ?? {})
  });

  React.useEffect(() => {
    navigate(
      {
        pathname: location.pathname,
        search: location.search
      },
      {
        replace: true,
        state: {
          ...location.state,
          filters
        }
      }
    );
  }, [filters]);

  React.useEffect(() => {
    const loadData = async () => {
      const resp = await productCatalog(true);
      setData({
        formulations: resp.data.formulation.sort((a, b) => {
          const textA = a.name.toUpperCase();
          const textB = b.name.toUpperCase();
          return textA < textB ? -1 : textA > textB ? 1 : 0;
        }),
        brands: resp.data.suppliers
          .sort((a, b) => {
            const textA = a.supplier_name.toUpperCase();
            const textB = b.supplier_name.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          })
          .sort((a) => (a.supplier_name.toUpperCase() === 'CIRCLE' ? -1 : 1)),
        loading: false
      });
      setProducts(resp.data.products);
    };
    loadData();
  }, []);

  const updateFilter =
    (id, type = null, name) =>
    (e) => {
      const newFilters = { ...filters };
      if (type) {
        newFilters[type] = [...newFilters[type]];
        const filterChecked = newFilters[type].includes(id);
        if (e.target.checked && !filterChecked) {
          newFilters[type].push(id);
        }
        if (!e.target.checked && filterChecked) {
          newFilters[type] = newFilters[type].filter((x) => x !== id);
        }
        const data = {
          product_name: name
        };
      } else {
        if (id === 'exclude-oos') {
          newFilters.exclude_oos = e.target.checked;
        }
        if (id === 'search') {
          newFilters.search = e.target.value;
        }
      }
      setFilters(newFilters);
    };

  const filteredProducts = React.useMemo(() => {
    let filteredProducts = products;
    if (filters.formulation.length > 0) {
      // setting up formulation based filter
      filteredProducts = filteredProducts.filter((x) => filters.formulation.includes(x.formulation_id));
    }
    if (filters.brand.length > 0) {
      // setting up Brand based filter
      filteredProducts = filteredProducts.filter((x) =>
        filters.brand.some((r) => x.Suppliers.find((sx) => sx.id === r))
      );
    }
    if (filters.exclude_oos) {
      // Exclude Out of Stock Filter
      filteredProducts = filteredProducts.filter((x) => !x.is_out_of_stock && !x.reasoning_toggle);
    }
    if (filters.search !== '') {
      // search field filter
      filteredProducts = filteredProducts.filter((x) => {
        const searchFields = [(x.short_name || x.name).toLowerCase()]; // filtering product name with regards to search field
        if (x.Suppliers.length > 0) {
          // filtering supplier name with regard to search field
          searchFields.push(x.Suppliers.map((s) => s.supplier_name.toLowerCase()).join(''));
        }
        return searchFields.some((r) => r.includes(filters.search.toLowerCase()));
      });
    }
    return filteredProducts;
  }, [products, filters]);

  const handleReset = () => {
    setFilters({ formulation: [], brand: [], exclude_oos: false, search: '' });
  };

  return (
    <div className="product-catalog-wrapper mx-auto body-content-container">
      {/* Desktop view */}
      {device === 'lg' && (
        <>
          <div className="d-flex justify-content-between">
            <div className="font-primary">
              <h3>Browse Products</h3>
            </div>
          </div>
          <hr className="mt-5 seprator-line" />
          <div className="row">
            <div className="col-md-3 col-lg-2 col-xl-2 product-sort-list">
              <Button
                className="btn btn-light mb-4 btn-block"
                onClick={() => handleReset()}
                text="Clear filters"
                type="button"
              />
              <label className="filter-heading">TYPE</label>
              <ul className="filter-menu">
                {data.formulations.map((ele) => (
                  <li key={ele.id} className="mt-1 mx-1 d-flex">
                    <input
                      type="checkbox"
                      className="checkbox mt-1"
                      style={{ width: '14px', height: '14px' }}
                      id={`type-${ele.id}`}
                      onChange={updateFilter(ele.id, 'formulation', ele.name)}
                      checked={!!filters.formulation.find((x) => x === ele.id)}
                    />
                    <label className="font-primary" htmlFor={`type-${ele.id}`}>
                      {ele.name}
                    </label>
                  </li>
                ))}
              </ul>
              <label className="filter-heading">BRAND</label>
              <ul className="filter-menu">
                {data.brands.map((ele) => (
                  <li key={ele.id} className="mt-1 mx-1 d-flex">
                    <input
                      type="checkbox"
                      className="checkbox mt-1"
                      style={{ width: '14px', height: '14px' }}
                      id={`brand-${ele.id}`}
                      onChange={updateFilter(ele.id, 'brand', ele.supplier_name)}
                      checked={filters.brand.find((x) => x === ele.id) ? true : false}
                    />
                    <label className="font-primary" htmlFor={`brand-${ele.id}`}>
                      {ele.supplier_name}
                    </label>
                  </li>
                ))}
              </ul>
              <label className="filter-heading">AVAILABILITY</label>
              <ul className="filter-menu">
                <li className="mt-1 mx-1 d-flex">
                  <input
                    type="checkbox"
                    className="checkbox mt-1"
                    style={{ width: '14px', height: '14px' }}
                    id="exclude-oos"
                    onChange={updateFilter('exclude-oos')}
                    checked={filters.exclude_oos}
                  />
                  <label className="font-primary" htmlFor="exclude-oos">
                    In-Stock
                  </label>
                </li>
              </ul>
            </div>
            <div className="col-md-9 col-lg-10 col-xl-10">
              <div className="row">
                <FormInput
                  placeholder="Search"
                  className="col-12"
                  type="text"
                  name="search"
                  value={filters.search}
                  noLabel={true}
                  onChange={updateFilter('search')}
                />
              </div>
              <div className="row">
                {filteredProducts.map((ele) => (
                  <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3 d-flex" key={ele.id}>
                    <ProductImageTile product={ele} type={data.formulations.find((x) => x.id === ele.formulation_id)} />
                  </div>
                ))}
                {filteredProducts.length === 0 && <p className="text-center w-100">No Products Available.</p>}
              </div>
            </div>
          </div>
        </>
      )}

      {device === 'sm' && (
        <>
          <div className="w-100 product-sort-mob">
            <h3 className="text-center font-primary">Browse Products</h3>
            <div className="row">
              <div className="col-6">{filteredProducts.length} Item(s)</div>
              <div className="col-6">
                <div className="d-flex float-right">
                  <div onClick={() => setDrawer('Filter')} className="ml-2 d-flex">
                    <img src={filter} style={{ width: '20px', height: '25px' }} />
                    <p className="ml-2" style={{ color: teal }}>
                      Filter
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Drawer show={Boolean(showDrawer)} className="p-2">
            {showDrawer && (
              <div className="modalheader">
                <div className="d-flex w-100 align-items-baseline">
                  {showDrawer === 'Filter' && (
                    <div style={{ color: teal, cursor: 'pointer' }} onClick={() => handleReset()}>
                      Clear
                    </div>
                  )}
                  <label className="text-center  mx-auto sort-title">{showDrawer}</label>
                  <div
                    className="float-right"
                    style={{ color: teal, cursor: 'pointer' }}
                    onClick={() => setDrawer(false)}
                  >
                    X
                  </div>
                </div>
              </div>
            )}
            {showDrawer === 'Filter' && selectedFilter === '' && (
              <div className="modalbody mt-3">
                {filterTypes.map((ele, index) => (
                  <h3
                    key={index}
                    className="text-center sort-list"
                    style={{ color: teal }}
                    onClick={() => setSelectedFilter(ele.name)}
                  >
                    {ele.name}
                  </h3>
                ))}
              </div>
            )}
            {showDrawer === 'Filter' && selectedFilter === 'Type' && (
              <>
                <div onClick={() => setSelectedFilter('')}>Back</div>
                <div className="text-center">
                  <label className="filter-heading mob">TYPE</label>
                </div>
                <ul className="filter-menu">
                  {data.formulations.map((ele) => (
                    <li key={ele.id} className="mt-1 mx-1 d-flex">
                      <input
                        type="checkbox"
                        className="checkbox mt-1"
                        style={{ width: '14px', height: '14px' }}
                        id={`type-${ele.id}`}
                        onChange={updateFilter(ele.id, 'formulation')}
                        checked={filters.formulation.find((x) => x === ele.id) ? true : false}
                      />
                      <label htmlFor={`type-${ele.id}`}>{ele.name}</label>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {showDrawer === 'Filter' && selectedFilter === 'Brand' && (
              <>
                <div onClick={() => setSelectedFilter('')}>Back</div>
                <div className="text-center">
                  <label className="filter-heading mob">BRANDS</label>
                </div>
                <ul className="filter-menu">
                  {data.brands.map((ele) => (
                    <li key={ele.id} className="mt-1 mx-1 d-flex">
                      <input
                        type="checkbox"
                        className="checkbox mt-1"
                        style={{ width: '14px', height: '14px' }}
                        id={`brand-${ele.id}`}
                        onChange={updateFilter(ele.id, 'brand')}
                        checked={filters.brand.find((x) => x === ele.id) ? true : false}
                      />
                      <label htmlFor={`brand-${ele.id}`}>{ele.supplier_name}</label>
                    </li>
                  ))}
                </ul>
              </>
            )}
            {showDrawer === 'Filter' && selectedFilter === 'Availability' && (
              <>
                <div onClick={() => setSelectedFilter('')}>Back</div>
                <div className="text-center">
                  <label className="filter-heading mob">AVAILABILITY</label>
                </div>
                <ul className="filter-menu">
                  <li className="mt-1 mx-1 d-flex">
                    <input
                      type="checkbox"
                      className="checkbox mt-1"
                      style={{ width: '14px', height: '14px' }}
                      id="exclude-oos"
                      onChange={updateFilter('exclude-oos')}
                      checked={filters.exclude_oos}
                    />
                    <label className="font-primary" htmlFor="exclude-oos">
                      In-Stock
                    </label>
                  </li>
                </ul>
              </>
            )}
          </Drawer>
          <div className="row">
            <FormInput
              placeholder="Search"
              className="col-12"
              type="text"
              name="search"
              value={filters.search}
              noLabel={true}
              onChange={updateFilter('search')}
            />
          </div>
          <div className="row">
            {filteredProducts.map((ele) => (
              <div className="col-sm-6 col-md-4 col-lg-3 col-xl-3" key={ele.id}>
                <ProductImageTile
                  product={ele}
                  type={data.formulations.find((x) => x.id === ele.formulation_id)}
                  history={navigation}
                />
              </div>
            ))}
            {filteredProducts.length === 0 && <p className="text-center w-100">No Products Available.</p>}
          </div>
        </>
      )}
    </div>
  );
};

export default ProductCatalog;
