import { useQuery } from '@tanstack/react-query';
import { useCallback } from 'react';

import { UserService } from '@/services/user.service';
import getQueryClient from '@/utils/getQueryClient';

export const QUERY_KEY_USER_PROFILE_DATA = 'userProfileData';

export async function userProfileQueryFunction() {
  return (await UserService.fetchUserProfile()).data;
}

export const useUserProfileData = () => {
  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: [QUERY_KEY_USER_PROFILE_DATA],
    queryFn: userProfileQueryFunction
  });

  const getUserState = useCallback(() => {
    if (!data || !data.states || !data.user) {
      return null;
    }

    const userState = data.states.find((state: { id: number }) => data.user.state_id === state.id);

    return userState ? userState.name : null;
  }, [data]);

  return {
    data,
    isError,
    isLoading,
    isSuccess,
    getUserState
  };
};

getQueryClient().setQueryDefaults([QUERY_KEY_USER_PROFILE_DATA], {
  queryFn: userProfileQueryFunction
});

export default useUserProfileData;
