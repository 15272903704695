import { BrandThemeProvider } from '@montugroup/themes';
import { Box, styled } from '@mui/material';
import type { ReactNode } from 'react';

import AlertSnackbarProvider from '@/components/error/AlertSnackbarProvider';

import { usePortalPartnerBrand } from '../../../hooks/portal-partner-brand';

import ParentHeader from './components/ParentHeader';
import PartnerAlert from './components/PartnerAlert';
import PartnerBack from './components/PartnerBack';
import PartnerContent from './components/PartnerContent';
import PartnerHeader from './components/PartnerHeader';

const LayoutContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

export type PortalPartnerLayoutProps = {
  children?: ReactNode;
};

export function PortalPartnerLayout({ children }: PortalPartnerLayoutProps) {
  const { key: partnerKey } = usePortalPartnerBrand();

  return (
    <BrandThemeProvider variant={partnerKey}>
      <AlertSnackbarProvider>
        <LayoutContainer>{children}</LayoutContainer>
      </AlertSnackbarProvider>
    </BrandThemeProvider>
  );
}

PortalPartnerLayout.ParentHeader = ParentHeader;
PortalPartnerLayout.PartnerHeader = PartnerHeader;
PortalPartnerLayout.Alert = PartnerAlert;
PortalPartnerLayout.Content = PartnerContent;
PortalPartnerLayout.Back = PartnerBack;

export default PortalPartnerLayout;
