import { Box, List, ListItem, ListItemText, styled, Typography } from '@mui/material';

import settings from '@/constants/constants';

import Footer from '../layout/footer';
import Header from '../layout/header';

const CustomListItem = styled(ListItem)(({ theme }) => ({
  paddingTop: theme.spacing(0),
  paddingBottom: theme.spacing(0),
  color: theme.palette.common.black
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black
}));

function TermsAndPolicy() {
  return (
    <>
      <Header />
      <Box sx={{ px: 4, py: 24, overflowY: 'auto' }}>
        <CustomTypography variant="h4" gutterBottom>
          TERMS AND CONDITIONS
        </CustomTypography>
        <CustomTypography gutterBottom variant="h6">
          This PARTNERSHIP AGREEMENT (the &quot;Agreement&quot;) is made on the date signed below between:
        </CustomTypography>
        <List>
          <CustomListItem>
            <ListItemText primary="(1) Montu Group Pty Ltd, ABN 35 634 198 360, located at 1 Nicholson St, East Melbourne, VIC 3002 ('Montu'); and" />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="(2) The Pharmacy with ABN and registered address as defined above in this agreement ('Pharmacy')" />
          </CustomListItem>
        </List>
        <ListItem sx={{ paddingY: 0 }}>
          <CustomTypography variant="h6">IT IS HEREBY AGREED as follows:</CustomTypography>
        </ListItem>
        <List>
          <ListItem>
            <CustomTypography>1. PARTNERSHIP</CustomTypography>
          </ListItem>
          <CustomListItem>
            <ListItemText primary="1.1. Montu supplies the Pharmacy with a range of Circle branded medicinal cannabis products (the 'Products') to be dispensed to patients presenting a valid prescription." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="1.2. All product costs, dispensing and shipping fees are paid directly to Montu by the patient before dispensing." />
          </CustomListItem>
          <ListItem>
            <CustomTypography>2. FEES AND PAYMENTS</CustomTypography>
          </ListItem>
          <CustomListItem>
            <ListItemText primary="2.1. Montu shall pay the Pharmacy a fixed fee of AUD30.00 (excl. GST) per transaction for dispensing services." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="2.2. Montu will issue the Pharmacy with an RCTI on the 1st of each month, consolidating all transactions of the previous month." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="2.3. The Pharmacy shall issue Montu with a matching tax invoice within 14 days of receipt of the RCTI." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="2.4. Montu shall make payment within 14 days of invoice receipt." />
          </CustomListItem>
          <ListItem>
            <CustomTypography>3. PRODUCT OWNERSHIP AND RESPONSIBILITIES</CustomTypography>
          </ListItem>
          <CustomListItem>
            <ListItemText primary="3.1. The Pharmacy confirms that it has processes and facilities in place to accommodate the storage and dispensing of Schedule 8 medications." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="3.2. The Pharmacy acknowledges and agrees that property in the Products supplied by Montu to the Pharmacy does not pass onto the Pharmacy." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="3.3. Risk of any loss, damage or deterioration of or to the Products passes to the Pharmacy on the sooner of delivery onto premises or delivery into control." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="3.4. While ownership of the Products remains with Montu, the Pharmacy must store them separately and identify them as belonging to Montu." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="3.5. The Pharmacy shall process, store, handle and display all Products in accordance with the instructions displayed on the Products. Failure to comply with this clause shall render the Pharmacy solely liable for any defect in those Products." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText
              primary={`3.6 The Pharmacy shall report to Montu any technical or quality complaints relating to the Products via phone (${settings.support.phone.display}) or email (circle.au@montugroup.com) within 24 hours of initial awareness.`}
            />
          </CustomListItem>
          <ListItem>
            <CustomTypography>4. CHANGES AND TERMINATION</CustomTypography>
          </ListItem>
          <CustomListItem>
            <ListItemText primary="4.1. At its discretion, Montu may alter the terms of this Agreement at any time. Any change in terms or fees shall be communicated by Montu to the Pharmacy in writing." />
          </CustomListItem>
          <CustomListItem>
            <ListItemText primary="4.2. Either party shall be able to terminate this Agreement, by providing the other party with 14 days written notice." />
          </CustomListItem>
        </List>
      </Box>
      <Footer />
    </>
  );
}

export default TermsAndPolicy;
