import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

function useGetBulkOrderStatus() {
  return useQuery({
    queryKey: ['bulkOrderStatus'],
    queryFn: async () => {
      try {
        const response = await OrderService.getOrderStatusBulk();
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch order statuses: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetBulkOrderStatus;
