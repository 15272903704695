import useGetAwaitingOrdersCount, {
  GET_AWAITING_ORDERS_COUNT_QUERY_KEY
} from '@/hooks/orders/useGetAwaitingOrdersCount';
import type { Order } from '@/services/data.service';
import { noOp } from '@/utils/noOp';
import { useQueryClient } from '@tanstack/react-query';
import type { PropsWithChildren } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';

export type ExportAndShipModalState = {
  visible: boolean;
};

export type SplitOrderModalState = {
  visible: boolean;
  order: Order | null;
};

export type UnableToDispenseModalState = {
  visible: boolean;
  orderId: number | null;
};

export type OrdersModalsData = {
  exportAndShipAwaitingModal: ExportAndShipModalState;
  setExportAndShipAwaitingModal: (state: ExportAndShipModalState) => void;
  splitOrderModal: SplitOrderModalState;
  setSplitOrderModal: (state: SplitOrderModalState) => void;
  unableToDispenseModal: UnableToDispenseModalState;
  setUnableToDispenseModal: (state: UnableToDispenseModalState) => void;
};

export const OrdersModalContext = createContext<OrdersModalsData>({
  exportAndShipAwaitingModal: { visible: false },
  setExportAndShipAwaitingModal: noOp,
  splitOrderModal: { visible: false, order: null },
  setSplitOrderModal: noOp,
  unableToDispenseModal: { visible: false, orderId: null },
  setUnableToDispenseModal: noOp
});

export function OrdersModalsProvider(props: PropsWithChildren) {
  const { children } = props;
  const [exportAndShipAwaitingModal, setExportAndShipAwaitingModal] = useState<ExportAndShipModalState>({
    visible: false
  });
  const [splitOrderModal, setSplitOrderModal] = useState<SplitOrderModalState>({
    visible: false,
    order: null
  });
  const [unableToDispenseModal, setUnableToDispenseModal] = useState<UnableToDispenseModalState>({
    visible: false,
    orderId: null
  });

  const value = useMemo(
    () => ({
      exportAndShipAwaitingModal,
      splitOrderModal,
      unableToDispenseModal,
      setExportAndShipAwaitingModal,
      setSplitOrderModal,
      setUnableToDispenseModal
    }),
    [exportAndShipAwaitingModal, splitOrderModal, unableToDispenseModal]
  );

  return <OrdersModalContext.Provider value={value}>{children}</OrdersModalContext.Provider>;
}

export const useExportAndShipAwaitingModal = () => {
  const { exportAndShipAwaitingModal, setExportAndShipAwaitingModal } = useContext(OrdersModalContext);
  const queryClient = useQueryClient();
  const { data, isPending } = useGetAwaitingOrdersCount();

  const showExportAndShipAwaitingModal = () => {
    queryClient.invalidateQueries({ queryKey: GET_AWAITING_ORDERS_COUNT_QUERY_KEY });
    setExportAndShipAwaitingModal({ visible: true });
  };

  const hideExportAndShipAwaitingModal = () => {
    setExportAndShipAwaitingModal({ visible: false });
  };

  return {
    visible: exportAndShipAwaitingModal.visible,
    isPending,
    data,
    showExportAndShipAwaitingModal,
    hideExportAndShipAwaitingModal
  };
};

export const useSplitOrderModal = () => {
  const { splitOrderModal, setSplitOrderModal } = useContext(OrdersModalContext);

  const showSplitOrderModal = (order: Order) => {
    setSplitOrderModal({ visible: true, order });
  };

  const hideSplitOrderModal = () => {
    setSplitOrderModal({
      visible: false,
      order: null
    });
  };

  return {
    visible: splitOrderModal.visible,
    order: splitOrderModal.order,
    showSplitOrderModal,
    hideSplitOrderModal
  };
};

export const useUnableToDispenseModal = () => {
  const { unableToDispenseModal, setUnableToDispenseModal } = useContext(OrdersModalContext);

  const showUnableToDispenseModal = (orderId: number) => {
    setUnableToDispenseModal({ visible: true, orderId });
  };

  const hideUnableToDispenseModal = () => {
    setUnableToDispenseModal({ visible: false, orderId: null });
  };

  return {
    visible: unableToDispenseModal.visible,
    orderId: unableToDispenseModal.orderId,
    showUnableToDispenseModal,
    hideUnableToDispenseModal
  };
};
