import { useQuery } from '@tanstack/react-query';

import { fetchPatientSecondaryProfiles } from '@/services/patientMerge.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGetSecondaryProfiles');

export const SECONDARY_PROFILE_QUERY_KEY = 'getSecondaryProfiles';

export const useGetSecondaryProfiles = (patientCode: string) =>
  useQuery({
    queryKey: [SECONDARY_PROFILE_QUERY_KEY],

    queryFn: async () => {
      try {
        if (!patientCode) {
          return null;
        }
        const data = await fetchPatientSecondaryProfiles(patientCode);
        return data;
      } catch (error) {
        logger.error('Error fetching secondary profiles:', error);
        throw new Error(String(error));
      }
    },

    retry: false,
    enabled: false
  });

export default useGetSecondaryProfiles;
