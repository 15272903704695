import { Box, styled } from '@mui/material';

import { TOOLTIPS } from '../../utils';

import KeyValueText from './KeyValueText';
import Section from './Section';

const OverviewContent = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    width: '80%'
  }
}));

export type ProductOverviewProps = {
  product: {
    brandName: string;
    strainName: string;
    scheduleName: string;
    usageTime: string;
    spectrum: string;
    category: string;
    terpeneProfile: string;
  };
};

export function ProductOverview({
  product: { brandName, strainName, scheduleName, usageTime, spectrum, category, terpeneProfile }
}: ProductOverviewProps) {
  return (
    <Section>
      <Section.Title>Overview</Section.Title>
      <Section.Content>
        <OverviewContent>
          <KeyValueText>
            <KeyValueText.Key>Brand:</KeyValueText.Key>
            <KeyValueText.Value>{brandName}</KeyValueText.Value>
          </KeyValueText>
          <KeyValueText>
            <KeyValueText.Key tooltip={TOOLTIPS.strain}>Strain:</KeyValueText.Key>
            <KeyValueText.Value>{strainName}</KeyValueText.Value>
          </KeyValueText>
          <KeyValueText>
            <KeyValueText.Key tooltip={TOOLTIPS.dayornight}>Day or night:</KeyValueText.Key>
            <KeyValueText.Value>{usageTime}</KeyValueText.Value>
          </KeyValueText>
          <KeyValueText>
            <KeyValueText.Key tooltip={TOOLTIPS.category}>Category:</KeyValueText.Key>
            <KeyValueText.Value>{category}</KeyValueText.Value>
          </KeyValueText>
          <KeyValueText>
            <KeyValueText.Key tooltip={TOOLTIPS.spectrum}>Spectrum:</KeyValueText.Key>
            <KeyValueText.Value>{spectrum}</KeyValueText.Value>
          </KeyValueText>
          <KeyValueText>
            <KeyValueText.Key tooltip={TOOLTIPS.schedule}>Schedule:</KeyValueText.Key>
            <KeyValueText.Value>{scheduleName}</KeyValueText.Value>
          </KeyValueText>
          <KeyValueText sx={{ gridColumn: '1 / -1' }}>
            <KeyValueText.Key tooltip={TOOLTIPS.terpeneprofile}>Terpene profile:</KeyValueText.Key>
            <KeyValueText.Value>{terpeneProfile}</KeyValueText.Value>
          </KeyValueText>
        </OverviewContent>
      </Section.Content>
    </Section>
  );
}

export default ProductOverview;
