import type { ChipProps as MuiChipProps } from '@mui/material';
import { Chip as MuiChip } from '@mui/material';

export type ChipProps = MuiChipProps;

export function Chip({ ...rest }: ChipProps) {
  return <MuiChip {...rest} />;
}

export default Chip;
