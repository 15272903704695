import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

// TODO: Use useGetOrder on orderForm page so the order/:id network request is deduped
function useGetOrder({ id }: { id: number }) {
  return useQuery({
    queryKey: ['order', id],
    queryFn: async () => {
      try {
        const response = await OrderService.getOrder({ id });
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch order: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetOrder;
