import { Box, Skeleton, styled } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius
}));

const OrderNumberContainer = styled(Box)(() => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

export function OrderHistoryCardLoading() {
  return (
    <Wrapper>
      <OrderNumberContainer>
        <Skeleton variant="text" width="50%" height={32} />
        <Skeleton variant="text" width={24} height={24} />
      </OrderNumberContainer>
    </Wrapper>
  );
}

export default OrderHistoryCardLoading;
