import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';
import axios from 'axios';

import settings from '../../constants/constants';

interface PasswordUpdateData {
  currentPassword: string;
  password: string;
}

interface PasswordUpdateResponse {
  id: number;
  message: string;
}

/**
 * Custom hook for updating user password.
 * @returns Mutation object with update password mutation function.
 */
const useUpdatePassword = (): UseMutationResult<AxiosResponse, unknown, PasswordUpdateData> => {
  const { url } = settings;
  const mutation = useMutation({
    mutationFn: async (passwordUpdateData: PasswordUpdateData) => {
      const response = await axios.put<PasswordUpdateResponse>(`${url}/user/password/`, {
        body: JSON.stringify({
          currentPassword: passwordUpdateData.currentPassword,
          password: passwordUpdateData.password
        })
      });
      // workaround as old endpoint that returns successful response upon validation checks
      if (response.status === 200 && response.data.id === 1) {
        return response;
      }
      throw new Error('Error updating password');
    }
  });

  return mutation;
};

export default useUpdatePassword;
