import { Box, Button, Skeleton, styled, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { Link as RouterLink } from 'react-router-dom';

import Error from '@/components/error/Error';
import HelpDeskPopup from '@/components/helpDesk/HelpDeskSelector';
import OrderHistoryCard from '@/components/order/order-history-card/OrderHistoryCard';
import OrderHistoryCardLoading from '@/components/order/order-history-card/OrderHistoryCardLoading';
import type { PatientOrder } from '@/hooks/patient/useGetPatientOrders';
import useGetPatientOrders from '@/hooks/patient/useGetPatientOrders';
import generateTrackingLink from '@/utils/patient/trackingLink';

import constants from '../../../constants/constants';

export const RECENT_ORDER_MONTHS_THRESHOLD = 1;

const getPastOrderMonth = (yearMonth: string) => DateTime.fromFormat(yearMonth, 'yyyy-MM').toFormat('MMMM yyyy');

const OrderHistoryCardContainer = styled(Box)(({ theme }) => ({
  paddingTop: theme.spacing(1.5),
  paddingBottom: theme.spacing(1.5)
}));

const PastOrderMonthHeadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  padding: theme.spacing(1),
  backgroundColor: theme.palette.primary.light,
  borderRadius: theme.shape.borderRadius
}));

const PageHeadingContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(8),
  paddingBottom: theme.spacing(3),
  flexDirection: 'column',
  [`${theme.breakpoints.up('lg')}`]: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  }
}));

const activeStatuses = [
  constants.orderStatus.AWAITING_PAYMENT,
  constants.orderStatus.PAID_NOT_APPROVED,
  constants.orderStatus.APPROVED_NOT_PAID,
  constants.orderStatus.AWAITING_SHIPMENT,
  constants.orderStatus.AWAITING_DISPENSE,
  constants.orderStatus.PHARMACY_DISPENSED
];

function PatientOrdersV2Loading() {
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
      <Box sx={{ padding: '1rem 0' }}>
        <Typography variant="h5" fontWeight="bold">
          <Skeleton />
        </Typography>
      </Box>
      <OrderHistoryCardContainer>
        <OrderHistoryCardLoading />
      </OrderHistoryCardContainer>
      <OrderHistoryCardContainer>
        <OrderHistoryCardLoading />
      </OrderHistoryCardContainer>
      <OrderHistoryCardContainer>
        <OrderHistoryCardLoading />
      </OrderHistoryCardContainer>
    </Box>
  );
}

export default function PatientOrdersV2() {
  const { data: orders, isError, isLoading } = useGetPatientOrders();

  if (isLoading) {
    return <PatientOrdersV2Loading />;
  }

  if (isError) {
    return <Error status={500} />;
  }

  const activeOrders: PatientOrder[] = [];
  const pastOrders = new Map<string, PatientOrder[]>();
  const recentOrderThreshold = DateTime.now().minus({ months: RECENT_ORDER_MONTHS_THRESHOLD });

  orders?.forEach((order: PatientOrder) => {
    const orderDate = DateTime.fromISO(order.order_date);
    const orderDateIsBeforeCutoff = orderDate < recentOrderThreshold;
    if (activeStatuses.includes(order.orderStatus) && !order.delivered && !orderDateIsBeforeCutoff) {
      activeOrders.push(order);
    } else {
      // map past orders, in desc, grouped by year and month
      const mapKey = orderDate.toFormat('yyyy-MM');
      if (!pastOrders.has(mapKey)) {
        pastOrders.set(mapKey, []);
      }
      pastOrders.get(mapKey)?.push(order);
    }
  });

  const pastOrdersMapKeys = Array.from(pastOrders.entries());
  return (
    <>
      <Box sx={{ width: '100%' }}>
        <PageHeadingContainer>
          <Button variant="contained" color="secondary" component={RouterLink} to="/patient/refill">
            Order Medication
          </Button>
          <Box>
            <Typography variant="h5" fontWeight="bold">
              Active Orders
            </Typography>
          </Box>
        </PageHeadingContainer>
        <Box>
          {activeOrders.length ? (
            activeOrders.map((order, index) => (
              <OrderHistoryCardContainer key={order.id}>
                <OrderHistoryCard
                  orderNumber={order.order_code}
                  cancelledDate={order.order_history_cancelled_date}
                  orderLink={`/patient/order-detail/${order.order_code}`}
                  numberOfItems={order.OrderProducts.length}
                  orderDate={new Date(order.order_date)}
                  pharmacyName={order.pharmacy?.name}
                  orderPrice={order.total_price}
                  orderStatus={order.orderStatus}
                  delivered={order.delivered}
                  trackingLink={generateTrackingLink(order.shipment_carrier, order.shipment_tracking_id)}
                  dispensingFee={order.dispensing_fee}
                  splitOrder={!order.orderStatus}
                  defaultExpanded={index === 0}
                />
              </OrderHistoryCardContainer>
            ))
          ) : (
            <Box sx={(theme) => ({ padding: `${theme.spacing(5)} 0` })}>
              <Typography>You have no active orders</Typography>
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ width: '100%' }}>
        <Box sx={(theme) => ({ padding: `${theme.spacing(3)} 0` })}>
          <Typography variant="h5" fontWeight="bold">
            Past Orders
          </Typography>
        </Box>
        {pastOrders.size ? (
          pastOrdersMapKeys.map(([key, groupedOrders]) => (
            <OrderHistoryCardContainer key={key}>
              <PastOrderMonthHeadingContainer>
                <Typography variant="caption" color="primary.contrastText" fontWeight="bold">
                  {getPastOrderMonth(key).toUpperCase()}
                </Typography>
              </PastOrderMonthHeadingContainer>
              {groupedOrders.map((order, index) => (
                <OrderHistoryCardContainer key={order.id}>
                  <OrderHistoryCard
                    orderNumber={order.order_code}
                    orderLink={`/patient/order-detail/${order.order_code}`}
                    numberOfItems={order.OrderProducts.length}
                    orderDate={new Date(order.order_date)}
                    pharmacyName={order.pharmacy?.name}
                    orderPrice={order.total_price}
                    orderStatus={order.orderStatus}
                    delivered={order.delivered}
                    trackingLink={generateTrackingLink(order.shipment_carrier, order.shipment_tracking_id)}
                    dispensingFee={order.dispensing_fee}
                    splitOrder={!order.orderStatus}
                    cancelledDate={order.order_history_cancelled_date}
                    defaultExpanded={!activeOrders.length && index === 0}
                  />
                </OrderHistoryCardContainer>
              ))}
            </OrderHistoryCardContainer>
          ))
        ) : (
          <Box sx={(theme) => ({ padding: `${theme.spacing(5)} 0` })}>
            <Typography>You have no past orders</Typography>
          </Box>
        )}
      </Box>

      <HelpDeskPopup />
    </>
  );
}
