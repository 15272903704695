import { Table } from '@montugroup/design-system';
import { Box, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';

import type { PatientProduct } from '@/types';

type MedicationTableProps = {
  productList: PatientProduct[];
};

function MedicationTable({ productList }: MedicationTableProps) {
  const columns: ColumnDef<PatientProduct>[] = [
    {
      accessorKey: 'name',
      header: 'Medication',
      enableSorting: false
    },
    {
      accessorKey: 'remaining_units',
      header: 'Units',
      enableSorting: false
    }
  ];

  if (productList.length === 0) {
    return null;
  }
  return (
    <Box sx={{ marginTop: '1rem' }}>
      <Typography variant="h6">Medication Prescribed</Typography>
      <Table columns={columns} data={productList} hasRowBackgroundColor={false} />
    </Box>
  );
}

export default MedicationTable;
