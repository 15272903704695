import type { ShippingAddressDto } from '@montugroup/circuit-api-contracts';

/**
 * Extracts the order code before any split suffix.
 *
 * Order codes can have various formats:
 * - Original: MPRS123
 * - Next: MPRS123-1
 * - Split: MPRS123-2-S1.
 *
 * This function removes the split suffix (e.g., `-S1`, `-S2`) from order codes.
 * If the order code does not have a split suffix, it returns the original code.
 *
 * @param orderCode - The full order code.
 * @returns - The base order code without the split suffix.
 *
 * @example
 * getBaseOrderCode('MPRS123-2-S1'); // Returns 'MPRS123-2'
 * getBaseOrderCode('MPRS123');      // Returns 'MPRS123'
 * getBaseOrderCode('MPRS123-1');    // Returns 'MPRS123-1'
 */
export function getBaseOrderCode(orderCode: string): string {
  // Regex to match the split suffix (e.g., '-S1', '-S2')
  const splitSuffixPattern = /-S\d+$/;

  return orderCode?.replace(splitSuffixPattern, '');
}

/**
 * Formats the shipping address into a single string.
 *
 * @param shippingAddress - The shipping address object.
 * @returns - The formatted shipping address string.
 */
export function formatShippingAddress(shippingAddress: ShippingAddressDto): string {
  const { address1, address2, city, zip, province } = shippingAddress;
  const addressParts = [address1, address2, city, zip, province].filter((part) => part && part.trim() !== '');

  return addressParts.join(' ');
}
