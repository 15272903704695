import { Logger } from '@/utils/logger';
import { toast } from '@montugroup/design-system';
import type { DefaultError, Query } from '@tanstack/react-query';
import { QueryCache, QueryClient } from '@tanstack/react-query';

const logger = new Logger('QueryClient');
let queryClient: QueryClient | undefined;

export interface QueryError {
  message?: string;
  log?: string;
  showToast?: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getDebugErrorMessages = ({ error, query }: { error: any; query: any }) => {
  return {
    primaryMessage:
      query?.meta?.error?.log ??
      query?.state?.error?.body?.error?.message ??
      error?.response?.data?.error?.message ??
      error?.body?.error?.message ??
      error?.message ??
      query?.meta?.error?.message ??
      'Unknown Error',
    allMessages: {
      'query.meta.error.log': query?.meta?.error?.log,
      'query.meta.error.message': query?.meta?.error?.message,
      'query.state.error.body.error.message': query?.state?.error?.body?.error?.message,
      'error.response.data.error.message': error?.response?.data?.error?.message,
      'error.body.error.message': error?.body?.error?.message,
      'error.message': error?.message
    }
  };
};

const getQueryErrorOverrides = (errorOverrides: unknown): QueryError | null => {
  if (typeof errorOverrides === 'object') {
    return errorOverrides as QueryError;
  }

  return null;
};

export const onError = ({ error, query }: { error: DefaultError; query: Query<unknown, unknown, unknown> }) => {
  const queryKey = query.queryKey?.[0] ?? 'UNKNOWN_QUERY';
  const { primaryMessage, allMessages } = getDebugErrorMessages({ error, query });
  const queryErrorOverrides = getQueryErrorOverrides(query.meta?.error);

  logger.error(`${queryKey}: ${primaryMessage}`, error, {
    queryErrorOverrides,
    allMessages
  });

  if (queryErrorOverrides?.showToast) {
    toast.error(queryErrorOverrides.message || error.message || primaryMessage);
  }
};

const getQueryClient = () => {
  if (!queryClient) {
    queryClient = new QueryClient({
      queryCache: new QueryCache({
        onError: (error, query) => onError({ error, query })
      })
    });
  }

  return queryClient;
};

export default getQueryClient;
