import { Box, Button, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Link } from 'react-router-dom';

interface NavbarMenuProps {
  name: string;
  link: string;
  activeReferal?: boolean;
  handleMenu?: () => void;
}

const capitalizeFirstLetter = (text: string) => text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();

const MenuContainer = styled(Box)(({ theme }) => ({
  color: theme.palette.primary.main,
  marginLeft: theme.spacing(6),
  listStyle: 'none'
}));

const MenuLink = styled(Link)(() => ({
  textDecoration: 'none',
  color: 'inherit'
}));

const MenuItemBox = styled(Box)(() => ({
  display: 'block'
}));

function NavbarMenuV2({ name, link, activeReferal, handleMenu }: NavbarMenuProps) {
  const theme = useTheme();
  const isMobileView = useMediaQuery(theme.breakpoints.down('md'));

  const handleMenuToggle = () => {
    if (isMobileView) {
      handleMenu?.();
    }
  };

  const navList = (menuName: string, isActiveReferal?: boolean) =>
    menuName === 'Referral' && isActiveReferal ? (
      <Button variant="contained" color="primary">
        REFER A FRIEND
      </Button>
    ) : (
      <Typography variant="body1" color="common.white" sx={{ textTransform: 'none' }}>
        {capitalizeFirstLetter(menuName)}
      </Typography>
    );

  return (
    <MenuContainer component="li" title={name}>
      <MenuLink to={link} onClick={handleMenuToggle}>
        <MenuItemBox sx={{ display: name === 'Referral' && !activeReferal ? 'none' : 'block' }} data-testid="menu-item">
          {navList(name, activeReferal)}
        </MenuItemBox>
      </MenuLink>
    </MenuContainer>
  );
}

export default NavbarMenuV2;
