import { Container } from '@mui/material';

import Error from '@/components/error/Error';

export function PageError() {
  return (
    <Container maxWidth="xl">
      <Error status={500} />
    </Container>
  );
}

export default PageError;
