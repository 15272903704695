import { Alert, AlertTitle, Stack } from '@mui/material';
import { useParams } from 'react-router-dom';

import PageLoadErrorAlertContent from '@/components/error/PageLoadErrorAlertContent';
import { PortalPartnerLayout } from '@/components/layout/portal-partner-layout';
import {
  ProductDescription,
  ProductImage,
  ProductOverview,
  ProductSummary,
  ProductUsage
} from '@/components/products/portal-partner/product-details';
import {
  DEFAULT_NO_VALUE_STR,
  getProductBrand,
  getProductCategory,
  getProductHowToUseSteps,
  getProductSpectrum,
  getProductTerpeneProfile,
  getProductUsageTime
} from '@/components/products/utils';
import { FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useProductDetails from '@/hooks/products/useProductDetails';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { ProductStrain, ProductStrainKey } from '@/types';
import { getWhiteLabelProductImageUrl } from '@/utils/getProductImageUrl';

import PageContentGrid from './components/PageContentGrid';
import PageSkeleton from './components/PageSkeleton';

export default function ProductDetailsPage() {
  const { id: productId } = useParams();
  const { flags } = useFeatureFlags();
  const { product, isError, isLoading, refetch } = useProductDetails(productId ?? '');

  const handleClickRetry = () => refetch();

  if (isLoading) {
    return <PageSkeleton />;
  }

  if (isError || !product) {
    return (
      <Alert severity="error">
        <AlertTitle>Page load error</AlertTitle>
        <PageLoadErrorAlertContent onClickRetry={handleClickRetry} />
      </Alert>
    );
  }

  const showProductDisplayAndUsage =
    flags[FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE] ?? ffDefaults[FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE];

  const canShowDescription = Boolean(showProductDisplayAndUsage && product.description_for_display);
  const canShowHowToUse = Boolean(showProductDisplayAndUsage && product.how_to_use);

  const productWhiteLabelImageUrl = getWhiteLabelProductImageUrl({
    whiteLabelImageUrl: product?.ProductFormulation?.whitelabel_image_url
  });
  const productStrainName = (product.ProductStrain?.name ?? null) as ProductStrain | null;
  const productStrainKey = (product.ProductStrain?.shopify_key ?? null) as ProductStrainKey | null;
  const productCategory = getProductCategory(product.ProductIngredient?.name).toString();
  const productTerpeneProfile = getProductTerpeneProfile(product.contents);
  const productUsageTime = getProductUsageTime(productStrainKey);
  const productSpectrum = getProductSpectrum(product.spectrum_id);
  const productHowToUseSteps = getProductHowToUseSteps(product.how_to_use);
  const productBrandName = getProductBrand(product?.Suppliers);
  const productFormulationName = product.ProductFormulation?.name ?? null;
  const productDescription = product.description_for_display ?? DEFAULT_NO_VALUE_STR;

  return (
    <>
      <PortalPartnerLayout.Back backText="Edit medication" defaultRoute="/patient/refill" />
      <PageContentGrid>
        <ProductImage
          sx={{ gridArea: 'image', pt: { xs: 4, md: 0 }, width: '100%', minWidth: '100px' }}
          src={productWhiteLabelImageUrl}
          alt={product.name}
        />
        <ProductSummary
          sx={{ gridArea: 'summary', mb: 3 }}
          product={{
            name: product.name,
            brandName: productBrandName,
            strainName: productStrainName,
            strainKey: productStrainKey,
            formulationName: productFormulationName,
            thcStrengthForDisplay: product.thc_strength_for_display,
            cbdStrengthForDisplay: product.cbd_strength_for_display,
            isConcession: product.is_concession
          }}
        />
        <Stack gap={6} sx={{ gridArea: 'details' }}>
          <ProductOverview
            product={{
              brandName: productBrandName,
              strainName: productStrainName ?? DEFAULT_NO_VALUE_STR,
              scheduleName: product.ProductSchedule?.name ?? DEFAULT_NO_VALUE_STR,
              usageTime: productUsageTime,
              spectrum: productSpectrum,
              category: productCategory,
              terpeneProfile: productTerpeneProfile
            }}
          />
          {canShowDescription && <ProductDescription product={{ description: productDescription }} />}
          {canShowHowToUse && <ProductUsage product={{ howToUseSteps: productHowToUseSteps }} />}
        </Stack>
      </PageContentGrid>
    </>
  );
}
