import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { FF_ENABLE_ORDER_PHARMACY_ALLOCATION, FF_ENABLE_RECOMMENDED_DEVICES } from '@/constants/featureFlags';
import type { ErrorDisplay } from '@/context/ErrorManagement';
import { useErrorManagement } from '@/context/ErrorManagement';
import { PreferencesContext } from '@/context/Preferences';
import { useGetRecommendedDevices } from '@/hooks/order/useGetRecommendedDevices';
import { usePatientRefillQuery } from '@/hooks/order/usePatientRefillQuery';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useGoogleAnalytics from '@/hooks/useGoogleAnalytics';
import useShippingPrice from '@/hooks/useShippingPrice';
import { getProductPurchaseIssue, UNKNOWN_SUPPLIER_NAME } from '@/pages/umeds/order-medication/util';
import { getPatientDiscountCreditLineItems } from '@/services/patientCredit.service';
import { PaymentService } from '@/services/payment.service';
import type { LoginUser } from '@/types';
import { GoogleAnalyticsEventName } from '@/types';
import { Logger } from '@/utils/logger';

import type { PersistedProductDetails } from './persistedOrderDetails';
import { managePersistedOrderDetails } from './persistedOrderDetails';
import type {
  AsyncContent,
  CreditLineItem,
  PatientRefillResponse,
  PrescribedProduct,
  RecommendedDevice
} from './types';
import { FORMULATION_ID_DEVICE, generateInitialQuantities, nullToUndefined } from './util';

const logger = new Logger('OrderMedicationHook');

export const RECOMMENDED_DEVICE_MAX_QUANTITY = 10;

export interface SelectedProductData {
  quantity_original: number;
  product_name: string;
  repeats: number;
  price: number;
  notAddable: boolean | number;
  remaining_units: number;
  interval: number;
  is_out_of_stock: boolean;
  short_name: string | null;
  is_concession: boolean;
  supplier: string;
}

export interface SelectedProduct {
  id: number;
  data: SelectedProductData & {
    product_id: number;
    quantity: number;
  };
}

export type ConfirmationPromise<T> = (data: T) => Promise<boolean>;
type PlaceOrderFn = () => Promise<void>;
type ValidateOrderFn = (
  showNotAddableConfirmation: ConfirmationPromise<SelectedProduct[]>,
  placeOrderCallback: PlaceOrderFn
) => Promise<void>;

const generateSelectedProducts = (
  productSelection: Map<number, number>,
  products: PrescribedProduct[]
): SelectedProduct[] => {
  const selectedProducts: SelectedProduct[] = [];
  productSelection.forEach((quantity, productId) => {
    const product = products.find((prd) => prd.id === productId);
    if (!product || quantity === 0) {
      return;
    }
    selectedProducts.push({
      id: productId,
      data: {
        ...product,
        product_name: product.name,
        quantity_original: product.quantity,
        quantity,
        product_id: product.id,
        repeats: product.repeats || 0,
        interval: product.interval || 0,
        short_name: product.short_name || null,
        supplier: product.Suppliers?.[0]?.supplier_name || UNKNOWN_SUPPLIER_NAME
      }
    });
  });
  return selectedProducts;
};

const generatePersistedProductDetails = (
  productSelection: Map<number, number>,
  products: PrescribedProduct[]
): PersistedProductDetails[] => {
  const selectedProducts: PersistedProductDetails[] = [];
  productSelection.forEach((quantity, productId) => {
    const product = products.find((prd) => prd.id === productId);
    if (!product || quantity === 0) {
      return;
    }
    selectedProducts.push({
      id: product.id,
      name: product.name,
      quantity,
      price: product.price
    });
  });
  return selectedProducts;
};

const generateGoogleAnalyticsEcommercePayload = (products: PrescribedProduct[]) => ({
  currency: 'AUD',
  value: products.reduce((acc, item) => acc + item.price * item.quantity, 0),
  items: products.map((product) => ({
    name: product.name,
    quantity: product.quantity,
    price: product.price
  }))
});

export interface OrderMedicationController {
  state: {
    refillResponse: AsyncContent<PatientRefillResponse>;
    recommendedDevices: AsyncContent<RecommendedDevice[]>;
    isSubmitting: boolean;
    selectedProducts: SelectedProduct[];
    hasSelectedAnyProducts: boolean;
    creditDiscounts: CreditLineItem[];
    // These are exposed for unit testing, but shouldn't be accessed in production code
    __testing: {
      productSelection: Map<number, number>;
      isPesistedStateLoaded: boolean;
    };
  };

  actions: {
    setProductQuantity: (id: number) => (quantity: number) => void;
    getProductQuantity: (id: number) => number;
    setInitialProductQuantities: (preLoadProductIDs: number[] | undefined) => void;
    submitOrder: (params: {
      confirmNotAddableCallback: ConfirmationPromise<SelectedProduct[]>;
      validateOrderCallback: ValidateOrderFn;
    }) => Promise<void>;
    validateOrder: ValidateOrderFn;
    enqueueError: (error: ErrorDisplay) => void;

    // These are exposed for unit testing, but should never be called
    __testing: {
      setIsSubmitting: Dispatch<SetStateAction<boolean>>;
      placeOrderShopify: () => Promise<void>;
      setIsPersistedSateLoaded: Dispatch<SetStateAction<boolean>>;
    };
  };
}

export const useOrderMedicationController = (
  orderId: number,
  user: LoginUser | undefined
): OrderMedicationController => {
  const navigate = useNavigate();
  const { enqueueError } = useErrorManagement();
  const { flags } = useFeatureFlags();
  const { sendGoogleAnalyticsGTagEvent } = useGoogleAnalytics();
  const persistedOrderDetails = managePersistedOrderDetails();

  const [productSelection, setProductSelection] = useState<Map<number, number>>(new Map([]));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPesistedStateLoaded, setIsPersistedSateLoaded] = useState(false);

  const shippingCosts = useShippingPrice();

  const { umedsMarketingConsent } = useContext(PreferencesContext);

  /**
   * ***********
   * Fetch data from Circuit Service
   ************
   */

  const refillResponse = usePatientRefillQuery(user!.id, orderId, {
    error: {
      message: 'Page load error',
      log: 'Call to fetch patient refill data failed',
      showToast: true
    }
  });

  const recommendedDevices = useGetRecommendedDevices(user!.id, {
    enabled: flags[FF_ENABLE_RECOMMENDED_DEVICES],
    error: {
      message: 'Unable to fetch recommended devices',
      log: `An error occurred fetching recommended devices for user ${user!.id}`,
      showToast: true
    }
  });

  /**
   * *************
   * Derived State
   **************
   */

  const refillAPIData = refillResponse.data;

  const selectedProducts = generateSelectedProducts(productSelection, refillAPIData?.products || []);
  const hasSelectedAnyProducts = selectedProducts.filter((x) => x.data.product_id !== null).length > 0;

  const creditDiscounts = !refillAPIData
    ? []
    : (getPatientDiscountCreditLineItems(
        {
          selectedProducts,
          patientDiscounts: refillAPIData?.patientDiscounts,
          currentCreditDiscounts: refillAPIData?.currentCreditDiscounts,
          patientCredit: refillAPIData?.patientCredit
        },
        true,
        shippingCosts.shipping,
        shippingCosts.shippingGst
      ) as CreditLineItem[]);

  // Persist current product selection, to allow navigating to/from the page without losing selection
  useEffect(() => {
    if (!isPesistedStateLoaded) {
      return;
    }

    const persistedProductDetails = generatePersistedProductDetails(productSelection, refillAPIData?.products || []);
    persistedOrderDetails.setOrderDetails(persistedProductDetails, creditDiscounts);
  }, [productSelection]);

  /**
   * *********
   * Callbacks
   *********
   */

  const setProductQuantity = useCallback(
    (productId: number) => (quantity: number) => {
      // Send Google Analytics event for Add to Card/Remove from Cart
      const oldQuantity = productSelection.get(productId);
      const isIncreasing = typeof oldQuantity === 'number' && oldQuantity > quantity;
      const product = refillAPIData?.products.find((x) => x.id === productId);
      if (product !== undefined) {
        sendGoogleAnalyticsGTagEvent(
          isIncreasing ? GoogleAnalyticsEventName.ADD_TO_CART : GoogleAnalyticsEventName.REMOVE_FROM_CART,
          generateGoogleAnalyticsEcommercePayload([product])
        );
      }

      if (quantity === 0) {
        setProductSelection((oldProductSelection) => {
          oldProductSelection.delete(productId);
          return new Map(oldProductSelection);
        });
      } else {
        setProductSelection((oldProductSelection) => {
          oldProductSelection.set(productId, quantity);
          return new Map(oldProductSelection);
        });
      }
    },
    [productSelection, setProductSelection, refillAPIData]
  );

  const getProductQuantity = useCallback(
    (productId: number) => productSelection.get(productId) || 0,
    [productSelection]
  );

  const setInitialProductQuantities = useCallback(
    (preLoadProductIDs: number[] | undefined) => {
      const prefilledProductQuantities = generateInitialQuantities(refillAPIData!, preLoadProductIDs || []);
      const sessionData = persistedOrderDetails.getSelection();

      // If a user has already loaded and edited their product selection, default to that
      // Otherwise, load in the generated initial quantities
      const products = refillAPIData?.products || [];
      const dataToPrefill =
        sessionData !== null ? sessionData : generatePersistedProductDetails(prefilledProductQuantities, products);

      if (dataToPrefill) {
        dataToPrefill.forEach((entry: PersistedProductDetails) => {
          // Product available can change over the course of a session,
          // so always ensure a product is still available before adding it to the order
          // Otherwise, there's no UI to remove it
          const product = (refillAPIData?.products || []).find((prd) => prd.id === entry.id);
          const isProductAvailable = product !== undefined && getProductPurchaseIssue(product) === null;
          if (isProductAvailable) {
            prefilledProductQuantities.set(entry.id, entry.quantity);
          }
        });
      }
      setIsPersistedSateLoaded(true);
      setProductSelection(prefilledProductQuantities);
    },
    [refillAPIData, setProductSelection]
  );

  const placeOrderShopify = useCallback(async () => {
    const { order } = refillAPIData!;

    const productsPayload = selectedProducts
      .filter((selectedProd) => {
        const product = refillAPIData!.products.find(({ id }) => id === selectedProd.data.product_id);
        return (product && product.remaining_units > 0) || product?.formulation_id === FORMULATION_ID_DEVICE;
      })
      .map(({ data }) => ({
        quantity: data.quantity,
        productId: data.product_id
      }));

    const { checkoutRedirectUrl, error: responseError } = await PaymentService.getCheckoutRedirectUrl(
      productsPayload,
      order?.id,
      nullToUndefined(order?.order_code),
      // TODO: to avoid empty arguments like this, `getCheckoutRedirectUrl`
      // should be refactored to accept an object
      // eslint-disable-next-line no-undefined
      undefined,
      umedsMarketingConsent
    );

    if (checkoutRedirectUrl) {
      window.location.replace(checkoutRedirectUrl);
    } else {
      setIsSubmitting(false);
      logger.error(responseError || 'Failed to retrieve checkoutUrl');
      throw new Error('Something went wrong, please try again');
    }
  }, [refillAPIData, selectedProducts]);

  // Last chance to stop order submission, perform validation logic here.
  const validateOrder = useCallback(
    async (
      showNotAddableConfirmation: ConfirmationPromise<SelectedProduct[]>,
      placeOrderCallback: PlaceOrderFn
    ): Promise<void> => {
      try {
        setIsSubmitting(true);
        // If there are 'notAddable' products in the list, require user confirmation before submitting
        // 'notAddable' represents how many days ago a product was shipped (as a number) or false if not shipped.
        const notAddableProducts = selectedProducts.filter((x) => x.data.notAddable || x.data.notAddable === 0);
        if (notAddableProducts.length > 0) {
          const shouldContinue = await showNotAddableConfirmation(notAddableProducts);
          if (!shouldContinue) {
            setIsSubmitting(false);
            return;
          }
        }

        await placeOrderCallback();
      } catch (error) {
        logger.error(error);
        setIsSubmitting(false);
        throw error;
      }
    },
    [setIsSubmitting, selectedProducts]
  );

  const submitOrder = async (params: {
    confirmNotAddableCallback: ConfirmationPromise<SelectedProduct[]>;
    validateOrderCallback: ValidateOrderFn;
  }) => {
    const { confirmNotAddableCallback, validateOrderCallback } = params;

    if (isSubmitting || !refillAPIData) {
      return;
    }

    const { order } = refillAPIData;

    // Send event via Google Analytics
    sendGoogleAnalyticsGTagEvent(GoogleAnalyticsEventName.CLICK_CTA, {
      link_id: 'cart_checkout_button'
    });

    if (flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION]) {
      await validateOrderCallback(confirmNotAddableCallback, async () => {
        navigate(
          {
            pathname: '/patient/refill-pharmacy'
          },
          {
            state: { orderId: order?.id, orderCode: order?.order_code, uMedsMarketingConsent: !!umedsMarketingConsent }
          }
        );
      });
    } else {
      await validateOrderCallback(confirmNotAddableCallback, placeOrderShopify);
    }
  };

  return {
    state: {
      refillResponse,
      recommendedDevices,
      isSubmitting,
      selectedProducts,
      hasSelectedAnyProducts,
      creditDiscounts,
      // These are exposed for unit testing, but shouldn't be accessed in production code
      __testing: {
        productSelection,
        isPesistedStateLoaded
      }
    },

    actions: {
      setProductQuantity,
      getProductQuantity,
      setInitialProductQuantities,
      submitOrder,
      validateOrder,
      enqueueError,

      // These are exposed for unit testing, but should never be called
      __testing: {
        setIsSubmitting,
        placeOrderShopify,
        setIsPersistedSateLoaded
      }
    }
  };
};

export default useOrderMedicationController;
