import { initTsrReactQuery } from '@ts-rest/react-query/v5';

import getAuthBearerToken from '@/utils/getAuthBearerToken';

import type { AppRouter, ClientArgs } from '@ts-rest/core';
import { apiConfig } from './api.config';

const clientArgs: ClientArgs = {
  baseUrl: apiConfig().baseApiUrl,
  baseHeaders: {
    authorization: () => getAuthBearerToken()
  }
} as const;

export const createTsRestClient: <TContract extends AppRouter>(
  contract: TContract
) => ReturnType<typeof initTsrReactQuery<TContract, typeof clientArgs>> = (contract) =>
  initTsrReactQuery(contract, clientArgs);
