import { useQuery } from '@tanstack/react-query';

import { PharmacyService } from '@/services/pharmacy.service';

function useGetPharmacyUpdateHistory() {
  const { isLoading, data, isError, isSuccess, error } = useQuery({
    queryKey: ['pharmacyUpdateHistory'],
    queryFn: async () => {
      try {
        const response = await PharmacyService.getUpdateHistory();
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch pharmacy update history: ${(e as { message: string }).message}`);
      }
    }
  });
  return { isLoading, data, isError, isSuccess, error };
}

export default useGetPharmacyUpdateHistory;
