import ViewPatientsActions from '@/components/admin/viewPatients/ViewPatientsActions';
import ViewPatientsTable from '@/components/admin/viewPatients/ViewPatientsTable';
import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import AdminPageLayout from '@/components/layout/AdminPageLayout';

function ViewPatients() {
  return (
    <AdminPageLayout title="Patients">
      <ManagedErrorToasts />
      <ViewPatientsActions />
      <ViewPatientsTable />
    </AdminPageLayout>
  );
}

export default ViewPatients;
