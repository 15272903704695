import TableSearchInput from '@/components/common/TableSearchInput';
import settings from '@/constants/constants';
import useGetPharmacyReport from '@/hooks/pharmacy/useGetPharmacyReport';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import useDownload from '@/hooks/useDownload';
import type { PharmacyReport } from '@/types/pharmacy.types';
import { Logger } from '@/utils/logger';
import { PaginationVariant, Table } from '@montugroup/design-system';
import { Box, Button, styled, Typography } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import type { ColumnDef } from '@tanstack/react-table';
import { useState } from 'react';
import NumberFormat from 'react-number-format';

const columns: ColumnDef<PharmacyReport>[] = [
  {
    accessorKey: 'order_code',
    header: 'Order ID',
    enableSorting: true
  },
  {
    accessorKey: 'quantity',
    header: 'Qty',
    enableSorting: true
  },
  {
    accessorKey: 'order_date',
    header: 'Date',
    enableSorting: true
  },
  {
    accessorKey: 'patient_name',
    header: 'Patient',
    enableSorting: true
  },
  {
    accessorKey: 'pharmacy_name',
    header: 'Pharmacy',
    enableSorting: true
  },
  {
    accessorKey: 'sasStatus',
    header: 'Status',
    enableSorting: true
  },
  {
    accessorKey: 'orderStatus',
    header: 'Order Status',
    enableSorting: true
  },
  {
    accessorKey: 'price',
    header: 'Value',
    enableSorting: true,
    cell: (row) => {
      return (
        <NumberFormat value={row.row.original.price} displayType={'text'} thousandSeparator={true} prefix={'AU$'} />
      );
    }
  }
];
const Conatiner = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5)
}));

const HeaderContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  gap: theme.spacing(2)
}));

const sortFieldOverrides = {
  order_code: 'order_code',
  quantity: 'quantity',
  order_date: 'order_date',
  patient_name: 'patient_name',
  pharmacy_name: 'pharmacy_name',
  sasStatus: 'sasStatus',
  orderStatus: 'orderStatus',
  price: 'price'
};

export function ViewPharmacyReport() {
  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [date, setDate] = useState<Date | null>(null);

  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel();
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({ sortFieldOverrides });
  const { downloadFile } = useDownload();

  const logger = new Logger('ViewPharmacyReport');

  const { isLoading, data } = useGetPharmacyReport({ page, pageSize, sortingOrder, date: date || undefined });
  const filterData = data;

  const handleDateChange = async (selectedDate: Date | null) => {
    if (selectedDate) {
      setDate(selectedDate);
    }
  };

  const handleExport = async () => {
    try {
      const itemsFormatted: { [key: string]: string | number }[] = [];
      const exportData = date ? data : filterData;

      exportData.forEach((item: PharmacyReport) => {
        itemsFormatted.push({
          'Pharmacy Name': item.pharmacy_name.replace(/,/g, ''),
          'Order Code': item.order_code,
          'Order Status': item.orderStatus,
          'Patient Name': item.patient_name,
          'Product Name': item.product_name,
          'Order Date': item.order_date,
          Quantity: item.quantity,
          'SAS Status': item.sasStatus,
          'Value(AUD)': item.price
        });
      });
      const fileName = `${new Date().getTime()}-montu-pharmacy-orders-report.csv`;

      await downloadFile(fileName, `${settings.url}/excel/pharmacy/report/download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify(itemsFormatted),
        responseType: 'blob'
      });
    } catch (error) {
      logger.error('Error during export:', error);
    }
  };

  return (
    <Conatiner>
      <Typography component="h5">All Pharmacy Report:</Typography>
      <HeaderContainer>
        <DatePicker onChange={handleDateChange} label="Reporting Month" views={['year', 'month']} value={date} />
        <Button onClick={handleExport} color="info">
          Export All
        </Button>
      </HeaderContainer>
      <TableSearchInput value={globalFilter} onChange={(value) => setGlobalFilter(value)} />
      <Table
        isLoading={isLoading}
        globalFilter={globalFilter}
        data={data || []}
        columns={columns}
        total={data?.length || 0}
        columnVisibility={{ id: false }}
        showPagination
        pageSize={pageSize}
        onPaginationModelChange={handlePaginationModelChange}
        hasRowBackgroundColor={false}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        onSortingChange={handleSortingOrderChange}
      />
    </Conatiner>
  );
}

export default ViewPharmacyReport;
