import { useTheme } from '@mui/material';

import { skyBlue } from '@/components/products/detail/common';

export function UpperLeftDecoration() {
  const theme = useTheme();
  return (
    <div style={{ position: 'absolute', top: 0, left: 0 }}>
      <svg width="258" height="43" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M258 0L-3.74342e-06 2.265e-05L0 43C106.594 43 200.802 26.0039 258 0Z"
          fill={theme.palette.primary.main}
        />
      </svg>
    </div>
  );
}

export function LowerRightDecoration() {
  return (
    <div style={{ position: 'absolute', bottom: 0, right: 0 }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="726" height="153" viewBox="0 0 726 153" fill="none">
        <path
          d="M1.33196e-05 153L726 153L726 -6.37361e-05C426.05 -3.74033e-05 160.954 60.4745 1.33196e-05 153Z"
          fill={skyBlue}
        />
      </svg>
    </div>
  );
}
