import { MgPrescription } from '@montugroup/icons';
import { CalendarToday, ContactSupportOutlined, ContentPaste, Home, Person, Receipt } from '@mui/icons-material';
import type { SvgIcon } from '@mui/material';

import USER_ROLES from '@/constants/userRoles';
import { BRANDS } from '@/types';

export const APP_BAR_HEIGHT = '77px';
export interface INavMenu {
  id: number;
  name: string;
  link: string;
  roleId: number[];
  brand: number;
  icon: typeof SvgIcon;
}

export const alternaleafAppBarConfig: INavMenu[] = [
  {
    id: 1,
    name: 'Home',
    link: '/home',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: Home
  },
  {
    id: 2,
    name: 'Consultations',
    link: '/patient/consultations',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: CalendarToday
  },
  {
    id: 3,
    name: 'Prescriptions',
    link: '/patient/prescription',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: ContentPaste
  },
  {
    id: 4,
    name: 'View orders',
    link: '/patient/orders',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: Receipt
  },
  {
    id: 5,
    name: 'Medicine information',
    link: '/product-catalog',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: MgPrescription
  },
  {
    id: 6,
    name: 'Profile',
    link: '/profile-detail',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.patient, USER_ROLES.ml, USER_ROLES.pharmacist, USER_ROLES.admin],
    brand: BRANDS.ALTERNALEAF,
    icon: Person
  },
  {
    id: 7,
    name: 'Help',
    link: '/faq',
    roleId: [USER_ROLES.patient],
    brand: BRANDS.ALTERNALEAF,
    icon: ContactSupportOutlined
  }
];

export const createOrderButton = {
  id: 8,
  name: 'Create order',
  link: '/patient/refill',
  roleId: [USER_ROLES.patient],
  brand: BRANDS.ALTERNALEAF,
  icon: Receipt
};
