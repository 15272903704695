import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import PharmacyUpdateHistoryTable from '@/components/pharmacy/PharmacyUpdateHistoryTable';

function PharmacyUpdateHistory() {
  return (
    <AdminPageLayout title="Pharmacy update history">
      <ManagedErrorToasts />
      <PharmacyUpdateHistoryTable />
    </AdminPageLayout>
  );
}

export default PharmacyUpdateHistory;
