import { Box, styled, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';

const PageContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin: 0 1.6rem;
`;

export type AdminPageProps = {
  title?: string;
};

function AdminPageLayout({ title, children }: PropsWithChildren<AdminPageProps>) {
  return (
    <PageContainer>
      {title && (
        <Typography component="h2" variant="h5">
          {title}
        </Typography>
      )}
      {children}
    </PageContainer>
  );
}

export default AdminPageLayout;
