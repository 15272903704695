import type { ConsultationResponseDto } from '@montugroup/pms-api-contracts';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AccordionSummary, Box, Container, Accordion as MUIAccordion, Stack, styled, Typography } from '@mui/material';
import type { SyntheticEvent } from 'react';

import {
  getDate,
  getName,
  getPaymentStatus,
  getPractionerType
} from '../../../components/patient/consultations/common';

export const MainContainer = styled(Container)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.20)',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  marginLeft: 0,
  marginBottom: theme.spacing(4),
  backgroundColor: theme.palette.common.white
}));

export const Accordion = styled(MUIAccordion)(() => ({
  border: 'none',
  boxShadow: 'none'
}));

export const ConsultItem = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  gap: theme.spacing(2),
  alignItems: 'center',
  color: theme.palette.common.black,
  paddingLeft: theme.spacing(2),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(6)
  }
}));

export const ConsultItemHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.black,
  marginTop: theme.spacing(4),
  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(4)
  }
}));

export interface IHistoryConsultationCard {
  consultation: ConsultationResponseDto;
  isExpanded: boolean;
  toggleExpanded: (_event: SyntheticEvent, expanded: boolean) => void;
}

const renderRow = (label: string, value: string | null) => (
  <ConsultItem>
    <Typography component="span" fontWeight="medium">
      {label}:{' '}
    </Typography>
    {label === 'Payment status' ? getPaymentStatus(value, true) : <Typography>{value}</Typography>}
  </ConsultItem>
);

export function HistoryConsultationCard({ consultation, isExpanded, toggleExpanded }: IHistoryConsultationCard) {
  return (
    <MainContainer maxWidth="md">
      <Accordion disableGutters expanded={isExpanded} onChange={toggleExpanded}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon htmlColor="black" />}
          aria-controls="consultation-content"
          id="consultation-content"
        >
          <Box>
            {getPractionerType(consultation)}
            <ConsultItemHeader>
              <Typography
                component="span"
                fontWeight={(theme) => theme.typography.fontWeightMedium}
                paddingRight={(theme) => theme.spacing(2)}
              >
                ID:
              </Typography>
              {consultation.code}
            </ConsultItemHeader>
          </Box>
        </AccordionSummary>
        <Stack gap={(theme) => theme.spacing(3)} marginBottom={(theme) => theme.spacing(3)}>
          {renderRow('Name', getName(consultation))}
          {renderRow('Date', getDate(consultation.startTime, consultation.endTime, true).date)}
          {renderRow('Time', getDate(consultation.startTime, consultation.endTime, true).time)}
          {renderRow('Consultation Status', consultation.status)}
          {renderRow('Payment status', consultation.paymentStatus)}
        </Stack>
      </Accordion>
    </MainContainer>
  );
}

export default HistoryConsultationCard;
