import React from 'react';
import PropTypes from 'prop-types';

const Button = ({
  id,
  icon,
  iconPosition,
  type,
  className,
  text,
  value,
  onClick,
  loading,
  style,
  disabled,
  // eslint-disable-next-line no-unused-vars
  ...props
}) => {
  return (
    <React.Fragment>
      <button
        {...props}
        id={id}
        type={type}
        value={value}
        onClick={onClick}
        className={className}
        style={style}
        disabled={loading || disabled}
      >
        {icon && iconPosition === 'left' ? <i className={`${icon} mr-2`} aria-hidden="true"></i> : null}
        {loading ? <div className="lds-dual-ring"></div> : text}
        {icon && iconPosition === 'right' ? <i className={`${icon} ml-2`} aria-hidden="true"></i> : null}
      </button>
    </React.Fragment>
  );
};
Button.defaultProps = {
  type: 'submit',
  className: '',
  iconPosition: 'left',
  disabled: false
};
Button.propTypes = {
  type: PropTypes.oneOf(['button', 'submit']),
  className: PropTypes.string,
  text: PropTypes.any,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
};

export default Button;
