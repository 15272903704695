import { FF_ENABLE_BRAINFISH, FF_ENABLE_ZENDESK_WIDGET } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

import BrainfishHelpdesk from './BrainfishHelpdesk';
import ZendeskChatWidget from './ZendeskChatWidget';

function HelpDeskSelector() {
  const { flags } = useFeatureFlags();
  const isZendeskEnabled = flags[FF_ENABLE_ZENDESK_WIDGET];
  const isBrainfishEnabled = flags[FF_ENABLE_BRAINFISH];

  if (isZendeskEnabled) {
    return <ZendeskChatWidget />;
  }

  if (isBrainfishEnabled) {
    return <BrainfishHelpdesk />;
  }

  return null;
}

export default HelpDeskSelector;
