import { BrandThemeProvider } from '@montugroup/themes';
import { Box, styled } from '@mui/material';

import Sessions from './Sessions';
import TopBanner from './TopBanner';

const sessionData = [
  {
    id: 1,
    header: 'LATEST',
    title: 'Learn how Complex Pain, Anxiety and Sleep can be improved with Medical Cannabis',
    presenter: 'Presented By Dr Kevin Cheng (MBBS)',
    videoSrc: 'https://circuit.montu.com.au/assets/videos/20210326+CPD+Recording+Dr+Cheng_vF.mp4',
    description: [
      'One in three Australian adults with severe pain, have high or very high levels of psychological distress. Chronic Pain and Anxiety related disorders account for more than 85% of all Medical Cannabis prescriptions in Australia.',
      'Medical cannabis has shown to be complementary to conventional pain management and in many cases led to a significant reduction in side effects associated with opioid, NSAID and SSRI intake.'
    ],
    benefits: [
      '- Understand how Medical Cannabis is being used to treat highly prevalent but complex comorbidities',
      '- How Medical Cannabis improves sleep and daily function',
      '- Understand the place of Schedule 4 cannabis products and driving'
    ]
  },
  {
    id: 2,
    header: 'PAST SESSIONS',
    title: 'Learn how Arthritis, Fibromyalgia and Endometriosis patients can benefit from Medical Cannabis',
    presenter: 'Presented By Dr Anjali Didi (MBBS. MRCGP. BSc. DFSRH (lon). DipGeriatrics. DipDerm. Lociut. LocSdi.)',
    videoSrc: 'https://circuit.montu.com.au/assets/videos/20200827+Montu+-+Dr+Didi+Recording_vF2.mp4',
    description: [
      'Chronic Pain accounts for more than 70% of all Medical Cannabis prescriptions in Australia, making it the number one indication approved by the TGA. Medical cannabis has shown to be complimentary to conventional pain management and in many cases led to a significant reduction in opioid intake. Learn how it can be applied to patients with Arthritis, Fibromyalgia, and Endometriosis'
    ],
    benefits: [
      '- Realize the relevance of Medical Cannabis for chronic pain',
      '- Learn about the opioid sparing effect',
      '- Know how to initiate Medical Cannabis with your patients',
      '- Be aware of relevant considerations when prescribing'
    ]
  },
  {
    id: 3,
    title: 'Learn how 100 Australian doctors prescribe Medical Cannabis',
    presenter: 'Presented By Mariam Sener (Medical Liaison, B.Pharm.Hons)',
    videoSrc: 'https://circuit.montu.com.au/assets/videos/20200723+Montu+-+CPD+Insights+Recording_vF.mp4',
    description: [
      'We have put together insights into the typical characteristics of Medical Cannabis prescribing in Australia from a randomly selected sample of 100 doctors within our network. Find out what doctors prescribe for, why they prescribe Medical Cannabis and what their experience has been so far. Learn about the patient profiles, their treatment regimes and other practical insights.'
    ],
    benefits: [
      '- Receive insights into the Australian medical cannabis prescription landscape',
      '- Learn how leading prescribers select medical cannabis',
      '- Gain understanding of the application process and typical treatment regimes'
    ]
  },
  {
    id: 4,
    title: 'Learn how your Chronic Pain patients can benefit from Medical Cannabis',
    presenter: 'Presented By Dr Hilda Jessop (MBBS, FRACGP, FRCGP, PGCMedEd)',
    videoSrc: 'https://circuit.montu.com.au/assets/videos/20200604+Montu+-+CPD+Hilda+Recording_vF.mp4',
    description: [
      'Chronic Pain is one of the most prevalent conditions, affecting 1 in every 5 Australians. Medical Cannabis can complement existing treatment regimes, where conventional medication is not providing sufficient relief. We will provide practical advice on how to initiate your patients and have invited Dr Hilda Jessop to share insights and the experiences of her Chronic Pain patients.'
    ],
    benefits: [
      '- Realize the relevance of Medical Cannabis for chronic pain',
      '- Learn about the opioid sparing effect',
      '- Know how to initiate Medical Cannabis with your patients',
      '- Be aware of relevant considerations when prescribing'
    ]
  },
  {
    id: 5,
    title: 'Learn how to evaluate and prescribe Medical Cannabis to your patients',
    presenter: 'Presented By Dr Ram Sakkera (MBBS, MD, FRACGP)',
    videoSrc: 'https://circuit.montu.com.au/assets/videos/20200430+Montu+-+CDP+Dr+Ram_Recording.mp4',
    description: [
      'Starting into Medical Cannabis can seem complicated and confusing. We will cover the basics to get you started and have invited Dr Ram to share his experience in prescribing Medical Cannabis by walking us through patient case studies from his clinic.'
    ],
    benefits: [
      '- Understand the relevance of Medical Cannabis for your clinic and patients',
      '- Have an overview of the applicable treatment areas',
      '- Be aware of relevant considerations when prescribing',
      '- Know how to initiate and titrate Medical Cannabis with your patient'
    ]
  }
];

const Section = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(3, 0)
}));

const Container = styled(Box)`
  min-width: 100%;
`;

const TopContainer = styled(Box)(({ theme }) => ({
  margin: theme.spacing(-4, -1.5, 0, -1.5),
  height: '100%'
}));

function CPDSessions() {
  return (
    <BrandThemeProvider variant="montu">
      <TopContainer>
        <TopBanner pText="CANNABIS PRESCRIBER DISCUSSIONS" h1Text="Watch Our Latest Roundtable Session" />
        <Section>
          <Container>
            {sessionData.map((session) => (
              <Sessions
                key={session.title}
                header={session.header}
                title={session.title}
                presenter={session.presenter}
                videoSrc={session.videoSrc}
                description={session.description}
                benefits={session.benefits}
                isLast={session.id === sessionData.length}
              />
            ))}
          </Container>
        </Section>
      </TopContainer>
    </BrandThemeProvider>
  );
}

export default CPDSessions;
