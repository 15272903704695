import { Autorenew as RefillIcon, CallSplit as SplitIcon } from '@mui/icons-material';
import { Box, Checkbox, IconButton } from '@mui/material';

import settings from '@/constants/constants';
import { useOrders } from '@/context/orders/Orders';
import { useSplitOrderModal } from '@/context/orders/OrdersModals';
import type { Order } from '@/services/data.service';
import { useNavigate } from 'react-router-dom';

type ActionTableCellProps = {
  order: Order;
  isDoc: boolean;
  isAdmin: boolean;
};

function ActionsTableCell({ order, isDoc, isAdmin }: ActionTableCellProps) {
  const { selectedOrders, toggleOrderSelection } = useOrders();
  const { showSplitOrderModal } = useSplitOrderModal();
  const navigate = useNavigate();

  const handleRefillOrder = () => {
    navigate(`/order/${order.id}/refill`);
  };

  const canSplitOrder = () => {
    if (order.payment_status === 'INVOICED') {
      return false;
    }

    const allowedOrderStatuses = [
      settings.orderStatus.OUT_OF_REPEATS,
      settings.orderStatus.PHARMACY_OUT_OF_STOCK,
      settings.orderStatus.INTERVAL_TIME,
      settings.orderStatus.PHARMACY_PARTIALLY_OUT_OF_STOCK,
      settings.orderStatus.UNABLE_TO_DISPENSE
    ];

    const notAllowedOrderStatuses = [
      settings.orderStatus.CANCELLED,
      settings.orderStatus.PENDING,
      settings.orderStatus.TEST,
      settings.orderStatus.ERROR,
      settings.orderStatus.EXPIRED,
      settings.orderStatus.PMS_DELETED,
      settings.orderStatus.PHARMACY_DISPENSED,
      settings.orderStatus.PHARMACY_COMPLETED,
      settings.orderStatus.PHARMACY_PENDING,
      settings.orderStatus.PHARMACY_TEST,
      settings.orderStatus.NO_PHARMACY,
      settings.orderStatus.RE_SCRIPT,
      settings.orderStatus.AWAITING_TOKEN
    ];

    return (
      allowedOrderStatuses.includes(order.order_status_id) || !notAllowedOrderStatuses.includes(order.order_status_id)
    );
  };

  return (
    <Box display="flex">
      <IconButton
        size="small"
        color="inherit"
        disabled={order.is_discharge}
        onClick={handleRefillOrder}
        data-testid="refill-button"
      >
        <RefillIcon />
      </IconButton>
      {!isDoc && (
        <IconButton
          size="small"
          color="inherit"
          disabled={!canSplitOrder()}
          onClick={() => showSplitOrderModal(order)}
          data-testid="split-button"
        >
          <SplitIcon />
        </IconButton>
      )}
      {isAdmin && (
        <Checkbox
          size="small"
          checked={selectedOrders.includes(order.id)}
          onChange={() => toggleOrderSelection(order.id)}
        />
      )}
    </Box>
  );
}

export default ActionsTableCell;
