import TableSearchInput from '@/components/common/TableSearchInput';
import USER_ROLES from '@/constants/userRoles';
import { useDoctors } from '@/context/doctors/Doctors';
import useUser from '@/hooks/user/useUser';
import type { GPData } from '@/services/data.service';
import { PaginationVariant, Table } from '@montugroup/design-system';
import { Link } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import debounce from 'awesome-debounce-promise';

const columns: ColumnDef<GPData>[] = [
  {
    accessorKey: 'id',
    header: '',
    enableSorting: false
  },
  {
    accessorKey: 'gp_code',
    header: 'ID',
    enableSorting: true,
    cell: ({ row }) => <Link href={`/gp-register/${row.original.id}`}>{row.original.gp_code}</Link>
  },
  {
    accessorKey: 'gp_name',
    header: 'Name',
    enableSorting: true
  },
  {
    accessorKey: 'email',
    header: 'Email',
    enableSorting: true
  },
  {
    accessorKey: 'joined_date',
    header: 'Joined',
    enableSorting: true
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    enableSorting: true
  },
  {
    accessorKey: 'ahpraNo',
    header: 'Ahpra',
    enableSorting: true
  },
  {
    accessorKey: 'clinic1',
    header: 'Clinic',
    enableSorting: false
  },
  {
    accessorKey: 'medical_Liasion',
    header: 'BDMS',
    enableSorting: false
  },
  {
    accessorKey: 'patients_count',
    header: 'Patients',
    enableSorting: false
  },
  {
    accessorKey: 'total_prescriptions',
    header: 'Prescriptions',
    enableSorting: false
  },
  {
    accessorKey: 'last_prescription',
    header: 'Last prescribed',
    enableSorting: false
  }
];

function DoctorsTable() {
  const { data: user } = useUser();

  const {
    pageSize,
    loading,
    doctors,
    doctorCount,
    filter,
    setFilter,
    handlePaginationModelChange,
    handleSortingOrderChange
  } = useDoctors();

  const onFilterChange = debounce((value: string) => setFilter(value), 500);

  return (
    <>
      <TableSearchInput value={filter} onChange={onFilterChange} />
      <Table
        isLoading={loading}
        data={doctors}
        total={doctorCount}
        columns={columns}
        columnVisibility={{ id: false, medical_Liasion: user?.roleId !== USER_ROLES.ml }}
        showPagination
        manualPagination
        pageSize={pageSize}
        onPaginationModelChange={handlePaginationModelChange}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        onSortingChange={handleSortingOrderChange}
      />
    </>
  );
}

export default DoctorsTable;
