import { Skeleton } from '@mui/material';

function LoadingContentBlock() {
  return (
    <>
      <Skeleton animation="wave" variant="text" sx={{ fontSize: '2rem' }} />
      <Skeleton animation="wave" variant="text" sx={{ fontSize: '.5rem' }} />
      <Skeleton animation="wave" variant="rounded" height={60} sx={{ mb: 4 }} />
    </>
  );
}

export default LoadingContentBlock;
