import DoctorsActions from '@/components/admin/doctors/DoctorsActions';
import DoctorsTable from '@/components/admin/doctors/DoctorsTable';
import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { DoctorsProvider } from '@/context/doctors/Doctors';

function Doctors() {
  return (
    <DoctorsProvider>
      <AdminPageLayout title="Doctors">
        <DoctorsActions />
        <DoctorsTable />
      </AdminPageLayout>
    </DoctorsProvider>
  );
}

export default Doctors;
