import { Alert } from '@mui/material';

import type { PrescribedProduct } from '@/hooks/patient/refill/types';
import type { OrderMedicationController } from '@/hooks/patient/refill/useOrderMedicationController';
import { RECOMMENDED_DEVICE_MAX_QUANTITY } from '@/hooks/patient/refill/useOrderMedicationController';

import IncrementProductRefillCard from './IncrementProductRefillCard';
import ProductCategoryAccordion from './ProductCategoryAccordion';

export interface RecommendedDevicesSectionProps {
  recommendedDevices: OrderMedicationController['state']['recommendedDevices'];
  prescribedDevices: Pick<PrescribedProduct, 'id'>[];
  getQuantity: (id: number) => number;
  setQuantity: (id: number) => (newQty: number) => void;
}

export function RecommendedDevicesSection(props: RecommendedDevicesSectionProps) {
  const { recommendedDevices, prescribedDevices, getQuantity, setQuantity } = props;
  const prescribedDeviceIds = prescribedDevices.map((device) => device.id);

  const nonPrescribed = (recommendedDevices.data || []).filter((x) => !prescribedDeviceIds.includes(x.id));
  const hasDevices = recommendedDevices.isSuccess && nonPrescribed.length > 0;
  const shouldShowError = !recommendedDevices.isLoading && recommendedDevices.isError;
  const isEmpty = recommendedDevices.isLoading || (recommendedDevices.isSuccess && nonPrescribed.length === 0);

  // Hide section if there are no items to show
  if (isEmpty) {
    return null;
  }

  return (
    <ProductCategoryAccordion label="Recommended devices" count={nonPrescribed.length} startCollapsed>
      {!shouldShowError ? null : <Alert severity="error">Unable to retrieve Recommended Devices</Alert>}
      {!hasDevices
        ? null
        : nonPrescribed.map((device) => (
            <IncrementProductRefillCard
              key={device.id}
              product={device}
              prescription={{ maxQuantity: RECOMMENDED_DEVICE_MAX_QUANTITY }}
              quantity={getQuantity(device.id)}
              setQuantity={setQuantity(device.id)}
            />
          ))}
    </ProductCategoryAccordion>
  );
}

export default RecommendedDevicesSection;
