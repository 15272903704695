import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import { toast } from '@montugroup/design-system';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import Select from 'react-select';

import { ConcessionPatientOnboardChip } from '@/components/concession';
import {
  FF_DISABLE_ADMIN_DISCOUNT_CREATION,
  FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE,
  FF_ENABLE_SHOPIFY_DISCOUNTS,
  FF_SHOPIFY_DISCOUNT_MODAL_QUERY_PARAMS,
  FF_SHOPIFY_DISCOUNT_SUPER_ADMINS
} from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useAddressValidation from '@/hooks/user/useAddressValidation';
import isValidPhoneNumber from '@/utils/isValidPhoneNumber';
import { Logger } from '@/utils/logger';
import { LocationInput } from '@montugroup/design-system';
import { Box, Chip, styled } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import Button from '../../components/common/button';
import CircleLoader from '../../components/common/circleLoader';
import FormInput from '../../components/common/FormInput';
import MedicationTable from '../../components/patient/MedicationTable';
import PatientApprovals from '../../components/patient/patientApprovals';
import PatientDiscountModal from '../../components/patient/patientDiscountModal';
import PatientDiscounts from '../../components/patient/patientDiscounts';
import PatientPrescriptions from '../../components/patient/patientPrescriptions';
import Switch from '../../components/toggle/switch';
import settings from '../../constants/constants';
import '../../scss/patientRefill.scss';
import { AuthService } from '../../services/authentication.service';
import { getCountries, getStates } from '../../services/data.service';
import { OrderService } from '../../services/order.service';

const logger = new Logger('patientOnboard');

const { url, shopifyDiscountsURL } = settings;

const ChipContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  padding-left: 0.5rem;
  padding-bottom: 1.5rem;
  padding-top: 0.5rem;
  gap: 0.5rem;
`;

function PatientOnboard(props) {
  const params = useParams();
  const navigate = useNavigate();

  const { id } = params;
  const { roleId } = AuthService.getUser().user;

  const validator = new SimpleReactValidator({
    validators: {
      e164Phone: {
        rule: (val) => {
          return isValidPhoneNumber(val);
        },
        message: 'Invalid phone number.',
        required: true
      }
    }
  });
  const noteValidator = new SimpleReactValidator();

  const { flags, loading: ffLoading } = useFeatureFlags();

  const [states, setStates] = React.useState([]);
  const [loading, setLoading] = React.useState(true);
  const [noteLoading, setNoteLoading] = React.useState(true);
  const [selectedDiscount, setSelectedDiscount] = React.useState(null);
  const [showModal, setShowModal] = React.useState({
    create: false,
    edit: false
  });
  const [productList, setProductList] = React.useState([]);
  const [patientDiscounts, setPatientDiscounts] = React.useState([]);
  const [orderList, setOrderList] = React.useState([]);
  const [data, setData] = React.useState({
    id,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    address: '',
    sub_address: '',
    country: 13,
    state: null,
    city: '',
    defaultCountry: { value: 'australia', label: 'Australia' },
    postal_code: '',
    formattedAddress: '',
    general_practitioner_id: AuthService.getUser().user.id,
    countries: [],
    sas: [],
    sasIngredients: [],
    visibility: '',
    newPharmacyName: '',
    newPharmacyAddress: '',
    pharmacy_name: '',
    pharmacy: '',
    pharmacies: [{ value: 'Choose Pharmacy', label: 'Choose Pharmacy' }],
    user_id: '',
    patient_id: '',
    patient_client: null,
    status: 0,
    patientCode: '',
    note: null,
    showMoreCount: 5,
    notes: [],
    addNote: false,
    discountType: '',
    discount: null,
    products: [{ label: 'Select Medication', value: '' }],
    productId: '',
    percentage: '1',
    startDate: null,
    endDate: null,
    indefinite: 0,
    createdDiscountId: '',
    medications: [],
    prescriptionDetail: [],
    priority: false,
    isActive: false,
    previousValues: { priority: false },
    patient: {
      firstName: '',
      lastName: '',
      id: '',
      code: ''
    },
    isConcessionPatient: false,
    isDischarged: false
  });

  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE];
  const { isDischarged, isConcessionPatient } = data;

  const [errors, setErrors] = React.useState({
    first_name: validator.message('first name', data.firstName, 'required'),
    last_name: validator.message('last name', data.lastName, 'required'),
    email: validator.message('email', data.email.trim(), 'required|email'),
    phone: validator.message('phone-number', data.phone, 'required|e164Phone'),
    address: validator.message('address', data.address, 'required'),
    city: validator.message('city', data.city, 'required'),
    state: validator.message('State', data.state, 'required'),
    country: validator.message('Country', data.country, 'required'),
    zipcode: validator.message('zip-code', data.postal_code, 'required')
  });
  const [noteError, setNoteErrors] = React.useState({
    note: noteValidator.message('Patient Note', data.note, 'required')
  });

  document.title = 'Update Patient - Montu Group | Making Medical Cannabis Accessible';

  const { validateParcelLocker, validateStreetNumber } = useAddressValidation();
  const [enableManualAddress, setEnableManualAddress] = React.useState(false);

  const fetchData = React.useCallback(async () => {
    const countryList = await getCountries();
    const pharList = await OrderService.getPharmacies();
      axios
        .get(`${url}/patient/${id}`)
        .then((resp) => {
          setOrderList(resp.data?.getPatientOrdersList);
          setProductList(resp.data.products);
          let splitAddress = resp.data.address;
          splitAddress = splitAddress.split('$$$$');
          const address = splitAddress[0];
          const subAddress = splitAddress[1];
          let pharmAddress;
          if (resp.data.pharmacy_id) {
            pharmAddress = resp.data.pharmaddress.split('$$$$')[0];
          }
          const formattedAddress = [address, subAddress, resp.data.city, resp.data.state, resp.data.postal_code]
            .filter(Boolean)
            .join(', ');
          setData({
            ...data,
            patient_id: resp.data.id,
            patient_client: resp.data.client_id,
            isActive: resp.data.getReferralToggleDetails?.active || false,
            user_id: resp.data.user_id,
            firstName: resp.data.first_name,
            lastName: resp.data.last_name,
            patientCode: resp.data.patient_code,
            note: resp.data.patient_note,
            email: resp.data.email,
            phone: resp.data.phone,
            address,
            sub_address: subAddress,
            state: resp.data.state_id,
            defaultCountry: { label: resp.data.country_name, value: resp.data.country_id },
            city: resp.data.city,
            postal_code: resp.data.postal_code,
            formattedAddress,
            sasIngredients: resp.data.SASIngredients,
            sas: resp.data.sas,
            ...(!resp.data.pharmacy_id && {
              newPharmacyName: resp.data.patient_pharmacy_name,
              newPharmacyAddress: resp.data.patient_pharmacy_address
            }),
            pharmacy: resp.data.pharmacy_id,
            pharmacy_name: `${resp.data.pharmacy} | ${pharmAddress} | ${resp.data.pharmsuburb} | ${resp.data.pharmzipcode}`,
            status: Number(resp.status),
            countries: countryList ? countryList.map((x) => ({ label: x.name, value: x.id })) : [],
            pharmacies: pharList.data.map((pharmacy) => ({
              label: `${pharmacy.name} | ${pharmacy.address.split('$$$$')[0]} | ${pharmacy.city} | ${
                pharmacy.zip_code
              }`,
              value: pharmacy.id
            })),
            medications: resp.data.products,
            notes: resp.data.notes,
            priority: resp.data.priority,
            previousValues: {
              priority: resp.data.priority
            },
            patient: {
              firstName: resp.data.first_name,
              lastName: resp.data.last_name,
              id: resp.data.id,
              code: resp.data.patient_code
            },
            ...(resp.data.prescriptions && { prescriptionDetail: resp.data.prescriptions }),
            isConcessionPatient: Boolean(resp.data.has_valid_concession_card),
            isDischarged: Boolean(resp.data.is_discharge),
            shopify_customer_id: resp.data.shopify_customer_id,
            shopifyProducts: resp.data.shopifyProducts
          });
          setPatientDiscounts(resp.data.patientDiscounts);
          setLoading(false);
        })
        .catch((err) => {
          logger.error(err);
          if (err.response?.status === 403) {
            navigate('/404');
          }
          setData({
            ...data,
            status: err.response?.status
          });
        });
  }, []);

  const setState = async () => {
    const stateList = await getStates(data.country);
    setStates(stateList ? stateList.map((x) => ({ label: x.name, value: x.id })) : []);
  };

  React.useEffect(() => {
    setState();
    setNoteLoading(false);
  }, [data.country]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  const goToShopifyStore = () => window.open(`${shopifyDiscountsURL}/discounts`, '_blank', 'noopener noreferrer');

  const handleUserInput =
    (prop, type = 'text') =>
    (e) => {
      let value;
      switch (type) {
        case 'number':
          value = parseInt(e.target.value, 10);
          break;
        case 'text':
          value = e.target.value;
          break;
        case 'boolean':
          value = e.target.checked;
          break;
        case 'date':
          value = e;
          break;
        default:
          value = e.target.value;
      }
      setData({ ...data, [prop]: value });

      if (prop === 'address' && !validateParcelLocker(value).isValid) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          address: `${validateParcelLocker(value).errorMessageTitle} ${validateParcelLocker(value).errorMessage}`
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          address: ''
        }));
      }
    };

  const handleClicked = (e) => {
    e.preventDefault();
    setData({ ...data, isClicked: true, isremove: true, none: '', visibility: 'd-none' });
  };

  const handleClick = () => {
    setData({ ...data, isClicked: false, newPharmacyName: null, newPharmacyAddress: null });
  };

  const handlePharmacy = (e) => {
    setData({
      ...data,
      pharmacy: e.value,
      pharmacy_name: e.label,
      isClicked: false,
      newPharmacyName: '',
      newPharmacyAddress: ''
    });
  };

  const handlePharmChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
      pharmacy: null,
      pharmacy_name: 'select...'
    });
  };

  const handleState = (selectedStateValue) => {
    setData({
      ...data,
      state: selectedStateValue.value
    });
  };

  const onCancelNote = () => {
    noteValidator.hideMessages();
    setNoteErrors({
      note: noteValidator.message('Patient Note', data.note, 'required')
    });
    setData({ ...data, addNote: false, note: null });
  };

  const onSubmissionNote = async (e) => {
    e.preventDefault();
    if (!noteValidator.allValid()) {
      noteValidator.showMessages();
      setNoteErrors({
        note: noteValidator.message('Patient Note', data.note, 'required')
      });
      return;
    }
    setNoteLoading(true);
    setNoteErrors({
      note: noteValidator.message('Patient Note', data.note, 'required')
    });
    const noteResponse = await axios.post(`${url}/patient/note`, {
      patient_id: data.id,
      note: data.note
    });
    if (noteResponse.data.status === 200) {
      const existingNotes = data.notes;
      existingNotes.splice(0, 0, noteResponse.data.data);

      setData({
        ...data,
        note: '',
        notes: existingNotes
      });
      setNoteLoading(false);
      toast.success(noteResponse.data.message);
    } else {
      toast.error(noteResponse.data.message);
      logger.error('onSubmissionNote', noteResponse?.data?.message);
    }
  };

  const increaseShowMore = () => {
    setData({ ...data, showMoreCount: data.showMoreCount + 5 });
  };

  const onCancelPatientEdit = () => {
    navigate('/patients');
  };

  const onSubmission = async (e) => {
    e.preventDefault();
    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        first_name: validator.message('first name', data.firstName, 'required'),
        last_name: validator.message('last name', data.lastName, 'required'),
        email: validator.message('email', data.email, 'required|email'),
        phone: validator.message('phone-number', data.phone, 'required|e164Phone'),
        address: validator.message('address', data.address, 'required'),
        city: validator.message('city', data.city, 'required'),
        state: validator.message('State', data.state, 'required'),
        country: validator.message('Country', data.country, 'required'),
        zipcode: validator.message('zip-code', data.postal_code, 'required')
      });
      return;
    }

    try {
      await axios.put(`${url}/patient/${data.id}`, {
        firstName: data.firstName,
        lastName: data.lastName,
        email: data.email.trim(),
        phone: data.phone,
        address: `${data.address}$$$$${data.sub_address}`,
        city: data.city,
        state: data.state,
        country_id: data.country,
        zip_code: data.postal_code,
        gp_id: data.general_practitioner_id,
        newPharmacyName: data.newPharmacyName,
        newPharmacyAddress: data.newPharmacyAddress,
        pharmacy_id: data.pharmacy === '-' ? null : data.pharmacy,
        note: data.note,
        roleId,
        priority: data.priority,
        previousValues: data.previousValues
      });

      toast.success('Patient successfully updated!', {});
      setData({ ...data, addNote: false, note: null, loading: false });

      if (AuthService.isDoc()) {
        navigate('/gp/patients');
      }
      if (AuthService.isAdmin()) {
        navigate('/patients');
      }
    } catch (error) {
      toast.error('Error updating patient');
      setData({ ...data, loading: false });
      logger.error('Error onboarding update patient', error);
    }
  };

  const updateReferralFeature = async () => {
    const response = await axios.post(`${url}/patient/referral/${data.id}`);
    if (response.data.status === 200) {
      toast.success(response.data.message, {});
      setData({
        ...data,
        isActive: !data.isActive
      });
    } else {
      toast.error(response.data.message);
      logger.error('updateReferralFeature', response?.data?.message);
    }
  };

  const onboardOrder = () => {
    navigate(`/order-patient/${data.patient_id}`);
  };

  if (data.id && data.status === 404) {
    navigate('/404');
  }

  if (ffLoading) {
    return <CircleLoader />;
  }

  const isShopifyDiscountEnabled = flags[FF_ENABLE_SHOPIFY_DISCOUNTS];
  const isShopifyModalQueryParamsEnabled = isShopifyDiscountEnabled && flags[FF_SHOPIFY_DISCOUNT_MODAL_QUERY_PARAMS];
  const isDiscountCreationDisabled = flags[FF_DISABLE_ADMIN_DISCOUNT_CREATION] || isDischarged;
  const isSuperAdmin = AuthService.isSuperAdmin() || flags[FF_SHOPIFY_DISCOUNT_SUPER_ADMINS];

  const handleAddDiscountClick = () => {
    return isShopifyModalQueryParamsEnabled
      ? setShowModal({
          edit: false,
          create: true
        })
      : goToShopifyStore();
  };

  const handleOnSelectedAddress = (addressData) => {
    const placeDetails = {
      address_components: [
        {
          long_name: addressData.street_number,
          short_name: addressData.street_number,
          types: ['street_number']
        }
      ]
    };

    const streetNumberValidationResult = validateStreetNumber(placeDetails);

    if (!streetNumberValidationResult.isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: `${streetNumberValidationResult.errorMessageTitle} ${streetNumberValidationResult.errorMessage}`
      }));
    } else {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: ''
      }));
    }

    const address = [addressData.subpremise, addressData.street_number, addressData.address].filter(Boolean).join(' ');

    const formattedAddress = [address, addressData.suburb || addressData.city, addressData.state, addressData.postcode]
      .filter(Boolean)
      .join(', ');

    setData({
      ...data,
      address,
      city: addressData.suburb || addressData.city,
      state: states.find((x) => addressData.state === x.label)?.value,
      postal_code: addressData.postcode,
      formattedAddress,
      sub_address: '' // The sub_address field is optional
    });
  };

  const handleManualAddressSave = () => {
    const addressValidationResult = validateParcelLocker(data.address);

    if (!addressValidationResult.isValid) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        address: `${addressValidationResult.errorMessageTitle} ${addressValidationResult.errorMessage}`
      }));
      return;
    }

    const formattedAddress = [
      data.address,
      data.city,
      states.find((x) => x.value === data.state)?.label,
      data.postal_code
    ]
      .filter(Boolean)
      .join(', ');

    setData({
      ...data,
      formattedAddress
    });
    setEnableManualAddress(false);
  };

  const handleOnReset = () => {
    setData({
      ...data,
      address: '',
      city: '',
      state: '',
      postal_code: '',
      sub_address: '',
      formattedAddress: ''
    });
    setErrors((prevErrors) => ({
      ...prevErrors,
      address: ''
    }));
  };

  const handleManualAddressClick = () => {
    setErrors((prevErrors) => ({
      ...prevErrors,
      address: ''
    }));
    setEnableManualAddress(true);
  };

  return (
    <div className="container">
      {data.id && !isDischarged && (
        <Button
          text="New Patient Order"
          type="submit"
          loading={loading}
          className="btn btn-primary float-right mt-5"
          onClick={onboardOrder}
        />
      )}
      {isSuperAdmin && data.id && !isDiscountCreationDisabled ? (
        isShopifyDiscountEnabled ? (
          <Button
            text="Add Discount"
            type="submit"
            loading={loading}
            icon="fa fa-shopify"
            className="btn btn-outline-primary float-right mt-5 mr-3"
            onClick={handleAddDiscountClick}
          />
        ) : (
          <Button
            text="Discount"
            type="submit"
            loading={loading}
            className="btn btn-outline-primary float-right mt-5 mr-3"
            style={{ width: '15%' }}
            onClick={() =>
              setShowModal({
                edit: false,
                create: true
              })
            }
          />
        )
      ) : (
        ''
      )}
      <div className="d-flex align-items-end float-right mt-5">
        {AuthService.isAdmin() && data.id && data.patient_client && (
          <>
            <label className="mb-2 pb-0 mr-3" htmlFor="referral">
              {}Enable Referral
            </label>
            <Switch
              className="mr-3"
              isOn={data.isActive}
              disabled={false}
              handleToggle={() => updateReferralFeature()}
            />
          </>
        )}
      </div>
      <br />
      <h3 id="title">Onboarding Form</h3>
      <h5 className="p-2">Patient Details</h5>
      <h6 className="p-2">{data.patientCode}</h6>
      {(isDischarged || isConcessionPatient) && (
        <ChipContainer>
          {isDischarged && <Chip color="error" label="Discharged" data-testid="discharged-status-chip" />}
          {isConcessionPatient && <ConcessionPatientOnboardChip data-testid="concession-status-chip" />}
        </ChipContainer>
      )}
      <PatientDiscountModal
        show={showModal.create ? showModal.create : showModal.edit}
        close={() => setShowModal({ create: false, edit: false })}
        availableProducts={productList}
        shopifyProducts={data.shopifyProducts}
        existingDiscount={showModal.edit ? selectedDiscount : ''}
        patientId={data.patient_id}
        shopifyCustomerId={data.shopify_customer_id}
        fetchData={() => fetchData()}
      />

      {!loading ? (
        <>
          <div className="col-md-12 mt-10">
            <div className="form-row">
              <FormInput
                type="text"
                name="firstName"
                label="First Name"
                className="col-md-6"
                value={data.firstName}
                onChange={handleUserInput('firstName')}
                error={errors.first_name}
              />
              <FormInput
                type="text"
                name="lastName"
                label="Last Name"
                className="col-md-6"
                value={data.lastName}
                onChange={handleUserInput('lastName')}
                error={errors.last_name}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-row">
              <FormInput
                type="email"
                name="email"
                label="Patient Email"
                value={data.email}
                className="col-md-6"
                onChange={handleUserInput('email')}
                error={errors.email}
              />
              <FormInput
                type="text"
                name="phone"
                label="Patient Phone"
                placeholder="eg.0343244556"
                value={data.phone}
                className="col-md-6"
                onChange={handleUserInput('phone')}
                error={errors.phone}
              />
            </div>
          </div>
          <div className="col-md-12">
            <div className="form-group ">
              {isGooglePlacesEnabled && !enableManualAddress ? (
                <>
                  <label className="float-left mr-2 label" htmlFor={id}>
                    Patient Address
                  </label>
                  <LocationInput
                    id="address"
                    value={data.formattedAddress || ''}
                    name="address"
                    aria-label="Patient Address"
                    googlePlacesApiKey={settings.googlePlacesApiKey}
                    variant="outlined"
                    onSelectedAddress={handleOnSelectedAddress}
                    onReset={handleOnReset}
                    manualAddressFn={handleManualAddressClick}
                    error={errors.address}
                  />
                </>
              ) : (
                <>
                  <FormInput
                    type="text"
                    name="address"
                    label="Patient Address"
                    value={data.address || ''}
                    placeholder=""
                    info="Street Address"
                    onChange={handleUserInput('address')}
                    error={errors.address}
                  />
                  <div className="form-group ">
                    <FormInput
                      type="text"
                      name="sub_address"
                      value={data.sub_address || ''}
                      info="Address Line 2"
                      onChange={handleUserInput('sub_address')}
                    />
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 my-auto">
                      <FormInput
                        type="text"
                        name="city"
                        placeholder="City"
                        value={data.city || ''}
                        info="City/Suburb"
                        onChange={handleUserInput('city')}
                        error={errors.city}
                      />
                    </div>
                    <div className="form-group col-md-6 my-auto pb-2">
                      <Select
                        name="state"
                        className={`${errors.state ? 'rounded dropdown-border' : ''}`}
                        value={
                          data.state ? states.find((x) => x.value === data.state) : { label: 'Select State', value: '' }
                        }
                        onChange={handleState}
                        isSearchable
                        placeholder="Select State"
                        options={states}
                      />
                      {errors.state}
                      <small className="form-text text-muted ml-1">State / Province / Region</small>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-6 my-auto">
                      <FormInput
                        type="text"
                        name="postal_code"
                        placeholder="Postcode"
                        value={data.postal_code || ''}
                        info="Postcode"
                        onChange={handleUserInput('postal_code')}
                        error={errors.zipcode}
                      />
                    </div>
                    <div className="form-group col-md-6 my-auto pb-2">
                      <Select
                        name="country"
                        defaultValue={data.defaultCountry}
                        onChange={(e) => setData({ ...data, country: e.value })}
                        isSearchable
                        placeholder="Select Country"
                        options={data.countries}
                      />
                      {errors.country}
                      <small className="form-text text-muted ml-1">Country</small>
                    </div>
                  </div>
                  <div>
                    <Button
                      text="Save address"
                      type="button"
                      className="btn btn-primary"
                      onClick={handleManualAddressSave}
                    />
                  </div>
                </>
              )}
            </div>

            <div className="form-row align-items-end">
              {data.id && (
                <>
                  <div className="form-group p-1 col-md-6 pr-1">
                    <label htmlFor="pharmacy">{}Trusted Partner Pharmacies (Type to search)</label>
                    <Select
                      value={{ label: data.pharmacy_name, value: data.pharmacy }}
                      name="pharmacy"
                      onChange={handlePharmacy}
                      isSearchable
                      options={data.pharmacies}
                    />
                  </div>
                  <div className="form-group p-1 col-md-6 pl-4">
                    {!data.isClicked && (
                      <>
                        <h5 className="add-option mb-0" role="presentation" onClick={handleClicked}>
                          + Add Patient's Preferred Pharmacy
                        </h5>
                        <small className="pl-4 form-text text-primary">
                          (if not currently part of our trusted network)
                        </small>
                      </>
                    )}
                  </div>
                  {(data.isClicked || (data.newPharmacyName && data.newPharmacyAddress)) && (
                    <div className="col-md-12">
                      <h6>Preferred Pharmacy details</h6>
                      <div className="form-row form-group align-items-baseline">
                        <FormInput
                          type="text"
                          className="col-md-5"
                          name="newPharmacyName"
                          label="Pharmacy Name"
                          value={data.newPharmacyName}
                          onChange={handlePharmChange}
                        />
                        <FormInput
                          type="text"
                          className="col-md-6"
                          name="newPharmacyAddress"
                          label="Pharmacy Address"
                          value={data.newPharmacyAddress}
                          onChange={handlePharmChange}
                        />
                        <Button
                          text="x"
                          type="button"
                          className="btn btn-primary btn-sm col-md-1 ml-auto"
                          onClick={handleClick}
                          style={{ maxWidth: '50px' }}
                        />
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>

            {data.id && (
              <>
                {data.patient_client && <MedicationTable productList={productList} />}
                <PatientApprovals
                  sas={data.sas}
                  sasIngredients={data.sasIngredients}
                  isAdmin={AuthService.isAdmin()}
                  id={data.id}
                />
              </>
            )}
            {/* Super Admin login only */}
            {AuthService.isAdmin() && (
              <PatientDiscounts
                patientDiscounts={patientDiscounts}
                setSelectedDiscount={setSelectedDiscount}
                setShowModal={setShowModal}
              />
            )}
            <PatientPrescriptions prescription={data.prescriptionDetail} redirect={url} />
            <div>
              <hr />
            </div>
            {data.id && !data.addNote && (
              <div className="form-row">
                <div className="form-group col-md-12">
                  <Button
                    text="New Note"
                    type="submit"
                    loading={loading}
                    className="btn btn-primary btn-md ml-3  mb-3 float-right"
                    onClick={() => setData({ ...data, note: null, addNote: true })}
                  />
                </div>
              </div>
            )}
            {data.id && (
              <>
                {data.addNote && (
                  <>
                    {' '}
                    <div className="mt-2">
                      <textarea
                        placeholder="New Note"
                        className="form-control col-md-12 mt-3 mb-2"
                        name="note"
                        label="Notes"
                        value={data.note}
                        onChange={handleUserInput('note')}
                      />
                      {noteError.note}
                      <div className="mt-2">
                        <Button
                          text="Cancel"
                          type="submit"
                          className="btn btn-outline-primary"
                          onClick={onCancelNote}
                        />
                        <Button
                          text="Post"
                          type="submit"
                          loading={noteLoading}
                          className="btn btn-primary btn-md ml-3  mb-6"
                          onClick={onSubmissionNote}
                        />
                      </div>
                    </div>
                  </>
                )}
                {data.notes && data.notes.length > 0 && (
                  <div className="d-block mt-3">
                    <b>Patient Notes:</b>
                    {data.notes.slice(0, data.showMoreCount).map((row) => (
                      <>
                        <div key={row.id} className="my-3">
                          <div style={{ 'text-align': 'justify', 'font-size': '12px' }}>
                            <span>{row.note}</span>
                          </div>
                          <div style={{ 'font-size': '12px', 'margin-top': '5px' }}>
                            <b>
                              {row.User.first_name} {row.User.last_name}
                            </b>
                          </div>
                          <div style={{ 'font-size': '12px' }}>
                            <span style={{ 'font-size': '10px' }}>
                              {moment(row.created_date).local().format('hh:mm A, DD-MMM-YYYY')}
                            </span>
                          </div>
                        </div>
                        <hr />
                      </>
                    ))}
                  </div>
                )}
                {data.notes && data.notes.length > 0 && !(data.notes.length <= data.showMoreCount) && (
                  <div className="d-block">
                    <Button
                      type="submit"
                      text="Show More >>"
                      className="bg-white"
                      onClick={increaseShowMore}
                      style={{
                        'text-decoration': 'underline',
                        color: '#069',
                        cursor: 'pointer',
                        'background-color': 'none!important',
                        border: 'none',
                        padding: '0!important'
                      }}
                    />
                  </div>
                )}
              </>
            )}
            <div className="patientEdit d-flex float-right">
              {roleId !== 2 && (
                <div>
                  <Button
                    text="Cancel"
                    type="submit"
                    className="btn btn-outline-primary"
                    onClick={onCancelPatientEdit}
                  />
                </div>
              )}

              <Button
                text={data.id ? 'Update Profile' : 'Prescription Details'}
                type="submit"
                loading={loading}
                className={AuthService.isPatient() ? 'btn pro-onSubmit' : 'btn btn-primary ml-3'}
                onClick={onSubmission}
              />
            </div>
          </div>
        </>
      ) : (
        <CircleLoader />
      )}
    </div>
  );
}

export default PatientOnboard;
