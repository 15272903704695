import settings from '@/constants/constants';
import { CloseRounded } from '@mui/icons-material';
import { Box, Button, CircularProgress, IconButton, Modal, styled, Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4
};

interface ShippitTestModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading: boolean;
  trackingNumber: string;
}

/**
 * This modal shows a success message when shippit token is added for a pharmacy
 *  If verification is enabled, it also shows the output of sending a test order to shippit through the pharmacy.
 * @returns Modal with opening button
 */
const ShippitTestModal = ({ isOpen, onClose, onSubmit, loading, trackingNumber }: ShippitTestModalProps) => {
  const isVerificationEnabled = trackingNumber !== 'Unavailable';
  return (
    <>
      <StyledButton type="submit" onClick={onSubmit} disabled={loading}>
        {loading ? <CircularProgress size={24} /> : 'Submit'}
      </StyledButton>
      <Modal open={isOpen} onClose={onClose} aria-labelledby="modal-title" aria-describedby="modal-description">
        <Box sx={style}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography id="modal-title" variant="h6" component="h2">
              Success!
            </Typography>
            <IconButton onClick={onClose}>
              <CloseRounded />
            </IconButton>
          </Box>
          {isVerificationEnabled && (
            <>
              <Box my={5}>
                <Typography>Test order made in shippit.</Typography>
                <Typography id="modal-description">Shippit tracking number: {trackingNumber}</Typography>
              </Box>
              <Link target="_blank" to={`${settings.shippitTrackingUrl}${trackingNumber}`}>
                View in Shippit
              </Link>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ShippitTestModal;

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: theme.spacing(2),
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.shadows[1],
  cursor: 'pointer',
  '&:hover': {
    opacity: 0.5
  }
}));
