import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import FilterableTable from 'rc-data-table';
import CircleLoader from '../common/circleLoader';
import Button from '../common/button';
import settings from '../../constants/constants';
import NumberFormat from 'react-number-format';
import withRouter from '@/utils/withRouter';

//react-filterable components
let filterData;

const url = settings.url;
const filter = (props) => {
  filterData = props.records;
};
class viewPharmacy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      date: '',
      sample: [],
      loading: true,
    };
  }

  handleChange = async (date) => {
    let selDate = new Date(date);
    selDate.setDate(selDate.getDate() + 1);
    this.setState({
      date: selDate,
    });
    await axios
      .get(url + '/order/pharmacy/report', {
        params: { date: selDate },
      })
      .then((data) => {
        this.setState({ data: data.data, loading: false });
      });
  };

  async componentDidMount() {
    document.title = 'View All Pharmacy Report - Montu Group | Making Medical Cannabis Accessible';
    await axios.get(url + '/order/pharmacy/report').then((data) => {
      this.setState({ data: data.data, loading: false });
    });
    this.forceUpdate();
    document.getElementsByClassName('filter-input')[0].placeholder = 'Search';
  }
  //export as excel
  downloadFile = async () => {
    const { data } = this.state;
    let itemsFormatted = [];
    if (this.state.date !== null) {
      data.forEach((item) => {
        itemsFormatted.push({
          'Pharmacy Name': item.pharmacy_name.replace(/,/g, ''),
          'Order Code': item.order_code,
          'Order Status': item.orderStatus,
          'Patient Name': item.patient_name,
          'Product Name': item.product_name,
          'Order Date': item.order_date,
          Quantity: item.quantity,
          'SAS Status': item.sasStatus,
          'Value(AUD)': item.price,
        });
      });
    } else {
      filterData.forEach((item) => {
        itemsFormatted.push({
          'Pharmacy Name': item.pharmacy_name.replace(/,/g, ''),
          'Order Code': item.order_code,
          'Order Status': item.orderStatus,
          'Patient Name': item.patient_name,
          'Product Name': item.product_name,
          'Order Date': item.order_date,
          Quantity: item.quantity,
          'SAS Status': item.sasStatus,
          'Value(AUD)': item.price,
        });
      });
    }
    let fileTitle = new Date().getTime() + '-montu-pharmacy-orders-report';
    await axios({
      url: 'excel/pharmacy/report/download',
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    let { loading, data, date } = this.state;
    const fields = [
      { name: 'order_code', displayName: 'Order ID', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'quantity', displayName: 'Qty', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'order_date',
        displayName: 'Date',
        sortFieldName: 'sortOrderDate',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      { name: 'patient_name', displayName: 'Patient', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      { name: 'product_name', displayName: 'Product', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'pharmacy_name',
        displayName: 'Pharmacy',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      { name: 'sasStatus', displayName: 'Status', inputFilterable: true, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'orderStatus',
        displayName: 'Order Status',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
      },
      {
        name: 'price',
        displayName: 'Value',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        render: (e) => {
          return <NumberFormat value={e.value} displayType={'text'} thousandSeparator={true} prefix={'AU$'} />;
        },
      },
      { name: 'filter', displayName: 'Filter', render: filter, tdClassName: 'd-none', thClassName: 'd-none' },
    ];
    const Array = [10, 20, 30, 50, 100];
    return (
      <div>
        {loading ? (
          <CircleLoader />
        ) : (
          <div className="container-fluid">
            <h5>All Pharmacy Report:</h5>
            <Button text="Export All" className="btn btn-md btn-primary float-right" onClick={this.downloadFile} />
            <div className="float-right mr-5">
              <DatePicker
                className="form-control"
                id="dataPicker"
                selected={date}
                onChange={this.handleChange}
                dateFormat="MM/yyyy"
                placeholderText="Reporting Month"
                showMonthYearPicker
              />
              <label htmlFor={'dataPicker'}>
                <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
              </label>
            </div>
            <FilterableTable
              className="table-responsive w-100 d-block d-lg-table"
              data={data}
              fields={fields}
              pageSizes={Array}
              serverSort={() => {}}
              topPagerVisible={false}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
            <div className="bottom-center text-center">
              <Link to={{ pathname: '/pharmacy' }} className="btn btn-primary">
                Back
              </Link>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(viewPharmacy);
