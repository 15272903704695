import { useParams } from 'react-router-dom';

import DefaultForgotPasswordPage from '@/components/auth/DefaultForgotPassword';
import PatientForgotPassword from '@/components/auth/PatientForgotPassword';
import PharmacistForgotPassword from '@/components/auth/pharmacist/PharmacistForgotPassword';

function ForgotPasswordPage() {
  const { loginType } = useParams<{ loginType: string }>();

  if (loginType === 'patient') {
    return <PatientForgotPassword />;
  }

  if (loginType === 'pharmacist') {
    return <PharmacistForgotPassword />;
  }

  return <DefaultForgotPasswordPage />;
}

export default ForgotPasswordPage;
