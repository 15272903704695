import { FF_BRAND_THEME_NAV } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import Brand from '@/utils/brand';

import Navbar from './Navbar';
import NavbarRebrand from './NavbarRebrand';
import NavbarV2 from './NavbarV2';

export default function NavbarContainer() {
  const { flags } = useFeatureFlags();
  const brandThemeNav = flags[FF_BRAND_THEME_NAV];

  if (Brand.isRebrand()) {
    return <NavbarRebrand />;
  }

  return brandThemeNav ? <NavbarV2 /> : <Navbar />;
}
