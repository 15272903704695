import { useMutation } from '@tanstack/react-query';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { AuthService } from '@/services/authentication.service';
import { changeUser } from '@/services/braze.service';
import type { PharmacistLoginFormData } from '@/types/pharmacist.types';
import type { LoginResponse } from '@/types/user.types';
import identifyUserForLaunchDarkly from '@/utils/identifyUserForLaunchDarkly';
import { Logger } from '@/utils/logger';

const logger = new Logger('PharmacistSignInForm');

function usePharmacistLogin() {
  const ldClient = useLDClient();

  const mutation = useMutation({
    mutationFn: async (data: PharmacistLoginFormData) => {
      const userResponse: LoginResponse = await AuthService.login(
        data.email.toLowerCase().trim(),
        data.password,
        data.remember,
        data.loginType
      );

      if (ldClient && userResponse?.user) {
        await identifyUserForLaunchDarkly(ldClient, userResponse.user);
        changeUser(userResponse.user.email);
      }
    },

    onError: (err) => {
      logger.error(err);
    },

    onSuccess: () => {
      window.location.replace('/pharmacist/orders');
    }
  });

  return {
    login: mutation.mutate,
    isPending: mutation.isPending
  };
}

export default usePharmacistLogin;
