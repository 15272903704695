import AuthService from '@/services/authentication.service';

import { Logger } from './logger';

export const NO_BEARER_TOKEN_FOUND = 'No bearer token found';

const logger = new Logger('getAuthBearerToken');

export default function getAuthBearerToken() {
  try {
    const currentUser = AuthService.getUser();
    if (typeof currentUser?.token !== 'string' || currentUser?.token?.length === 0) {
      throw new Error(NO_BEARER_TOKEN_FOUND);
    }
    return `Bearer ${currentUser.token}`;
  } catch (error) {
    logger.error('Error getting auth bearer token', error);
    return NO_BEARER_TOKEN_FOUND;
  }
}
