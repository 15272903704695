import React from 'react';
import { NavLink } from 'react-router-dom';
import { AuthService } from '../../../services/authentication.service';

const MoreMenu = (props) => {
  const [data, setData] = React.useState({
    showNavMenu: false,
    showMoreItem: false,
  });

  return (
    <React.Fragment>
      {AuthService.isAdmin() && (
        <li
          className="nav-item ml-5 nav-response"
          key={props.menuLength.length + 1}
          data-toggle="tooltip"
          data-placement="center"
          title="MORE"
        >
          <div className="nav-link">
            <span
              onClick={() => setData({ showMoreItem: !data.showMoreItem })}
              style={{ cursor: 'pointer' }}
              className="nav-link-text"
            >
              MORE
            </span>
          </div>
          {data.showMoreItem && (
            <ul className="show-more-item">
              {props.moreMenu.map((menu) => (
                <li key={`sub-menu-${menu.id}`}>
                  <NavLink
                    to={menu.link}
                    className={({ isActive }) => 'nav-link' + (isActive ? ' navbar__link--active' : '')}
                  >
                    <span data-testid="spanID" className="nav-link-text">
                      {menu.name}
                    </span>
                  </NavLink>
                </li>
              ))}
            </ul>
          )}
        </li>
      )}
    </React.Fragment>
  );
};

export default MoreMenu;
