import type { AxiosResponse } from 'axios';
import axios from 'axios';
import moment from 'moment';

import type {
  GeneralPractictioner,
  GenericStatusResponse,
  LoginUser,
  OrderStatus,
  OutOfStockOrders,
  Patient,
  Pharmacy,
  Product,
  ProductInventoryStatus,
  ReplaceOrderProduct
} from '@/types';

import settings from '../constants/constants';

const API_URL = `${settings.url}/order`;

const findOutOfStockOrders = (id: number) => axios.get<OutOfStockOrders>(`${API_URL}/v1/findOutOfStockOrders?id=${id}`);

const getAllActiveAndInactiveProducts = () => axios.get(`${API_URL}/all-products`);

const getAllProducts = () => axios.get<Product[]>(`${API_URL}/product`);

export type AwaitingOrdersResponse = {
  count: number;
};

const getAwaitingOrdersCount = async () => axios.post<AwaitingOrdersResponse>(`${API_URL}/awaitingOrdersCount`);

export type ExportAndShipAwaitingOrdersResponse = {
  message: string;
};

const exportAndShipAwaitingOrders = async () =>
  axios.put<ExportAndShipAwaitingOrdersResponse>(`${API_URL}/exportAndShipAllAwaiting`, {
    tz: moment().format('Z'),
    updateDate: moment(new Date()).valueOf()
  });

export type PatientExistRefillResponse = {
  found: boolean;
};

const getPatientExistRefill = (userId: number, pIds: number[]) =>
  axios.get<PatientExistRefillResponse>(`${API_URL}/exist-refill/${userId}/${pIds.join(',')}`);

const getPharmacies = async () => axios.get<Pharmacy[]>(`${API_URL}/pharmacy`);

const getOrderStatus = async (): Promise<AxiosResponse<OrderStatus[]>> => axios.get(`${API_URL}/order-status`);

const getOrderStatusBulk = async (): Promise<AxiosResponse<OrderStatus[]>> => axios.get(`${API_URL}/order-status/bulk`);

const getProductsFromInventory = async (pharmacyId: number): Promise<AxiosResponse<ProductInventoryStatus[]>> =>
  axios.post(`${API_URL}/getProductsFromInventory`, { pharmacyId });

const replaceInvoiceOrderProducts = (orderId: number, products: ReplaceOrderProduct[]) =>
  axios.patch(`${API_URL}/v1/${orderId}/replaceInvoiceOrderProducts`, { products });

const getOrderCreditDiscount = (data: unknown) => axios.post(`${API_URL}/getOrderCreditDiscount`, data);

const getOrderForPatientRefill = (userId: string | number, orderId = 0) =>
  axios.get(`${API_URL}/patient-refill/${userId}/${orderId || ''}`);

export interface GetOrderedProductsResponse {
  id: number;
  order_code: string;
  xero_order_code: any;
  payment_status: string;
  medical_condition: any;
  prescription_notes: any;
  order_date: string;
  order_date_tz: string;
  shipped_date: string;
  pms_order_code: string;
  gp_id: number;
  modified_by: number;
  is_expired: boolean;
  total_price: string;
  dispensing_fee: string;
  priority: boolean;
  dispensed_date: any;
  shipment_carrier: any;
  shipment_tracking_id: any;
  delivered: boolean;
  delivered_date: any;
  first_attempted_delivery_date: any;
  paid_date: string;
  order_notes: any;
  created_date: string;
  potential_same_day_delivery: any;
  patient_id: number;
  order_status_id: number;
  pharmacy_id: number;
  refill_pharmacy_id: any;
  created_by: number;
  transaction_type_id: number;
  admin_order_creation_reason_id: any;
  payment_initiated_path: any;
  modified_date: string;
  OrderProducts: OrderProduct[];
  Patient: Patient;
  PrescriptionOrderHistories: PrescriptionOrderHistory[];
  products: ProductData[];
}

export type ProductData = {
  product_name: string;
  product_id: number;
  quantity: number;
  repeats: number;
  remainingUnits: number;
  interval: number;
  base_order_id: number;
  status: string;
  status_id: number;
  is_out_of_stock: boolean;
  stock_return_date: any;
  stock_return_date_string: string;
  reasoning_toggle: boolean;
  reasoning: any;
  formulation_id: number;
};

export interface OrderProduct {
  id: number;
  quantity: number;
  repeats: number;
  interval: number;
  base_order_id: number;
  repeats_sequence_no: number;
  sas_expiry_date: any;
  ap_expiry_date: any;
  price: string;
  reason: any;
  additional_comment: any;
  product_id: number;
  order_id: number;
  prescription_product_id: number;
  pharmacist_reason_status: any;
  created_date: string;
  modified_date: string;
  Product: Product & {
    formulation_id: number;
    strain_id: number;
    ingredient_id: number;
    schedule_id: number;
    spectrum_id: any;
    hybrid_id: any;
    cbd_id: number;
    thc_id: number;
    rx_range_id: any;
  };
  PrescriptionProduct: PrescriptionProduct;
}

export interface PrescriptionProduct {
  id: number;
  interval: number;
  repeats: number;
  quantity: number;
  remaining_units: number;
  expired: boolean;
  prescription_id: number;
  product_id: number;
  eprescription_token: any;
  version_id: number;
  Prescription: Prescription;
}

export interface Prescription {
  id: number;
  prescription_code: string;
  active: boolean;
  patient_id: number;
  gp_id: number;
  expired: boolean;
  isAlternatePrescription: boolean;
  GeneralPractitioner: Pick<GeneralPractictioner, 'id' | 'user_id' | 'gp_code'> & {
    Doctor: Pick<LoginUser, 'id' | 'first_name' | 'last_name' | 'email'>;
  };
}

export interface PrescriptionOrderHistory {
  id: number;
  order_id: number;
  dispense_date: any;
  cancelled: any;
  product_id: number;
  prescription_id: number;
  units: number;
}

const getOrderedProducts = async (orderId: number) =>
  axios.get<GetOrderedProductsResponse>(`${API_URL}/ordered-products/${orderId}`, {
    headers: {
      prescription: true
    }
  });

export type SplitOrder = {
  order: number;
  orderName: string;
  products: number[];
  isOutOfStock?: boolean;
};

export type SaveSplitOrdersResponse = {
  status: number;
  message: string;
};

const saveSplitOrders = async (orderId: number, orders: SplitOrder[]) =>
  axios.post<SaveSplitOrdersResponse>(`${API_URL}/v1/splitOrder`, {
    orderId,
    orders
  });

const updateShippedStatus = async (orderIds: number[]) =>
  axios.put<GenericStatusResponse<string>>(`${API_URL}/exportAndShip`, {
    orderIds,
    tz: moment().format('Z'),
    updateDate: moment(new Date()).valueOf()
  });

export type BulkStatusUpdateResponse = {
  message?: string;
  success?: boolean;
};

const bulkStatusUpdate = async (orderStatusId: number, orderIds: number[]) =>
  axios.put<BulkStatusUpdateResponse>(`${API_URL}/bulk-update`, {
    order_status_id: orderStatusId,
    items: orderIds,
    tz: moment().format('Z'),
    order_date: moment(new Date()).valueOf()
  });

const getOrder = async ({ id }: { id: number }) => axios.get(`${API_URL}/${id}`);

// eslint-disable-next-line import/prefer-default-export
export const OrderService = {
  getOrder,
  bulkStatusUpdate,
  exportAndShipAwaitingOrders,
  findOutOfStockOrders,
  getAllActiveAndInactiveProducts,
  getAllProducts,
  getOrderedProducts,
  getAwaitingOrdersCount,
  getOrderCreditDiscount,
  getPatientExistRefill,
  getPharmacies,
  getOrderForPatientRefill,
  getOrderStatus,
  getOrderStatusBulk,
  getProductsFromInventory,
  replaceInvoiceOrderProducts,
  saveSplitOrders,
  updateShippedStatus
};
