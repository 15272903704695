import React from 'react';
import { Link } from 'react-router-dom';
import logo from './montu.png';

const HeaderUnauth = () => {
  return (
    <React.Fragment>
      <Link to="/">
        <img className="d-block mx-auto pt-3 pt-sm-4 montu-logo" src={logo} alt="Montu logo" />
      </Link>
    </React.Fragment>
  );
};

export default HeaderUnauth;
