import { Box, Grid, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { MdCancel } from 'react-icons/md';
import type { SingleValue } from 'react-select';
import AsyncSelect from 'react-select/async';

type SelectProduct = {
  value: number;
  label: string;
};

type AvailableProduct = {
  id: number;
  name: string;
  short_name: string;
};

type AlternativeProductSelectProps = {
  availableProducts: AvailableProduct[];
  selectedDefaultProducts: (SelectProduct | null)[];
  setSelectedDefaultProducts: Dispatch<SetStateAction<(SelectProduct | null)[]>>;
  shopifyDisableProductEdit: boolean;
};

export default function AlternativeProductSelect({
  availableProducts,
  selectedDefaultProducts,
  setSelectedDefaultProducts,
  shopifyDisableProductEdit
}: AlternativeProductSelectProps) {
  const [changeKey, setChangeKey] = useState(0);

  const filterOptions = useCallback(
    (searchText: string) => {
      const normalizedSearchText = searchText.toLowerCase();
      return availableProducts
        .filter(
          (option) =>
            !selectedDefaultProducts.some((item) => item && item.value === option.id) &&
            option.name.toLowerCase().includes(normalizedSearchText)
        )
        .map((item) => ({
          value: item.id,
          label: item.name
        }));
    },
    [availableProducts, selectedDefaultProducts]
  );

  const getOptions = useCallback(
    (inputValue: string, callback: (options: SelectProduct[]) => void) => {
      const filteredOptions = filterOptions(inputValue);
      callback(filteredOptions);
    },
    [filterOptions]
  );

  const updateProductState = useCallback(
    (index: number, value: SelectProduct | null) => {
      const updatedProducts = [...selectedDefaultProducts];
      updatedProducts[index] = value;
      setSelectedDefaultProducts(updatedProducts);
      setChangeKey((prevKey) => prevKey + 1);
    },
    [selectedDefaultProducts, setSelectedDefaultProducts]
  );

  if (availableProducts.length === 0) {
    return (
      <Box className="form-row col-7 col-md-12">
        <Typography className="col-md-12">No products found</Typography>
      </Box>
    );
  }

  return (
    <>
      <Box className="form-row col-7 col-md-12">
        <Typography className="col-md-12">Choose alternative default product</Typography>
      </Box>
      <Box className="form-row col-8">
        {[1].map((index) => (
          <Box className="col-md-12 mb-3" key={index}>
            <Grid container spacing={2}>
              <Grid item xs={10}>
                <AsyncSelect
                  key={`defaultProduct${index}_${changeKey}`}
                  defaultOptions
                  name={`defaultProduct${index}`}
                  loadOptions={getOptions}
                  value={selectedDefaultProducts[index - 1]}
                  onChange={(selectedOption: SingleValue<SelectProduct>) =>
                    updateProductState(index - 1, selectedOption)
                  }
                  isDisabled={shopifyDisableProductEdit}
                />
              </Grid>
              <Grid item xl={2}>
                <IconButton onClick={() => updateProductState(index - 1, null)}>
                  <MdCancel />
                </IconButton>
              </Grid>
            </Grid>
          </Box>
        ))}
      </Box>
    </>
  );
}
