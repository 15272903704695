// eslint-disable-next-line no-shadow,import/prefer-default-export
export enum GoogleAnalyticsEventName {
  STC_BANNER_CLICK = 'stc_banner_click',
  STC_COPY_TO_CLIPBOARD = 'stc_copy_to_clipboard',
  STC_TERMS_CONDITIONS = 'stc_terms_conditions',
  LINK_CLICK = 'link_click',
  ACCORDION_CLICK = 'accordion_click',
  CLICK_CTA = 'click_cta',
  LOGIN_MODAL_CLOSE_CLICK = 'login-modal-close-click',
  LOGIN_MODAL_CONTINUE_CLICK = 'login-modal-continue-click',
  LOGIN_MODAL_OUTSIDE_CLICK = 'login-modal-outside-click',
  VIEW_CART = 'view_cart',
  ADD_TO_CART = 'add_to_cart',
  REMOVE_FROM_CART = 'remove_from_cart'
}
