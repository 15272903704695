import { HttpStatus } from '@montugroup/apis-styleguide';
import { getZendeskToken } from '@montugroup/circuit-api-contracts';
import { useQuery } from '@tanstack/react-query';
import { TsRestResponseError } from '@ts-rest/core';

import { getTsCustomerPortalClient } from '@/api/customer-portal.client';
import { FF_ENABLE_ZENDESK_AUTHENTICATION } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

const client = getTsCustomerPortalClient();

export default function useGetZendeskToken() {
  const { flags } = useFeatureFlags();
  const { data, isError, isLoading } = useQuery({
    queryKey: ['zendeskToken'],
    queryFn: async () => {
      if (!flags[FF_ENABLE_ZENDESK_AUTHENTICATION]) {
        return null;
      }

      const res = await client.getZendeskToken.query();
      if (res.status !== HttpStatus.OK) {
        throw new TsRestResponseError(getZendeskToken, res);
      }
      return res.body.data;
    },
    retry: (_, error) => {
      if (error instanceof TsRestResponseError) {
        return false;
      }

      return true;
    }
  });

  return {
    data,
    isError,
    isLoading
  };
}
