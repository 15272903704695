import { Alert, AlertTitle, Chip, Typography } from '@mui/material';

import type { SupportTaskProps } from '@/components/supportSelfService/types';

import LoadingContentBlock from '../common/LoadingContentBlock';

interface Props {
  tasks: SupportTaskProps[];
  teams: string[];
  loading?: boolean;
  error?: string;
}

function SupportHeader({ tasks, teams, loading, error }: Props) {
  return (
    <>
      <Typography variant="h3" component="h3" gutterBottom>
        Support Self Service
      </Typography>

      {loading && <LoadingContentBlock />}

      {!loading && (
        <>
          {error && (
            <Alert severity="error">
              <AlertTitle>{error}</AlertTitle>
              Please reach out to the Engineering Support Team - <strong>@eng_on_call</strong> on Slack
            </Alert>
          )}

          {!error && (
            <>
              <Typography variant="h6" component="h6" gutterBottom>
                {teams.length > 0 ? (
                  <>
                    Teams you are assigned to:
                    {teams.map((team: string) => (
                      <Chip key={team} label={team} color="primary" sx={{ ml: 4 }} />
                    ))}
                  </>
                ) : (
                  <>You are not assigned to any teams</>
                )}
              </Typography>

              {tasks.length < 1 && (
                <Typography variant="body1">
                  You do not have the the required permissions to access to this page, if you believe this is a bug
                  please reach out to the Engineering Support Team - @eng_on_call on Slack.
                </Typography>
              )}
            </>
          )}
        </>
      )}
    </>
  );
}

export default SupportHeader;
