import { apiAdminContract } from '@montugroup/circuit-api-contracts';

import { createTsRestClient } from './ts-rest.client';

let cachedClient: ReturnType<typeof createTsRestClient<typeof apiAdminContract>> | null = null;

export const getTsAdminClient = () => {
  if (!cachedClient) {
    cachedClient = createTsRestClient(apiAdminContract);
  }

  return cachedClient;
};

export default null;
