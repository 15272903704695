import type { ChipProps } from '@/ui-library';
import { Chip } from '@/ui-library';

export type ConcessionPatientOnboardChipProps = Omit<ChipProps, 'label'>;

export function ConcessionPatientOnboardChip({ ...rest }: ConcessionPatientOnboardChipProps) {
  return <Chip color="primary" label="Concession holder" {...rest} />;
}

export default ConcessionPatientOnboardChip;
