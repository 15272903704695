import { MgPhone } from '@montugroup/icons';
import { ChevronRight, HelpCenterOutlined, MailOutline, Send } from '@mui/icons-material';
import { Box, Link, Stack, styled, Typography } from '@mui/material';

import BackButton from '@/ui-library/backButton/BackButton';

const LinkCard = styled(Link)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.2)',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  width: '100%',
  padding: theme.spacing(4),
  borderRadius: '0.5rem',
  textDecoration: 'none',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.action.hover,
    transition: 'background-color 0.3s ease'
  }
}));

const IconContainer = styled(Box)(({ theme }) => ({
  border: '1px solid rgba(0, 0, 0, 0.2)',
  backgroundColor: '#FFFCF2', // TODO: Change to theme warm white once design system is updated
  marginRight: theme.spacing(4),
  padding: theme.spacing(2.5),
  borderRadius: '0.5rem'
}));

const Text = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
  lineHeight: theme.spacing(6)
}));

export default function PatientHelp() {
  const supportLinks = [
    {
      href: 'https://support.alternaleaf.com.au/hc/en-au',
      text: 'Help and support centre',
      icon: HelpCenterOutlined,
      target: '_blank'
    },
    {
      href: 'https://support.alternaleaf.com.au/hc/en-au/requests/new?ticket_form_id=14679759615641',
      text: 'Submit a request',
      icon: Send,
      target: '_blank'
    }
  ];
  const contactLinks = [
    {
      href: 'tel:1800 864 878',
      text: 'Call us',
      icon: MgPhone,
      subText: '1800 864 878'
    },
    {
      href: 'mailto:info@alternaleaf.com.au',
      text: 'Email',
      icon: MailOutline,
      subText: 'Info@alternaleaf.com.au'
    }
  ];

  return (
    <>
      <Box display="flex" pb={{ xs: 4, sm: 2 }}>
        <BackButton />
        <Typography variant="h5" component="h1" color="primary.main" lineHeight={(theme) => theme.spacing(9)}>
          We&apos;re here to help
        </Typography>
      </Box>
      <Typography color="primary.main" pb={6} lineHeight={(theme) => theme.spacing(6)}>
        Our customer service team is available Monday to Friday from 9AM to 9PM (AEST).
      </Typography>
      <Stack gap={4} mb={10}>
        {supportLinks.map((link) => (
          <LinkCard key={link.href} href={link.href} target={link.target} maxWidth={{ sm: '420px' }}>
            <Stack direction="row" alignItems="center">
              <IconContainer>
                <link.icon sx={{ color: 'primary.main' }} />
              </IconContainer>
              <Text>{link.text}</Text>
            </Stack>
            <ChevronRight sx={{ color: 'primary.main' }} />
          </LinkCard>
        ))}
      </Stack>

      <Typography variant="subtitle1" component="h2" color="primary.main" lineHeight={(theme) => theme.spacing(8.5)}>
        Get in touch
      </Typography>
      <Typography color="primary.main" pb={{ xs: 6, sm: 4 }} lineHeight={(theme) => theme.spacing(6)}>
        We&apos;re available Mon-Fri, 9AM to 9PM (AEST)
      </Typography>
      <Stack direction={{ xs: 'column', sm: 'row' }} minWidth="100%" gap={{ xs: 4, sm: 6 }}>
        {contactLinks.map((link) => (
          <LinkCard key={link.href} href={link.href} maxWidth={{ sm: '300px' }}>
            <Stack direction="row" alignItems="center">
              <IconContainer>
                <link.icon sx={{ color: 'primary.main' }} />
              </IconContainer>
              <Box>
                <Text pb={1}>{link.text}</Text>
                {link.subText && <Text>{link.subText}</Text>}
              </Box>
            </Stack>
          </LinkCard>
        ))}
      </Stack>
    </>
  );
}
