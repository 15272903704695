/* eslint-disable no-console */
import axios from 'axios';
import settings from '../constants/constants';

//get entire country list around the world
const getEwayPaymentUrl = (orderDetails) => {
  return axios.post(`${settings.url}/eway/redirecturl`, { ...orderDetails });
};

const checkTransactionResponse = (accessCode) => {
  return axios.get(`${settings.url}/eway/checkTransactionResponse/${accessCode}`);
};

const saveOrderTransaction = async (transactionDetail) => {
  return axios.post(`${settings.url}/eway/saveordertransaction`, transactionDetail);
};

export { checkTransactionResponse, getEwayPaymentUrl, saveOrderTransaction };
