import axios from 'axios';

import { Logger } from '@/utils/logger';

import settings from '../constants/constants';

const logger = new Logger('DiscountMigrationService');

interface DiscountMigrationBaseResponse<T> {
  data: T;
}

type DiscountMigrationResponse = DiscountMigrationBaseResponse<{
  migratedDiscountsCount: number[];
  migratedDiscountsShopifyId: number[];
  remainingDiscountsCount: number;
}>;

export const makeDiscountMigrationRequest = async <T>(
  method: 'POST' | 'GET',
  path: string,
  data: Record<string, unknown> | null,
  errorMessage?: string
): Promise<T | null> => {
  try {
    const response = await axios<T>(`${settings.url}/discount/migrate${path}`, {
      method,
      data,
      headers: { 'Content-Type': 'application/json' }
    });

    return response?.data;
  } catch (error) {
    logger.error(errorMessage, error);
    return null;
  }
};

export const migrateDiscountsForPatient = async () =>
  makeDiscountMigrationRequest<DiscountMigrationResponse>(
    'POST',
    '',
    { shouldMigrateDiscountsForPatient: true },
    'Failed to migrate patient discounts.'
  );
