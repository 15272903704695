import { Chip, styled } from '@mui/material';

import type { Prescription } from './common';
import { getStatus } from './common';

export type PrescriptionStatusChipProps = {
  prescription: Prescription;
  size?: 'small' | 'medium';
};

export const StatusChip = styled(Chip)(({ theme }) => ({
  fontFamily: theme.typography.body1.fontFamily,
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightMedium
}));

export function PrescriptionStatusChip({ prescription, size = 'medium' }: PrescriptionStatusChipProps) {
  const determineStatusColor = () => {
    const isInactive = prescription.inActive;
    if (isInactive || prescription.status === 'Ceased' || prescription.status === 'Expired') {
      return 'error';
    }
    if (prescription.status === 'Active') {
      return 'success';
    }
    return 'info';
  };

  return <StatusChip label={getStatus(prescription)} variant="filled" color={determineStatusColor()} size={size} />;
}

export default PrescriptionStatusChip;
