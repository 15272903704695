import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

import settings from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useGenerateReferralCode');

export interface IGenerateCodePayload {
  promoCode: string;
  message: string;
}

export function useGenerateReferralCode() {
  const generateReferralCodeMutation = useMutation({
    mutationFn: async () => {
      try {
        const token = AuthService.getUser()?.token;

        const config = {
          headers: {
            Authorization: `Bearer ${token}`
          }
        };

        const response = await axios.post(`${settings.url}/patient/consultations/referrals`, {}, config);

        return response.data as IGenerateCodePayload;
      } catch (error) {
        logger.error('Error generating referral code:', error);
        throw new Error(String(error));
      }
    },

    retry: false,

    onSuccess: (data) => {
      logger.info('Referral code generated successfully:', data);
    }
  });

  const generateReferralCode = async () => {
    try {
      await generateReferralCodeMutation.mutateAsync();
    } catch (error) {
      logger.error('Error generating referral code:', error);
    }
  };

  return {
    generateReferralCode,
    isPending: generateReferralCodeMutation.isPending,
    isError: generateReferralCodeMutation.isError,
    isSuccess: generateReferralCodeMutation.isSuccess,
    data: generateReferralCodeMutation.data
  };
}

export default useGenerateReferralCode;
