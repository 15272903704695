import { Button } from '@montugroup/design-system';
import { Box, styled } from '@mui/material';
import { useState } from 'react';

import ExportAllDateRangeButton from '@/components/common/ExportAllDateRangeButton';
import MergePatientModal from '@/components/patient/mergePatientModal/mergePatientModal';
import useDownload from '@/hooks/useDownload';
import AuthService from '@/services/authentication.service';

const ActionsContainer = styled(Box)`
  display: flex;
  justify-content: end;
`;

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(2)
}));

function ViewPatientsActions() {
  const [showMergePatientModal, setShowMergePatientModal] = useState<boolean>(false);
  const { downloadFile } = useDownload();

  const exportAll = async (fromDate: string, toDate: string) => {
    const fileName = `${new Date().getTime()}-montu-patients.csv`;

    await downloadFile(fileName, 'excel/patient/download', {
      method: 'POST',
      responseType: 'blob',
      data: {
        from_date: fromDate,
        to_date: toDate
      }
    });
  };

  return (
    <ActionsContainer>
      <Box>
        {AuthService.isSuperAdmin() && (
          <>
            <ButtonContainer>
              <Button size="small" onClick={() => setShowMergePatientModal(true)}>
                Merge patients
              </Button>
              <ExportAllDateRangeButton buttonProps={{ size: 'small', color: 'info' }} onExport={exportAll} />
            </ButtonContainer>

            <MergePatientModal onClick={() => setShowMergePatientModal(false)} open={showMergePatientModal} />
          </>
        )}
      </Box>
    </ActionsContainer>
  );
}

export default ViewPatientsActions;
