import { BrandThemeProvider } from '@montugroup/themes';
import { useLocation, useNavigate } from 'react-router-dom';

import CircleLoader from '@/components/common/circleLoader';
import OrderConfirmed from '@/components/payment/OrderConfirmed';
import { FF_ENABLE_SHOPIFY_CHECKOUT } from '@/constants/featureFlags';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import AuthService from '@/services/authentication.service';

function PaymentSuccessAuth() {
  const navigate = useNavigate();
  const location = useLocation();
  const { key: partnerKey } = usePortalPartnerBrand();

  const { orderCode, transactionType, bankTransferReference } = location.state;

  const user = AuthService.getUser();

  const { flags, isIdentified } = useFeatureFlags();

  // TODO analytics
  // For logged in patients we check if isIdentified is true
  if (!isIdentified) {
    return <CircleLoader />;
  }

  if (!flags[FF_ENABLE_SHOPIFY_CHECKOUT]) {
    navigate('/404');
  } else if (!orderCode) {
    navigate('/patient/orders');
  }

  return (
    <BrandThemeProvider variant={partnerKey}>
      <OrderConfirmed
        orderCode={orderCode}
        transactionType={transactionType}
        bankTransferReference={bankTransferReference}
        patientName={user?.user?.first_name}
        patientEmail={user?.user?.email}
        loggedIn
      />
    </BrandThemeProvider>
  );
}

export default PaymentSuccessAuth;
