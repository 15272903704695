import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MobileLogoContainer = styled(Box)(
  ({ theme }) => `
    background-color: ${theme.palette.background.default};
    max-width: 100%;
    margin-top: 2rem;
    margin-bottom: 2rem;
  
    ${theme.breakpoints.up('md')} {
      display: none;
    }
  `
);

export const AuthPageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  height: '100%',
  justifyContent: 'center',
  backgroundColor: theme.palette.background.default,
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#ceedff',
    padding: '3rem 3rem',
    alignItems: 'center'
  }
}));

export const AuthCardContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  [theme.breakpoints.up('md')]: {
    maxHeight: '850px',
    minHeight: '650px',
    backgroundColor: theme.palette.background.default,
    borderRadius: '25px',
    width: '75%',
    maxWidth: '1024px',
    boxShadow: '0 1rem 3rem rgba(0, 0, 0, 0.175)'
  },
  '& a': {
    color: theme.palette.secondary.main,
    fontWeight: 500
  },
  '& h2': {
    color: theme.palette.primary.main,
    fontSize: '1.5rem',
    fontWeight: 700
  }
}));

export const CardHalfDecoration = styled(Box)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    width: '50%',
    display: 'flex'
  }
}));

export const CardHalfContent = styled(Box)(({ theme }) => ({
  padding: '1rem',
  position: 'relative',
  zIndex: 1,
  [theme.breakpoints.up('md')]: {
    width: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }
}));

export const AuthFormGroup = styled(Box)(({ theme }) => ({
  '& input[type="text"], & input[type="password"]': {
    backgroundColor: theme.palette.background.default,
    borderColor: theme.palette.primary.main,
    borderRadius: '0.25rem',
    borderWidth: '1px',
    padding: '0.5rem 1rem',
    height: '3.5rem',
    fontSize: '1.25rem',
    '&::placeholder': {
      fontWeight: 400
    }
  },
  '& label': {
    color: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
    marginBottom: '-0.6rem',
    padding: '0 0.25rem',
    marginLeft: '1rem',
    fontSize: '0.75rem'
  }
}));

export const ScreenReaderText = styled('p')`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  word-wrap: normal !important;
`;

export const MobileOnly = styled(Box)(({ theme }) => ({
  display: 'flex',
  [theme.breakpoints.up('md')]: {
    display: 'none'
  }
}));

export const FormContainer = styled(Box)`
  flex: 1;
  padding: 1.5rem;
`;
