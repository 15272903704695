import { Alert, Box, styled } from '@mui/material';

import EnquiryDialog from './EnquiryDialog';

type EnquiryErrorDialogProps = {
  open: boolean;
  onClose?: () => void;
};

const Container = styled(Box)`
  text-align: center;
`;

export default function EnquiryErrorDialog({ open, onClose }: EnquiryErrorDialogProps) {
  return (
    <EnquiryDialog open={open} onClose={onClose}>
      <Container>
        <Alert severity="error">An unexpected error occurred. Please try again.</Alert>
      </Container>
    </EnquiryDialog>
  );
}
