import { datadogRum } from '@datadog/browser-rum';
import version from 'config';

import type { EnvSettings } from '@/constants/constants';
import { Logger } from '@/utils/logger';

const logger = new Logger('datadog');

export default function init(settings: EnvSettings) {
  const { datadog } = settings;

  if (datadog) {
    logger.debug('Initializing DataDog RUM');

    datadogRum.init({
      ...datadog,
      version,
      applicationId: '2a7ec7af-8bd2-403e-bca9-618f1d3c3a38',
      clientToken: 'pubbc48eee0cbeb1f3ac5db6d8bd99c0f3b',
      service: 'circuit-frontend',
      site: 'us3.datadoghq.com',
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      enableExperimentalFeatures: ['feature_flags']
    });

    datadogRum.startSessionReplayRecording();
  }
}
