import { Box, skeletonClasses, styled } from '@mui/material';

export const PageContent = styled(Box)(({ theme }) => ({
  gap: theme.spacing(0),
  alignSelf: 'stretch',
  marginBottom: theme.spacing(4),
  display: 'grid',
  gridTemplateColumns: '1fr',
  gridTemplateAreas: `
    "title"
    "steps"
    "products"
    "purchase"`,
  [`${theme.breakpoints.up('lg')}`]: {
    gap: theme.spacing(4),
    gridTemplateColumns: '2fr 1fr',
    gridTemplateAreas: `"title title"
     "steps steps"
     "products purchase"`
  },
  width: 'inherit'
}));

export const PageSection = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  '&:empty': {
    margin: 0
  },
  [`& .${skeletonClasses.root}`]: {
    marginBottom: theme.spacing(4),
    height: theme.spacing(16)
  }
}));

export const DisclaimerRRP = styled(Box)(({ theme }) => ({
  padding: `${theme.spacing(2)} 0`,
  marginBottom: theme.spacing(10),
  [`${theme.breakpoints.up('lg')}`]: {
    marginBottom: '0'
  }
}));

export const DesktopOnly = styled(Box)(({ theme }) => ({
  display: 'none',
  position: 'relative',
  [`${theme.breakpoints.up('lg')}`]: {
    display: 'flex'
  }
}));

export const MobileOnlySticky = styled(Box)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  padding: 0,
  display: 'flex',
  zIndex: theme.zIndex.drawer,
  width: '100vw',
  background: 'white',
  boxShadow: '0 -4px 20px rgba(0, 0, 0, 0.1)',
  '& > *': {
    flex: 1
  },
  [`${theme.breakpoints.up('lg')}`]: {
    display: 'none'
  }
}));
