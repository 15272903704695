import { BrandThemeProvider } from '@montugroup/themes';
import { Box, Link, styled, Typography } from '@mui/material';

import type { ProductItemProps } from '@/types/productItem.types';
import pxToRemConverter from '@/utils/pxToRemConverter';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: theme.palette.common.white,
  padding: theme.spacing(4, 0),
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'row',
  borderRadius: pxToRemConverter(20),
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    padding: theme.spacing(2, 1)
  }
}));

const CustomTypography = styled(Typography)({
  fontSize: pxToRemConverter(14)
});

const ImageStyle = styled('img')({
  width: pxToRemConverter(87),
  height: pxToRemConverter(184),
  objectFit: 'contain'
});

const Span = styled('span')`
  font-weight: bold;
`;

const SuperScript = styled('sup')({
  position: 'relative',
  top: '-0.2em',
  fontSize: pxToRemConverter(14)
});

const ImageBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(0, 6, 0, 7)
}));

const SmallComponent = styled('small')({
  fontSize: pxToRemConverter(12)
});

function ProductItem({
  imgUrl,
  imgBannerText,
  title,
  dose,
  quantityDesc,
  tgaName,
  titration,
  treatmentArea,
  price,
  cmiSheet
}: ProductItemProps) {
  return (
    <BrandThemeProvider variant="montu">
      <Box sx={{ width: '45%' }} data-testid="product-item">
        <Container>
          <ImageBox>
            <ImageStyle src={imgUrl} alt={title} />
            {imgBannerText ? <Typography variant="body2">{imgBannerText}</Typography> : ''}
          </ImageBox>
          <Box sx={{ flexGrow: 1, mt: 4 }}>
            <Typography variant="h6">{title} </Typography>
            <Typography>{dose.l1}</Typography>
            <Typography>{dose.l2}</Typography>
            <Typography>{quantityDesc.l1}</Typography>
            <Typography>{quantityDesc.l2}</Typography>
          </Box>
        </Container>

        <Box sx={{ mt: 4 }}>
          <CustomTypography>
            <Span> Registered Name: </Span>
            {tgaName}
          </CustomTypography>

          <CustomTypography>
            <Span> Titration: </Span>
            {titration}
          </CustomTypography>

          <CustomTypography>
            <Span> Treatment Area: </Span>
            {treatmentArea}
          </CustomTypography>

          <CustomTypography>
            <Span>Price: </Span> ${price.cost}
            <SuperScript>*</SuperScript>
            <SmallComponent> {price.perMg}</SmallComponent>
          </CustomTypography>

          <CustomTypography>
            <Link href={cmiSheet} target="_blank" rel="noopener noreferrer" underline="none">
              =&gt; CMI Sheet
            </Link>
          </CustomTypography>
        </Box>
      </Box>
    </BrandThemeProvider>
  );
}

export default ProductItem;
