import { useQuery } from '@tanstack/react-query';

import type { PharmacistOrderTableView } from '@/context/pharmacist/orders/PharmacistOrders';
import { getPharmacistOrders } from '@/services/pharmacist.service';
import type { OrderStatus, TableSortOrder } from '@/types';

export type UseGetPharmacistOrdersParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
  activeTab: PharmacistOrderTableView;
  statusFilter: OrderStatus[];
};

function useGetPharmacistOrders({
  page,
  pageSize,
  sortingOrder,
  filter,
  activeTab,
  statusFilter
}: UseGetPharmacistOrdersParams) {
  const { isLoading, data, isError, isSuccess, refetch } = useQuery({
    queryKey: ['getPharmacistOrders', page, pageSize, sortingOrder, filter, activeTab, statusFilter],
    queryFn: async ({ signal }) => {
      try {
        const response = await getPharmacistOrders(
          pageSize,
          page,
          sortingOrder,
          filter,
          activeTab,
          statusFilter,
          signal
        );
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch doctors availability: ${(error as { message: string }).message}`);
      }
    }
  });

  return { isLoading, data, isError, isSuccess, refetch };
}

export default useGetPharmacistOrders;
