import { z } from 'zod';

import settings from '@/constants/constants';

const ApiConfig = z.object({
  baseApiUrl: z
    .string()
    .url()
    .transform((s) => {
      const resource = new URL(s);
      return `${resource.protocol}//${resource.host}`;
    })
});

// eslint-disable-next-line no-redeclare
export type ApiConfig = z.infer<typeof ApiConfig>;

/**
 * This is a workaround for fetching the existing base URL from our environment variables.
 * Our ts-rest contracts provide the full api url path, so we must do it this way.
 */
export const apiConfig = (): ApiConfig => {
  const baseApiUrl = settings.url;
  return ApiConfig.parse({
    baseApiUrl
  });
};
