import React, { Component } from 'react';
import axios from 'axios';
import FilterableTable from 'rc-data-table';
import CircleLoader from '../common/circleLoader';
import Button from '../common/button';
import { Link } from 'react-router-dom';
import { AuthService } from '../../services/authentication.service';
import Select from 'react-select';
import { GetGPs } from '../../services/data.service';
import settings from '../../constants/constants';
import editIcon from '../../assets/images/edit.png';
import USER_ROLES from '@/constants/userRoles';
import withRouter from '@/utils/withRouter';

const CancelToken = axios.CancelToken;
let cancel;

class GPView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      pageSize: 50,
      count: 0,
      page: 0,
      filter: null,
      sort: { name: 'id', reverse: true },
      loading: true,
      user_role_id: AuthService.getUser().user.role_id,
      gpFilter: 1,
    };
  }

  dataBind = async (gpFilter = null) => {
    const { page, pageSize, sort, filter } = this.state;
    if (cancel) {
      cancel();
    }
    axios
      .get(`${settings.url}/data/doctors`, {
        cancelToken: new CancelToken(function executor(c) {
          cancel = c;
        }),
        params: {
          limit: pageSize,
          offset: page * pageSize,
          sort: sort.name,
          sortOrder: sort.reverse ? 'DESC' : 'ASC',
          filter,
          gpFilter: gpFilter ? gpFilter : this.state.gpFilter,
          ts: new Date().getTime(),
        },
      })
      .then((data) => {
        this.setState({ data: data.data.doctors, count: data.data.count, loading: false });
      });
  };

  pageChange = async (page, size) => {
    this.setState({ page: page, pageSize: size, loading: true }, function () {
      this.dataBind();
    });
  };

  getSortColumn = (sort) => {
    this.setState({ sort: sort[0], loading: true }, function () {
      this.dataBind();
    });
  };

  onFilter = (filter) => {
    this.setState({ filter, page: 0 }, function () {
      this.dataBind();
    });
  };

  async componentDidMount() {
    document.title = 'Doctors - Montu Group | Making Medical Cannabis Accessible';
    await this.dataBind();
  }

  setFilter = async (e) => {
    this.setState(
      {
        gpFilter: e.value,
      },
      await this.dataBind(e.value),
    );
  };

  downloadFile = async () => {
    const data = await GetGPs();
    let itemsFormatted = [];

    data.data.doctors.forEach((item) => {
      let splitPrimaryAddress = item.clinic_address ? item.clinic_address.split('$$$$') : item.clinic_address;
      let primaryAddressMain = item.clinic_address ? splitPrimaryAddress[0] : '';
      let primaryAddress = item.clinic_address ? splitPrimaryAddress[1] : '';

      let expObj = {
        'Doctor code': item.gp_code,
        'First Name': item.first_name,
        'Last Name': item.last_name,
        Email: item.email,
        Phone: item.phone ? item.phone : '',
        'AHPRA No': item.ahpraNo,
        BDMS: item.medical_Liasion ? item.medical_Liasion : '',
        'Total Prescription': item.total_prescriptions ? item.total_prescriptions : '',
        'Total Patients': item.patients_count ? item.patients_count : '',
        'Primary Clinic Name': item.clinic1 ? item.clinic1 : '',
        'Primary Clinic Email': item.clinic_email ? item.clinic_email : '',
        'Primary Clinic Code': item.clinic_code ? item.clinic_code : '',
        'Primary Clinic phone': item.clinic_phone ? item.clinic_phone : '',
        'Primary Clinic Address': splitPrimaryAddress
          ? primaryAddressMain + '  ' + primaryAddress
          : item.clinic_address,
        'Primacy Clinic City': item.clinic_city ? item.clinic_city : '',
        'Primary Clinic State': item.clinic_state ? item.clinic_state : '',
        'Primary Clinic Country': item.clinic_country ? item.clinic_country : '',
        'Primary Clinic Postcode': item.clinic_postCode ? item.clinic_postCode : '',
        'Secondary Clinic Name': item.clinic ? item.clinic : '',
        'Secondary Clinic Email': item.clinic1_email ? item.clinic1_email : '',
        'Secondary Clinic Code': item.clinic1_code ? item.clinic1_code : '',
        'Secondary Clinics Phone': item.clinic1_phone ? item.clinic1_phone : '',
        'Secondary Clinic City': item.clinic1_city ? item.clinic1_city : '',
        'Secondary Clinic State': item.clinic1_state ? item.clinic1_state : '',
        'Secondary Clinic Country': item.clinic1_country ? item.clinic1_country : '',
        'Secondary Clinic Postcode': item.clinic1_postCode ? item.clinic1_postCode : '',
      };
      if (item.clinic1_address) {
        let splitSecondaryAddress = item.clinic1_address.split('$$$$');
        let sec_AddressMain = splitSecondaryAddress[0];
        let sec_Address = splitSecondaryAddress[1];
        expObj.sec_Address = sec_AddressMain + ' ' + sec_Address;
      }
      itemsFormatted.push(expObj);
    });
    let fileTitle = new Date().getTime() + '-montu-doctors';
    await axios({
      url: 'excel/gp/download',
      method: 'POST',
      data: itemsFormatted,
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileTitle}.xlsx`);
      document.body.appendChild(link);
      link.click();
    });
  };

  render() {
    let { loading, data, user_role_id, count, sort, page, pageSize, filter } = this.state;
    const fields = [
      { name: 'id', displayName: 'id', inputFilterable: true, visible: false, sortable: true, tdClassName: 'sub-text' },
      {
        name: 'gp_code',
        displayName: 'Doctor ID',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        render: (e) => {
          return <Link to={`/gp-register/${e.record.id}`}>{e.record.gp_code}</Link>;
        },
      },
      {
        name: 'gp_name',
        displayName: 'Name',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'Doctor.first_name',
      },
      {
        name: 'email',
        displayName: 'Email',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'Doctor.email',
      },
      {
        name: 'joined_date',
        displayName: 'Joined Date',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'created_date',
      },
      {
        name: 'phone',
        displayName: 'Phone',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'Doctor.phone',
      },
      {
        name: 'ahpraNo',
        displayName: 'Ahpra',
        inputFilterable: true,
        sortable: true,
        tdClassName: 'sub-text',
        sortFieldName: 'ahpra_no',
      },
      { name: 'clinic1', displayName: 'Primary Clinic', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'medical_Liasion',
        displayName: 'BDMS',
        inputFilterable: true,
        thClassName: user_role_id === USER_ROLES.ml ? 'd-none' : ' ',
        tdClassName: user_role_id === USER_ROLES.ml ? 'd-none' : 'sub-text',
      },
      { name: 'patients_count', displayName: 'Total Patients', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'total_prescriptions',
        displayName: 'Total Prescriptions',
        inputFilterable: true,
        tdClassName: 'sub-text',
      },
      { name: 'last_prescription', displayName: 'Last Prescribed', inputFilterable: true, tdClassName: 'sub-text' },
      {
        name: 'id',
        displayName: 'Edit',
        thClassName: user_role_id === USER_ROLES.ml ? 'd-none' : ' ',
        tdClassName: user_role_id === USER_ROLES.ml ? 'd-none' : 'sub-text',
        render: (e) => {
          return (
            <Link to={`/gp-register/${e.value}`}>
              <img src={editIcon} alt="edit_icon" className="prescriptionIcon" />
            </Link>
          );
        },
      },
    ];

    return (
      <div>
        <div className="container-fluid">
          <h3>Doctors</h3>
          <div className="float-right d-flex">
            {AuthService.isSuperAdmin() ? (
              <Button text="Export All" className="btn btn-md btn-primary float-right" onClick={this.downloadFile} />
            ) : (
              ''
            )}
            <div className="form-inline bulk-update mr-5">
              <Select
                name="gpFilter"
                placeholder="Filter"
                className="select-field mx-1 rounded"
                options={[
                  { label: 'All', value: 1 },
                  { label: 'General Prescriber', value: 3 },
                  { label: 'Authorised Prescriber', value: 2 },
                ]}
                onChange={this.setFilter}
              />
            </div>
          </div>
          {loading ? (
            <CircleLoader />
          ) : (
            <FilterableTable
              topPagerVisible={false}
              className="table-responsive w-100 d-xl-table"
              data={data}
              fields={fields}
              paginationOnServer={true}
              initialSort={sort.name}
              initialSortDir={!sort.reverse}
              onPageChanged={this.pageChange}
              filterData={this.onFilter}
              serverSort={this.getSortColumn}
              totalRecords={count}
              currentPage={page}
              pageSize={pageSize}
              filterPlaceholder={filter ? filter : 'Search'}
              pageSizes={false}
              maintainPageOnSetData={true}
              noRecordsMessage="There are no record to display"
              noFilteredRecordsMessage="No record match your filters!"
            />
          )}
        </div>
      </div>
    );
  }
}

export default withRouter(GPView);
