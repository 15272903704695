import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../../scss/patientRefill.scss';

const Refillordersuccess = () => {
  return (
    <div style={{ height: '100vh', alignSelf: 'center' }}>
      <div className="card text-center shadow py-4 center-box refillSuccessBox" style={{ maxWidth: '450px' }}>
        <div className="card-body">
          <p className="card-title text-box-head">
            Your order invoice has been emailed to you.
            <br /> Your medication will be shipped upon payment.
          </p>
          <Link className="btn backtohome" to="/home">
            Back to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Refillordersuccess;
