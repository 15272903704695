import { useEffect, useRef } from 'react';

import useGetZendeskToken from '@/hooks/zendesk/useGetZendeskToken';
import { AuthService } from '@/services/authentication.service';

import settings from '../../constants/constants';

declare global {
  interface Window {
    zE: (widget: string, command: string, callback?: (fn: (token?: string) => void) => void) => void;
  }
}

function ZendeskChatWidget() {
  const scriptRef = useRef<HTMLScriptElement | null>(null);
  const { data } = useGetZendeskToken();
  const isPatient = AuthService.isPatient();

  useEffect(() => {
    if (isPatient && !scriptRef.current) {
      const script = document.createElement('script');

      script.id = 'ze-snippet';
      script.src = `https://static.zdassets.com/ekr/snippet.js?key=${settings.ZENDESK_CHAT_KEY}`;
      script.async = true;

      document.body.appendChild(script);
      scriptRef.current = script;
    }

    return () => {
      if (scriptRef.current) {
        document.body.removeChild(scriptRef.current);
        scriptRef.current = null;
      }
    };
  }, [isPatient]);

  useEffect(() => {
    if (window.zE && data?.token) {
      window.zE('messenger', 'loginUser', (cb) => cb(data.token));
    }
  }, [data]);

  return null;
}

export default ZendeskChatWidget;
