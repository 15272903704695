import { KeyboardArrowLeft as KeyboardArrowLeftIcon } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { useEffect, useRef, useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { EMAIL_PATTERN } from '@/constants/constants';
import AuthService from '@/services/authentication.service';
import { alternaleafTheme } from '@/theme';
import { LoadingButton } from '@/ui-library';

import FormInput from '../common/FormInputTyped';
import { PapayaContained } from '../common/Papaya';
import logo from '../layout/alternaleaf.svg';

import { toast } from '@montugroup/design-system';
import {
  AuthCardContainer,
  AuthFormGroup,
  AuthPageContainer,
  CardHalfContent,
  CardHalfDecoration,
  MobileLogoContainer,
  MobileOnly
} from './authPage';

interface ForgotPasswordFormData {
  email: string;
}

const LoginCardBackground = styled(Box)(({ theme }) => ({
  height: '100%',
  width: '100%',
  backgroundColor: theme.palette.primary.main,
  // eslint-disable-next-line quotes
  backgroundImage: "url('/assets/images/patientBack_AL.png')",
  backgroundPosition: 'top left',
  backgroundSize: 'contain',
  backgroundRepeat: 'no-repeat',
  position: 'relative',
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'flex-start',
  padding: '2rem',
  borderTopLeftRadius: '25px',
  borderBottomLeftRadius: '25px'
}));

const BackLinkContainer = styled(Box)(({ theme }) => ({
  marginBottom: '20px',
  [theme.breakpoints.up('md')]: {
    marginBottom: '60px'
  }
}));

export function PatientForgotPassword() {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);

  const errorToastRef = useRef<string | number>('');

  const { register, handleSubmit, watch, formState } = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: ''
    }
  });

  const { errors } = formState;

  const email = watch('email');
  const dismissErrorToast = () => {
    if (errorToastRef.current) {
      toast.dismiss(errorToastRef.current);
      errorToastRef.current = '';
    }
  };

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = async (formData) => {
    setLoading(true);
    dismissErrorToast();
    const response = await AuthService.resetPassword(formData.email);
    const isError = response instanceof Error;
    setMessage(!isError);
    if (isError) {
      errorToastRef.current = toast.error('Reset password attempt failed. Please, try again.', { autoClose: false });
    }
    setLoading(false);
  };
  useEffect(() => dismissErrorToast, []);

  const shouldShowForm = !message || email === '';

  return (
    <CssVarsProvider theme={alternaleafTheme}>
      <div className="w-100 h-100">
        <AuthPageContainer>
          <AuthCardContainer>
            <CardHalfDecoration>
              <LoginCardBackground>
                <PapayaContained />
              </LoginCardBackground>
            </CardHalfDecoration>
            <CardHalfContent>
              <MobileLogoContainer>
                <a href="https://www.montu.com.au" target="blank" className="">
                  <img src={logo} alt="Alternaleaf" className="px-4 py-2" />
                </a>
              </MobileLogoContainer>
              <div className="p-4 h-100">
                <BackLinkContainer>
                  <Link to="/login/patient">
                    <KeyboardArrowLeftIcon fontSize="medium" />
                    <span>Back to Login</span>
                  </Link>
                </BackLinkContainer>
                <form data-private>
                  <h2 className="mb-4">{shouldShowForm ? 'Forgot Password' : 'Email Sent'}</h2>

                  {shouldShowForm ? (
                    <AuthFormGroup>
                      <p className="mb-4">
                        Forgot your account’s password? Enter your email address and we’ll send you a recovery link.
                      </p>
                      <FormInput
                        {...register('email', {
                          required: 'Please enter your email',
                          pattern: {
                            value: EMAIL_PATTERN,
                            message: 'You must enter a valid email'
                          }
                        })}
                        error={errors.email?.message}
                        type="text"
                        id="userEmail"
                        placeholder="Enter email"
                        label="Email"
                      />
                      <div className="form-group">
                        <LoadingButton
                          id="btnSubmit"
                          loading={loading}
                          variant="contained"
                          color="primary"
                          type="submit"
                          className="w-100"
                          onClick={handleSubmit(onSubmit)}
                          data-testid="submit-button"
                        >
                          Submit
                        </LoadingButton>
                      </div>
                    </AuthFormGroup>
                  ) : (
                    <p className="lead text-success">
                      If your email is already registered. We&apos;ll email you instructions to reset your password.{' '}
                    </p>
                  )}
                </form>
              </div>
            </CardHalfContent>
          </AuthCardContainer>
          <MobileOnly>
            <PapayaContained sx={{ position: 'fixed' }} />
          </MobileOnly>
        </AuthPageContainer>
      </div>
    </CssVarsProvider>
  );
}

export default PatientForgotPassword;
