import type { TypographyProps } from '@mui/material';
import { Box, Divider, Stack, styled, Typography } from '@mui/material';

import { FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE } from '@/constants/featureFlags';
import ffDefaults from '@/constants/ffDefaults';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { ClickableTooltip as Tooltip } from '@/ui-library/tooltip/ClickableTooltip';

import {
  DEFAULT_NO_VALUE_STR,
  getProductCategory,
  getProductHowToUseSteps,
  getProductSpectrum,
  getProductTerpeneProfile,
  getProductUsageTime,
  TOOLTIPS
} from '../utils';

export type ProductOverviewProps = {
  product: {
    brand: string;
    productIngredientName: string | null;
    contents: string | null;
    descriptionForDisplay: string;
    productScheduleName: string;
    productStrainName?: string;
    productStrainKey?: string;
    spectrumId?: number | null;
    howToUse?: string | null;
  };
};

const ProductValueText = styled(Typography)(() => ({
  fontVariant: 'body1'
}));

function Title(props: Pick<TypographyProps, 'children'>) {
  return (
    <Box>
      <Typography variant="body1" fontWeight="bold" gutterBottom {...props} />
      <Divider />
    </Box>
  );
}

const Container = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(6)
}));

const Section = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(4)
}));

const OverviewContent = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(4),

  [theme.breakpoints.up('md')]: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)',
    width: '80%'
  }
}));

const HowToUseContent = styled(Stack)(({ theme }) => ({
  gap: theme.spacing(1)
}));

function ProductLabelText({ children, sx, tooltip }: Pick<TypographyProps, 'children' | 'sx'> & { tooltip?: string }) {
  const text = (
    <Typography
      component="span"
      variant="body1"
      sx={[
        { cursor: tooltip ? 'pointer' : 'default', fontWeight: 'bold', textDecoration: 'underline', display: 'inline' },
        ...(Array.isArray(sx) ? sx : [sx])
      ]}
    >
      {children}
    </Typography>
  );

  if (tooltip) {
    return (
      <Tooltip arrow title={tooltip}>
        {text}
      </Tooltip>
    );
  }
  return text;
}

export default function ProductOverview({ product }: ProductOverviewProps) {
  const { flags } = useFeatureFlags();

  const {
    productIngredientName,
    contents,
    brand,
    productStrainName,
    productStrainKey,
    spectrumId,
    descriptionForDisplay,
    productScheduleName,
    howToUse
  } = product;

  const showProductDisplayAndUsage =
    flags[FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE] ?? ffDefaults[FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE];

  const canShowDescription = Boolean(showProductDisplayAndUsage && descriptionForDisplay);
  const canShowHowToUse = Boolean(showProductDisplayAndUsage && howToUse);

  const productCategory = getProductCategory(productIngredientName);
  const productTerpeneProfile = getProductTerpeneProfile(contents);
  const productUsageTime = getProductUsageTime(productStrainKey);
  const spectrum = getProductSpectrum(spectrumId);
  const howToUseSteps = getProductHowToUseSteps(howToUse);

  return (
    <Container>
      <Section>
        <Title>Overview</Title>
        <OverviewContent>
          <ProductValueText>
            <ProductLabelText sx={{ textDecoration: 'none' }}>Brand:</ProductLabelText>
            &nbsp;{brand}
          </ProductValueText>
          <ProductValueText>
            <ProductLabelText tooltip={TOOLTIPS.strain}>Strain:</ProductLabelText>
            &nbsp;{productStrainName || DEFAULT_NO_VALUE_STR}
          </ProductValueText>
          <ProductValueText>
            <ProductLabelText tooltip={TOOLTIPS.dayornight}>Day or night:</ProductLabelText>
            &nbsp;{productUsageTime}
          </ProductValueText>
          <ProductValueText>
            <ProductLabelText tooltip={TOOLTIPS.category}>Category:</ProductLabelText>
            &nbsp;{productCategory}
          </ProductValueText>
          <ProductValueText>
            <ProductLabelText tooltip={TOOLTIPS.spectrum}>Spectrum:</ProductLabelText>
            &nbsp;{spectrum}
          </ProductValueText>
          <ProductValueText>
            <ProductLabelText tooltip={TOOLTIPS.schedule}>Schedule:</ProductLabelText>
            &nbsp;{productScheduleName || DEFAULT_NO_VALUE_STR}
          </ProductValueText>
          <ProductValueText sx={{ gridColumn: '1 / -1' }}>
            <ProductLabelText tooltip={TOOLTIPS.terpeneprofile}>Terpene profile:</ProductLabelText>
            &nbsp;{productTerpeneProfile}
          </ProductValueText>
        </OverviewContent>
      </Section>

      {canShowDescription && (
        <Section>
          <Title>Description</Title>
          <Typography>{descriptionForDisplay}</Typography>
        </Section>
      )}

      {canShowHowToUse && (
        <Section>
          <Title>Product usage</Title>
          <HowToUseContent>
            {howToUseSteps.map((step) => (
              <Typography gutterBottom key={step}>
                {step}
              </Typography>
            ))}
          </HowToUseContent>
        </Section>
      )}
    </Container>
  );
}
