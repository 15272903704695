import moment from 'moment';

import type { PrescriptionInfo, ProductInfo } from '@/components/products/refill/ProductRefillCard';
import type { UnavailableProductIssue } from '@/components/products/refill/UnavailableProductRefillCard';
import settings from '@/constants/constants';
import {
  deriveMaxAllowedQuantity,
  getProductFilterTypeFromFormulationId,
  nullToUndefined
} from '@/hooks/patient/refill/util';
import type { ProductStrain, ProductStrainKey } from '@/types';
import getProductImageUrl from '@/utils/getProductImageUrl';
import type { Logger } from '@/utils/logger';

import type { PrescribedProduct, ProductCardDetails } from '../../../hooks/patient/refill/types';

const DISCONTINUED_TITLE = 'Discontinued';
const OUT_OF_STOCK_TITLE = 'Out of stock';

export const PRODUCT_PURCHASE_ISSUE_TEXT = {
  supportPhone: settings.support.phone.display,

  prescriptionExpired: {
    title: 'Prescription expired',
    info: 'Book a follow up with your doctor',
    linkTo: '/patient/consultations'
  },

  outOfRepeats: {
    title: 'Out of repeats',
    info: 'Book a follow up with your doctor',
    linkTo: '/patient/consultations'
  },

  outOfStock: {
    title: OUT_OF_STOCK_TITLE,
    info: 'Check the product status to request a replacement product.'
  },

  intervalPeriod: {
    title: 'Interval time lock',
    defaultInfo: 'You have ordered the maximum prescribed quantity of this product for your interval period.',
    getInfoWithDate: (intervalEndDate: string) => `Re-order available on: ${intervalEndDate}`
  },

  discontinued: {
    title: DISCONTINUED_TITLE,
    // eslint-disable-next-line quotes
    info: "Please select 'Request replacement' to notify your doctor for a replacement product."
  },
  // eslint-disable-next-line quotes
  infoRequestAlternate: "Please select 'Request alternative product' to notify your doctor for a replacement product."
};

export const UNKNOWN_SUPPLIER_NAME = 'Unknown Supplier';

export const isProductOutOfStock = (product: PrescribedProduct) =>
  Boolean(product.is_out_of_stock || product.reasoning_toggle);

/**
 * For some reason, the Product Refill API response returns either 'remainingUnits' or 'remaining_units'.
 * Which one it returns is inconsistent, so we're normalising it here to snake_case.
 */
export const mapToProductRefillCardProduct = (prescribedProduct: PrescribedProduct): ProductInfo => ({
  id: prescribedProduct.id,
  cbdForDisplay: prescribedProduct.cbd_strength_for_display || '',
  thcForDisplay: prescribedProduct.thc_strength_for_display || '',
  formulation: getProductFilterTypeFromFormulationId(prescribedProduct.formulation_id),
  price: prescribedProduct.price,
  productName: prescribedProduct.name,
  strainName: prescribedProduct.Strain?.name as ProductStrain,
  strainKey: prescribedProduct.Strain?.shopify_key as ProductStrainKey,
  supplier: prescribedProduct.Suppliers?.[0]?.supplier_name || UNKNOWN_SUPPLIER_NAME,
  size: prescribedProduct.size,
  isConcession: prescribedProduct.is_concession
});

export const mapToProductCard = (prescribedProduct: PrescribedProduct | undefined): ProductCardDetails | null => {
  if (prescribedProduct === null || typeof prescribedProduct === 'undefined') {
    return null;
  }
  return {
    id: prescribedProduct.id,
    name: prescribedProduct.name,
    price: prescribedProduct.price,
    supplierName: prescribedProduct.Suppliers[0]?.supplier_name || UNKNOWN_SUPPLIER_NAME,
    isOutOfStock: isProductOutOfStock(prescribedProduct),
    reasoningToggle: prescribedProduct.reasoning_toggle,
    isGenerativeScripting: !!prescribedProduct.is_generative_scripting,
    productType: getProductFilterTypeFromFormulationId(prescribedProduct.formulation_id),
    imageUrl: getProductImageUrl(nullToUndefined(prescribedProduct.image_url), prescribedProduct.ProductImages),
    shortName: nullToUndefined(prescribedProduct.short_name),
    thcStrengthForDisplay: nullToUndefined(prescribedProduct.thc_strength_for_display),
    cbdStrengthForDisplay: nullToUndefined(prescribedProduct.cbd_strength_for_display),
    remainingUnits: prescribedProduct.remaining_units,
    repeats: prescribedProduct.repeats || 0,
    isIntervalLocked: prescribedProduct.isProductLocked,
    intervalEndDate: prescribedProduct.intervalEndDate,
    maxAllowedQuantity: deriveMaxAllowedQuantity(prescribedProduct),
    isExpired: prescribedProduct.is_expired,
    prescriptionId: prescribedProduct.prescription_id || 0,
    isCampaignDiscountPrice: prescribedProduct.campaign_discount_price
  };
};

export const mapToProductRefillCardPrescription = (prescribedProduct: PrescribedProduct): PrescriptionInfo => ({
  expiry: prescribedProduct.latest_prescription_expiry_date || '',
  maxQuantity: prescribedProduct ? deriveMaxAllowedQuantity(prescribedProduct) : 0,
  maxRepeats: prescribedProduct ? prescribedProduct.repeats || 0 : 0,
  interval: prescribedProduct.interval || 0,
  productSchedule: prescribedProduct?.ProductSchedule
});

export const getProductPurchaseIssue = (
  product: PrescribedProduct,
  logger?: Logger
): UnavailableProductIssue | null => {
  const isOutOfStock = product.is_out_of_stock || product.reasoning_toggle;
  const hasNoRemainingUnits = product.remaining_units <= 0;

  if (product.is_expired) {
    return {
      title: PRODUCT_PURCHASE_ISSUE_TEXT.prescriptionExpired.title,
      info: PRODUCT_PURCHASE_ISSUE_TEXT.prescriptionExpired.info,
      isHighSeverity: false
    };
  }

  if (hasNoRemainingUnits) {
    return {
      title: PRODUCT_PURCHASE_ISSUE_TEXT.outOfRepeats.title,
      info: PRODUCT_PURCHASE_ISSUE_TEXT.outOfRepeats.info,
      isHighSeverity: false
    };
  }

  if (isOutOfStock) {
    return {
      title: PRODUCT_PURCHASE_ISSUE_TEXT.outOfStock.title,
      info: PRODUCT_PURCHASE_ISSUE_TEXT.outOfStock.info,
      isHighSeverity: true
    };
  }

  if (product.isProductLocked) {
    const validDate = moment(product.intervalEndDate).isValid();
    if (!validDate || !product.intervalEndDate) {
      logger?.warn('Invalid end date or no intervalEndDate', validDate, product.intervalEndDate);
    }

    const defaultInfoText = PRODUCT_PURCHASE_ISSUE_TEXT.intervalPeriod.defaultInfo;
    const infoTextWithDate = PRODUCT_PURCHASE_ISSUE_TEXT.intervalPeriod.getInfoWithDate(
      validDate && product.intervalEndDate ? moment(product.intervalEndDate).format('MMMM D, YYYY [at] h:mma') : ''
    );

    return {
      title: PRODUCT_PURCHASE_ISSUE_TEXT.intervalPeriod.title,
      info: validDate && product.intervalEndDate ? infoTextWithDate : defaultInfoText,
      isHighSeverity: false
    };
  }

  if (product.is_generative_scripting) {
    return {
      title: PRODUCT_PURCHASE_ISSUE_TEXT.discontinued.title,
      info: '',
      isHighSeverity: true
    };
  }

  return null;
};
