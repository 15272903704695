import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

type Product = {
  name: string | null;
  short_name: string | null;
  image_url: string | null;
  price: number | null;
  is_out_of_stock: boolean | null;
  reasoning_toggle: boolean | null;
  stock_return_date: string | null;
  formulation_id: number | null;
  ProductImages: Array<unknown>; // todo
  ProductFormulation: {
    id: number | null;
    name: string | null;
    whitelabel_image_url: string | null;
  };
};

export type OrderDetailsProducts = {
  quantity: number | null;
  product_id: number | null;
  price: string | null;
  id: number | null;
  Product: Product;
};

export type OrderDetails = {
  order_code: string;
  order_date: string;
  id: number;
  total_price: string | null;
  shipment_carrier: string | null;
  shipment_tracking_id: string | null;
  order_status_id: number;
  patient_id: number | null;
  delivered: boolean;
  delivered_date: string | null;
  shipped_date: string | null;
  dispensed_date: string | null;
  Patient: {
    id: number;
    is_discharge: string | null;
  };
  Pharmacy: {
    id: number | null;
    name: string | null;
  };
  OrderProducts: OrderDetailsProducts[];
  OrderStatus: {
    name: string | null;
  };
  OrderManagementShopify: string | null;
};

const logger = new Logger('useGetOrderDetails');

export default function useGetOrderDetails(orderCode: string, enabled: boolean) {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['orderDetails', orderCode],
    queryFn: async () => {
      try {
        const res = await axios.get<OrderDetails[]>(`${settings.url}/patient/orderDetail/${orderCode}`);
        return res.data;
      } catch (error) {
        logger.error(error);
        throw new Error('Failed to fetch order details');
      }
    },
    enabled
  });

  return {
    data,
    isError,
    isLoading
  };
}
