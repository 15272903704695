import { toast } from '@montugroup/design-system';
import { Box, Divider, styled } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';

import USER_ROLES from '@/constants/userRoles';
import { Button, LoadingButton, TextField } from '@/ui-library';
import { Logger } from '@/utils/logger';

import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';
import { Papaya } from '../common/Papaya';

const logger = new Logger('resetPassword');

const { url } = settings;

const ContentContainer = styled(Box)`
  max-width: 100%;
  margin: initial;
`;

const PageContainer = styled(Box)(({ theme }) => ({
  padding: '2rem 1rem',
  width: '100%',
  [theme.breakpoints.up('lg')]: {
    padding: '2rem 10rem'
  }
}));

const PapayaComponent = styled(Papaya)(({ theme }) => ({
  display: 'none',
  [theme.breakpoints.up('md')]: {
    display: 'block'
  }
}));

export interface ResetPasswordFormData {
  currentPassword: string;
  password: string;
  confirmPassword: string;
}

export default function ResetPasswordRebrand() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [, forceUpdate] = React.useReducer((x) => x + 1, 0);

  const { control, handleSubmit } = useForm<ResetPasswordFormData>({
    defaultValues: {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  });

  const { loginType } = useParams<{ loginType: string }>();

  const handlePasswordSubmit: SubmitHandler<ResetPasswordFormData> = async (formData: ResetPasswordFormData) => {
    setLoading(true);

    await axios
      .put(`${url}/user/password/`, {
        body: JSON.stringify({
          currentPassword: formData.currentPassword,
          password: formData.password
        })
      })
      .then(async (res) => {
        setLoading(false);

        if (res.status === 200 && res.data.id === 1) {
          toast.success('Password details are updated');
          const user = AuthService.getUser();

          if (user?.user?.role_id === USER_ROLES.patient) {
            window.location.replace('/home');
          } else {
            window.location.replace('/orders');
          }
        } else if (res.data.id === 0) {
          toast.error('Password details not updated');
          logger.error('Password details not updated, res.data.id === 0');

          navigate('/password');
          forceUpdate();
        }
      });
  };

  return (
    <PageContainer className="reset-password">
      <ContentContainer>
        <h3 className="form-heading">Change your password</h3>

        <Divider className="form-divider" />

        <form className="form-container" data-private onSubmit={handleSubmit(handlePasswordSubmit)}>
          <div className="form-row col-sm-12 col-md-6 col-lg-4 px-0">
            <div className="text-field col-12">
              <Controller
                name="currentPassword"
                control={control}
                rules={{ required: 'You must enter the current password' }}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <TextField
                    type="password"
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    label="Current Password"
                    placeholder="Current Password"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-row col-sm-12 col-md-6 col-lg-4 px-0">
            <div className="text-field col-12">
              <Controller
                name="password"
                control={control}
                rules={{
                  required: 'You must enter a password',
                  minLength: {
                    value: 5,
                    message: 'Password must be at least 5 characters'
                  }
                }}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <TextField
                    type="password"
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    label="New Password"
                    placeholder="New Password"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-row col-sm-12 col-md-6 col-lg-4 px-0">
            <div className="text-field col-12">
              <Controller
                name="confirmPassword"
                control={control}
                rules={{
                  required: 'Passwords must match',
                  validate: (value, formData) => formData.password === value || 'Passwords must match'
                }}
                render={({ field: { onChange, onBlur, value, ref }, fieldState: { error } }) => (
                  <TextField
                    type="password"
                    value={value}
                    inputRef={ref}
                    onChange={onChange}
                    onBlur={onBlur}
                    label="Confirm Password"
                    placeholder="Confirm Password"
                    error={!!error}
                    helperText={error?.message}
                  />
                )}
              />
            </div>
          </div>

          <div className="form-actions col-sm-12 col-md-6 px-0">
            {loginType === 'patient' && (
              <Button type="button" variant="outlined" color="primary" onClick={() => navigate('/home')}>
                Back
              </Button>
            )}
            <LoadingButton loading={loading} id="btnSubmit" type="submit" variant="contained" color="primary">
              <span>Submit</span>
            </LoadingButton>
          </div>
        </form>
      </ContentContainer>

      <PapayaComponent />
    </PageContainer>
  );
}
