/* eslint-disable react/no-unstable-nested-components */
import { PaginationVariant, Table } from '@montugroup/design-system';
import { Button, Stack, Typography } from '@mui/material';
import type { Column, ColumnDef, Row } from '@tanstack/react-table';
import debounce from 'awesome-debounce-promise';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import TableSearchInput from '@/components/common/TableSearchInput';
import TableCell, {
  ApprovalsTableCell,
  ArrayValueTableCell,
  DoctorTableCell,
  OrderSelectTableCell,
  PrescriptionsTableCell,
  ProductNameTableCell,
  ShipmentStatusTableCell
} from '@/components/pharmacist/orders/PharmacistOrdersTableCell';
import { PharmacistOrderTableView, usePharmacistOrders } from '@/context/pharmacist/orders/PharmacistOrders';
import { usePharmacistSplitOrderModal } from '@/context/pharmacist/orders/PharmacistOrdersModals';
import type { PharmacistOrder } from '@/types';

import { FF_PHARMACIST_ORDERS_TABLE_SMALL_SIZE } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import PharmacistOrdersActions from './PharmacistOrdersActions';
import { dateColumnSort, sortDispenseDate } from './utils/PharmacistOrdersTableUtils';

const DATE_DISPLAY_FORMAT = 'DD-MMM-YYYY';

function PharmacistOrdersTable() {
  const {
    activeView,
    orders,
    count,
    pageSize,
    handlePaginationModelChange,
    handleSortingOrderChange,
    search,
    setSearch,
    loading,
    shouldResetPageIndex
  } = usePharmacistOrders();
  const { showSplitOrderModal } = usePharmacistSplitOrderModal();
  const navigate = useNavigate();
  const { loading: flagsLoading, flags } = useFeatureFlags();

  const renderSimpleValueTableCell = ({
    row,
    column
  }: {
    row: Row<PharmacistOrder>;
    column: Column<PharmacistOrder>;
  }) => <TableCell>{row.getValue(column.id)}</TableCell>;

  const columns: ColumnDef<PharmacistOrder>[] = useMemo(
    () => [
      {
        accessorKey: 'shipped_date',
        header: 'Date shipped',
        enableSorting: true,
        sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.shipped_date, rowB.original.shipped_date),
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'order_code',
        header: 'Order ID',
        enableSorting: true,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'order_date',
        header: 'Order placed',
        enableSorting: true,
        accessorFn: (originalRow) => moment(new Date(originalRow.order_date)).format(DATE_DISPLAY_FORMAT),
        sortingFn: (rowA, rowB) => dateColumnSort(rowA.original.order_date, rowB.original.order_date),
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'patient_id',
        header: 'Patient ID',
        enableSorting: true,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'patient_name',
        header: 'Patient',
        enableSorting: true,
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'gp_name',
        header: 'Doctor',
        enableSorting: true,
        cell: ({ row }) => <DoctorTableCell gps={row.original.gps} />
      },
      {
        accessorKey: 'approvals',
        header: 'Approvals',
        enableSorting: false,
        cell: ({ row }) => <ApprovalsTableCell order={row.original} />
      },
      {
        accessorKey: 'prescriptions',
        header: 'Prescriptions',
        enableSorting: false,
        cell: ({ row }) => <PrescriptionsTableCell prescriptions={row.original.prescriptions} />
      },
      {
        accessorKey: 'product_name',
        header: 'Product',
        enableSorting: false,
        cell: ({ row }) => <ProductNameTableCell productDetails={row.original.product_details} />
      },
      {
        accessorKey: 'quantity',
        header: 'Quantity',
        enableSorting: false,
        cell: ({ row }) => <ArrayValueTableCell values={row.original.quantity} />
      },
      {
        accessorKey: 'repeats',
        header: 'Repeats',
        enableSorting: false,
        cell: ({ row }) => <ArrayValueTableCell values={row.original.repeats} />
      },
      {
        accessorKey: 'dispensed_date',
        header: 'Dispensed date',
        enableSorting: true,
        accessorFn: (originalRow) =>
          originalRow.dispensed_date !== '-'
            ? moment(new Date(originalRow.dispensed_date)).format(DATE_DISPLAY_FORMAT)
            : '-',
        sortingFn: (rowA, rowB) => sortDispenseDate(rowA.original.dispensed_date, rowB.original.dispensed_date),
        cell: renderSimpleValueTableCell
      },
      {
        accessorKey: 'shipmentStatus',
        header: 'Status',
        enableSorting: false,
        cell: ({ row }) => (
          <ShipmentStatusTableCell status={row.original.shipmentStatus} orderId={row.original.orderId} />
        )
      },
      {
        enableSorting: false,
        id: 'unableToDispense',
        cell: ({ row }) => {
          if (row.original.shipmentStatus === 'Processing Dispense') {
            return (
              <Button
                aria-label="Unable to dispense"
                variant="text"
                size="small"
                onClick={() => showSplitOrderModal(row.original)}
              >
                <Typography fontSize="small">Unable to dispense</Typography>
              </Button>
            );
          }
          return null;
        }
      },
      ...(activeView !== PharmacistOrderTableView.PROCESSING_DISPENSE
        ? ([
            {
              accessorKey: 'select',
              header: 'Select',
              enableSorting: false,

              cell: ({ row }) => (
                <OrderSelectTableCell
                  orderId={row.original.id}
                  potentialSameDayDelivery={Boolean(row.original.potential_same_day_delivery)}
                />
              )
            }
          ] as ColumnDef<PharmacistOrder>[])
        : [])
    ],
    [activeView, showSplitOrderModal]
  );

  const handleSearch = debounce((value: string) => {
    const path = value ? `/pharmacist/orders?search=${encodeURIComponent(value)}` : '/pharmacist/orders';
    navigate(path);
    setSearch(value);
  }, 500);

  // The BE does not support batches bigger than 50 when running a legacy code variation.
  const limitTableSize = flags[FF_PHARMACIST_ORDERS_TABLE_SMALL_SIZE];
  const pageSizeOptions = useMemo(() => {
    const options = [10, 25, 50, 100];
    if (!flagsLoading && limitTableSize) {
      options.pop();
    }
    return options;
  }, [flagsLoading, limitTableSize]);

  return (
    <>
      <Stack
        direction={{ sm: 'column', md: 'row' }}
        justifyContent="space-between"
        gap={4}
        paddingY={(theme) => theme.spacing(4)}
      >
        <TableSearchInput value={search} onChange={handleSearch} />
        <PharmacistOrdersActions />
      </Stack>
      <Table
        data={orders}
        columns={columns}
        showPagination
        manualPagination
        shouldResetPageIndex={shouldResetPageIndex()}
        pageSize={pageSize}
        pageSizeOptions={pageSizeOptions}
        total={count}
        paginationVariant={PaginationVariant.VARIABLE_PAGE_SIZE}
        onPaginationModelChange={handlePaginationModelChange}
        onSortingChange={handleSortingOrderChange}
        isLoading={loading}
      />
    </>
  );
}

export default PharmacistOrdersTable;
