import { Logout as LogoutIcon } from '@mui/icons-material';
import { MenuItem, styled } from '@mui/material';

import AuthService from '@/services/authentication.service';

const LogoutMenuItem = styled(MenuItem)(({ theme }) => ({
  padding: `${theme.spacing(4)} 0 ${theme.spacing(4)} ${theme.spacing(7)}`,
  fontWeight: theme.typography.fontWeightMedium,
  gap: theme.spacing(4),
  borderRadius: 0,
  justifyContent: 'flex-start',
  backgroundColor: theme.palette.common.white,
  color: theme.palette.primary.main,
  '&:hover': {
    backgroundColor: theme.palette.grey[200]
  }
}));

function Logout({ toggleText = true }: { toggleText?: boolean }) {
  return (
    <LogoutMenuItem onClick={() => AuthService.logout()}>
      <LogoutIcon />
      {toggleText && 'Logout'}
    </LogoutMenuItem>
  );
}

export default Logout;
