import { Stack, Typography } from '@mui/material';

export function HeadingSection() {
  return (
    <Stack marginBottom={6} gap={1}>
      <Typography variant="h5" color="primary.main">
        Medicine information
      </Typography>
      <Typography variant="body2" color="primary.main">
        Discover details about the products you&apos;ve been prescribed
      </Typography>
    </Stack>
  );
}

export default HeadingSection;
