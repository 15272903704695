import { useQuery } from '@tanstack/react-query';

import type { Product } from '@/components/products/detail/types';
import { PatientService } from '@/services/patient.service';
import { Logger } from '@/utils/logger';

type OrderProducts = {
  quantity: number;
  product_id: number;
  order_id: number;
  id: number;
  Product: Product;
};

export type PatientOrder = {
  order_code: string;
  order_date: string;
  id: number;
  total_price: string;
  OrderProducts: OrderProducts[];
  delivered: boolean;
  deliveredDate: string | null;
  shipped_date: string | null;
  dispensed_date: string | null;
  dispensing_fee: string;
  shipment_tracking_id: string | null;
  shipment_carrier: string | null;
  pharmacy: {
    id: string;
    name: string;
  };
  orderStatus: number;
  intervalTime: string | null;
  intervalDate: string | null;
  order_history_cancelled_date: string | null;
};

const logger = new Logger('useGetPatientOrders');

function useGetPatientOrders() {
  const { data, isError, isLoading } = useQuery({
    queryKey: ['patientOrders'],
    queryFn: async () => {
      try {
        const res = await PatientService.getPatientOrders();
        return res.data;
      } catch (error) {
        logger.error(error);
        throw new Error('Failed to fetch patient orders');
      }
    }
  });

  return {
    data,
    isError,
    isLoading
  };
}

export default useGetPatientOrders;
