import { WithSnowplow } from '@montugroup/data-collection';
import { setUserId } from '@snowplow/browser-tracker';
import React, { useEffect } from 'react';

import { FF_ENABLE_SNOWPLOW_CIRCUIT } from '@/constants/featureFlags';
import { SNOWPLOW_APP_ID, SNOWPLOW_TRACKER_NAME } from '@/constants/snowplow';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { AuthService } from '@/services/authentication.service';

// This component acts as a wrapper around WithSnowplow from data-collection
// It ensures the userId field is properly set at all times
export const AuthenticatedSnowplow = (props: React.PropsWithChildren) => {
  const { children } = props;
  const { flags } = useFeatureFlags();
  const enableSnowplow = flags[FF_ENABLE_SNOWPLOW_CIRCUIT];
  let user = AuthService.getUser();
  let userId = user?.user?.id?.toString();

  useEffect(() => {
    const handleUserChange = () => {
      user = AuthService.getUser();
      userId = user?.user?.id?.toString();
      setUserId(userId);
    };
    window.addEventListener('user', handleUserChange);
    return () => {
      window.removeEventListener('user', handleUserChange);
    };
  }, []);

  return WithSnowplow(
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>,
    {
      appId: SNOWPLOW_APP_ID,
      trackerName: SNOWPLOW_TRACKER_NAME,
      userId
    },
    enableSnowplow
  );
};

export default AuthenticatedSnowplow;
