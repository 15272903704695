import { Box, styled, Typography } from '@mui/material';
import type { Dispatch } from 'react';
import React from 'react';
import type { FileWithPath } from 'react-dropzone';
import { useDropzone } from 'react-dropzone';

const ZoneContainer = styled(Box)(({ theme }) => ({
  border: `1px dashed ${theme.palette.divider}`,
  borderRadius: `${theme.shape.borderRadius}px`,
  padding: theme.spacing(3, 2),
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  gap: theme.spacing(1)
}));

const ALLOWED_FILE_TYPES = {
  'image/png': ['.png'],
  'image/jpeg': ['.jpg', '.jpeg'],
  'image/webp': ['.webp'],
  'image/gif': ['.gif']
};

function PharmacyLogoUpload({
  files,
  setFiles,
  existingLogoUrl
}: {
  files: FileWithPath[];
  setFiles: Dispatch<React.SetStateAction<FileWithPath[]>>;
  existingLogoUrl?: string;
}) {
  const { getRootProps, getInputProps } = useDropzone({
    multiple: false,
    accept: ALLOWED_FILE_TYPES,
    maxSize: 1024 * 1024 * 2, // 2MB
    onDrop: (acceptedFiles: File[]) => {
      setFiles(acceptedFiles);
    }
  });

  const renderPreview = () => {
    let previewImage = existingLogoUrl;

    if (files && files.length > 0) {
      previewImage = URL.createObjectURL(files[0]);
    }

    if (!previewImage) {
      return null;
    }

    return <img src={previewImage} alt="pharmacy-logo-preview" />;
  };

  return (
    <Box sx={{ width: '100%' }}>
      <ZoneContainer {...getRootProps()}>
        <input {...getInputProps()} />
        <Typography>Click to upload pharmacy logo</Typography> or drag and drop
        <Box>
          <Typography variant="body2" color="textSecondary">
            (Only {Object.values(ALLOWED_FILE_TYPES).flat().join(', ')} images will be accepted)
          </Typography>
        </Box>
      </ZoneContainer>
      {renderPreview()}
    </Box>
  );
}

export default PharmacyLogoUpload;
