import { Box, Divider, styled, Typography } from '@mui/material';

import type { ProductItemProps } from '@/types/productItem.types';

import ProductItem from './ProductItem';

interface ProductSectionProps {
  title: string;
  products: ProductItemProps[];
}

const ProductComponents = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
  flexDirection: 'row',
  margin: theme.spacing(8, 0)
}));

const DividerStyle = styled(Divider)(({ theme }) => ({
  margin: 0,
  backgroundColor: theme.palette.common.black
}));

function ProductSection({ title, products }: ProductSectionProps) {
  return (
    <>
      <Typography variant="h6" textAlign="center">
        {title}
      </Typography>
      <DividerStyle data-testid="divider-style" />
      <ProductComponents>
        {products.map((item) => (
          <ProductItem key={item.imgUrl} {...item} />
        ))}
      </ProductComponents>
    </>
  );
}

export default ProductSection;
