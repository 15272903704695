import { BrandThemeProvider } from '@montugroup/themes';
import { ArrowBack } from '@mui/icons-material';
import { Alert, Box, Button, Link, Stack, styled, TextField, Typography } from '@mui/material';
import { useMutation } from '@tanstack/react-query';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';

import { EMAIL_PATTERN } from '@/constants/constants';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

import { AuthService } from '../../../services/authentication.service';

import { AuthCardContainer, AuthPageContainer, Background, CentreContainer } from './PharmacistStyles';

interface ForgotPasswordFormData {
  email: string;
}

const SuccessAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.success.light,
  color: theme.palette.success.dark,
  margin: theme.spacing(6, 0, 2, 0),
  textAlign: 'left'
}));

const resetPassword = async (email: string) => {
  const response = await AuthService.resetPassword(email);
  if (response.status !== 200) {
    throw new Error('Failed to reset password');
  }
  return response;
};

export function PharmacistForgotPassword() {
  const { key: brandName, Icon: Bg, Logo: TopLogo } = usePortalPartnerBrand();
  const { mutate, isPending, isSuccess } = useMutation({
    mutationFn: resetPassword
  });

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: ''
    }
  });

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = async (formData) => {
    mutate(formData.email.trim());
  };

  return (
    <BrandThemeProvider variant={brandName}>
      <AuthPageContainer>
        <CentreContainer>
          <AuthCardContainer>
            <Stack mb={10}>
              <TopLogo width="150px" height="auto" fillColor={(theme) => theme.palette.primary.main} />
            </Stack>
            <Typography color="primary" variant="h5" align="left">
              Forgot Password
            </Typography>
            {isSuccess ? (
              <SuccessAlert severity="success">
                If your email is registered, we’ll send you instructions to reset your password shortly.
              </SuccessAlert>
            ) : (
              <>
                <Typography color="primary" align="left" pt={3} pb={1}>
                  Enter your email address and we’ll send you a recovery link to reset your password.
                </Typography>
                <Box component="form" onSubmit={handleSubmit(onSubmit)} data-private>
                  <TextField
                    {...register('email', {
                      required: 'Please enter your email',
                      pattern: {
                        value: EMAIL_PATTERN,
                        message: 'You must enter a valid email'
                      }
                    })}
                    error={!!errors.email}
                    helperText={errors.email?.message}
                    type="text"
                    id="userEmail"
                    placeholder="Enter email"
                    label="Email"
                    fullWidth
                    sx={{
                      marginY: 4
                    }}
                  />
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    color="secondary"
                    disabled={isPending}
                    data-testid="submit-button"
                    size="large"
                  >
                    Submit
                  </Button>
                </Box>
              </>
            )}
            <Link
              component={RouterLink}
              color="primary"
              to="/login/pharmacist"
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                mt: 4
              }}
            >
              <ArrowBack />
              Back to Login
            </Link>
          </AuthCardContainer>
        </CentreContainer>
      </AuthPageContainer>
      <Background>
        <Bg />
      </Background>
    </BrandThemeProvider>
  );
}

export default PharmacistForgotPassword;
