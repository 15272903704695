import { useState } from 'react';
import type { SubmitHandler } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';

import { EMAIL_PATTERN } from '@/constants/constants';
import { LoadingButton } from '@/ui-library';

import { AuthService } from '../../services/authentication.service';
import FormInput from '../common/FormInputTyped';
import montuLogo from '../layout/montu.png';

interface ForgotPasswordFormData {
  email: string;
}

export function DefaultForgotPasswordPage() {
  const [loading, setLoading] = useState<boolean>(false);
  const [message, setMessage] = useState<boolean>(false);

  const { register, handleSubmit, watch, formState } = useForm<ForgotPasswordFormData>({
    defaultValues: {
      email: ''
    }
  });

  const { errors } = formState;

  const email = watch('email');

  const onSubmit: SubmitHandler<ForgotPasswordFormData> = async (formData) => {
    setMessage((state) => !state);
    setLoading(true);
    const response = await AuthService.resetPassword(formData.email);
    if (response.status === 200) {
      setLoading(false);
    }
  };

  const shouldShowForm = !message || email === '';

  return (
    <div className="auth-white">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 mx-auto mt-5">
            <div className="container p-3">
              <div>
                <a href="https://www.montu.com.au" target="blank">
                  <img src={montuLogo} alt="montu" className="p-2 montu-logo" />
                </a>
              </div>
              <div className="card w-100  shadow-lg auth-border">
                <div className="row auth-border">
                  <div className="col-md-6">
                    <div className="auth-opacity auth-image-border">
                      <img
                        src="/assets/images/back.png"
                        className="w-100 auth-image auth-image-border img-fluid"
                        alt="MONTU"
                      />
                      <div className="centered text-left">
                        A platform for healthcare professionals to manage their medicinal cannabis patients and
                        prescriptions.
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 login-response mt-5 pr-3">
                    <div className="sign-in mt-5">
                      <div className="auth-wrapper mx-auto d-flex justify-content-center">
                        <div className="text-center">
                          <h2>Forgot Password</h2>
                          <p>
                            Forgot your account’s password? Enter your email address and we’ll send you a recovery link.
                          </p>
                          <small>
                            <Link to="/">Back to Login</Link>
                          </small>
                          <div className="mt-4 auth-wrapper rounded mx-auto">
                            <form className="p-3">
                              {shouldShowForm ? (
                                <>
                                  <FormInput
                                    {...register('email', {
                                      required: 'Please enter your email',
                                      pattern: {
                                        value: EMAIL_PATTERN,
                                        message: 'You must enter a valid email'
                                      }
                                    })}
                                    error={errors.email?.message}
                                    type="text"
                                    id="userEmail"
                                    label="Your Email"
                                  />
                                  <div className="form-group p-1">
                                    <LoadingButton
                                      id="btnSubmit"
                                      loading={loading}
                                      type="submit"
                                      variant="contained"
                                      color="primary"
                                      className="w-50 p-2"
                                      onClick={handleSubmit(onSubmit)}
                                      data-testid="submit-button"
                                    >
                                      Submit
                                    </LoadingButton>
                                  </div>
                                </>
                              ) : (
                                <p className="lead text-success">
                                  If your email is already registered. We&apos;ll email you instructions to reset your
                                  password.{' '}
                                </p>
                              )}
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DefaultForgotPasswordPage;
