import { Link } from '@mui/material';

import settings from '@/constants/constants';

type ConsignmentTableCellProps = {
  consignmentCode: string;
  shipmentTrackingId: string;
  shipmentCarrier: string;
};

function ConsignmentTableCell({ consignmentCode, shipmentTrackingId, shipmentCarrier }: ConsignmentTableCellProps) {
  if (!shipmentTrackingId || shipmentTrackingId === '-' || !shipmentCarrier) {
    return <span>{consignmentCode}</span>;
  }

  let trackingUrl;
  switch (shipmentCarrier) {
    case settings.shipmentCarrier.ausPost:
      trackingUrl = `${settings.ausPostTrackingUrl}${shipmentTrackingId}`;
      break;
    case settings.shipmentCarrier.shippit:
      trackingUrl = `${settings.shippitTrackingUrl}${shipmentTrackingId}`;
      break;
    default:
      break;
  }

  return (
    <Link href={trackingUrl} target="_blank" rel="noopener noreferrer">
      {consignmentCode}
    </Link>
  );
}

export default ConsignmentTableCell;
