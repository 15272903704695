import { Box, Divider, Skeleton, styled } from '@mui/material';

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  borderRadius: theme.shape.borderRadius
}));

const OrderBreakdownContainer = styled(Box)(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    backgroundColor: '#EBEEEF', // todo: confirm the semantic color for this hexcode
    padding: theme.spacing(5),
    borderRadius: theme.spacing(2)
  }
}));

const TotalContainer = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  marginTop: theme.spacing(2)
}));

export function OrderBreakdownLoading() {
  return (
    <Wrapper>
      <OrderBreakdownContainer>
        <Skeleton variant="text" width="100%" height={32} />
        <Skeleton variant="text" width={24} height={24} />
        <Divider />
        <TotalContainer>
          <Skeleton variant="text" width="100%" height={32} />
        </TotalContainer>
      </OrderBreakdownContainer>
    </Wrapper>
  );
}

export default OrderBreakdownLoading;
