import { useLocation, useNavigate } from 'react-router-dom';

import UnauthenticatedLayout from '@/components/layout/UnauthenticatedLayout';
import OrderConfirmed from '@/components/payment/OrderConfirmed';

function PaymentSuccessUnauth() {
  const navigate = useNavigate();
  const location = useLocation();

  const { orderCode, transactionType, bankTransferReference, firstName, email, onBoardKey } = location.state;

  // TODO analytics
  // For logged out patients we check if isReady is true

  if (!orderCode) {
    navigate('/login/patient', { state: { from: { pathname: '/patient/orders' } } });
  }

  return (
    <UnauthenticatedLayout>
      <OrderConfirmed
        loggedIn={false}
        orderCode={orderCode}
        transactionType={transactionType}
        bankTransferReference={bankTransferReference}
        patientName={firstName}
        patientEmail={email}
        onBoardKey={onBoardKey}
      />
    </UnauthenticatedLayout>
  );
}

export default PaymentSuccessUnauth;
