import type { BoxProps } from '@mui/material';
import { Box } from '@mui/material';

export type ProductImageProps = Pick<BoxProps<'img'>, 'sx' | 'src' | 'alt'>;

export function ProductImage(props: ProductImageProps) {
  return <Box component="img" {...props} />;
}

export default ProductImage;
