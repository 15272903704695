import USER_ROLES from '@/constants/userRoles';
import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import settings from '../../constants/constants';
import { AuthService } from '../../services/authentication.service';
import '../dashboard/dashboard.scss';
import AdminMenu from './navbar/adminTile';
import NavbarMenu from './navbar/dashboardMenuTile';
import ImageTile from './navbar/imageTile';
import MoreMenu from './navbar/moreMenuTile';

const url = settings.url;
const Navbar = () => {
  const [state, setState] = useState({
    isExpanded: false,
    active: false
  });

  const [user, setUser] = useState({
    firstName: '',
    gp_code: '',
    role_id: '',
    patNumber: '',
    client_id: ''
  });

  const menus = useMemo(() => {
    return [
      {
        id: 3,
        name: 'ORDERS',
        link: '/orders',
        role_id: [
          USER_ROLES.superAdmin,
          USER_ROLES.doctor,
          USER_ROLES.ml,
          USER_ROLES.admin,
          USER_ROLES.ptxSupportPartner
        ]
      },
      {
        id: 4,
        name: 'PHARMACIES',
        link: '/pharmacy',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 5,
        name: 'PATIENTS',
        link: '/patients',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 6,
        name: 'DOCTORS',
        link: '/doctors',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.ml, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 7,
        name: 'PATIENTS',
        link: '/gp/patients',
        role_id: [USER_ROLES.doctor]
      },
      {
        id: 10,
        name: 'ORDERS',
        link: '/pharmacist/orders',
        role_id: [USER_ROLES.pharmacist]
      },
      {
        id: 11,
        name: 'INVENTORY',
        link: '/pharmacist/inventory',
        role_id: [USER_ROLES.pharmacist]
      },
      {
        id: 12,
        name: 'Home',
        link: '/home',
        role_id: [USER_ROLES.patient]
      },
      {
        id: 13,
        name: 'Refill',
        link: '/patient/refill',
        role_id: [USER_ROLES.patient]
      },
      {
        id: 14,
        name: 'Products',
        link: '/product-catalog',
        role_id: [USER_ROLES.patient]
      },
      {
        id: 15,
        name: 'Orders',
        link: '/patient/orders',
        role_id: [USER_ROLES.patient]
      },
      {
        id: 16,
        name: 'PRODUCT CATALOG',
        link: '/product-catalog',
        role_id: [USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: '17',
        name: 'Consultations',
        link: '/patient/consultations',
        role_id: [USER_ROLES.patient]
      },
      {
        id: '18',
        name: 'Prescriptions',
        link: '/patient/prescription',
        role_id: [USER_ROLES.patient]
      },
      {
        id: '19',
        name: 'FAQs',
        link: '/faq',
        role_id: [USER_ROLES.patient]
      }
    ];
  }, []);

  const moreMenu = useMemo(() => {
    return [
      {
        id: 2,
        name: 'SUPPLIERS',
        link: '/suppliers',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 3,
        name: 'PRODUCTS',
        link: '/products',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 4,
        name: 'PRODUCT CATALOG',
        link: '/product-catalog',
        role_id: [USER_ROLES.superAdmin]
      },
      {
        id: 5,
        name: 'INVOICES',
        link: '/invoices',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 10,
        name: 'SUPPORT SELF SERVICE',
        link: '/support-self-service',
        role_id: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
      },
      {
        id: 11,
        name: 'ORDER ALLOCATION TOOL',
        link: '/order-allocation-tool',
        role_id: [1, 6]
      },
      {
        id: 12,
        name: 'BETA PHARMACY INVENTORY',
        link: '/beta-pharmacy-inventory',
        role_id: [1, 6]
      },
      {
        id: 13,
        name: 'PHARMACY ALLOCATION SETTINGS',
        link: '/pharmacy-allocation-settings',
        role_id: [1, 6]
      }
    ];
  }, []);

  useEffect(() => {
    const user = AuthService.getUser().user;
    axios.get(url + '/user').then((resp) => {
      let data = resp.data;
      setUser({
        firstName: data.first_name,
        gp_code: data.gp_code,
        role_id: data.role_id,
        patNumber: data.patNumber,
        client_id: data.client_id
      });
    });
    axios.get(`${settings.url}/patient/patientReferral/${user.id}`).then((response) => {
      if (response.data.patientReferralCode) {
        setState({
          ...state,
          active: true
        });
      }
    });
  }, []);

  useEffect(() => {
    const handleClick = () => removeShowClassName();
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, []);

  function removeShowClassName() {
    let nav = document.getElementById('navbarNavDropdown');
    nav.classList.remove('show');
  }

  const handleMenu = () => {
    setState({
      isExpanded: false
    });
  };

  const handleToggle = (e) => {
    e.preventDefault();
    setState({
      isExpanded: !state.isExpanded
    });
  };

  const role_id = AuthService.getUser().user.role_id || USER_ROLES.doctor;
  const menuItems = menus.filter((menu) => menu.role_id.includes(role_id));
  const moreMenuItems = moreMenu.filter((moreMenus) => moreMenus.role_id.includes(role_id));

  return (
    <nav
      className={`${
        role_id === USER_ROLES.patient
          ? 'navbar fixed-top nav-shadow navbar-expand-lg navbg justify-content-between d-flex'
          : 'navbar fixed-top p-0 shadow navbar-expand-lg navbg justify-content-between d-flex'
      }`}
    >
      <ImageTile role_id={role_id} />
      <button
        className={'navbar-toggler'}
        type="button"
        data-toggle="collapse"
        onClick={(e) => handleToggle(e)}
        data-target="#navbarNavDropdown"
        aria-controls="navbarNavDropdown"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span
          className="navbar-toggler-icon fa fa-bars"
          // TECH DEBT: hardcoded color as work around for iOS devices
          style={{ color: 'black' }}
        ></span>
      </button>
      <div
        className={`navbar-collapse w-100 ${
          state.isExpanded
            ? 'mx-auto justify-content-center'
            : 'navbar-position mx-auto collapse navbar-collapse justify-content-center'
        }`}
        id="navbarNavDropdown"
      >
        <ul className="navbar-nav nav_underscore align-nav">
          {menuItems.map((menu) => (
            <NavbarMenu
              key={menu.id}
              name={menu.name}
              link={menu.link}
              roleID={menu.role_id}
              activeReferal={state.active}
              handleMenu={handleMenu}
            />
          ))}
          {moreMenuItems && <MoreMenu moreMenu={moreMenuItems} menuLength={moreMenuItems.length} />}
        </ul>
        <div className="mob-view">
          <AdminMenu
            role_id={role_id}
            patNumber={user.patNumber}
            firstName={user.firstName}
            gp_code={user.gp_code}
            client_id={user.client_id}
          />
        </div>
      </div>
      <div className={role_id === USER_ROLES.patient ? 'pat-login-drop' : 'des-view'}>
        <AdminMenu
          role_id={role_id}
          patNumber={user.patNumber}
          firstName={user.firstName}
          gp_code={user.gp_code}
          client_id={user.client_id}
        />
      </div>
    </nav>
  );
};

export default Navbar;
