import React from 'react';
import logo from './../montu.png';
import USER_ROLES from '@/constants/userRoles';

const ImageTile = (props) => {
  return (
    <React.Fragment>
      <a
        href={props.role_id === USER_ROLES.patient ? '/home' : 'https://www.montu.com.au'}
        className={`${
          props.role_id === USER_ROLES.patient ? 'pat-navbar-brand nav-response' : 'navbar-brand nav-response'
        }`}
        target={props.role_id === USER_ROLES.patient ? '' : 'blank'}
        style={{ zIndex: 1 }}
      >
        <img src={logo} alt="montu" className="montu-logo pat-montu-logo" />
      </a>
    </React.Fragment>
  );
};

export default ImageTile;
