import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

import { Button, LoadingButton } from '@/ui-library';

import { REQUEST_CONFIRMATION_MESSAGE } from '../common';

type RequestConfirmationDialogProps = {
  open: boolean;
  onClose?: () => void;
  onSendRequest: () => void;
  loading?: boolean;
};

export default function RequestConfirmationDialog({
  open,
  onClose,
  onSendRequest,
  loading
}: RequestConfirmationDialogProps) {
  return (
    <Dialog open={open}>
      <DialogTitle variant="h5">Confirm your request</DialogTitle>
      <DialogContent>
        <DialogContentText variant="body2">{REQUEST_CONFIRMATION_MESSAGE}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="outlined" onClick={onClose} data-testid="undo-request">
          Go back
        </Button>
        <LoadingButton
          data-testid="continue"
          onClick={onSendRequest}
          loading={loading}
          color="primary"
          variant="contained"
        >
          Send request
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
