import { useEffect, useState } from 'react';
import { PatientService } from '@/services/patient.service';
import CircleLoader from '../../components/common/circleLoader';
import OrderTile from '../../components/patient/patientOrderTile';
import MontuBrandWrapper from '@/components/common/MontuBrandWrapper';
import HelpDeskPopup from '@/components/helpDesk/HelpDeskSelector';
import useWindowWidth from '@/hooks/useWindowWidth';
import { useNavigate } from 'react-router-dom';

const PatientOrders = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const screenWidth = useWindowWidth();
  const navigate = useNavigate();

  useEffect(() => {
    PatientService.getPatientOrders().then((res) => {
      setData(res.data);
      setLoading(false);
    });
  }, []);

  const showOrder = (orderId) => {
    navigate(`/patient/order-detail/${orderId}`);
  };

  return (
    <MontuBrandWrapper header="My Orders">
      <div className="w-80 mx-auto patient-orders-page" data-dd-privacy="allow">
        {loading ? (
          <CircleLoader />
        ) : (
          <div>
            {data.map((order) => (
              <OrderTile
                order={order}
                key={`order-list-${order.id}`}
                onClick={showOrder}
                screenWidth={screenWidth}
                {...props}
              />
            ))}
          </div>
        )}
      </div>
      <HelpDeskPopup />
    </MontuBrandWrapper>
  );
};

export default PatientOrders;
