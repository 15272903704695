import { FF_ENABLE_SHOPIFY_DISCOUNTS, FF_SHOPIFY_DISCOUNT_MODAL_QUERY_PARAMS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import useShippingPrice from '@/hooks/useShippingPrice';
import { Logger } from '@/utils/logger';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import { datadogRum } from '@datadog/browser-rum';
import { toast } from '@montugroup/design-system';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import DatePicker from 'react-datepicker';
import Select from 'react-select';
import settings from '../../constants/constants';
import Button from '../common/button';
import CustomModal from '../common/CustomModal';
import FormInput from '../common/FormInput';

const logger = new Logger('patientDiscountModal');

function transformProductToSelectOption(product) {
  return {
    label: product.name,
    value: product.id,
    // Concession products are not eligible for any further discounts
    isDisabled: product.is_concession
  };
}

const PatientDiscountModal = (props) => {
  const [data, setData] = React.useState({
    discountTypes: [],
    orderDiscountReason: [{ label: 'Select Reason', value: '' }],
    discountReasonId: '',
    discountNote: '',
    orderDiscount: [{ label: 'Select Discount', value: '' }],
    discountType: '',
    showMedication: false,
    discountProducts: null,
    discountProductList: [],
    discountAmount: null,
    percentage: '1',
    endDate: moment().add(6, 'months').valueOf(),
    startDate: moment.now(),
    indefinite: 0,
    discountId: null,
    edit: false,
    discountRepeat: [{ label: 1, value: 1 }],
    discountRepeatCount: 1,
    orderLimitation: true,
    showOrderLimitation: true
  });

  const [orderLimitData, setOrderLimitData] = React.useState([]);
  const [discountReasons, setDiscountReasons] = React.useState([]);
  const { flags } = useFeatureFlags();
  const isShopifyDiscountEnabled = flags[FF_ENABLE_SHOPIFY_DISCOUNTS];
  const isShopifyModalQueryParamsEnabled = isShopifyDiscountEnabled && flags[FF_SHOPIFY_DISCOUNT_MODAL_QUERY_PARAMS];

  const { discountType } = settings;

  const discountValidator = new SimpleReactValidator();
  const [errors, setErrors] = React.useState({
    discountReasonId: discountValidator.message('Discount Reason', data.discountReasonId, 'required'),
    discountNote: discountValidator.message('Reason Text', data.discountNote, 'required'),
    discountType: discountValidator.message('Discount Type', data.discountType, 'required'),
    ...(data.discountType === 3 && {
      discountProducts: discountValidator.message('Product', data.discountProducts, 'required')
    }),
    discountAmount: discountValidator.message('discount', data.discountAmount, 'required'),
    ...(data.indefinite && {
      startDate: discountValidator.message('discount', data.startDate, 'required'),
      endDate: discountValidator.message('discount', data.endDate, 'required')
    }),
    ...(data.orderLimitation && {
      discountRepeatCount: discountValidator.message('Order Limitation', data.discountRepeatCount, 'required')
    })
  });
  const [loading, setloading] = React.useState(false);

  const shippingParts = useShippingPrice();

  const minDate = moment().add(1, 'day').toDate();
  const maxDate = moment().toDate();

  const fetchDiscountReasonData = React.useCallback(async () => {
    await axios.get(`${settings.url}/data/discount-reasons`).then((resp) => {
      setDiscountReasons(resp.data.map((x) => ({ label: x.reason, value: x.id })));
    });
  });

  const fetchData = React.useCallback(async () => {
    await axios.get(`${settings.url}/data/discount-types`).then((resp) => {
      setData({
        ...data,
        discountTypes: resp.data
          .filter((type) => type.name === 'Shipping Fee')
          .map((discount) => ({ label: discount.name, value: discount.id }))
      });
    });
  });

  React.useEffect(() => {
    if (props.availableProducts && props.availableProducts.length > 0) {
      setData({ ...data, discountProductList: props.availableProducts.map(transformProductToSelectOption) });
    }
  }, [props.availableProducts]);

  React.useEffect(() => {
    if (props.existingDiscount) {
      setData({
        ...data,
        discountReasonId: props.existingDiscount.discount_reason_id,
        discountNote: props.existingDiscount.discount_note,
        discountType: props.existingDiscount.discount_type_id,
        showMedication: props.existingDiscount.discount_type_id === 3,
        ...(props.existingDiscount.discount_type_id === 3 && {
          discountProducts: { label: props.existingDiscount.Product.name, value: props.existingDiscount.Product.id }
        }),
        discountAmount: props.existingDiscount.discount_amount || props.existingDiscount.discount_percentage,
        percentage: props.existingDiscount.discount_amount ? '0' : '1',
        orderDiscount: [
          { label: props.existingDiscount.DiscountType.name, value: props.existingDiscount.discount_type_id }
        ],
        orderDiscountReason: [
          { label: props.existingDiscount.DiscountReason.reason, value: props.existingDiscount.discount_reason_id }
        ],
        indefinite: props.existingDiscount.is_indefinite ? 1 : 0,
        discountId: props.existingDiscount.id,
        endDate: props.existingDiscount.end_date
          ? new Date(props.existingDiscount.end_date)
          : props.existingDiscount.is_indefinite
          ? moment().add(6, 'months').valueOf()
          : null,
        startDate: props.existingDiscount.start_date
          ? new Date(props.existingDiscount.start_date)
          : props.existingDiscount.is_indefinite
          ? moment.now()
          : null,
        ...(props.existingDiscount.order_limit && {
          orderLimitation: true,
          discountRepeatCount: props.existingDiscount.order_limit,
          discountRepeat: [{ label: props.existingDiscount.order_limit, value: props.existingDiscount.order_limit }]
        }),
        edit: true
      });
    }
  }, [props.existingDiscount]);

  React.useEffect(() => {
    fetchData();
    const orderLimitCount = [];
    for (let i = 1; i <= 10; i++) {
      orderLimitCount.push({
        label: i,
        value: i
      });
    }
    setOrderLimitData(orderLimitCount);
  }, []);

  React.useEffect(() => {
    fetchDiscountReasonData();
  }, []);

  const handleDiscount = (selecteDiscountValue) => {
    if (selecteDiscountValue.value === 3) {
      setData({
        ...data,
        orderDiscount: selecteDiscountValue,
        showMedication: true,
        discountType: selecteDiscountValue.value,
        orderLimitation: true
      });
    } else {
      setData({
        ...data,
        orderDiscount: selecteDiscountValue,
        showMedication: false,
        discountType: selecteDiscountValue.value,
        orderLimitation: true
      });
    }
  };

  const handleProductChange = (selectedOptions) => {
    setData({
      ...data,
      discountProducts: selectedOptions
    });
  };

  const handleReasonChange = (selectedOptions) => {
    setData({
      ...data,
      orderDiscountReason: selectedOptions,
      discountReasonId: selectedOptions.value
    });
  };

  const closeModal = () => {
    setData({
      ...data,
      indefinite: 0,
      orderDiscount: [{ label: 'Select Discount', value: '' }],
      discountType: '',
      discountReasonId: '',
      discountNote: '',
      orderDiscountReason: [{ label: 'Select Reason', value: '' }],
      discountProducts: null,
      showModal: false,
      showMedication: false,
      discountAmount: null,
      edit: false,
      orderLimitation: true,
      discountRepeat: [{ label: 1, value: 1 }]
    });
    props.close();
  };

  const checkSetErrors = () => {
    setErrors({
      discountReasonId: discountValidator.message('Discount Reason', data.discountReasonId, 'required'),
      discountNote: discountValidator.message('Discount Notes', data.discountNote, 'required'),
      discountType: discountValidator.message('Discount Type', data.discountType, 'required'),
      ...(data.discountType === 3 && {
        discountProducts: discountValidator.message('Product', data.discountProducts, 'required')
      }),
      discountAmount: discountValidator.message('discount', data.discountAmount, 'required'),
      ...(!data.indefinite
        ? {
            startDate: discountValidator.message('startDate', data.startDate, 'required'),
            endDate: discountValidator.message('endDate', data.endDate, 'required')
          }
        : {
            indefinite: discountValidator.message('indefinite', data.indefinite, 'required')
          }),
      ...(data.orderLimitation && {
        discountRepeatCount: discountValidator.message('Order Limitation', data.discountRepeatCount, 'required')
      })
    });
  };

  const submissionValidator = () => {
    discountValidator.purgeFields();
    checkSetErrors();
    if (!discountValidator.allValid()) {
      discountValidator.showMessages();
      checkSetErrors();
      return false;
    }

    if (data.discountAmount <= 0) {
      toast.warn('Discount Amount should not be negative and zero', { toastId: 'discount-max-amount' });
      return false;
    }

    if (
      (data.discountType === 2 && data.discountAmount > shippingParts.shipping && data.percentage === '0') ||
      (data.discountAmount > 100 && data.percentage === '1')
    ) {
      toast.warn('Discount Amount is higher than maximum limit.', { toastId: 'discount-max-amount' });
      return false;
    }

    return true;
  };

  const handleDiscountRepeat = (selectedDiscountRepeat) => {
    setData({
      ...data,
      discountRepeat: [{ label: selectedDiscountRepeat.label, value: selectedDiscountRepeat.value }],
      discountRepeatCount: selectedDiscountRepeat.value
    });
  };

  const handleUserInput =
    (prop, type = 'text') =>
    (e) => {
      setData({ ...data, [prop]: e.target.value });
    };

  const onSubmissionModal = async (e) => {
    e.preventDefault();
    const canSubmit = submissionValidator();
    if (!canSubmit) {
      return;
    }
    setloading(true);
    const discountData = {
      patientId: props.patientId,
      discountReasonId: data.discountReasonId,
      discountType: data.discountType,
      discountNote: data.discountNote,
      ...(data.percentage === '1'
        ? { discountPercentage: data.discountAmount }
        : { discountAmount: data.discountAmount }),
      ...(data.indefinite
        ? { isIndefinite: data.indefinite }
        : {
            discountStartDate: data.startDate ? data.startDate : '',
            discountEndDate: data.endDate ? data.endDate : ''
          }),
      ...(data.discountType === 3 ? { productId: data.discountProducts.value } : null),
      ...(data.orderLimitation && { orderLimit: data.discountRepeatCount })
    };

    try {
      const createDiscount = await axios.post(`${settings.url}/discount/create`, discountData);
      if (createDiscount) {
        toast.success('Discount created successfully');
        props.close();
        props.fetchData();
        setloading(false);
      }
    } catch (err) {
      toast.error('Failed to create discount. Please try again later.');
      logger.error('Failed to create discount', err);
      setloading(false);
    }
  };

  const openNewShopifyDiscount = async (e) => {
    e.preventDefault();
    const canSubmit = submissionValidator();
    if (!canSubmit) {
      return;
    }

    const {
      shopifyDiscountsURL,
      shopifyOrderDiscountAppURL,
      shopifyProductDiscountAppURL,
      shopifyShippingDiscountAppURL,
      discountType
    } = settings;

    const selectedShopifyProduct =
      data.discountProducts?.value &&
      props.shopifyProducts.find((shopifyProduct) => shopifyProduct.product_id === data.discountProducts.value);

    const discountData = {
      customerid: props.shopifyCustomerId,
      reason: data.discountReasonId,
      note: data.discountNote,
      percentage: data.discountAmount,
      startdate: data.startDate ? data.startDate.valueOf() : '',
      enddate: data.endDate ? data.endDate.valueOf() : '',
      ...(data.discountType === discountType.MEDICATION_SPECIFIC &&
        selectedShopifyProduct?.shopify_variant_id && { productid: selectedShopifyProduct.shopify_variant_id }),
      ...(data.discountType === discountType.DISPENSING_FEE &&
        data.orderLimitation && { usagelimit: data.discountRepeatCount })
    };
    const queryParams = new URLSearchParams(discountData).toString();

    const discountTypeAppUrlMap = {
      [discountType.FULL_ORDER]: shopifyOrderDiscountAppURL,
      [discountType.DISPENSING_FEE]: shopifyShippingDiscountAppURL,
      [discountType.MEDICATION_SPECIFIC]: shopifyProductDiscountAppURL
    };
    const appUrl = discountTypeAppUrlMap[data.discountType];

    datadogRum.addAction('open-new-shopify-discount', {
      ...discountData,
      appUrl
    });

    window.open(`${shopifyDiscountsURL}/${appUrl}/new?${queryParams}`, '_blank', 'noopener noreferrer');
    closeModal();
  };

  const handleSubmit = (...args) => {
    return isShopifyModalQueryParamsEnabled ? openNewShopifyDiscount(...args) : onSubmissionModal(...args);
  };

  const handleEdit = async (e) => {
    e.preventDefault();
    const canSubmit = submissionValidator();
    if (!canSubmit) {
      return;
    }
    setloading(true);
    const discountData = {
      patientId: props.patientId,
      discountReasonId: data.discountReasonId,
      discountType: data.discountType,
      discountNote: data.discountNote,
      ...(data.percentage === '1'
        ? { discountPercentage: data.discountAmount }
        : { discountAmount: data.discountAmount }),
      ...(data.indefinite
        ? { isIndefinite: data.indefinite }
        : { discountStartDate: data.startDate, discountEndDate: data.endDate }),
      ...(data.discountType === 3 ? { productId: data.discountProducts.value } : null),
      ...(data.orderLimitation && { orderLimit: data.discountRepeatCount })
    };
    try {
      const EditDiscount = await axios.put(`${settings.url}/discount/update/${data.discountId}`, discountData);
      if (EditDiscount) {
        toast.success('Discount Updated successfully');
        props.close();
        props.fetchData();
        setloading(false);
      }
    } catch (err) {
      toast.error('Failed to update discount. Please try again later.');
      logger.error('Failed to update discount', err);
      setloading(false);
    }
  };

  const validatePercentage = (evt) => {
    if (evt.target.value < 101) {
      setData({
        ...data,
        discountAmount: Number(evt.target.value)
      });
    }
  };

  const customStyles = {
    content: {
      width: '35%',
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)'
    }
  };

  return (
    <React.Fragment>
      <CustomModal
        isOpen={props.show}
        onRequestClose={props.close}
        style={customStyles}
        shouldCloseOnOverlayClick={false}
      >
        <React.Fragment>
          <div className="modalheader">
            <h3>Add Discount</h3>
          </div>
          <div className="modalbody">
            <label>Items</label>
            <Select
              name="items-select"
              classNamePrefix="items-select"
              options={data.discountTypes}
              value={data.orderDiscount || ''}
              onChange={handleDiscount}
              width="20"
              isDisabled={data.edit ? true : false}
            />
            {errors.discountType}
            {data.showMedication && (
              <div className="mt-3">
                <label>Available Medications</label>
                <Select
                  value={data.discountProducts || ''}
                  // NOTE: using props directly here seems to be required for the jest test
                  options={(props.availableProducts || []).map(transformProductToSelectOption)}
                  onChange={handleProductChange}
                />
                {data.discountType === 3 ? errors.discountProducts : ''}
              </div>
            )}

            <div className="mt-3">
              <label>Reason</label>
              <Select
                classNamePrefix="reason-select"
                name="reason-select"
                options={discountReasons}
                value={data.orderDiscountReason || ''}
                onChange={handleReasonChange}
                width="20"
                menuPortalTarget={document.body}
                styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
              />
              {errors.discountReasonId}
            </div>

            <div className="mt-3">
              <FormInput
                noLabel={true}
                name={'discountNote'}
                value={data.discountNote}
                onChange={handleUserInput('discountNote')}
                placeholder={'Discount Notes'}
                maxlength={100}
              />
              {errors.discountNote}
            </div>

            <div className="p-0 mt-3 col-md-5">
              <label>Discount</label>
              <div className="input-group">
                <input
                  type="number"
                  name="discountAmount"
                  value={Math.floor(data.discountAmount) || ''}
                  placeholder={'100%'}
                  onChange={validatePercentage}
                  className="form-control"
                />
                <div className="input-group-prepend">
                  <span className="input-group-text" id="basic-addon1">
                    %
                  </span>
                </div>
              </div>
              {errors.discountAmount}
            </div>
            {!(
              isShopifyModalQueryParamsEnabled &&
              [discountType.FULL_ORDER, discountType.MEDICATION_SPECIFIC].includes(data.discountType)
            ) &&
              data.showOrderLimitation && (
                <div className="form-row align-items-baseline">
                  <div className="form-group p-1 col-sm-6">
                    <div className="d-flex mt-2">
                      <div className="custom-control custom-switch" style={{ paddingLeft: '18px' }}>
                        <input
                          type="checkbox"
                          name="orderLimitation"
                          className="custom-control-input"
                          id="orderLimitation"
                          onChange={(e) => setData({ ...data, orderLimitation: e.target.checked })}
                          checked={data.orderLimitation}
                        />
                        <label htmlFor="orderLimitation" className="ml-3 checkbox custom-control-label"></label> Order
                        Limitation
                      </div>
                    </div>
                  </div>
                  <div className="form-group p-1 col-sm-6">
                    {data.orderLimitation && (
                      <>
                        <Select
                          options={orderLimitData}
                          value={data.discountRepeat}
                          onChange={handleDiscountRepeat}
                          width="20"
                          className="mt-3"
                          menuPortalTarget={document.body}
                          styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
                        />
                      </>
                    )}
                    {data.orderLimitation && errors.discountRepeatCount}
                  </div>
                </div>
              )}

            <div className="mt-3">
              <label>Duration</label>
              <br />
              {!data.indefinite && (
                <div className="form-row">
                  <div className="form-group p-1 col-sm-6">
                    <label className="d-block"> Discount Start Date </label>
                    <DatePicker
                      id="startDate"
                      selected={new Date(data.startDate) || ''}
                      onChange={(e) => setData({ ...data, startDate: e })}
                      minDate={maxDate}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      dropdownMode="select"
                      className="form-control"
                      popperPlacement="top-start"
                    />
                    <label htmlFor={'startDate'}>
                      <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
                    </label>
                    {errors.startDate}
                  </div>
                  <div className="form-group p-1 col-sm-6">
                    <label className="d-block"> Discount End Date </label>
                    <DatePicker
                      id="endDate"
                      selected={new Date(data.endDate) || ''}
                      onChange={(e) => setData({ ...data, endDate: e })}
                      minDate={minDate}
                      showMonthDropdown
                      showYearDropdown
                      dateFormat="dd/MM/yyyy"
                      dropdownMode="select"
                      className="form-control"
                      popperPlacement="top-end"
                    />
                    <label htmlFor={'endDate'}>
                      <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
                    </label>
                    {errors.endDate}
                  </div>
                </div>
              )}
              {!isShopifyModalQueryParamsEnabled && (
                <>
                  <input
                    type="checkbox"
                    name="Indefinite"
                    className="checkbox"
                    id="indefinite"
                    onChange={(e) => setData({ ...data, indefinite: e.target.checked })}
                    checked={data.indefinite}
                  />
                  <label htmlFor="indefinite" className="ml-3 checkbox">
                    Indefinite
                  </label>
                </>
              )}
              <br />
            </div>
          </div>
          <div className="modalfooter">
            <Button
              text={data.edit ? 'Update Discount' : 'Add Discount'}
              type="submit"
              className="btn btn-primary float-right mt-5"
              onClick={data.edit ? handleEdit : handleSubmit}
              disabled={loading}
            />
            <Button
              text="Close"
              type="submit"
              className="btn btn-outline-primary float-right mt-5 mr-3"
              onClick={closeModal}
            />
          </div>
        </React.Fragment>
      </CustomModal>
    </React.Fragment>
  );
};

export default PatientDiscountModal;
