import { ChevronLeft } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';

const defaultBackText = 'Back';

export type PartnerBackProps = {
  backText?: string;
  defaultRoute?: string;
};

function PartnerBack({ backText = defaultBackText, defaultRoute }: PartnerBackProps) {
  const navigate = useNavigate();
  const location = useLocation();

  const hasBack = location.key && location.key !== 'default';
  const canNav = Boolean(hasBack || defaultRoute);

  const handleClickBack = () => {
    if (hasBack) {
      navigate(-1);
    } else if (defaultRoute) {
      navigate(defaultRoute);
    }
  };

  return (
    <Box sx={{ mb: 3, display: 'flex', width: '100%' }}>
      <Button
        size="large"
        variant="text"
        color="primary"
        startIcon={<ChevronLeft />}
        onClick={handleClickBack}
        disabled={!canNav}
      >
        {backText}
      </Button>
    </Box>
  );
}

export default PartnerBack;
