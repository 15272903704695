import PageLoadErrorAlertContent from '@/components/error/PageLoadErrorAlertContent';
import type { ErrorDisplay } from '@/context/ErrorManagement';

const hardReload = () => window.location.reload();

export const PageLoadError: ErrorDisplay = {
  title: 'Page load error',
  body: <PageLoadErrorAlertContent onClickRetry={hardReload} />
};

export default PageLoadError;
