import React from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import Button from '../common/button';
import { getPatientsForDropdown } from '@/services/data.service';
import { SasService } from '@/services/sas.service';
import { getApprovalDropdowns } from '@/services/product.service';
import SasApproval from '../../assets/images/sas-b-approval-sample.jpg';
import StateApproval from '../../assets/images/s8-state-approval-sample.jpg';
import 'react-datepicker/dist/react-datepicker.css';
import settings from '@/constants/constants';
import FormInput from '../common/FormInput';
import axios from 'axios';
import { AuthService } from '@/services/authentication.service';
import withRouter from '@/utils/withRouter';

const API_URL = `${settings.url}/sas`;

class sasApprovalForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ingredients: [],
      formulations: [],
      patient: '',
      isChecked: '',
      script: false,
      stateapproval: false,
      s8approval: false,
      date: moment().add(24, 'months').toDate(),
      defaultDate: moment().add(1, 'days').toDate(),
      sasFile: '',
      stateFile: '',
      sasFileError: '',
      stateFileError: '',
      patients: [],
      incredient: '',
      formulation: '',
      patientGP: null,
      patientGPList: [],
      user: AuthService.getUser().user,
      mbNumber: '',
      expDate: moment().add(24, 'months').toDate(),
      approvalDate: moment().toDate(),
      startDate: moment().toDate(),
      pastDate: moment().subtract(12, 'months').toDate(),
    };
    this.formRef = React.createRef();
    this.validator = new SimpleReactValidator();
  }

  async componentDidMount() {
    document.title = 'Upload Approval - Montu Group | Making Medical Cannabis Accessible';
    if (this.props.params.id) {
      if (AuthService.isAdmin()) {
        await axios.get(`${settings.url}/data/patientgp/${this.props.params.id}`).then((resp) => {
          const gpList = resp.data.GPs.map((gp) => ({
            label: gp.doctor_name + ' | ' + gp.doctor_email,
            value: gp.user_id,
          }));
          this.setState({
            patientGPList: gpList,
            data: resp.data.patient,
            patient: resp.data.patient.name,
            patientUserId: resp.data.patient.user_id,
          });
        });
      } else {
        await axios.get(`${API_URL}/${this.props.params.id}`).then((data) => {
          this.setState({
            data: data.data,
            patient: data.data.name,
            patientUserId: data.data.user_id,
          });
        });
      }
    } else {
      getPatientsForDropdown().then((patients) => {
        const patList = patients.data.patients.map((patient) => ({
          label: `${patient.PatientUser.first_name} ${patient.PatientUser.last_name}`,
          value: patient.id,
        }));
        this.setState({ patients: patList });
      });
    }
    this.bindProducts();
  }

  bindProducts = async () => {
    const dropdowns = await getApprovalDropdowns();
    this.setState({
      ingredients: dropdowns.data.ingredients.map((x) => ({ label: x.name, value: x.id })),
      formulations: dropdowns.data.formulations.map((x) => ({ label: x.name, value: x.id })),
    });
  };

  handleChange = (date) => {
    this.setState({
      date: date,
    });
  };
  handleCheckbox = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handlePatientGP = (selected) => {
    this.setState({ patientGP: selected });
  };
  handlePatient = (e) => {
    this.setState({
      patient: e.value,
    });
  };

  handleMBNumber = (e) => {
    this.setState({
      mbNumber: e.target.value,
    });
  };

  handleApprovalChange = (approvalDate) => {
    this.setState({
      approvalDate: approvalDate,
      expDate: moment(approvalDate).add(24, 'months').toDate(),
    });
  };

  handleExpiryChange = (expDate) => {
    this.setState({
      expDate: expDate,
    });
  };

  // SAS file-upload format verification
  fileHandling = (e) => {
    let file = e.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['png', 'jpg', 'jpeg', 'pdf'].indexOf(fileExtension) === -1) {
      this.setState({
        sasFileError: 'This File type is not allowed',
      });
    } else if (fileSize > 26214400) {
      this.setState({
        sasFileError: 'SAS File Exceeds Limit',
      });
    } else {
      this.setState({
        sasFile: file,
        sasFileError: '',
      });
    }
  };
  // State file-upload format verification
  fileStateHandling = (e) => {
    let file = e.target.files[0];
    let fileName = file.name;
    let fileSize = file.size;
    let fileExtension = fileName.split('.')[fileName.split('.').length - 1].toLowerCase();
    if (['png', 'jpg', 'jpeg', 'pdf'].indexOf(fileExtension) === -1) {
      this.setState({
        stateFileError: 'This File type is not allowed',
      });
    } else if (fileSize > 26214400) {
      this.setState({
        stateFileError: 'STATE File Exceeds Limit',
      });
    } else {
      this.setState({
        stateFile: file,
        stateFileError: '',
      });
    }
  };
  onSubmission = async (e) => {
    e.preventDefault();
    if (!this.validator.allValid()) {
      this.validator.showMessages();
      this.forceUpdate();
      return;
    }
    this.setState({ loading: true });

    const payload = {
      files: [],
      patient: this.props.params.id ? this.props.params.id : this.state.patient,
      incredient: this.state.incredient.value,
      formulation: this.state.formulation.value,
      expiryDate: moment(this.state.expDate).utc().toString(),
      tz: moment().format('Z').toString(),
      patient_script: this.state.script,
      patient_sas_approval: this.state.stateapproval,
      patient_s8_approval: this.state.s8approval,
      mbNumber: this.state.mbNumber,
      approvalDate: moment(this.state.approvalDate).utc().toString(),
    };

    if (this.state.sasFile) {
      payload.files.push(this.state.sasFile);
    }

    if (this.state.stateFile) {
      payload.files.push(this.state.stateFile);
    }

    if (this.state.patientGP) {
      payload.gp_id = this.state.patientGP.value;
    }

    const data = await SasService.sasUpload(payload);

    if (data) {
      this.setState({
        loading: false,
        date: this.state.date,
        sasFile: '',
        stateFile: '',
        sasFileError: '',
        stateFileError: '',
      });
    }
    this.props.navigate('/orders');
  };

  render() {
    const {
      loading,
      patient,
      patients,
      date,
      sasFile,
      stateFile,
      sasFileError,
      stateFileError,
      formulation,
      formulations,
      incredient,
      ingredients,
      defaultDate,
      patientGP,
      patientGPList,
      mbNumber,
      approvalDate,
      expDate,
      startDate,
      pastDate,
    } = this.state;
    return (
      <React.Fragment>
        <div className="container sas-form">
          <form ref={this.formRef} encType="multipart/form-data">
            <span className="h3">Upload Approval</span>
            <div className="col-md-8 mt-4">
              <div className="form-group">
                <label> Patient Name</label>
                {this.props.params.id ? (
                  <div>
                    <FormInput type="text" name="pat_name" readOnly onChange={this.handlePatient} value={patient} />
                  </div>
                ) : (
                  <Select
                    name="patient"
                    onChange={this.handlePatient}
                    className={`${
                      this.validator.message('Patient Name', patient, 'required') ? 'rounded dropdown-border' : ''
                    }`}
                    isSearchable={true}
                    defaultValue={patient}
                    placeholder="Choose Patient Name"
                    options={patients}
                  />
                )}
                {this.validator.message('Patient Doctor', patient, 'required')}
              </div>
              {AuthService.isAdmin() && (
                <div className="form-group">
                  <label> Patient's Doctor</label>
                  <Select
                    name="PatientGp"
                    value={patientGP}
                    className={`${
                      this.validator.message('Patient GP', patientGP, 'required') ? 'rounded dropdown-border' : ''
                    }`}
                    onChange={this.handlePatientGP}
                    isSearchable={true}
                    placeholder="Select Patient's Doctor"
                    options={patientGPList}
                  />
                  {this.validator.message('doctor', patientGP, 'required')}
                </div>
              )}
              <div className="form-row ">
                <div className="col-sm-6">
                  <div className="form-group p-1">
                    <label className="mb-0">Attach SAS Approval Form</label>
                    <small className="text-secondary d-block">(jpeg,png,jpg,pdf) are allowed</small>
                    <div className="file-upload mt-2 p-2">
                      <label className="d-block" htmlFor="file-input">
                        <i className="fa fa-cloud-upload fa-5x" aria-hidden="true"></i>
                        <input
                          id="file-input"
                          name="sasFile"
                          className="d-none"
                          type="file"
                          onChange={this.fileHandling}
                        />
                        {(() => {
                          if (sasFile === '' && sasFileError === '') {
                            return (
                              <React.Fragment>
                                <p className="mb-0 text-primary">Choose a file</p>
                                <small>File limit 25MB</small>
                              </React.Fragment>
                            );
                          } else if (sasFileError !== '') {
                            return <p className="text-danger p-1">{sasFileError}</p>;
                          } else {
                            return <p className="file-name">{sasFile.name}</p>;
                          }
                        })()}
                      </label>
                    </div>
                    {this.validator.message('Approval', sasFile, 'required')}
                    <small>
                      <a href={SasApproval} target="blank" className="text-secondary d-block">
                        See Sample Image
                      </a>
                    </small>
                  </div>
                </div>
                <div className="col-sm-6 d-none">
                  <div className="form-group p-1">
                    <label className="mb-0">Attach State Approval Form (if required)</label>
                    <small className="text-secondary d-block">(jpeg,png,jpg,pdf) are allowed</small>
                    <div className="file-upload mt-2 p-2">
                      <label className="d-block" htmlFor="file-input-state">
                        <i className="fa fa-cloud-upload fa-5x" aria-hidden="true"></i>
                        <input
                          id="file-input-state"
                          name="stateFile"
                          className="d-none"
                          type="file"
                          onChange={this.fileStateHandling}
                        />
                        {(() => {
                          if (stateFile === '' && stateFileError === '') {
                            return (
                              <React.Fragment>
                                <p className="mb-0 text-primary">Choose a file</p>
                                <small>File limit 25MB</small>
                              </React.Fragment>
                            );
                          } else if (stateFileError !== '') {
                            return <p className="text-danger p-1">{stateFileError}</p>;
                          } else {
                            return <p className="file-name">{stateFile.name}</p>;
                          }
                        })()}
                      </label>
                    </div>
                    <small>
                      <a href={StateApproval} target="blank" className="text-secondary d-block">
                        See Sample Image
                      </a>
                    </small>
                  </div>
                </div>
              </div>
              <div className="form-group pl-1">
                <label>MB Number</label>
                <FormInput type="text" name="mb_number" onChange={this.handleMBNumber} value={mbNumber} />
                {this.validator.message('mbNumber', mbNumber, 'required')}
              </div>
              <div className="form-group p-1">
                <label className="d-block"> Date of Approval </label>
                <DatePicker
                  className="form-control"
                  id="dataPicker"
                  selected={approvalDate}
                  onChange={this.handleApprovalChange}
                  minDate={pastDate}
                  maxDate={startDate}
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  dropdownMode="select"
                />
                <label htmlFor={'dataPicker'}>
                  <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
                </label>
              </div>
              <div className="form-group p-1">
                <label className="d-block"> Approval Expiration Date </label>
                <DatePicker
                  className="form-control"
                  id="dataPicker"
                  selected={expDate ? expDate : date}
                  onChange={this.handleExpiryChange}
                  minDate={defaultDate}
                  showMonthDropdown
                  showYearDropdown
                  dateFormat="dd/MM/yyyy"
                  dropdownMode="select"
                />
                <label htmlFor={'dataPicker'}>
                  <i className="fa fa-calendar mx-2" aria-hidden="true"></i>
                </label>
              </div>
              <div className="form-group p-1 mb-0 pb-0">
                <label>Approved Category</label>
                <Select
                  name="product"
                  value={incredient}
                  className={`${
                    this.validator.message('product', incredient, 'required') ? 'rounded dropdown-border' : ''
                  }`}
                  onChange={(e) => this.setState({ incredient: e })}
                  isSearchable={true}
                  options={ingredients}
                />
                {this.validator.message('Category', incredient, 'required')}
              </div>
              <div className="form-group p-1 mb-0 pb-0">
                <label>Approved Formulation</label>
                <Select
                  name="product"
                  value={formulation}
                  className={`${
                    this.validator.message('product', formulation, 'required') ? 'rounded dropdown-border' : ''
                  }`}
                  onChange={(e) => this.setState({ formulation: e })}
                  isSearchable={true}
                  options={formulations}
                />
                {this.validator.message('Formulation', formulation, 'required')}
              </div>
              <div className="form-group p-1 sub-text">
                <div className="px-4">
                  <input
                    type="checkbox"
                    className="form-check-input checkbox"
                    name="script"
                    id="script"
                    onChange={this.handleCheckbox}
                    value={true}
                  />
                  <label htmlFor="script" className="checkbox">
                    Have you provided the patient with a script?
                  </label>
                </div>
                <div className="px-4">
                  <input
                    type="checkbox"
                    name="stateapproval"
                    className="form-check-input checkbox"
                    id="sasapproval"
                    onChange={this.handleCheckbox}
                    value={true}
                  />
                  <label htmlFor="sasapproval" className="checkbox">
                    Have you provided the patient with the SAS approval form?
                  </label>
                </div>
                <div className="px-4">
                  <input
                    type="checkbox"
                    name="s8approval"
                    className="form-check-input checkbox"
                    id="s8approval"
                    onChange={this.handleCheckbox}
                    value={true}
                  />
                  <label htmlFor="s8approval" className="checkbox">
                    Have you provided the patient with the state approval form (if applicable) ?{' '}
                  </label>
                </div>
                <h6 className="font-weight-bold">
                  Note that the patient will need to present these documents at the pharmacy to pick-up their medicine.
                </h6>
              </div>
              <Button
                text="Submit"
                loading={loading}
                type="submit"
                className="btn btn-primary btn-md ml-1 mb-5 float-right"
                onClick={this.onSubmission}
              />
            </div>
          </form>
        </div>
      </React.Fragment>
    );
  }
}

export default withRouter(sasApprovalForm);
