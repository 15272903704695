import { Box, Divider, styled, Typography } from '@mui/material';

import pxToRemConverter from '@/utils/pxToRemConverter';

interface SessionProps {
  title: string;
  header?: string;
  presenter: string;
  videoSrc: string;
  videoCaptionSrc?: string;
  description: string[];
  benefits: string[];
  isLast?: boolean;
}

const ContentDivider = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.common.white,
  margin: theme.spacing(8)
}));

const Container = styled(Box)`
  text-align: center;
  margin: 0 25%;
`;

const Presenter = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(2, 0)
}));

const CustomTypography = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(3)
}));

const BoldTypography = styled(Typography)(({ theme }) => ({
  margin: theme.spacing(3, 0),
  fontWeight: 'bold'
}));

const BoxContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4)
}));

function Session({ title, header, presenter, videoSrc, videoCaptionSrc, description, benefits, isLast }: SessionProps) {
  return (
    <Container>
      {header && <Typography>{header}</Typography>}
      <Typography component="h2" variant="h4">
        {title}
      </Typography>
      <Presenter>{presenter}</Presenter>
      <BoxContainer>
        <video
          controls
          src={videoSrc}
          data-testid="video-element"
          style={{ maxHeight: pxToRemConverter(870), maxWidth: pxToRemConverter(880) }}
        >
          <track kind="captions" src={videoCaptionSrc} label="English" default />
        </video>
      </BoxContainer>
      {description.map((text) => (
        <CustomTypography key={text}>{text}</CustomTypography>
      ))}
      <BoldTypography>Benefits from this session:</BoldTypography>
      {benefits.map((benefit) => (
        <Typography key={benefit}>{benefit}</Typography>
      ))}
      {!isLast && <ContentDivider />}
    </Container>
  );
}

export default Session;
