import { Box, Link, styled } from '@mui/material';

type OrderNumberTableCellProps = {
  isDoc: boolean;
  orderCode: string;
  orderId: number;
};

const Content = styled(Box)`
  width: 5rem;
`;

function OrderNumberTableCell({ isDoc, orderCode, orderId }: OrderNumberTableCellProps) {
  return (
    <Content>
      {isDoc && orderCode}
      {!isDoc && (
        <Link href={`/order/${orderId}`} target="_blank">
          {orderCode}
        </Link>
      )}
    </Content>
  );
}

export default OrderNumberTableCell;
