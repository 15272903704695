import { MgBalanced } from '@montugroup/icons';
import { NightlightRound as MdNightlightRound, WbSunny as MdWbSunny } from '@mui/icons-material';
import type { SvgIcon } from '@mui/material';

import type { ProductStrainKey } from '@/types';
import { ProductStrainKeys } from '@/types';

interface StrainIconDefn {
  icon: typeof SvgIcon;
  color: string;
  bgColor: string;
}

const ProductStrainsMap: Record<ProductStrainKey, StrainIconDefn> = {
  [ProductStrainKeys.SATIVA]: {
    icon: MdWbSunny,
    color: '#FFA726',
    bgColor: '#FFF3E0'
  },
  [ProductStrainKeys.INDICA]: {
    icon: MdNightlightRound,
    color: '#4DB6AC',
    bgColor: '#E0F2F1'
  },
  [ProductStrainKeys.HYBRID]: {
    icon: MgBalanced,
    color: '#555555',
    bgColor: '#F5F5F5'
  }
} as const;

export default ProductStrainsMap;
