import type { UserRoles } from '@/types';

const USER_ROLES: UserRoles = {
  superAdmin: 1,
  doctor: 2,
  patient: 3,
  ml: 4,
  pharmacist: 5,
  admin: 6,
  ptxSupportPartner: 7
} as const;

export default USER_ROLES;
