import ManagedErrorToasts from '@/components/error/ManagedErrorToasts';
import PatientsTable from '@/components/gp/PatientsTable';
import AdminPageLayout from '@/components/layout/AdminPageLayout';

function GPPatients() {
  return (
    <AdminPageLayout title="Patients">
      <ManagedErrorToasts />
      <PatientsTable />
    </AdminPageLayout>
  );
}

export default GPPatients;
