import AdminPageLayout from '@/components/layout/AdminPageLayout';
import PharmacyView from '@/components/pharmacy/PharmacyView';

function Pharmacies() {
  return (
    <AdminPageLayout title="Pharmacies">
      <PharmacyView />
    </AdminPageLayout>
  );
}

export default Pharmacies;
