import moment from 'moment';

export type Prescription = {
  createdDate: string;
  expiryDate: string;
  inActive: boolean;
  interval: number | null;
  presciptionCode: string;
  productName: string;
  productActive: boolean;
  quantity: number;
  repeats: string;
  status: string;
  productId: number;
  thcStrength?: string;
  cbdStrength?: string;
  dosage?: string;
};

export function getUniqueKey(prescription: Prescription) {
  return `${prescription.presciptionCode}-${prescription.productName}`;
}

export function getCreatedDate(prescription: Prescription) {
  return prescription.createdDate ? moment(prescription.createdDate).format('MMM DD, YYYY') : '-';
}

export function getCode(prescription: Prescription) {
  return prescription.presciptionCode || '-';
}

export function getProductName(prescription: Prescription) {
  return prescription.productName || '-';
}

export function getQuantity(prescription: Prescription) {
  return prescription.quantity || '-';
}

export function getInterval(prescription: Prescription) {
  return prescription.interval ? `${prescription.interval} days` : '-';
}

export function getExpiryDate(prescription: Prescription) {
  return prescription.expiryDate ? moment(prescription.expiryDate).format('MMM DD, YYYY') : '-';
}

export function getStatus(prescription: Prescription) {
  if (prescription.inActive) {
    return 'Inactive';
  }
  return prescription.status || '-';
}

export function getDaysUntilExpiry(prescription: Prescription) {
  return Math.max(moment(prescription.expiryDate).diff(moment(), 'days'), 0);
}
