import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import { useMutation } from '@tanstack/react-query';
import type { ChangeEvent } from 'react';
import { useState } from 'react';

import type { PharmacistLoginFormData } from '@/types/pharmacist.types';

function usePharmacistForm(initialState: PharmacistLoginFormData) {
  const [data, setData] = useState<PharmacistLoginFormData>(initialState);
  const validator = new SimpleReactValidator();
  const [errors, setErrors] = useState({
    email: validator.message('email', data.email.trim(), 'required|email'),
    password: validator.message('password', data.password, 'required')
  });

  const handleUserInput = (prop: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setData({ ...data, [e.target.name]: prop === 'checkbox' ? e.target.checked : e.target.value });
  };

  const mutation = useMutation({
    mutationFn: async (formData: PharmacistLoginFormData) => {
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });
      if (!response.ok) {
        throw new Error('Login failed');
      }
      return response.json();
    }
  });

  const handleSubmit = async () => {
    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        email: validator.message('email', data.email, 'required|email'),
        password: validator.message('password', data.password, 'required')
      });
      return;
    }

    mutation.mutate(data);
  };

  return {
    data,
    errors,
    setErrors,
    handleUserInput,
    validator,
    handleSubmit,
    isPending: mutation.isPending
  };
}

export default usePharmacistForm;
