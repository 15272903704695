import { FF_SHOW_PAYMENT_WARNING } from '@/constants/featureFlags';
import { brazeTrackOrderPurchase } from '@/services/braze.service';
import { checkTransactionResponse, getEwayPaymentUrl, saveOrderTransaction } from '@/services/eWayPayment.service';
import { OrderService } from '@/services/order.service';
import { groupCreditLineItem } from '@/services/patientCredit.service';
import { Logger } from '@/utils/logger';
import withFlags from '@/utils/withFlags';
import withRouter from '@/utils/withRouter';
import SimpleReactValidator from '@crystaldelta/simple-react-validator';
import { toast } from '@montugroup/design-system';
import Alert from '@mui/material/Alert';
import axios from 'axios';
import { Component } from 'react';
import NumberFormat from 'react-number-format';
import settings from '../../../constants/constants';
import Button from '../../common/button';
import CircleLoader from '../../common/circleLoader';
import CustomModal from '../../common/CustomModal';
import OrderConfirmed from '../OrderConfirmed';

const logger = new Logger('CardCheckout');

class CardCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedData: props.selectedData,
      orderData: props.orderData,
      loading: false,
      isTransactionBegin: true,
      isTransactionCreditCardSuccess: false,
      isTransactionFailed: false,
      transactionSuccessMessage: '',
      transactionFailureMessage: '',
      isInvoiceSentSuccess: false,
      userDetail: null,
      edit: props.edit,
      navigate: props.navigate,
      orderCode: '',
      credit: true,
      invoice: false,
      user: props.user,
      isPayNow: true,
      isPayLater: false,
      sharedUrl: '',
      accessCode: '',
      modelShow: false,
      creditDiscounts: props.selectedData.creditDiscounts,
      ifRefundTrue: props.isFlagOn,
      transactionSuccessEventSent: false,
      didSelectPayByInvoiceAtLeastOnce: false,
      featureFlagsLoading: props.loading,
      featureFlags: props.flags
    };
    this.validator = new SimpleReactValidator();
  }

  componentDidMount() {
    this.getUserDetailsById();
    if (this.state.edit) {
      this.checkAndUpdateOrderCredit();
    }
    if (settings.paymentGatwayEnable && this.getTotalAmount() > 0) {
      this.setState({
        isPayNow: true,
        isPayLater: false,
        orderData: {
          ...this.state.orderData,
          transactionType: settings.transactionType.CREDIT_CARD
        }
      });
    } else {
      this.setState({
        isPayNow: false,
        isPayLater: true,
        orderData: {
          ...this.state.orderData,
          transactionType: settings.transactionType.BANK
        }
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    try {
      if (
        !this.state.transactionSuccessEventSent &&
        (this.state.isTransactionCreditCardSuccess || this.state.isInvoiceSentSuccess)
      ) {
        this.setState({ transactionSuccessEventSent: true });
        const brazeProducts = [];

        const products = this.state.selectedData?.selectedProducts?.reduce((acc, product) => {
          const p = {
            'Product Name': product?.data?.product_name,
            Price: product?.data?.price,
            Quantity: product?.data?.quantity,
            'Remaining Units': product?.data?.remainingUnits,
            Repeats: product?.data?.repeats,
            'Short Name': product?.data?.short_name,
            'Is Out of Stock': product?.data?.is_out_of_stock,
            'Not Addable': product?.data?.notAddable,
            'Product ID': product?.data?.product_id
          };
          acc[product?.data?.product_id] = p;
          brazeProducts.push(p);
          return acc;
        }, {});

        const numberOfProducts = Object.keys(products)?.length;
        const shippingPrice = this.getShippingParts().shipping;
        const gst = Number(this.getShippingParts().shippingGst.toFixed(2));
        const totalOrderPrice = Number(this.getTotalAmount());
        const checkoutMethod = this.state.isTransactionCreditCardSuccess ? 'Credit Card' : 'Invoice';

        // Purchase event for invoice payments will be sent in the BE when the invoice is paid.
        if (this.state.isTransactionCreditCardSuccess) {
          brazeTrackOrderPurchase(brazeProducts);
        }
      }
    } catch (err) {
      logger.error('Mixpanel checkout event failed to send', err);
    }
  }

  /**
   * @returns {import('@/hooks/useShippingPrice').ShippingParts}
   */
  getShippingParts = () => {
    return this.props.shippingParts;
  };

  getTotalAmount = () => {
    return (
      this.state.selectedData.selectedProducts
        .map((row) => (row.data.price ? row.data.price * row.data.quantity : 0))
        .reduce((a, b) => a + b, 0) +
      this.getShippingParts().shippingTotal -
      this.state.creditDiscounts
        .map((row) => (parseFloat(row.amount) ? parseFloat(row.amount) : 0))
        .reduce((a, b) => a + b, 0)
    ).toFixed(2);
  };

  getUserDetailsById = async () => {
    await axios.get(settings.url + '/user').then((resp) => {
      this.setState({ ...this.state.data, userDetail: resp.data });
    });
  };

  checkAndUpdateOrderCredit = async () => {
    const params = {
      orderId: this.state.edit,
      patientId: this.state.user.user.id,
      products: this.state.selectedData.selectedProducts
    };

    const orderCreditDiscount = await OrderService.getOrderCreditDiscount(params);

    if (orderCreditDiscount && orderCreditDiscount.data && orderCreditDiscount.status === 200) {
      const { orderCredits, orderDiscount, orderCredit } = orderCreditDiscount.data;
      let creditDiscounts = this.state.creditDiscounts;

      if (this.state.ifRefundTrue) {
        if (orderCredits.length) {
          creditDiscounts = creditDiscounts.filter((x) => x.isDiscount);
          const lineItems = groupCreditLineItem(orderCredits);
          creditDiscounts.push(...lineItems);
        }
      } else if (orderCredit) {
        creditDiscounts = creditDiscounts.filter((x) => x.isDiscount);
        creditDiscounts.push(orderCredit);
      }

      if (orderDiscount && orderDiscount.length >= 0) {
        creditDiscounts = creditDiscounts.filter((x) => !x.isDiscount);
        orderDiscount.forEach((element) => {
          creditDiscounts.push(element);
        });
      }
      this.setState({ creditDiscounts: creditDiscounts });
    }
  };

  handleTransactionTypeSelection =
    (prop, type = 'text') =>
    (e) => {
      let value;
      if (type === 'boolean') {
        value = e.target.checked;
      }
      if (prop === 'payNow' && value) {
        this.setState({
          isPayNow: true,
          isPayLater: false,
          orderData: {
            ...this.state.orderData,
            transactionType: settings.transactionType.CREDIT_CARD
          }
        });
      }

      if (prop === 'payLater' && value) {
        this.setState({
          isPayNow: false,
          isPayLater: true,
          orderData: {
            ...this.state.orderData,
            transactionType: settings.transactionType.BANK
          },
          didSelectPayByInvoiceAtLeastOnce: true
        });
      }
    };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  resultCallback = async (result, transactionID, errors) => {
    if (result == 'Complete') {
      logger.info('Payment Successful', {
        type: 'Credit Card'
      });
      const transaction = await checkTransactionResponse(this.state.accessCode);

      if (transaction) {
        if (transaction.status === 200 && transaction.data.data.status) {
          const order = await axios.post(
            `${settings.url}/order/v1/patientRefil${this.state.edit ? '/edit/' + this.state.edit : ''}`,
            this.state.orderData
          );
          if (order) {
            const transactionDetail = {
              orderId: order.data.success.id,
              transactionId: transactionID,
              amount: this.getTotalAmount(),
              orderCode: order.data.success.order_code,
              patientId: order.data.success.patient_id,
              gpId: order.data.success.gp_id,
              transactionStatus: result,
              status: true
            };

            await saveOrderTransaction(transactionDetail);

            this.setState({
              ...this.state.data,
              isTransactionBegin: false,
              isTransactionCreditCardSuccess: true,
              isTransactionFailed: false,
              transactionSuccessMessage: '',
              orderCode: order.data.success.order_code,
              loading: false,
              modelShow: false
            });
            logger.info('Checkout Completed', {
              transactionDetail
            });
            this.scrollToTop();
          }
        } else {
          this.setState({
            ...this.state.data,
            isTransactionBegin: true,
            isTransactionCreditCardSuccess: false,
            isTransactionFailed: true,
            transactionFailureMessage: transaction.data?.data?.reponseMessage
              ? transaction.data.data.reponseMessage
              : 'Issue while checking transaction response',
            loading: false,
            modelShow: false
          });
          const transactionDetail = {
            transactionId: transactionID,
            amount: this.getTotalAmount(),
            transactionStatus: result,
            responseMessage: transaction.data?.data?.reponseMessage
              ? transaction.data.data.reponseMessage
              : 'Issue while checking transaction response',
            status: false
          };
          await saveOrderTransaction(transactionDetail);
          logger.error('Issue while checking transaction response', { transactionDetail });
          toast.warn(
            transaction.data?.data?.reponseMessage
              ? transaction.data.data.reponseMessage
              : 'Issue while checking transaction response'
          );
        }
      } else {
        this.setState({
          ...this.state.data,
          isTransactionBegin: true,
          isTransactionCreditCardSuccess: false,
          isTransactionFailed: true,
          transactionFailureMessage: 'Issue while checking transaction response',
          loading: false,
          modelShow: false
        });
        const transactionDetail = {
          transactionId: transactionID,
          amount: this.getTotalAmount(),
          transactionStatus: result,
          responseMessage: 'Issue while checking transaction response',
          status: false
        };
        await saveOrderTransaction(transactionDetail);
        logger.error('Issue while checking transaction response', { transactionDetail });
        toast.warn('Issue while checking transaction response');
      }
    } else if (result == 'Error') {
      this.setState({
        ...this.state.data,
        isTransactionBegin: true,
        isTransactionCreditCardSuccess: false,
        isTransactionFailed: true,
        transactionFailureMessage: errors,
        loading: false,
        modelShow: false
      });
      const transactionDetail = {
        transactionId: transactionID,
        amount: this.getTotalAmount(),
        transactionStatus: result,
        responseMessage: errors,
        status: false
      };
      logger.error('Issue while checking transaction response', { transactionDetail });
      await saveOrderTransaction(transactionDetail);
      toast.warn(errors);
    } else if (result == 'Cancel') {
      this.setState({
        ...this.state.data,
        isTransactionBegin: true,
        isTransactionCreditCardSuccess: false,
        isTransactionFailed: false,
        transactionFailureMessage: 'Transaction cancelled',
        loading: false,
        modelShow: false
      });
      const transactionDetail = {
        transactionId: 'No',
        amount: this.getTotalAmount(),
        transactionStatus: result,
        responseMessage: 'Transaction cancelled',
        status: false
      };
      await saveOrderTransaction(transactionDetail);
      logger.info('Transaction cancelled', { transactionDetail });
      toast.warn('Transaction cancelled');
    }
  };

  proceedPayment = async () => {
    if (this.state.selectedData.selectedProducts.length > 0) {
      if (this.state.isPayNow) {
        logger.info('Checkout Submitted', {
          type: 'Credit Card',
          selectedProducts: this.state.selectedData.selectedProducts
        });
        this.setState({
          ...this.state.data,
          loading: true,
          modelShow: true,
          isTransactionFailed: false,
          transactionFailureMessage: ''
        });

        const orderDetails = {
          products: this.state.selectedData.selectedProducts,
          edit: this.state.edit ? true : false,
          orderId: this.state.edit ? this.state.edit : '',
          order: this.state.orderData
        };

        const response = await getEwayPaymentUrl(orderDetails);

        if (response) {
          if (response.data.status === 200) {
            const sharedURL = response.data.eWayResponse.message;
            const params = new Proxy(new URLSearchParams(sharedURL.substr(sharedURL.indexOf('?'))), {
              get: (searchParams, prop) => searchParams.get(prop)
            });
            const eWAYConfig = {
              sharedPaymentUrl: sharedURL
            };

            this.setState({
              ...this.state.data,
              sharedUrl: sharedURL,
              accessCode: params.AccessCode,
              loading: false,
              orderData: {
                ...this.state.orderData,
                order_code: response.data.message
              }
            });
            window.eCrypt.showModalPayment(eWAYConfig, this.resultCallback);
          } else {
            this.setState({
              ...this.state.data,
              isTransactionBegin: true,
              isTransactionCreditCardSuccess: false,
              isTransactionFailed: true,
              transactionFailureMessage: response.data.message,
              loading: false,
              modelShow: false
            });
          }
        } else {
          this.setState({
            ...this.state.data,
            isTransactionBegin: true,
            isTransactionCreditCardSuccess: false,
            isTransactionFailed: true,
            transactionFailureMessage: '',
            loading: false,
            modelShow: false
          });
        }
      } else {
        this.setState({
          ...this.state.data,
          loading: true,
          modelShow: false,
          isTransactionFailed: false,
          transactionFailureMessage: ''
        });
        const order = await axios.post(
          `${settings.url}/order/v1/patientRefil${this.state.edit ? '/edit/' + this.state.edit : ''}`,
          this.state.orderData
        );
        if (order) {
          logger.info(`Pay by invoice`);
          this.setState({ isInvoiceSentSuccess: true });
          this.props.navigate('/refillordersuccess');
          toast.success('Order details updated successfully.', { toastId: 'order-placed-successfully' });
        }
      }
    } else {
      toast.error('Product not available');
      logger.error('Product not available');
    }
  };

  render() {
    const patientName = this.state.user?.user?.first_name;
    const patientEmail = this.state.user?.user.email;
    const customStyles = {
      content: {
        top: '20%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: '35px',
        border: 0
      }
    };

    return (
      <>
        {this.state.isTransactionBegin && (
          <div className="patient-checkout-container">
            <div>
              <div className="mt-4">
                <div className="mx-auto">
                  <div className="d-flex justify-content-between align-items-center mb-3">
                    <h2 className="checkout-title">Checkout</h2>
                  </div>
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-7 col-xl-8">
                      <div className="border border-1 shadow p-4 mb-5 bg-body rounded">
                        <h5 className="mb-3">Shipment Details</h5>
                        <p>
                          {this.state.user &&
                            this.state.user.user.address.replace('$$$$', ' ').replace('undefined', '')}
                        </p>
                        <p>{this.state.user && this.state.user.user.city} </p>
                        <p>{this.state.user && this.state.user.user.zip_code} </p>
                      </div>

                      <div className="order-summary-wrapper border border-1 shadow p-4 mb-5 bg-body rounded">
                        <h5 className="mb-3 side-heading">Order Summary</h5>
                        <div className="mob-row-wrap mb-3">
                          <div className="row">
                            <div className="col-sm-11 col-md-9 text-right">
                              <div className="align-items-center d-flex w-100">
                                <div className="form-group text-left"></div>
                              </div>
                            </div>
                            <div className="col-sm-1 col-md-1 text-center mob-view-none">
                              <div className="form-group">
                                <p className="mob-view-none">Qty</p>
                              </div>
                            </div>
                            <div className="col-sm-1 col-md-2 text-right">
                              <div className="form-group">
                                <p>Price</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {this.state.selectedData.selectedProducts &&
                          this.state.selectedData.selectedProducts.map((row, index) => (
                            <div key={index} className="mob-row-wrap">
                              <div className="row ">
                                <div className="col-sm-11 col-md-9 text-right">
                                  <div className="align-items-center d-flex w-100">
                                    <div className="form-group text-left">{row.data.product_name}</div>
                                  </div>
                                </div>
                                <div className="col-sm-1 col-md-1 text-center mob-view-none">
                                  <div className="form-group">
                                    <p className="mob-view-none"> {row.data.quantity}</p>
                                  </div>
                                </div>
                                <div className="col-sm-1 col-md-2 text-right">
                                  <div className="form-group">
                                    {row.data.price ? (
                                      <NumberFormat
                                        value={(row.data.price * row.data.quantity).toFixed(2)}
                                        decimalScale={2}
                                        fixedDecimalScale={true}
                                        displayType={'text'}
                                        thousandSeparator={true}
                                        prefix={'$'}
                                      />
                                    ) : (
                                      0
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        <hr />
                        <div className=" d-flex align-items-center">
                          <div className="my-1 text-left">Shipping and dispensing fees</div>
                          <div className="my-1 ml-auto">{this.getShippingParts().formattedShipping}</div>
                        </div>

                        <div className=" d-flex align-items-center">
                          <div className="my-1 text-left">Subtotal</div>
                          <div className="my-1 ml-auto">
                            {this.state.selectedData.selectedProducts.length === 0 ? (
                              0
                            ) : (
                              <NumberFormat
                                value={(
                                  this.state.selectedData.selectedProducts
                                    .map((row) => (row.data.price ? row.data.price * row.data.quantity : 0))
                                    .reduce((a, b) => a + b, 0) + this.getShippingParts().shipping
                                ).toFixed(2)}
                                decimalScale={2}
                                fixedDecimalScale={true}
                                displayType={'text'}
                                thousandSeparator={true}
                                prefix={'$'}
                              />
                            )}
                          </div>
                        </div>

                        <div className=" d-flex align-items-center">
                          <div className="my-1 text-left">GST</div>
                          <div className="my-1 ml-auto">{this.getShippingParts().formattedShippingGst}</div>
                        </div>

                        {this.state.creditDiscounts
                          .filter((x) => parseFloat(x.amount) > 0)
                          .map((row, index) => (
                            <div className="mob-row-wrap d-flex align-items-center" key={index}>
                              <div className="my-1  text-left">{row.name}</div>
                              <div className="my-1 ml-auto">- ${parseFloat(row.amount).toFixed(2)}</div>
                            </div>
                          ))}
                        <hr />

                        <div className=" d-flex align-itmes-center">
                          <div className="form-group text-left total-text">Total Amount (AUD)</div>
                          <div className="form-group ml-auto total-text">
                            <NumberFormat
                              value={this.getTotalAmount()}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              displayType={'text'}
                              thousandSeparator={true}
                              prefix={'$'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-5 col-xl-4">
                      <div className="border border-1 shadow p-3 mb-5 bg-body rounded">
                        <div className="p-3">
                          <h5 className="mb-2 side-heading">Payment</h5>

                          {settings.paymentGatwayEnable && this.getTotalAmount() > 0 ? (
                            <div
                              style={
                                this.state.isPayNow ? { border: '2px solid grey' } : { border: '1px solid #dee2e6' }
                              }
                            >
                              <div className="d-flex p-2 ml-4">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  onChange={this.handleTransactionTypeSelection('payNow', 'boolean')}
                                  checked={this.state.isPayNow}
                                  name="credit"
                                  id="credit"
                                />
                                <label className="form-check-label" style={{ marginTop: '0.1rem' }} htmlFor="credit">
                                  Pay By Debit/Credit Card
                                </label>
                              </div>
                            </div>
                          ) : null}

                          {this.state.isTransactionFailed && (
                            <div className="ml-2 mt-2 text-danger">
                              <span style={{ fontSize: '12px', fontWeight: '100' }}>
                                Due to error ({this.state.transactionFailureMessage}) transaction has been failed.
                                Re-try payment or proceed with Pay By Invoice
                              </span>
                            </div>
                          )}

                          <div
                            className="mt-4"
                            style={
                              this.state.isPayLater ? { border: '2px solid grey' } : { border: '1px solid #dee2e6' }
                            }
                          >
                            <div className="d-flex p-2 ml-4 ">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="invoice"
                                id="invoice"
                                onChange={this.handleTransactionTypeSelection('payLater', 'boolean')}
                                checked={this.state.isPayLater}
                              />
                              <label className="form-check-label" style={{ marginTop: '0.1rem' }} htmlFor="invoice">
                                Pay By Invoice
                                <br />
                              </label>
                            </div>
                          </div>
                          {this.state.isPayLater && (
                            <small className="ml-2" style={{ fontSize: '9px', fontWeight: '100' }}>
                              Longer processing time might apply
                            </small>
                          )}
                          {this.state.isPayNow &&
                            !this.state.featureFlagsLoading &&
                            this.state.featureFlags[FF_SHOW_PAYMENT_WARNING] !== 'OFF' && (
                              <div className="mt-4">
                                <Alert severity="info">{this.state.featureFlags[FF_SHOW_PAYMENT_WARNING]}</Alert>
                              </div>
                            )}
                          <div className="mt-4">
                            <div className="row">
                              {this.state.isPayLater && (
                                <div className="col-sm-12 text-center">
                                  <Button
                                    loading={this.state.loading}
                                    type="submit"
                                    text="Place Order"
                                    className="btn btn-checkout-payment ml-3 mx-auto d-block  w-100"
                                    onClick={this.proceedPayment}
                                  />
                                  <small
                                    className="ml-2"
                                    style={{
                                      fontSize: '9px',
                                      fontWeight: '100'
                                    }}
                                  >
                                    Invoice will be sent to your email
                                  </small>
                                </div>
                              )}
                              {this.state.isPayNow && (
                                <div className="col-sm-12 text-center">
                                  <Button
                                    loading={this.state.loading}
                                    type="submit"
                                    text="Make Payment"
                                    className="btn btn-checkout-payment ml-3 mx-auto d-block w-100"
                                    onClick={this.proceedPayment}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="mt-3 text-center">
                              <a href="/patient/refill">
                                <p>Cancel</p>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.isTransactionCreditCardSuccess && (
          <OrderConfirmed
            loggedIn={true}
            patientName={patientName}
            patientEmail={patientEmail}
            orderCode={this.state.orderCode}
          />
        )}
        <CustomModal isOpen={this.state.modelShow} style={customStyles} shouldCloseOnOverlayClick={false}>
          <CircleLoader style={{ margin: 0 }} />
        </CustomModal>
      </>
    );
  }
}

export default withFlags(withRouter(CardCheckout));
