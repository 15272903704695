import { datadogRum } from '@datadog/browser-rum';
import { styled } from '@mui/material';
import type { ReactNode } from 'react';
import { useEffect, useState } from 'react';

import { Button } from '@/ui-library';

const HelpDeskButton = styled(Button)(({ theme }) => ({
  position: 'fixed',
  right: 20,
  bottom: 40,
  zIndex: 1,
  backgroundColor: theme.palette.secondary.main,
  '&:hover, &:focus': {
    backgroundColor: theme.palette.secondary.dark
  },
  [theme.breakpoints.down('md')]: {
    position: 'static',
    right: 'auto',
    left: 'auto',
    margin: '0 0 20px'
  }
}));

declare global {
  interface Window {
    Brainfish: { HelpWidget: { open: (id: string) => void } };
  }
}

interface Props {
  text?: ReactNode;
}

function BrainfishHelpdesk({ text = 'Open Help Center' }: Props) {
  const [isLoaded, setLoaded] = useState(false);
  const identifier = 'brainfish-trigger-button';

  useEffect(() => {
    if (window.Brainfish) {
      setLoaded(true);
    }
  }, []);

  if (!isLoaded) {
    return null;
  }

  const handleClick = () => {
    window.Brainfish.HelpWidget.open(identifier);
    datadogRum.addAction('help-desk-button-click');
  };

  return (
    <HelpDeskButton data-dd-privacy="allow" className={identifier} onClick={handleClick}>
      {text}
    </HelpDeskButton>
  );
}

export default BrainfishHelpdesk;
