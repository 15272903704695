import { alpha, Box, Radio, styled, Typography, useTheme } from '@mui/material';
import { useCallback } from 'react';

import { formatPrice } from './util';

interface RefillDoseOptionProps {
  quantity: number;
  setQuantity: (qty: number) => void;
  maxQuantity: number;
  prescriptionPeriodDays: number;
  pricePerUnit: number;
  isSelected: boolean;
}

interface DoseDisclaimerParams {
  maxQuantity: number;
  prescriptionPeriodDays: number;
}

export const generateMaxDoseDisclaimer = (params: DoseDisclaimerParams) => {
  const { maxQuantity, prescriptionPeriodDays } = params;
  return `Your prescription lets you order a maximum of ${maxQuantity} ${
    maxQuantity === 1 ? 'unit' : 'units'
  } within a ${prescriptionPeriodDays}-day period`;
};

export const generateReducedDoseDisclaimer = (params: DoseDisclaimerParams) => {
  const { prescriptionPeriodDays } = params;
  return `If you purchase a reduced dose you can still order the remainder of your prescribed dose within ${prescriptionPeriodDays} days`;
};

const DoseOptionContainer = styled(Box)(({ theme }) => ({
  border: '1px solid',
  borderRadius: theme.shape.borderRadius * 2,
  borderColor: theme.palette.action.disabled,
  columnGap: theme.spacing(1),
  padding: theme.spacing(2),
  paddingRight: theme.spacing(4), // The radio control takes up space on the left. Adjusting withspacing on the right.
  paddingBottom: theme.spacing(4), // The radio control takes up space on the top. Adjusting withspacing on the bottom.
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  gridTemplateRows: 'auto auto',
  gridTemplateAreas: `"control header"
  ". content"`,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: alpha(
      theme.palette.action.selected,
      0.04
    ) /* Note, there isn't the desired theme colour available. Using Alpha of of the selected state */
  },
  transition: theme.transitions.create('background', {
    easing: theme.transitions.easing.easeIn,
    duration: theme.transitions.duration.shortest
  })
}));

const DoseOptionControls = styled(Box)(() => ({
  gridArea: 'control'
}));

const DoseOptionContent = styled(Box)(() => ({
  gridArea: 'content',
  display: 'flex',
  flexDirection: 'column',
  flex: 1
}));

const DoseOptionHeader = styled(Box)(() => ({
  gridArea: 'header',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between'
}));

const DoseOptionDisclaimer = styled(Box)(() => ({}));

export function RefillDoseOption(props: RefillDoseOptionProps) {
  const theme = useTheme();
  const { quantity, maxQuantity, prescriptionPeriodDays, pricePerUnit, isSelected, setQuantity } = props;
  const price = formatPrice(pricePerUnit * quantity);

  const isMaxDose = quantity === maxQuantity;
  const quantityLabel = `${quantity} ${quantity === 1 ? 'unit' : 'units'}`;
  const maxDoseLabel = `${isMaxDose ? ' (prescribed quantity)' : ''}`;
  const disclaimer = isMaxDose
    ? generateMaxDoseDisclaimer({ maxQuantity, prescriptionPeriodDays })
    : generateReducedDoseDisclaimer({ maxQuantity, prescriptionPeriodDays });

  const onClick = useCallback(() => {
    setQuantity(quantity);
  }, [setQuantity, quantity]);

  return (
    <DoseOptionContainer
      sx={{ backgroundColor: isSelected ? theme.palette.action.selected : theme.palette.background.default }}
      onClick={onClick}
    >
      <DoseOptionControls>
        <Radio checked={isSelected} />
      </DoseOptionControls>
      <DoseOptionHeader>
        <Typography>
          <Box component="span" fontWeight="bold">
            {quantityLabel}
          </Box>
          {maxDoseLabel}
        </Typography>
        <Typography>{price}</Typography>
      </DoseOptionHeader>
      <DoseOptionContent>
        <DoseOptionDisclaimer>
          <Typography>{disclaimer}</Typography>
        </DoseOptionDisclaimer>
      </DoseOptionContent>
    </DoseOptionContainer>
  );
}

export default RefillDoseOption;
