import { SnackbarProvider } from 'notistack';
import type { ReactNode } from 'react';

import AlertToast from './AlertToast';

const MAX_TOAST_STACKS = 10;

export type AlertSnackbarProviderProps = {
  children?: ReactNode;
};

function AlertSnackbarProvider({ children }: AlertSnackbarProviderProps) {
  return (
    <SnackbarProvider
      maxSnack={MAX_TOAST_STACKS}
      Components={{
        error: AlertToast,
        success: AlertToast,
        info: AlertToast,
        warning: AlertToast,
        default: AlertToast
      }}
    >
      {children}
    </SnackbarProvider>
  );
}

export default AlertSnackbarProvider;
