import { Add, Remove } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Link, styled, Typography } from '@mui/material';
import { buttonClasses } from '@mui/material/Button';
import { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import type { PrescriptionInfo, ProductInfo } from './ProductRefillCard';
import { formatPrice } from './util';

export interface IncrementProductRefillCardProps {
  product: Pick<ProductInfo, 'id' | 'productName' | 'price' | 'supplier'>;
  prescription: Pick<PrescriptionInfo, 'maxQuantity'>;
  quantity: number;
  setQuantity: (newQty: number) => void;
}

const StyledCard = styled(Card)(({ theme }) => ({
  border: '2px solid',
  borderRadius: theme.shape.borderRadius * 2,
  borderColor: theme.palette.action.disabledBackground,
  marginBottom: theme.spacing(4),
  '&:first-of-type': {
    marginTop: theme.spacing(4)
  }
}));

const CardLayout = styled(CardContent)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: '0.5rem',
  padding: theme.spacing(4)
}));

const CardHeader = styled(Box)`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const CardHeaderTitles = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  align-items: flex-start;
`;

const CardHeaderControls = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const InputStepperContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  borderRadius: theme.spacing(5),
  border: '1px solid',
  padding: `${theme.spacing(1)} ${theme.spacing(3)}`,
  button: {
    padding: 0,
    margin: 0,
    minWidth: theme.spacing(4),
    fontSize: theme.typography.body1.fontSize
  },
  // Prevent button from jumping in size when focused
  [`button.${buttonClasses.sizeMedium}.${buttonClasses.textPrimary}:not(.${buttonClasses.disabled}):not(.${buttonClasses.focusVisible}):hover`]:
    {
      padding: 0
    }
}));

export function IncrementProductRefillCard(props: IncrementProductRefillCardProps) {
  const { quantity, setQuantity, prescription, product } = props;

  const { maxQuantity } = prescription;

  const increment = useCallback(
    () => setQuantity(Math.min(quantity + 1, maxQuantity)),
    [maxQuantity, quantity, setQuantity]
  );
  const decrement = useCallback(() => setQuantity(Math.max(0, quantity - 1)), [quantity, setQuantity]);

  const canDecrement = quantity > 0;
  const canIncrement = quantity < maxQuantity;

  return (
    <StyledCard elevation={0}>
      <CardLayout>
        <CardHeader>
          <CardHeaderTitles>
            <Link
              variant="subtitle2"
              component={RouterLink}
              to={`/customer/product-detail/${product.id}`}
              sx={{ fontWeight: 600 }}
            >
              {product.productName}
            </Link>
            <Typography variant="body2">
              {product.supplier} | RRP <strong>{formatPrice(product.price)}</strong>
            </Typography>
          </CardHeaderTitles>
          <CardHeaderControls>
            <InputStepperContainer>
              <Button onClick={decrement} disabled={!canDecrement} aria-label="Decrease by 1">
                <Remove focusable={false} />
              </Button>
              <Typography sx={(theme) => ({ textAlign: 'center', width: theme.spacing(5.5) })}>{quantity}</Typography>
              <Button onClick={increment} disabled={!canIncrement} aria-label="Increase by 1">
                <Add focusable={false} />
              </Button>
            </InputStepperContainer>
          </CardHeaderControls>
        </CardHeader>
      </CardLayout>
    </StyledCard>
  );
}

export default IncrementProductRefillCard;
