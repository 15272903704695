import { Box, Button, Menu, MenuItem, styled } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const VisuallyHiddenInput = styled('input')({
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1
});

interface PharmacyUpdateCtaProps {
  fileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
  downloadTemplate: () => void;
}

function PharmacyUpdateCta({ fileUpload, downloadTemplate }: PharmacyUpdateCtaProps) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box>
      <Button
        aria-haspopup="true"
        aria-expanded={Boolean(anchorEl)}
        color="info"
        onClick={handleClick}
        endIcon={<ArrowDropDownIcon />}
      >
        Pharmacy Update
      </Button>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        <Box component="div" onChange={fileUpload}>
          <MenuItem component="label" htmlFor="file-input">
            Upload csv
            <VisuallyHiddenInput type="file" accept=".csv" id="file-input" />
          </MenuItem>
        </Box>
        <MenuItem onClick={() => navigate('/updatehistory')} role="menuitem">
          View Past Changes
        </MenuItem>
        <MenuItem onClick={downloadTemplate}>Download Template</MenuItem>
      </Menu>
    </Box>
  );
}
export default PharmacyUpdateCta;
