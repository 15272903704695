export type LoginType = 'patient' | 'admin' | 'doctor' | 'ml' | 'pharmacist';

export const CONTACT_EMAILS: Map<LoginType, string> = new Map([
  ['patient', 'orders@montu.com.au'],
  ['admin', 'circuit@montu.com.au'],
  ['doctor', 'hcp@montu.com.au'],
  ['ml', 'hcp@montu.com.au']
]);

export const OTHER_LOGINS: Map<LoginType, LoginType[]> = new Map([
  ['patient', ['doctor']],
  ['doctor', ['patient']],
  ['pharmacist', ['pharmacist']],
  ['ml', ['doctor', 'patient', 'pharmacist']]
]);

export interface SignInFormData {
  email: string;
  password: string;
  rePassword: string;
  remember: boolean;
}
