import { ListItemIcon } from '@mui/material';
import { useLocation } from 'react-router-dom';

import type { INavMenu } from '@/components/layout/navbar/navbar.config';

import { NavMenuLink, NavMenuListItem } from '../navbar/NavMenuItem';

interface ISidebarNavMenuItem {
  menuItem: INavMenu;
  toggleText: boolean;
  onClick?: () => void;
}

function SidebarNavMenuItem({ menuItem, toggleText, onClick }: ISidebarNavMenuItem) {
  const location = useLocation();

  const isMedicineInfoActive =
    menuItem.id === 5 &&
    (location.pathname === menuItem.link ||
      location.pathname === '/medicine-information' ||
      location.pathname.includes('/product-detail'));
  const isActive = isMedicineInfoActive || location.pathname === menuItem.link;

  return (
    <NavMenuListItem
      className={isActive ? 'active' : ''}
      sx={{
        paddingLeft: (theme) => theme.spacing(6.5),
        paddingRight: (theme) => theme.spacing(6.5)
      }}
      onClick={onClick}
    >
      <NavMenuLink
        to={menuItem.link}
        sx={{
          display: 'flex',
          alignItems: 'center',
          width: '100%'
        }}
      >
        <ListItemIcon
          sx={{
            color: (theme) => theme.palette.common.white
          }}
        >
          <menuItem.icon />
        </ListItemIcon>
        {toggleText && menuItem.name}
      </NavMenuLink>
    </NavMenuListItem>
  );
}

export default SidebarNavMenuItem;
