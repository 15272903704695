import { Alert, Stack, styled } from '@mui/material';

export const LoadingIconContainer = styled(Stack)(({ theme }) => ({
  justifyContent: 'center',
  alignItems: 'center',
  marginTop: theme.spacing(10)
}));

export const ErrorAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.dark,
  [theme.breakpoints.up('md')]: {
    maxWidth: '24.5rem'
  }
}));

export const InfoAlert = styled(Alert)(({ theme }) => ({
  backgroundColor: theme.palette.info.light,
  color: theme.palette.info.dark,
  [theme.breakpoints.up('md')]: {
    maxWidth: '16rem'
  }
}));
