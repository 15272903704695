import { useQuery } from '@tanstack/react-query';

import { OrderService } from '@/services/order.service';

import type { QueryError } from '@/utils/getQueryClient';
import type { AsyncContent, PatientRefillResponse } from '../patient/refill/types';
import { remapResponseRemainingUnits } from '../patient/refill/util';

interface UsePatientRefillQueryOptions {
  error: QueryError;
}

const QUERY_KEY_PATIENT_REFILL = 'patientRefill';

export const usePatientRefillQuery = (
  userId: number,
  orderId: number,
  options: UsePatientRefillQueryOptions
): AsyncContent<PatientRefillResponse> => {
  const { error } = options;

  const { data, isError, isLoading, isSuccess } = useQuery({
    queryKey: [QUERY_KEY_PATIENT_REFILL, userId],
    queryFn: async () => {
      const refillResponse = await OrderService.getOrderForPatientRefill(userId, orderId);
      // Workaround for a backend bug
      return remapResponseRemainingUnits(refillResponse.data);
    },
    meta: {
      error
    }
  });

  return {
    data,
    isError,
    isLoading,
    isSuccess
  };
};

export default usePatientRefillQuery;
