import { Search } from '@mui/icons-material';
import ClearIcon from '@mui/icons-material/Clear';
import { Box, IconButton, InputAdornment, styled, TextField } from '@mui/material';
import type { ChangeEvent } from 'react';
import { useEffect, useState } from 'react';

const QueryFilterContainer = styled(Box)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  display: 'flex',
  maxWidth: '16rem'
}));

const QueryFilterInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    minWidth: 350
  }
}));

const ClearButton = styled(IconButton)({
  '&:focus': {
    outline: 'none'
  }
});

export type TableFilterInputProps = {
  value?: string;
  onChange: (value: string) => void;
  label?: string;
};

function TableSearchInput({ value = '', label = 'Search', onChange }: TableFilterInputProps) {
  const [localValue, setLocalValue] = useState(value);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocalValue(e.target.value);
    if (e.target.value !== value) {
      onChange(e.target.value);
    }
  };

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  const handleClear = () => {
    onChange('');
  };

  return (
    <QueryFilterContainer>
      <QueryFilterInput
        value={localValue}
        onChange={handleChange}
        placeholder={label}
        variant="standard"
        InputProps={{
          endAdornment: value ? (
            <InputAdornment position="end">
              <ClearButton aria-label="Clear search" onClick={handleClear} edge="end">
                <ClearIcon />
              </ClearButton>
            </InputAdornment>
          ) : null,
          startAdornment: (
            <Search
              color="action"
              sx={{
                marginRight: '0.25rem'
              }}
            />
          )
        }}
      />
    </QueryFilterContainer>
  );
}

export default TableSearchInput;
