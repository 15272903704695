import { useQuery } from '@tanstack/react-query';

import { searchPatients } from '@/services/data.service';
import type { TableSortOrder } from '@/types';

export type UseSearchPatientsParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
};

function useSearchPatients({ page, pageSize, sortingOrder, filter }: UseSearchPatientsParams) {
  const { isLoading, data, isError, isSuccess, error } = useQuery({
    queryKey: ['searchPatients', page, pageSize, sortingOrder, filter],
    queryFn: async ({ signal }) => {
      try {
        const response = await searchPatients({ page, pageSize, sortingOrder, filter, signal });
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch doctors availability: ${(e as { message: string }).message}`);
      }
    }
  });

  return { isLoading, data, isError, isSuccess, error };
}

export default useSearchPatients;
