import { DateTime } from 'luxon';

export const numberFormat = (
  value: number,
  locale = 'en-AU',
  options: Partial<Intl.NumberFormatOptions> = {
    style: 'currency',
    currency: 'AUD'
  }
) => new Intl.NumberFormat(locale, options).format(value);

/**
 * Formats an ISO date for display on the front end
 * @param isoDate A date formatted with .toISOString()
 * @returns A date formatted dd-MMM-yyyy (ex. 19-Oct-2025)
 */
export const formatISODateHelper = (isoDate: string) => {
  return DateTime.fromISO(isoDate).toFormat('dd-MMM-yyyy');
};

export default {
  numberFormat
};
