import { BrandThemeProvider } from '@montugroup/themes';
import { Box, Button, Stack, styled, Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

type OrderMedicationCardProps = {
  primaryButtonText?: string;
  primaryButtonLink: string;
  secondaryButtonText?: string;
  secondaryButtonLink?: string;
  showPrimaryButton?: boolean;
  showSecondaryButton?: boolean;
};

const OrderCard = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.common.white,
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, 0.05)',
  boxShadow: theme.shadows[1],
  justifyContent: 'center',
  gap: theme.spacing(2),
  padding: theme.spacing(6),

  [theme.breakpoints.up('md')]: {
    height: '100%'
  }
}));

const TextWithLogo = styled(Box)(({ theme }) => ({
  position: 'relative',
  bottom: theme.spacing(2),
  '& img': {
    width: '5.3125rem',
    height: '1.375rem',
    position: 'absolute',
    left: theme.spacing(18),
    bottom: theme.spacing(-0.5)
  }
}));

export function OrderMedicationCard({
  primaryButtonText = 'Create order',
  primaryButtonLink,
  secondaryButtonText,
  secondaryButtonLink,
  showPrimaryButton = true,
  showSecondaryButton = true
}: OrderMedicationCardProps) {
  const { key: partnerKey, Logo: PartnerLogo } = usePortalPartnerBrand();

  return (
    <BrandThemeProvider variant={partnerKey}>
      <OrderCard>
        <TextWithLogo>
          <Typography variant="subtitle2" color="primary" fontWeight="bold">
            Order my medication
          </Typography>

          <Typography variant="body2" color="primary" sx={{ mt: 1 }}>
            Powered by <PartnerLogo fillColor="currentColor" />
          </Typography>
        </TextWithLogo>
        {showPrimaryButton && (
          <Button variant="contained" color="primary" component={RouterLink} to={primaryButtonLink} size="large">
            {primaryButtonText}
          </Button>
        )}
        {showSecondaryButton && secondaryButtonText && secondaryButtonLink && (
          <Button variant="outlined" color="primary" component={RouterLink} to={secondaryButtonLink} size="large">
            {secondaryButtonText}
          </Button>
        )}
      </OrderCard>
    </BrandThemeProvider>
  );
}

export default OrderMedicationCard;
