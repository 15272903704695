import settings from '@/constants/constants';

const generateTrackingLink = (carrier: string | null, trackingId: string | null) => {
  switch (carrier) {
    case settings.shipmentCarrier.ausPost:
      return `${settings.ausPostTrackingUrl}${trackingId}`;
    case settings.shipmentCarrier.shippit:
      return `${settings.shippitTrackingUrl}${trackingId}`;
    default:
      // eslint-disable-next-line no-undefined
      return undefined;
  }
};

export default generateTrackingLink;
