import { Box, Grid, Skeleton } from '@mui/material';

import { FF_ENABLE_GOOGLE_PLACES_UMEDS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

export default function ShippingAddressLoadingSkeleton() {
  const { flags } = useFeatureFlags();
  const isGooglePlacesEnabled = flags[FF_ENABLE_GOOGLE_PLACES_UMEDS];

  return (
    <Box sx={{ mt: 6 }}>
      {isGooglePlacesEnabled ? (
        <Grid container rowSpacing={4} columnSpacing={7}>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={4} columnSpacing={7}>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Skeleton variant="rectangular" width="100%" height={56} />
          </Grid>
        </Grid>
      )}
    </Box>
  );
}
