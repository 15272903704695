import { FilterAlt } from '@mui/icons-material';
import { Button, Card, CardContent, Checkbox, FormControlLabel, FormGroup, Popover, Stack } from '@mui/material';
import axios from 'axios';
import * as React from 'react';
import { useCallback, useEffect } from 'react';

import settings from '@/constants/constants';
import { FF_ENABLE_ORDER_FILTER_BY_PHARMACY } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';

type Props<T> = {
  selectedPharmacies: T[] | null;
  onSelect: (status: T[] | null) => void;
  buttonSize?: 'small' | 'medium' | 'large';
};

type PharmacyRes = {
  pharmacies: {
    id: number;
    pharmacy_code: string;
    name: string;
    email: string;
    state: string;
    city: string;
    zip_code: number;
    address: string;
    phone: string;
    identifier_name: string;
  }[];
};

function FilterOrderByPharmacySelect<T extends string>({
  selectedPharmacies,
  onSelect,
  buttonSize = 'small'
}: Props<T>) {
  const [anchorElement, setAnchorElement] = React.useState<HTMLButtonElement | null>();
  const [pharmacies, setPharmacies] = React.useState<{ label: string; value: string }[]>([]);
  const { flags } = useFeatureFlags();
  const isPharmacyFilterEnabled = flags[FF_ENABLE_ORDER_FILTER_BY_PHARMACY];

  const onFilterByPharmacyClick: React.MouseEventHandler<HTMLButtonElement> = (e) =>
    setAnchorElement((current) => (current ? null : e.currentTarget));

  const onClose = () => setAnchorElement(null);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value as T;
    let updatedSelectedPharmacies: T[];
    if (selectedPharmacies?.includes(value)) {
      updatedSelectedPharmacies = selectedPharmacies.filter((pharmacy) => pharmacy !== value);
    } else {
      updatedSelectedPharmacies = [...(selectedPharmacies ?? []), value];
    }
    onSelect(updatedSelectedPharmacies);
  };

  const fetchPharmacies = useCallback(async () => {
    await axios.get<PharmacyRes>(`${settings.url}/pharmacy/search`).then((resp) => {
      setPharmacies(resp.data.pharmacies.map((x) => ({ label: x.name, value: `${x.id}` })));
    });
  }, []);

  useEffect(() => {
    fetchPharmacies();
  }, []);

  return (
    isPharmacyFilterEnabled && (
      <>
        <Stack direction="row" justifyContent="flex-end" position="relative">
          <Stack>
            <Button
              startIcon={<FilterAlt />}
              size={buttonSize}
              onClick={onFilterByPharmacyClick}
              style={{
                background: '#194457',
                color: 'white'
              }}
            >
              Filter by Pharmacy
            </Button>
          </Stack>
        </Stack>
        <Popover
          id="pharmacy-filters-popover"
          open={!!anchorElement}
          anchorEl={anchorElement}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
        >
          <Card raised sx={{ maxHeight: '50vh', overflow: 'scroll' }}>
            <CardContent>
              <Stack spacing={2} alignContent="center">
                <FormGroup>
                  {pharmacies.map((pharmacy) => (
                    <FormControlLabel
                      key={`order-pharmacy-filter-${pharmacy.value}`}
                      control={
                        <Checkbox
                          color="secondary"
                          value={pharmacy.value}
                          onChange={onChange}
                          checked={selectedPharmacies?.includes(pharmacy.value as T)}
                        />
                      }
                      label={pharmacy.label}
                    />
                  ))}
                </FormGroup>
              </Stack>
            </CardContent>
          </Card>
        </Popover>
      </>
    )
  );
}

export default FilterOrderByPharmacySelect;
