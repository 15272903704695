import type { PaginationModel } from '@montugroup/design-system';
import { useCallback, useState } from 'react';

const DEFAULT_PAGINATION_MODEL: PaginationModel = {
  page: 0,
  pageSize: 10
};

function useTablePaginationModel(initialModel = DEFAULT_PAGINATION_MODEL) {
  const [paginationModel, setPaginationModel] = useState<PaginationModel>(initialModel);

  const handlePaginationModelChange = useCallback((model: PaginationModel) => {
    setPaginationModel(model);
  }, []);

  return { page: paginationModel.page, pageSize: paginationModel.pageSize, handlePaginationModelChange };
}

export default useTablePaginationModel;
