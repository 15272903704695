import { BrandThemeProvider } from '@montugroup/themes';
import { Button, Link, Stack, TextField, Typography } from '@mui/material';
import type { FormEvent } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import usePharmacistForm from '@/hooks/pharmacist/usePharmacistForm';
import usePharmacistLogin from '@/hooks/pharmacist/usePharmacistLogin';
import useSessionExpirationCheck from '@/hooks/pharmacist/useSessionExpirationCheck';
import { usePortalPartnerBrand } from '@/hooks/portal-partner-brand';

import { AuthCardContainer, AuthPageContainer, Background, CentreContainer } from './PharmacistStyles';

function PharmacistSignInForm() {
  const { key: brandName, Icon: Bg, Logo: TopLogo } = usePortalPartnerBrand();

  const { data, errors, handleUserInput, setErrors, validator } = usePharmacistForm({
    email: '',
    password: '',
    loginType: 'pharmacist'
  });

  const { login, isPending } = usePharmacistLogin();

  useSessionExpirationCheck();

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    if (!validator.allValid()) {
      validator.showMessages();
      setErrors({
        email: validator.message('email', data.email, 'required|email'),
        password: validator.message('password', data.password, 'required')
      });
      return;
    }

    await login(data);
  };

  return (
    <BrandThemeProvider variant={brandName}>
      <AuthPageContainer>
        <CentreContainer>
          <AuthCardContainer>
            <Stack mb={10}>
              <TopLogo width="150px" height="auto" fillColor={(theme) => theme.palette.primary.main} />
            </Stack>
            <Typography color="primary" variant="h5" align="left">
              Pharmacist login
            </Typography>
            <form onSubmit={handleSubmit}>
              <TextField
                label="Email"
                name="email"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.email}
                helperText={errors.email}
                onChange={handleUserInput('text')}
                value={data.email}
                autoComplete="email"
                sx={{
                  marginBottom: 0
                }}
              />
              <TextField
                label="Password"
                type="password"
                name="password"
                fullWidth
                margin="normal"
                variant="outlined"
                error={!!errors.password}
                helperText={errors.password}
                onChange={handleUserInput('text')}
                value={data.password}
                autoComplete="off"
              />
              <Button
                type="submit"
                variant="contained"
                fullWidth
                sx={{
                  marginTop: 2
                }}
                color="secondary"
                disabled={isPending}
                size="large"
              >
                Login
              </Button>
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                sx={{ alignSelf: 'stretch', mt: 4 }}
              >
                <Link component={RouterLink} to={`/forgot-password/${data.loginType}`} color="primary">
                  Forgot password?
                </Link>
              </Stack>
            </form>
          </AuthCardContainer>
        </CentreContainer>
      </AuthPageContainer>
      <Background>
        <Bg />
      </Background>
    </BrandThemeProvider>
  );
}

export default PharmacistSignInForm;
