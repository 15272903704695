import type { ChipProps } from '@mui/material';
import { Chip, Typography } from '@mui/material';
import { lightGreen } from '@mui/material/colors';

export type KeyValueChipProps = Pick<ChipProps, 'sx' | 'variant' | 'size' | 'color'> & {
  name: string;
  value: string;
};

function KeyValueChip({ name, value, variant = 'outlined', ...otherProps }: KeyValueChipProps) {
  return (
    <Chip
      variant={variant}
      sx={{
        backgroundColor: lightGreen[50]
      }}
      {...otherProps}
      label={
        <Typography variant="body2" fontWeight="bold">
          {name}&nbsp;
          <Typography variant="body2" component="span" fontWeight="regular">
            {value}
          </Typography>
        </Typography>
      }
    />
  );
}

export default KeyValueChip;
