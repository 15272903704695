import { Box } from '@mui/material';
import type { ReactNode } from 'react';

interface TabPanelProps {
  children?: ReactNode;
  index: number;
  value: number;
  label: string;
  id: string;
}

export default function TabPanel(props: TabPanelProps) {
  const { children, value, index, label, id, ...other } = props;

  return (
    <Box role="tabpanel" hidden={value !== index} id={id} aria-labelledby={`tab-${label}`} {...other}>
      {value === index && (
        <Box
          sx={{
            paddingTop: (theme) => theme.spacing(4)
          }}
        >
          {children}
        </Box>
      )}
    </Box>
  );
}
