import { viteAppEnv } from '@/constants/env';
import usePatientConsentStatus from '@/hooks/patient/usePatientConsentStatus';

import PatientConsentDialog from './PatientConsentDialog';

export function PatientConsent() {
  const patientConsentStatus = usePatientConsentStatus();

  // Disable this dialog when developing locally, to improve DX.
  const isDev = viteAppEnv() === 'development';
  if (isDev) {
    return null;
  }

  const requiresConsentSubmission = Boolean(patientConsentStatus.data?.requiresConsentSubmission);
  const requireReload = patientConsentStatus.data?.requireReload;

  if (!requiresConsentSubmission) {
    return null;
  }

  return (
    <PatientConsentDialog
      isOpen={requiresConsentSubmission}
      onSubmit={patientConsentStatus.submitConsent}
      requireReload={requireReload}
    />
  );
}

export default PatientConsent;
