import React, { useState } from 'react';

type WarningType =
  | 'mark-as-oos'
  | 'mark-as-gscript'
  | 'oos-to-gscript'
  | 'gscript-to-oos'
  | 'turn-off-oos'
  | 'turn-off-gscript'
  | 'none';

interface GScripWarningSettings {
  outOfStock?: boolean;
  isGenerativeScripting?: boolean;
}

type GetWarningTypeArgs = {
  currentSettings: GScripWarningSettings;
  updatedSettings: Partial<GScripWarningSettings>;
};

interface GScriptProductSettings {
  isGenerativeScripting?: boolean;
  outOfStock?: boolean;
  reasoningToggle?: boolean;
  prop: keyof GScriptProductSettings;
}

interface GScriptMetadata {
  prop: string;
  updatedValue: boolean | null;
}

export const gScriptWarningModalStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    transform: 'translate(-50%, -50%)',
    padding: '35px',
    borderStyle: 'solid',
    borderWidth: 2,
    borderColor: 'red'
  }
};

export const getGScriptWarningComponent = (warningType: WarningType) => {
  switch (warningType) {
    case 'mark-as-oos':
      return (
        <>
          <h1>Warning - Making a product OOS affects patients and doctors</h1>
          <p>
            This action will affect doctors and patients who have scripts for this product. By clicking proceed you will
            prompt all prescribers to review their active scripts for this product and create a temporary 0-repeat
            script.
          </p>
        </>
      );
    case 'mark-as-gscript':
      return (
        <>
          <h1>Warning - Marking a product for g-scripting affects patients and doctors</h1>
          <p>
            This action will affect doctors and patients who have scripts for this product. By clicking proceed you will
            prompt all prescribers to cancel all active scripts for this product and write a new script for an
            alternative.
          </p>
        </>
      );
    /**
     * The case where we are turning OOS off and turning on GScripting
     */
    case 'oos-to-gscript':
      return (
        <>
          <h1>Warning - This product is already marked OOS, changing to g-scripting will cause duplicate requests</h1>
          <p>
            By making this change, you will trigger duplicate requests for the same scripts to doctors. Are you sure you
            wish to proceed? If you make this action you must contact the tech team immediately to remove duplicate
            requests.
          </p>
        </>
      );
    /**
     * The case where we are turning GScripting off and turning on OOS
     */
    case 'gscript-to-oos':
      return (
        <>
          <h1>
            Warning - This product is already marked for g-scripting, changing to OOS will cause duplicate requests
          </h1>
          <p>
            By making this change, you will trigger duplicate requests for the same scripts to doctors. Are you sure you
            wish to proceed? If you make this action you must contact the tech team immediately to remove duplicate
            requests.
          </p>
        </>
      );
    case 'turn-off-oos':
      return (
        <>
          <h1>Are you sure you wish to turn off OOS?</h1>
          <p>
            Turning off OOS means this product is back in stock. If you make this action you must contact the tech team
            immediately to remove existing requests.
          </p>
        </>
      );
    case 'turn-off-gscript':
      return (
        <>
          <h1>Are you sure you wish to turn off g-scripting?</h1>
          <p>
            Turning off g-scripting means this product is back in circulation. If you make this action you must contact
            the tech team immediately to remove existing requests.
          </p>
        </>
      );

    default:
      return <h1>Nothing to see here</h1>;
  }
};

export const getGScriptWarningType = ({ currentSettings, updatedSettings }: GetWarningTypeArgs): WarningType => {
  const gScriptingAlreadyOn = currentSettings.isGenerativeScripting;
  const outOfStockAlreadyOn = currentSettings.outOfStock;

  const bothOptionsAlreadyOff = !gScriptingAlreadyOn && !outOfStockAlreadyOn;
  if (bothOptionsAlreadyOff && updatedSettings.isGenerativeScripting) {
    return 'mark-as-gscript';
  }
  if (bothOptionsAlreadyOff && updatedSettings.outOfStock) {
    return 'mark-as-oos';
  }

  if (gScriptingAlreadyOn && updatedSettings.outOfStock) {
    return 'gscript-to-oos';
  }
  if (outOfStockAlreadyOn && updatedSettings.isGenerativeScripting) {
    return 'oos-to-gscript';
  }

  if (gScriptingAlreadyOn && updatedSettings.isGenerativeScripting === false) {
    return 'turn-off-gscript';
  }
  if (outOfStockAlreadyOn && updatedSettings.outOfStock === false) {
    return 'turn-off-oos';
  }

  return 'none';
};

const useGenerativeScriptingSafeguards = () => {
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [gScriptWarningType, setGScriptWarningType] = useState<WarningType>('none');
  const [warningElement, setWarningElement] = useState<React.ReactNode | null>(null);
  const [gScriptMetadata, setGScriptMetadata] = useState<GScriptMetadata>({ prop: '', updatedValue: null });

  // Open a modal when the user tries to change a gscript/oos setting, only applies change if user selects proceed
  const tryChangeGScriptingSetting = (
    currentSettings: GScriptProductSettings,
    updatedSettings: GScriptProductSettings
  ) => {
    const warningType = getGScriptWarningType({
      updatedSettings: {
        isGenerativeScripting: updatedSettings.isGenerativeScripting,
        outOfStock: Boolean(updatedSettings.reasoningToggle || updatedSettings.outOfStock)
      },
      currentSettings: {
        isGenerativeScripting: currentSettings.isGenerativeScripting,
        outOfStock: Boolean(currentSettings.outOfStock || currentSettings.reasoningToggle)
      }
    });
    const warningComponent = getGScriptWarningComponent(warningType);

    setGScriptWarningType(warningType);
    setWarningElement(warningComponent);
    setShowWarningModal(true);
    setGScriptMetadata({ prop: updatedSettings.prop, updatedValue: updatedSettings[updatedSettings.prop] as boolean });
  };

  return {
    showWarningModal,
    gScriptWarningType,
    warningElement,
    gScriptMetadata,
    setShowWarningModal,
    tryChangeGScriptingSetting
  };
};

export default useGenerativeScriptingSafeguards;
