import { styled, Tab, Tabs } from '@mui/material';
import React from 'react';

import { PharmacistOrderTableView, usePharmacistOrders } from '@/context/pharmacist/orders/PharmacistOrders';

const PharmacistTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontFamily: theme.typography.button.fontFamily,
  '&:focus': {
    outline: 'none'
  },
  '&:focus-visible': {
    outline: 'auto'
  }
}));

function PharmacistOrderTableSelector() {
  const { activeView, changeTableView } = usePharmacistOrders();

  const handleChange = (_event: React.SyntheticEvent, newView: PharmacistOrderTableView) => {
    changeTableView(newView);
  };

  return (
    <Tabs value={activeView} onChange={handleChange} textColor="secondary" indicatorColor="secondary">
      <PharmacistTab label="Open orders" value={PharmacistOrderTableView.OPEN_ORDERS} />
      <PharmacistTab label="Processing dispense" value={PharmacistOrderTableView.PROCESSING_DISPENSE} />
      <PharmacistTab label="Dispensed orders" value={PharmacistOrderTableView.DISPENSED_ORDERS} />
      <PharmacistTab label="All orders" value={PharmacistOrderTableView.ALL_ORDERS} />
    </Tabs>
  );
}

export default PharmacistOrderTableSelector;
