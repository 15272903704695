import debounce from 'awesome-debounce-promise';
import { useEffect, useState } from 'react';

function useWindowWidth() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    // Creating a debounced function using awesome-debounce-promise
    // It wraps the setWidth function in a debounce handler
    const handleResize = debounce(() => {
      setWidth(window.innerWidth);
    }, 100);

    // Set up the event listener
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove the event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return width;
}

export default useWindowWidth;
