import type { AlertProps } from '@mui/material';
import { Alert } from '@mui/material';
import type { CustomContentProps } from 'notistack';
import { SnackbarContent, useSnackbar } from 'notistack';
import { forwardRef, useCallback } from 'react';

const severityMap: Record<CustomContentProps['variant'], AlertProps['severity']> = {
  success: 'success',
  error: 'error',
  default: 'info',
  warning: 'warning',
  info: 'info'
};

const AlertToast = forwardRef<HTMLDivElement, CustomContentProps>(({ id, ...props }, ref) => {
  const { closeSnackbar } = useSnackbar();
  const handleDismiss = useCallback(() => {
    closeSnackbar(id);
  }, [id, closeSnackbar]);

  return (
    <SnackbarContent ref={ref}>
      <Alert variant="standard" severity={severityMap[props.variant]} sx={{ boxShadow: 6 }} onClose={handleDismiss}>
        {props.message}
      </Alert>
    </SnackbarContent>
  );
});
AlertToast.displayName = 'AlertToast';

export default AlertToast;
