import React from 'react';
import Modal from 'react-modal';

interface CustomModalProps extends React.ComponentProps<typeof Modal> {
  children: React.ReactNode;
}

/**
 * @deprecated Use MUI Modal in the future instead
 */
function CustomModal({ children, ...props }: CustomModalProps) {
  return <Modal {...props}>{children}</Modal>;
}

export default CustomModal;
