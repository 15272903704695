import { Box, Button, styled, Typography } from '@mui/material';
import type { PropsWithChildren } from 'react';
import { AiOutlineShoppingCart } from 'react-icons/ai';

export interface OrderSummaryButtonProps {
  totalNumberOfProducts: number;
  onClick: () => void;
  amount?: string;
  disabled?: boolean;
}

const CheckoutButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(4),
  padding: theme.spacing(2),
  paddingLeft: theme.spacing(4),
  paddingRight: theme.spacing(4)
}));

export const formatOrderSummaryText = (params: { totalNumberOfProducts: number; amount?: string }) => {
  const { totalNumberOfProducts, amount } = params;
  const countSuffix = totalNumberOfProducts === 1 ? 'unit' : 'units';
  const textSuffix = amount ? ` - ${amount}` : '';
  return `Proceed (${totalNumberOfProducts} ${countSuffix})${textSuffix}`;
};

// This should eventually replace the original, once it is deprecated
export function OrderSummaryButton(props: OrderSummaryButtonProps) {
  const { totalNumberOfProducts, onClick, amount, disabled } = props;
  const text = formatOrderSummaryText({
    totalNumberOfProducts,
    amount
  });

  return (
    <CheckoutButton variant="contained" onClick={onClick} startIcon={<AiOutlineShoppingCart />} disabled={disabled}>
      <Typography variant="body1" component="span" sx={{ fontWeight: 600 }}>
        {text}
      </Typography>
    </CheckoutButton>
  );
}

export interface OrderSummaryButtonContainerProps {
  isSticky: boolean;
}

const Container = styled(Box)(({ theme }) => ({
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(5),
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  display: 'flex',
  justifyContent: 'stretch',
  alignItems: 'center',
  '& > button': {
    width: '100%'
  }
}));

const StickyContainer = styled(Container)(({ theme }) => ({
  position: 'sticky',
  bottom: theme.spacing(4),
  left: 0,
  padding: 0
}));

export function OrderSummaryButtonContainer(props: PropsWithChildren<OrderSummaryButtonContainerProps>) {
  const { isSticky, children } = props;

  const Wrapper = isSticky ? StickyContainer : Container;

  return <Wrapper>{children}</Wrapper>;
}
