import { PaginationVariant, Table } from '@montugroup/design-system';
import { Button, Typography } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';

import TableSearchInput from '@/components/common/TableSearchInput';
import { useErrorManagement } from '@/context/ErrorManagement';
import useGetPharmacyUpdateHistory from '@/hooks/pharmacy/useGetPharmacyUpdateHistory';
import useDownload from '@/hooks/useDownload';
import type { PharmacyUpdate } from '@/types';

function DownloadFileCell({ linkText, fileName }: { linkText: string; fileName: string }) {
  const { isDownloading, downloadFile } = useDownload();

  const handleClick = async () => {
    await downloadFile(fileName, '/pharmacy/update-history-file-download', {
      method: 'GET',
      params: {
        name: fileName
      }
    });
  };

  if (!fileName) {
    return null;
  }

  return (
    <Button variant="text" onClick={handleClick} disabled={isDownloading}>
      <Typography fontSize="small">{linkText}</Typography>
    </Button>
  );
}

const columns: ColumnDef<PharmacyUpdate>[] = [
  {
    accessorKey: 'createdDate',
    header: 'Created date',
    enableSorting: true
  },
  {
    accessorKey: 'mailId',
    header: 'Created by',
    enableSorting: true
  },
  {
    accessorKey: 'uploadedFile',
    header: 'Uploaded file',
    enableSorting: true,
    cell: ({ row }) => <DownloadFileCell fileName={row.original.uploadedFile} linkText="Uploaded .csv" />
  },
  {
    accessorKey: 'resultFile',
    header: 'Result file',
    enableSorting: true,
    cell: ({ row }) => <DownloadFileCell fileName={row.original.resultFile} linkText="Result .csv" />
  }
];

function PharmacyUpdateHistoryTable() {
  const { data, isLoading, error } = useGetPharmacyUpdateHistory();
  const [filter, setFilter] = useState<string>('');
  const { enqueueError } = useErrorManagement();

  useEffect(() => {
    if (error) {
      enqueueError({ title: 'Something went wrong', body: (error as { message: string }).message || '' });
    }
  }, [enqueueError, error]);

  return (
    <>
      <TableSearchInput value={filter} onChange={(value) => setFilter(value)} />
      <Table
        data={data || []}
        globalFilter={filter}
        columns={columns}
        isLoading={isLoading}
        compact
        hasRowBackgroundColor={false}
        showPagination
        pageSize={10}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        initialSort={[{ id: 'createdDate', desc: true }]}
      />
    </>
  );
}

export default PharmacyUpdateHistoryTable;
