import { List, ListItem, Link as MUILink } from '@mui/material';
import { Link } from 'react-router-dom';

import type { Order, PrescriberDetail } from '@/services/data.service';

type DoctorTableCellProps = {
  order: Order;
};

function DoctorListItem({ prescriber }: { prescriber: PrescriberDetail }) {
  if (prescriber.docUserId === '-') {
    return <span>{prescriber.name}</span>;
  }
  return (
    <MUILink component={Link} to={`/gp-register/${prescriber.docUserId}`}>
      {prescriber.name}
    </MUILink>
  );
}

function DoctorTableCell({ order }: DoctorTableCellProps) {
  const usePrescriber = order.client_id === 1;

  if (!usePrescriber) {
    return (
      <MUILink component={Link} to={`/gp-register/${order.gp_uniq_id}`}>
        {order.gp_name}
      </MUILink>
    );
  }
  return (
    <List dense disablePadding>
      {order.prescriber_details.map((prescriber) => (
        <ListItem key={prescriber.docUserId} disablePadding>
          <DoctorListItem prescriber={prescriber} />
        </ListItem>
      ))}
    </List>
  );
}

export default DoctorTableCell;
