import React from 'react';

import Brand from '@/utils/brand';

interface BodyProps {
  children: React.ReactNode;
}

export default function MainContentWrapper({ children }: BodyProps) {
  const customClassName = Brand.isRebrand() ? 'brand-alternaleaf' : ''; // TODO: Remove below when alternaleaf.scss file is deprecated

  return (
    <div
      className={`d-flex h-100 flex-column justify-content-between ${customClassName}`}
      style={{ minHeight: '100vh' }}
    >
      {children}
    </div>
  );
}
