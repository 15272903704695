import { useQuery } from '@tanstack/react-query';

import { FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { getCircuitProducts, getInventoryStatusList } from '@/services/pharmacist.service';
import { PharmacyService } from '@/services/pharmacy.service';
import type { PharmacyProductInventory } from '@/types';

const useGetInventoryProducts = (pharmacyId: number | null) => {
  const { flags } = useFeatureFlags();

  const fetchProducts = async () => {
    if (!pharmacyId) {
      return { products: [], inventoryStatusList: [] };
    }
    if (flags[FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS]) {
      // Fetch products using the new method
      const pharmacyInventoryResponse = await PharmacyService.getPharmacyProductInventory(pharmacyId);

      const inactiveProducts = pharmacyInventoryResponse.data.data.map((product: PharmacyProductInventory) => {
        if (!product.active) {
          return { ...product, name: `${product.name} - (Inactive)` };
        }
        return product;
      });

      return {
        products: inactiveProducts,
        inventoryStatusList: []
      };
    }
    // Fetch products using the old method
    const availableProducts = await getCircuitProducts();
    const inventoryProducts = await PharmacyService.getPharmacyProducts(pharmacyId);

    const allProducts = availableProducts.data.map((item: { id: number; name: string }) => {
      const inventoryProduct = inventoryProducts.data.products.find(
        (product: { product_id: number }) => product.product_id === item.id
      );
      return {
        ...item,
        stock: inventoryProduct?.total_stock || 0,
        availableStock: inventoryProduct?.available_stock || 0,
        allocatedStock: inventoryProduct?.allocated_stock || 0
      };
    });

    const statusList = await getInventoryStatusList();
    return { products: allProducts, inventoryStatusList: statusList.data };
  };

  const { isLoading, error, data, refetch, isError } = useQuery({
    queryKey: ['products', pharmacyId],
    queryFn: fetchProducts,
    meta: {
      error: {
        message: 'Error fetching products',
        showToast: true
      }
    }
  });

  return {
    products: data?.products || [],
    inventoryStatusList: data?.inventoryStatusList || [],
    isLoading,
    error,
    isError,
    refetch
  };
};

export default useGetInventoryProducts;
