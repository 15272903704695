import { useQuery } from '@tanstack/react-query';

import type { IPatientOrderSummary } from '@/services/data.service';
import { getPatientOrderSummary } from '@/services/data.service';

function useGetPatientOrderSummary() {
  return useQuery({
    queryKey: ['getPatientOrderSummary'],

    queryFn: async (): Promise<{ data: IPatientOrderSummary }> => {
      const { data } = await getPatientOrderSummary();
      return data;
    },

    select: (response) => response.data
  });
}

export default useGetPatientOrderSummary;
