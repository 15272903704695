import axios from 'axios';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';
import { HttpStatus } from '@montugroup/apis-styleguide';
import { useQuery } from '@tanstack/react-query';

const logger = new Logger('useGetUser');
const { url } = settings;

export interface UserDetails {
  firstName: string;
  gpCode?: string;
  mPatNumber?: string;
  roleId?: number;
}

export interface UserResponseData {
  first_name: string;
  gp_code?: string;
  patNumber?: string;
  role_id?: number;
}

const useGetUser = () =>
  useQuery({
    queryKey: ['get-user'],
    queryFn: async () => {
      const response = await axios.get<UserResponseData>(`${url}/user`);
      const { data, status } = response;

      if (status !== HttpStatus.OK) {
        logger.error(`error with status: ${status}`);
        throw new Error(`error with status: ${status}`);
      }

      return {
        firstName: data.first_name,
        gpCode: data.gp_code,
        mPatNumber: data.patNumber,
        roleId: data.role_id
      };
    }
  });

export default useGetUser;
