import type { PlaceDetails } from '@montugroup/design-system/LocationInput';

export type ValidationResult = {
  isValid: boolean;
  errorMessage?: string;
  errorMessageTitle?: string;
  isWarning?: boolean;
};

const statePostcodeRanges: { [key: string]: { min: number; max: number }[] } = {
  NSW: [
    { min: 1000, max: 1999 }, // po boxes
    { min: 2000, max: 2599 },
    { min: 2619, max: 2899 },
    { min: 2921, max: 2999 }
  ],
  ACT: [
    { min: 200, max: 299 }, // po boxes
    { min: 2600, max: 2618 },
    { min: 2900, max: 2920 }
  ],
  VIC: [
    { min: 3000, max: 3996 },
    { min: 8000, max: 8999 } // po boxes
  ],
  QLD: [
    { min: 4000, max: 4999 },
    { min: 9000, max: 9999 } // po boxes
  ],
  SA: [
    { min: 5000, max: 5799 },
    { min: 5800, max: 5999 } // po boxes
  ],
  WA: [
    { min: 6000, max: 6797 },
    { min: 6800, max: 6999 } // po boxes
  ],
  TAS: [
    { min: 7000, max: 7799 },
    { min: 7800, max: 7999 } // po boxes
  ],
  NT: [
    { min: 800, max: 899 },
    { min: 900, max: 999 } // po boxes
  ]
};

export default function useAddressValidation() {
  const validatePostcode = (postcode: string): ValidationResult => {
    const postcodePattern = /^\d{4}$/;
    if (!postcodePattern.test(postcode)) {
      return {
        isValid: false,
        errorMessage: 'Please enter a valid 4-digit postcode.'
      };
    }
    return { isValid: true };
  };

  const validateParcelLocker = (value: string): ValidationResult => {
    const parcelLockerPattern = /(\d{5}\s?\d{5})?\s?(parcel\s*?locker)\s?(\d{5}\s?\d{5})?/i;
    if (parcelLockerPattern.test(value)) {
      return {
        isValid: false,
        errorMessageTitle: 'Parcel Lockers are not allowed.',
        errorMessage: 'Please use a residential, business, or PO Box address.'
      };
    }
    return { isValid: true };
  };

  const validateStreetNumber = (value: PlaceDetails): ValidationResult => {
    const hasStreetNumber = value.address_components.some(
      (component) => component.types.includes('street_number') && component.long_name
    );

    if (!hasStreetNumber) {
      return {
        isValid: false,
        errorMessageTitle: 'Invalid address.',
        errorMessage: 'Please type street number.'
      };
    }

    return { isValid: true };
  };

  // Add other validation functions as needed
  // ensures a given postcode matches the selected state, and vice versa
  const validateStateBelongsToPostcode = (postcode: string, state: string): ValidationResult => {
    if (!state || !postcode) {
      return { isValid: true };
    }

    const postcodeNum = parseInt(postcode, 10);
    if (isNaN(postcodeNum)) {
      return { isValid: true };
    }

    const ranges = statePostcodeRanges[state];
    if (!ranges) {
      return { isValid: true };
    }

    const isValid = ranges.some((range) => postcodeNum >= range.min && postcodeNum <= range.max);
    return {
      isValid,
      errorMessage: isValid ? undefined : 'Postcode does not match the selected state.',
      isWarning: true
    };
  };

  return {
    validatePostcode,
    validateParcelLocker,
    validateStreetNumber,
    validateStateBelongsToPostcode
  };
}
