import { useQuery, useQueryClient } from '@tanstack/react-query';

import { FF_USE_CUSTOMER_HOOK } from '@/constants/featureFlags';
import USER_ROLES from '@/constants/userRoles';
import type { IUserProfileResponse } from '@/types/user.types';
import useFeatureFlags from '../useFeatureFlags';
import { QUERY_KEY_USER_PROFILE_DATA } from './useGetUserProfile';
import useUser from './useUser';

export type Customer = {
  id: number;
  code: string;
  isDischarged: boolean;

  shippingAddress: {
    address: string;
    phone: string;
    zipCode: string;
    city: string;
    countryId: number;
    stateId: number;
  };

  /**
   * @deprecated The following properties are only used for braze ATM, we may remove in the future. If these properties are used outside of braze
   * we should move them out of this object on the root of this Customer type
   */
  braze: {
    createdBy?: number;
    dateOfBirth: string | null;
    isNewPatient: boolean;
    patientPharmacyName: string | null;
    patientPharmacyAddress: string | null;
    // TODO: Move pharmacyId into useUser patient connection when useUser BE Endpoint has been created
    pharmacyId?: number | null;
    priority: boolean;
  };

  // TODO: Add more to the Customer type where needed, should be the source of truth for customer data
};

const useCustomer = () => {
  const queryClient = useQueryClient();
  const { data: user } = useUser();
  const { flags } = useFeatureFlags();

  return useQuery<Customer | null>({
    queryKey: ['customer'],
    enabled: () => flags[FF_USE_CUSTOMER_HOOK] && user?.roleId === USER_ROLES.patient,
    queryFn: async () => {
      const userProfileData = (await queryClient.fetchQuery({
        queryKey: [QUERY_KEY_USER_PROFILE_DATA],
        // HACK: A small staleTime is only required to share the userProfileData queryCache data from useUser to useCustomer before they become dependant on seperate API requests
        staleTime: 1000
      })) as IUserProfileResponse;

      return {
        id: userProfileData.patient.id,
        code: userProfileData.patient.patient_code,
        isDischarged: !!userProfileData.patient.is_discharge,

        shippingAddress: {
          address: userProfileData.user.address,
          phone: userProfileData.user.phone,
          zipCode: userProfileData.user.zip_code,
          city: userProfileData.user.city,
          countryId: userProfileData.user.country_id,
          stateId: userProfileData.user.state_id
        },

        braze: {
          createdBy: userProfileData.patient.created_by,
          dateOfBirth: userProfileData.patient.dob,
          isNewPatient: userProfileData.patient.is_new_patient,
          patientPharmacyName: userProfileData.patient.patient_pharmacy_name,
          patientPharmacyAddress: userProfileData.patient.patient_pharmacy_address,
          pharmacyId: userProfileData.patient.pharmacy_id,
          priority: userProfileData.patient.priority
        }
      };
    }
  });
};

export default useCustomer;
