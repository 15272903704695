import InfoIcon from '@mui/icons-material/Info';
import { Box, Chip, IconButton, Stack } from '@mui/material';
import moment from 'moment';

import { OrderStatus } from '@/components/order/order.types';
import settings from '@/constants/constants';
import { useUnableToDispenseModal } from '@/context/orders/OrdersModals';

type OrderStatusTableCellProps = {
  orderId: number;
  orderStatusId: number;
  orderStatus: string;
  modifiedDate: string;
};

function OrderStatusTableCell({ orderId, orderStatusId, orderStatus, modifiedDate }: OrderStatusTableCellProps) {
  const { showUnableToDispenseModal } = useUnableToDispenseModal();

  const handleUnableToDispenseInfoClick = () => {
    showUnableToDispenseModal(orderId);
  };

  const showDate = orderStatus === OrderStatus.Cancelled || orderStatus === OrderStatus.Refunded;

  if (orderStatusId === settings.orderStatus.UNABLE_TO_DISPENSE) {
    return (
      <>
        <IconButton onClick={handleUnableToDispenseInfoClick}>
          <InfoIcon />
        </IconButton>
        Unable to dispense
      </>
    );
  }

  return (
    <Stack gap={1}>
      {orderStatus === OrderStatus.Refunded && (
        <>
          {OrderStatus.Cancelled}
          <Chip color="primary" size="small" label={orderStatus} />
        </>
      )}
      {orderStatus !== OrderStatus.Refunded && orderStatus}
      {showDate && <Box>{moment(modifiedDate).format('DD-MMM-YYYY')}</Box>}
    </Stack>
  );
}

export default OrderStatusTableCell;
