import { useQueryClient } from '@tanstack/react-query';

import type { PatientRescriptRequestPayload } from '@/services/patient.service';
import { PatientService } from '@/services/patient.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useCreatePatientRescriptRequest');
const ERROR_ON_CREATE_PATIENT_RESCRIPT_REQUEST = 'Failed to create patient rescript request';

export default function useCreatePatientRescriptRequest() {
  const queryClient = useQueryClient();

  return (patientId: number, data: PatientRescriptRequestPayload) =>
    queryClient.fetchQuery({
      queryKey: ['patientRescriptRequest', patientId],
      queryFn: async () => {
        try {
          return await PatientService.createPatientRescriptRequest(patientId, data);
        } catch (error) {
          logger.error(ERROR_ON_CREATE_PATIENT_RESCRIPT_REQUEST, error);
          throw new Error(ERROR_ON_CREATE_PATIENT_RESCRIPT_REQUEST);
        }
      }
    });
}
