import { apiPatientPortalContract } from '@montugroup/pms-api-contracts';

import { createTsRestClient } from './ts-rest.client';

let cachedClient: ReturnType<typeof createTsRestClient<typeof apiPatientPortalContract>> | null = null;

export const getTsPatientPortalClient = () => {
  if (!cachedClient) {
    cachedClient = createTsRestClient(apiPatientPortalContract);
  }

  return cachedClient;
};

export default null;
