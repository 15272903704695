import {
  MgCannabis,
  MgCapsule,
  MgCartridge,
  MgDevice,
  MgInhaler,
  MgOralLiquid,
  MgPastille,
  MgResin,
  MgSpray,
  MgTablets,
  MgTopical,
  MgWafer
} from '@montugroup/icons';
import type { SvgIcon } from '@mui/material';

import type { ProductFormulation } from '@/types';
import { ProductFormulations } from '@/types';

interface FormulationIconDefn {
  icon: typeof SvgIcon;
  color: string;
  displayName: string;
}

const ProductFormulationsMap: Record<ProductFormulation, FormulationIconDefn> = {
  [ProductFormulations.HERB_DRIED]: {
    icon: MgCannabis,
    color: '#4F6B73',
    displayName: 'Flower'
  },
  [ProductFormulations.INHALATION]: {
    icon: MgCartridge,
    color: '#23AFFF',
    displayName: 'Inhalation'
  },
  [ProductFormulations.CAPSULE]: {
    icon: MgCapsule,
    color: '#EC407A',
    displayName: 'Capsule'
  },
  [ProductFormulations.ORAL_LIQUID]: {
    icon: MgOralLiquid,
    color: '#7986CB',
    displayName: 'Oral liquid'
  },
  [ProductFormulations.PASTILLE]: {
    icon: MgPastille,
    color: '#FFA726',
    displayName: 'Pastille'
  },
  [ProductFormulations.TABLET_CHEWABLE]: {
    icon: MgTablets,
    color: '#ba2cbf',
    displayName: 'Chewable tablet'
  },
  [ProductFormulations.DEVICE]: {
    icon: MgDevice,
    color: '#0074C4',
    displayName: 'Device'
  },
  [ProductFormulations.SPRAY]: {
    icon: MgSpray,
    color: '#406ac9', // todo real colour
    displayName: 'Spray'
  },
  [ProductFormulations.TOPICAL]: {
    icon: MgTopical,
    color: '#9E9D24', // todo real colour
    displayName: 'Topical'
  },
  [ProductFormulations.WAFER]: {
    icon: MgWafer,
    color: '#EF6C00', // todo real colour
    displayName: 'Wafer'
  },
  [ProductFormulations.CONCENTRATED_EXTRACT]: {
    icon: MgResin,
    color: '#4A148C',
    displayName: 'Concentrated Extract'
  },
  [ProductFormulations.INHALANT_PRESSURIZED]: {
    icon: MgInhaler,
    color: '#00897B',
    displayName: 'Inhalant (pressure)'
  }
} as const;

export default ProductFormulationsMap;
