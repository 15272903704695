import type { ConsultationResponseDto } from '@montugroup/pms-api-contracts';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined';
import ScheduleIcon from '@mui/icons-material/Schedule';
import VideoChatOutlinedIcon from '@mui/icons-material/VideoChatOutlined';
import { Button, Link, Stack, styled, SvgIcon, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import { useEffect, useState } from 'react';

import useCalendarBooking from '@/hooks/consultations/useCalcomBooking';

interface ConsultationTileProps {
  consultation: ConsultationResponseDto;
  refetchConsultations: () => void;
}

export const ConsultationContainer = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  borderRadius: theme.spacing(2),
  border: '1px solid rgba(0, 0, 0, 0.20)',
  backgroundColor: theme.palette.common.white,
  width: theme.spacing(111),
  gap: theme.spacing(1),
  [theme.breakpoints.down('md')]: {
    width: '100%'
  }
}));

export const DetailText = styled(Typography)(({ theme }) => ({
  lineHeight: theme.spacing(6)
}));

const ManageButton = styled('button')(({ theme }) => ({
  color: theme.palette.info.main,
  fontFamily: theme.typography.body1.fontFamily,
  textDecoration: 'underline ',
  textAlign: 'left',
  cursor: 'pointer',
  border: 'none',
  background: 'none',
  '&:hover': {
    color: theme.palette.info.main
  },
  '& span': {
    paddingLeft: theme.spacing(1)
  },
  '&:focus': {
    outline: 'none'
  },
  '&:focus-visible': {
    outline: 'auto'
  }
}));

const StartButtonLink = styled(Link)(() => ({
  textDecoration: 'none',
  width: '100%'
}));

const StartButton = styled(Button)(({ theme }) => ({
  width: '100%',
  height: theme.spacing(10)
}));

const AccessMessage = styled(Typography)(({ theme }) => ({
  fontSize: '0.875rem',
  padding: theme.spacing(2, 0),
  alignSelf: 'flex-start'
}));

const BoldText = styled('span')(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  letterSpacing: 0
}));

export const StyledIcon = styled(SvgIcon)(({ theme }) => ({
  width: theme.spacing(5),
  height: theme.spacing(5),
  color: theme.palette.info.main,
  marginRight: theme.spacing(1)
}));

function UpcomingConsultationCard({ consultation, refetchConsultations }: ConsultationTileProps) {
  const startTime = DateTime.fromISO(consultation.startTime);
  const endTime = DateTime.fromISO(consultation.endTime);
  const [isStartButtonDisabled, setIsStartButtonDisabled] = useState(
    startTime.diff(DateTime.now(), 'minutes').minutes > 10
  );

  const { origin } = useCalendarBooking({
    handleBookingSuccess: refetchConsultations,
    handleRescheduleSuccess: refetchConsultations,
    handleCancelSuccess: refetchConsultations
  });

  useEffect(() => {
    const intervalId = setInterval(() => {
      setIsStartButtonDisabled(startTime.diff(DateTime.now(), 'minutes').minutes > 10);
    }, 60000);

    return () => clearInterval(intervalId);
  }, [startTime]);

  const formattedTime = `${startTime.toFormat('hh:mm a')} - ${endTime.toFormat('hh:mm a')} (AEST)`;

  return (
    <ConsultationContainer direction="column" spacing={3} alignItems="flex-start">
      <Typography variant="subtitle2" sx={{ lineHeight: '2.5rem' }}>
        {consultation.type === 'Initial' ? 'Medical' : 'Follow up'} doctor consult
      </Typography>
      <Stack direction="row" alignItems="center" gap={1} width="100%">
        <StyledIcon>
          <AccountCircleIcon />
        </StyledIcon>
        {consultation?.practitioner?.name && <DetailText variant="body1">{consultation.practitioner.name}</DetailText>}
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} width="100%" data-chromatic="ignore">
        <StyledIcon>
          <CalendarTodayOutlinedIcon />
        </StyledIcon>
        <DetailText variant="body1">{startTime.toFormat('EEEE, dd LLLL')}</DetailText>
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} width="100%" data-chromatic="ignore">
        <StyledIcon>
          <ScheduleIcon />
        </StyledIcon>
        <DetailText variant="body1">{formattedTime}</DetailText>
      </Stack>
      <ManageButton
        data-cal-link={`booking/${consultation.calcomBookingUuid}`}
        data-cal-config={{}}
        data-cal-origin={origin}
      >
        <StyledIcon
          sx={{
            marginRight: (theme) => theme.spacing(2)
          }}
        >
          <VideoChatOutlinedIcon />
        </StyledIcon>
        Manage, reschedule or cancel your booking
      </ManageButton>
      <DetailText variant="body1">
        <BoldText>Consultation I.D:</BoldText> {consultation.code}
      </DetailText>
      {consultation?.practitioner?.coviuUrl && (
        <Stack direction="column" alignItems="center" alignSelf="stretch">
          {isStartButtonDisabled ? (
            <StartButton variant="contained" color="primary" disabled>
              Start consultation
            </StartButton>
          ) : (
            <StartButtonLink href={consultation.practitioner.coviuUrl} target="_blank">
              <StartButton variant="contained" color="primary">
                Start consultation
              </StartButton>
            </StartButtonLink>
          )}
          {isStartButtonDisabled && (
            <AccessMessage>
              You will have access to your consultation <BoldText>10 minutes</BoldText> prior to the start time
            </AccessMessage>
          )}
        </Stack>
      )}
    </ConsultationContainer>
  );
}

export default UpcomingConsultationCard;
