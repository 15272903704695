import type { SortingState } from '@tanstack/react-table';
import { useCallback, useState } from 'react';

import type { TableSortOrder } from '@/types';

type UseTableSortingOrderArgs = {
  initialSortingOrder?: TableSortOrder;
  sortFieldOverrides?: {
    [key: string]: string;
  };
};

function useTableSortingOrder(options: UseTableSortingOrderArgs = {}) {
  const [sortingOrder, setSortingOrder] = useState<TableSortOrder>(options.initialSortingOrder || {});

  const handleSortingOrderChange = useCallback(
    (state: SortingState) => {
      if (state.length > 0) {
        const { id, desc } = state[0];
        const overrideField = (options.sortFieldOverrides || {})[id as keyof typeof options.sortFieldOverrides] || id;
        if (sortingOrder.name !== overrideField || sortingOrder.reverse !== desc) {
          setSortingOrder({ name: overrideField || id, reverse: desc });
        }
      }
    },
    [options, sortingOrder.name, sortingOrder.reverse]
  );

  return { sortingOrder, handleSortingOrderChange };
}

export default useTableSortingOrder;
