import type { Dispatch, ReactNode } from 'react';
import { createContext, useContext, useMemo, useReducer } from 'react';

// eslint-disable-next-line no-shadow
export enum PatientReferralActionType {
  TOGGLE_OPEN = 'TOGGLE_OPEN',
  SET_PROMO_CODE = 'SET_PROMO_CODE'
}

type Action =
  | { type: PatientReferralActionType.TOGGLE_OPEN }
  | { type: PatientReferralActionType.SET_PROMO_CODE; payload: string };

interface IPatientReferralState {
  open: boolean;
  promoCode: string;
}

type PatientReferralProviderProps = {
  children: ReactNode;
};

export type PatientReferralContextType = IPatientReferralState & { dispatch: Dispatch<Action> };

export const PatientReferralContext = createContext<PatientReferralContextType | null>(null);

const initialState: IPatientReferralState = {
  open: false,
  promoCode: ''
};

function usePatientReferral() {
  const context = useContext(PatientReferralContext);
  if (!context) {
    throw new Error('PatientReferral must be wrapped inside PatientReferralProvider');
  }
  return context;
}

export function patientReferralReducer(prevState: IPatientReferralState, action: Action): IPatientReferralState {
  switch (action.type) {
    case PatientReferralActionType.TOGGLE_OPEN: {
      return {
        ...prevState,
        open: !prevState.open
      };
    }
    case PatientReferralActionType.SET_PROMO_CODE: {
      return {
        ...prevState,
        promoCode: action.payload
      };
    }
    default: {
      throw new Error(`Unhandled action type: ${action}`);
    }
  }
}

function PatientReferralProvider({ children }: PatientReferralProviderProps) {
  const [state, dispatch] = useReducer(patientReferralReducer, initialState);
  const value = useMemo(() => ({ ...state, dispatch }), [state, dispatch]);

  return <PatientReferralContext.Provider value={value}>{children}</PatientReferralContext.Provider>;
}

export { PatientReferralProvider, usePatientReferral };
