import moment from 'moment';
import React from 'react';
import Select, { components } from 'react-select';
import FormInput from '../common/FormInput';
import { OrderService } from '../../services/order.service';
import settings from '../../constants/constants';
import USER_ROLES from '@/constants/userRoles';

const Option = (props) => {
  const optionStyle = {
    ...(!props.data.isDisabled && props.data.error && { color: 'black' }),
  };
  const subLabelStyle = {
    fontSize: 12,
    marginTop: '5px',
    ...((props.data.isDisabled || props.data.error) && { color: 'red' }),
  };
  return (
    <components.Option {...props}>
      <div style={optionStyle}>
        <div>{props.data.label}</div>
        <div style={subLabelStyle}>{props.data.subLabel}</div>
      </div>
    </components.Option>
  );
};

const handleHeaderClick = (id) => {
  const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
  const classes = node.classList;
  if (classes.contains('select-field-grroup-collapsed')) {
    node.classList.remove('select-field-grroup-collapsed');
  } else {
    node.classList.add('select-field-grroup-collapsed');
  }
};

const handleHeaderLoad = (id) => {
  const node = document.querySelector(`#${id}`).parentElement.nextElementSibling;
  const classes = node.classList;
  if (!classes.contains('select-field-grroup-collapsed') && !classes.contains('option-loaded')) {
    node.classList.add('select-field-grroup-collapsed');
    node.classList.add('option-loaded');
  }
};

const groupStyles = {
  color: 'gray',
  fontSize: '18px',
  display: 'flex',
};

const CustomGroupHeading = (props) => {
  React.useEffect(() => {
    handleHeaderLoad(props.id);
  });
  return (
    <div className="group-heading-wrapper" style={groupStyles} onClick={() => handleHeaderClick(props.id)}>
      <components.GroupHeading {...props} />
    </div>
  );
};

const ProductRows = (props) => {
  const { productList } = props; //Not an exhaustive list of props
  const initState = [
    {
      id: 1,
      data: {
        product_id: null,
        quantity: null,
        repeats: null,
        interval: null,
        dispensed_date: null,
        intervalEndDate: null,
        doctor_name: null,
        prescription_code: null,
      },
    },
  ];
  const [selectedProducts, setSelectedProducts] = React.useState(initState);
  const [inventory, setInventory] = React.useState([]);

  const { validator } = props;

  React.useEffect(() => {
    if (props.selectedProducts && props.selectedProducts.length > 0) {
      setSelectedProducts(props.selectedProducts);
      let errors = {};
      props.selectedProducts.forEach((row) => {
        errors[`product${row.id}`] = validator.message(`product${row.id}`, row.data.product_id, 'required');
        errors[`quantity${row.id}`] = validator.message(`quantity${row.id}`, row.data.quantity, 'required');
      });
      props.setErrors({ ...props.errors, ...errors });
    }
  }, [props.selectedProducts]);

  React.useEffect(() => {
    async function fetchInventory() {
      if (!props.pharmacyId) {
        return;
      }
      const inventory = await OrderService.getProductsFromInventory(props.pharmacyId);
      if (Number(inventory.status) === 200) {
        setInventory(inventory.data);
      }
    }

    fetchInventory();
  }, [props.pharmacyId]);

  const getQuantity = (baseQuantity) => {
    const quantites = [...Array(baseQuantity).keys()].map((x) => ({ label: x + 1, value: x + 1 }));
    return quantites;
  };

  function getQuantityValue(selectedProduct) {
    if (!selectedProduct.product_id) {
      return '';
    }
    const product = productList.find((product) => product.id === selectedProduct.product_id);
    if (!product.remaining_units) {
      return { label: selectedProduct.quantity, value: selectedProduct.quantity };
    }
    const quantity = selectedProduct.quantity
      ? Math.min(selectedProduct.quantity, product.remaining_units)
      : Math.min(product.quantity_base_order, product.remaining_units);
    return { label: quantity, value: quantity };
  }

  function showRepeatsAndInterval(selectedProduct) {
    const product = productList.find((product) => product.id === selectedProduct.data.product_id);
    return Number(product && product.formulation_id) !== 5;
  }

  function getProductOptions() {
    const products = productList
      .filter((x) => !selectedProducts.map((prd) => prd.data.product_id).includes(x.id))
      .map((x) => {
        const errorMessage = getErrorMessage(x, props.pms_client, inventory).trim();
        const isDisabled = !!(
          errorMessage ||
          (props.pms_client && x.base_quantity == 0) ||
          x.is_expired ||
          x.reasoning_toggle
        );
        return {
          label: x.name,
          value: x.id,
          subLabel: errorMessage,
          error: checkProductIsInStock(x),
          isDisabled: isDisabled,
          supplier_name: x.supplier_name,
          order: x.order,
        };
      })
      .reduce((group, product) => {
        const { supplier_name } = product;
        group[supplier_name] = group[supplier_name] ?? [];
        group[supplier_name].push(product);
        return group;
      }, {});

    const groups = Object.keys(products).map(function (key) {
      const options = products[key];
      options.sort((a, b) => (a.label > b.label ? 1 : b.label > a.label ? -1 : 0));
      return { label: key, options: options };
    });
    groups.sort((a, b) => a.options[0].order - b.options[0].order);
    const others = groups.find((a) => a.label === 'Others');
    if (others) {
      const newGroup = groups.filter((itm) => itm.label !== 'Others');
      newGroup.push(others);
      return newGroup;
    } else {
      return groups;
    }
  }

  function checkProductIsInStock(product, status = false) {
    const inventoryProduct = inventory.find((item) => Number(item.productId) === Number(product.id));
    if (!inventoryProduct) {
      if (product.is_out_of_stock) {
        return !!product.is_out_of_stock;
      }
      if (product.reasoning_toggle) {
        return !!product.reasoning_toggle;
      }
    }
    if (status) {
      return !!(inventoryProduct && inventoryProduct.outOfStock && inventoryProduct.outOfStock);
    }
    return !!(inventoryProduct && inventoryProduct.outOfStock && product.is_out_of_stock);
  }

  function getDispensedDate(orderStatus, data) {
    if (orderStatus === 5) {
      return data.shipped_date;
    }

    if (orderStatus === 15) {
      return data.dispensed_date;
    }

    return '-';
  }

  function getStockStatus(productID) {
    if (props.orderStatus === settings.orderStatus.PHARMACY_DISPENSED) {
      return 'In-Stock';
    }
    const product = productList.find((item) => Number(item.id) === Number(productID));
    if (product) {
      const stockData = props.inventoryStock.find((prd) => Number(prd.id) === Number(productID));
      if (stockData?.stock) {
        return stockData.stock;
      }
      const data = checkProductIsInStock(product, true);
      return data ? 'Out of Stock' : 'In-Stock';
    }
    return '-';
  }

  const getLastDispensedPharmacy = (pharmacyName, orderStatus) => {
    if ([5, 15].includes(orderStatus)) {
      return pharmacyName;
    }
    return '-';
  };

  const getClass = (product = false) => {
    const pmsPatient = props.pms_client;
    const doctor = props.user.role_id === USER_ROLES.doctor;
    const patientIdExist = props.patientID;

    if (product) {
      if (pmsPatient) {
        return 'col-md-2';
      }
      if (doctor || patientIdExist) {
        return 'col-md-6';
      } else {
        return 'col-md-4';
      }
    } else {
      if (pmsPatient) {
        return 'col-md-1';
      }
      if (doctor || patientIdExist) {
        return 'col-md-5';
      } else {
        return 'col-md-3';
      }
    }
  };

  const getIntervalEndDate = (row) => {
    if (props.orderStatus === settings.orderStatus.INTERVAL_TIME && row.data.dispensed_date !== null)
      return row.data.intervalEndDate;
    else return '-';
  };

  return (
    <React.Fragment>
      <label className="pl-1">Product Details</label>
      <div className="row pl-3">
        <div className={`p-1 ${getClass(true)} `}>
          <label className="mr-2">Product</label>
        </div>
        <div className={`p-1 ${getClass()} `}>
          <label className="mr-2">Quantity</label>
        </div>
        {props.pms_client && (
          <React.Fragment>
            <div className="p-1 col-md-1">
              <label className="mr-2">Repeats(left)</label>
            </div>
            <div className="p-1 col-md-1">
              <label className="mr-2">Interval</label>
            </div>
            {props.user.role_id !== USER_ROLES.doctor && (
              <>
                <div className="p-1 col-md-1">
                  <label className="mr-2">Prescription</label>
                </div>
                <div className="p-1 col-md-1">
                  <label className="mr-2">Doctor</label>
                </div>
                <div className="p-1 col-md-1">
                  <label className="mr-2">Interval End Date</label>
                </div>
              </>
            )}
          </React.Fragment>
        )}
        {props.user.role_id !== USER_ROLES.doctor && !props.patientID && (
          <React.Fragment>
            {props.isHomePharmacy && (
              <div className="p-1 col-md-1">
                <label className="mr-2">Inventory Status</label>
              </div>
            )}
            <div className="p-1 col-md-1">
              <label className="mr-2">Dispensed Date</label>
            </div>
            <div className={`p-1 col-md-${props.pms_client ? '1' : '2'}`}>
              <label className="mr-2">Last Dispensed Location</label>
            </div>
          </React.Fragment>
        )}
      </div>
      {selectedProducts.map((row) => (
        <div key={row.id}>
          <div className="row pl-3">
            <div className={`form-group px-1 ${getClass(true)} `}>
              <Select
                name={`product${row.id}`}
                value={row.data.product_id ? { label: row.data.product_name, value: row.data.product_id } : ''}
                className={`${props.errors[`product${row.id}`] ? 'rounded dropdown-border' : ''} `}
                onChange={(e) => props.handleProduct(e, row.id)}
                components={{ Option: Option, GroupHeading: CustomGroupHeading }}
                isSearchable={true}
                placeholder="Select Product"
                options={getProductOptions()}
                isDisabled={props.isAdmin && !props.patientID && props.showView ? true : false}
              />
              {props.errors[`product${row.id}`]}
            </div>

            <div className={`form-group px-1 ${getClass()}`}>
              <Select
                name={`quantity${row.id}`}
                value={getQuantityValue(row.data)}
                className={`${props.errors[`quantity${row.id}`] ? 'rounded dropdown-border' : ''} `}
                options={getQuantity(row.data.base_quantity)}
                onChange={(e) => props.handleQuantity(e, row.id)}
                placeholder="Quantity"
                isSearchable={true}
                isDisabled={props.isAdmin && !props.patientID && props.showView ? true : false}
              />
              {props.errors[`quantity${row.id}`]}
            </div>
            {props.pms_client && (
              <>
                <div className={props.showView ? 'form-group px-2 col-md-1' : 'form-group px-1 col-md-1'}>
                  {showRepeatsAndInterval(row) ? (
                    <FormInput
                      type="text"
                      noLabel={true}
                      error={row.data.repeats <= 0}
                      placeholder="Repeats"
                      name="repeats"
                      readOnly={true}
                      value={Math.floor(row.data.repeats) || ''}
                      showViewMode={true}
                    />
                  ) : (
                    <div className="mb-5" />
                  )}
                </div>
                <div className="form-group px-1 col-md-1">
                  {showRepeatsAndInterval(row) ? (
                    <FormInput
                      type="text"
                      noLabel={true}
                      name="interval"
                      placeholder="Interval"
                      readOnly={true}
                      showViewMode={true}
                      value={row.data.interval || ''}
                    />
                  ) : (
                    <div className="mb-5" />
                  )}
                </div>
                <div className="form-group px-1 col-md-1">
                  {showRepeatsAndInterval(row) ? (
                    <FormInput
                      type="text"
                      noLabel={true}
                      name="prescription"
                      placeholder="Prescription"
                      readOnly={true}
                      showViewMode={true}
                      onClick={() =>
                        props.getPrescriptionUrl(`downloads/prescriptions/${row.data.prescription_code}.pdf`)
                      }
                      style={{ cursor: 'pointer' }}
                      value={row.data.prescription_code || ''}
                    />
                  ) : (
                    <div className="mb-5" />
                  )}
                </div>
                <div className="form-group px-1 col-md-1">
                  {showRepeatsAndInterval(row) ? (
                    <FormInput
                      type="text"
                      noLabel={true}
                      name="doctor"
                      placeholder="Doctor"
                      readOnly={true}
                      showViewMode={true}
                      value={row.data.doctor_name || ''}
                    />
                  ) : (
                    <div className="mb-5" />
                  )}
                </div>
                <div className="form-group px-1 col-md-1">
                  {showRepeatsAndInterval(row) ? (
                    <FormInput
                      type="text"
                      noLabel={true}
                      name="interval_end_date"
                      placeholder="-"
                      readOnly={true}
                      showViewMode={true}
                      value={getIntervalEndDate(row)}
                    />
                  ) : (
                    <div className="mb-5" />
                  )}
                </div>
              </>
            )}
            {props.user.role_id !== USER_ROLES.doctor && !props.patientID && (
              <>
                {props.isHomePharmacy && (
                  <div className="form-group px-1 col-md-1">
                    <FormInput
                      type="text"
                      noLabel={true}
                      name="stock_status"
                      placeholder="-"
                      readOnly={true}
                      showViewMode={true}
                      value={getStockStatus(row.data.product_id) || ''}
                    />
                  </div>
                )}
                <div className="form-group px-1 col-md-1">
                  <FormInput
                    type="text"
                    noLabel={true}
                    name="dispensed_date"
                    placeholder="-"
                    readOnly={true}
                    showViewMode={true}
                    value={getDispensedDate(props.orderStatus, row.data)}
                  />
                </div>
                <div className={`form-group px-1 col-md-${props.pms_client ? '1' : '2'}`}>
                  <FormInput
                    type="text"
                    noLabel={true}
                    name="dispensed_location"
                    placeholder="Dispensed Location"
                    readOnly={true}
                    showViewMode={true}
                    value={getLastDispensedPharmacy(props.pharmacyName, props.orderStatus)}
                  />
                </div>
              </>
            )}
            {props.isAdmin && !props.patientID && props.showView
              ? ''
              : !props.campaign && (
                  <div className="form-group col-md-1">
                    <button
                      type="button"
                      className="btn btn-primary"
                      style={{ background: '#578297' }}
                      disabled={selectedProducts.length === 1}
                      value={row.id}
                      onClick={() => props.deleteRow(row.id)}
                    >
                      x
                    </button>
                  </div>
                )}
          </div>
        </div>
      ))}
      {props.isAdmin && !props.patientID && props.showView
        ? ''
        : !props.campaign && (
            <div className="row">
              <button
                type="button"
                className="btn btn-primary btn-sm ml-4"
                disabled={selectedProducts.length === 10}
                onClick={props.addRow}
              >
                + Add Product
              </button>
            </div>
          )}
      {Array.isArray(props.skippedProducts) && props.skippedProducts.length > 0 && (
        <>
          <label>Skipped Product Details</label>
          <div className="row pl-3">
            <div className={`p-1 ${getClass(true)} `}>
              <label className="mr-2">Product</label>
            </div>
            <div className={`p-1 ${getClass()} `}>
              <label className="mr-2">Reason</label>
            </div>
          </div>
          {props.skippedProducts.map(({ id, name, reason }) => (
            <div key={id}>
              <div className="row pl-3">
                <div className={`form-group px-1 ${getClass(true)} `}>{name}</div>
                <div className={`form-group px-1 ${getClass()} `}>{reason}</div>
              </div>
            </div>
          ))}
        </>
      )}
    </React.Fragment>
  );
};

/**
 * @returns {string} The error message or an empty string if no errors are found.
 * @param {Object} product
 * @param {unknown} pms_client
 * @param {Array<unknown>} [inventory=[]]
 */
export function getErrorMessage(product, pms_client, inventory = []) {
  if (pms_client) {
    if (product.is_expired) {
      return 'Prescription expired - need new prescription';
    }
    if (Number(product.remaining_units) <= 0 && product.formulation_id !== 5) {
      return 'Out of repeats - need new prescription';
    }
  }
  const inventoryProduct = inventory.find((item) => Number(item.productId) === Number(product.id));
  if (product.isProductLocked) {
    return `Interval Ends: ${moment(new Date(product.intervalEndDate)).format('DD-MM-YYYY')}`;
  }
  if (!inventoryProduct) {
    if (product.is_out_of_stock) {
      return `Out of Stock - Expected Return ${moment(new Date(product.stock_return_date)).format('DD-MMM-YYYY')}`;
    }
    if (product.reasoning_toggle) {
      return `Out of Stock - ${product.reasoning}`;
    }
  }
  if (inventoryProduct && inventoryProduct.outOfStock && product.is_out_of_stock) {
    return `Out of Stock - Expected Return ${moment(product.stock_return_date).format('DD-MMM-YYYY')}`;
  }
  if (inventoryProduct && product.reasoning_toggle) {
    return `Out of Stock - ${product.reasoning}`;
  }
  return '';
}

export default ProductRows;
