import { BrandThemeProvider } from '@montugroup/themes';
import { Box, styled, Typography } from '@mui/material';

import useShippingPrice from '@/hooks/useShippingPrice';
import type { ProductItemProps } from '@/types/productItem.types';
import pxToRemConverter from '@/utils/pxToRemConverter';

import ProductSection from './ProductSection';
import TopBanner from './TopBanner';

const CBDDominantProducts: ProductItemProps[] = [
  {
    imgUrl: 'https://circuit.montu.com.au/assets/images/products/Cannabis+Oil+Mockups_Pure.webp',
    title: 'Circle | CBD PURE 100',
    dose: { l1: 'CBD 100 mg/mL', l2: 'THC 0 mg/mL' },
    quantityDesc: { l1: 'Cannabis Oil', l2: '10 mL bottle' },
    tgaName: 'Circle CBD PURE 100 Oil Drops 10mL',
    titration: 'Initial Dose: 0.2 ml/day | Maximal Dose: 3.0 ml/day',
    treatmentArea: 'Chronic Pain, Anxiety, Epilepsy, PTSD',
    price: { cost: 99, perMg: '(10 cents per mg)' },
    cmiSheet: 'https://www.montu.com.au/cmi-cbd-pure-100'
  },
  {
    imgUrl: 'https://circuit.montu.com.au/assets/images/products/CBD+-+Cannabis+Oil.webp',
    title: 'Circle | CBD 20',
    dose: { l1: 'CBD 20 mg/mL', l2: 'THC <3 mg/mL' },
    quantityDesc: { l1: 'Cannabis Oil', l2: '30 mL bottle' },
    tgaName: 'Circle CBD 20 Oil Drops 30mL',
    titration: 'Initial Dose: 0.4 ml/day | Maximal Dose: 4.0 ml/day',
    treatmentArea: 'Chronic Pain, Anxiety, Epilepsy, PTSD',
    price: { cost: 119, perMg: '(17 cents per mg)' },
    cmiSheet: 'https://www.montu.com.au/cmi-cbd-20'
  }
];

const BalancedProducts: ProductItemProps[] = [
  {
    imgUrl: 'https://circuit.montu.com.au/assets/images/products/Balance+2-1+Cannabis+Oil+New.webp',
    title: 'Circle | BALANCE 2:1',
    dose: { l1: 'CBD 20 mg/mL', l2: 'THC 12 mg/mL' },
    quantityDesc: { l1: 'Cannabis Oil', l2: '30 mL bottle' },
    tgaName: 'Circle BALANCE CBD 20 : THC 12 Oil Drops 30mL',
    titration: 'Initial Dose: 0.2 ml/day | Maximal Dose: 3.0 ml/day',
    treatmentArea: 'Cancer Pain, PTSD, Neuropathic Pain, Anorexia, Multiple Sclerosis, IBS',
    price: { cost: 199, perMg: '(21 cents per mg)' },
    cmiSheet: 'https://www.montu.com.au/cmi-balance-2-1'
  },
  {
    imgUrl: 'https://circuit.montu.com.au/assets/images/products/Balance+1-1+Cannabis+Oil.webp',
    title: 'Circle | BALANCE 1:1',
    dose: { l1: 'CBD 10 mg/mL', l2: 'THC 10 mg/mL' },
    quantityDesc: { l1: 'Cannabis Oil', l2: '30 mL bottle' },
    tgaName: 'Circle BALANCE 1:1 (CBD10 : THC10) Cannabis Oil',
    titration: 'Initial Dose: 0.2 ml/day | Maximal Dose: 3.0 ml/day',
    treatmentArea: 'Palliative Care, Cancer Pain, PTSD, Neuropathic Pain, Anorexia, Multiple Sclerosis, IBS',
    price: { cost: 129, perMg: '(22 cents per mg)' },
    cmiSheet: 'https://www.montu.com.au/cmi-balance-1-1'
  }
];

const THCDominantProducts: ProductItemProps[] = [
  {
    imgUrl: 'https://circuit.montu.com.au/assets/images/products/THC+-+Cannabis+Oil.webp',
    title: 'Circle | THC 20',
    dose: { l1: 'THC 20 mg/mL', l2: 'CBD <3 mg/mL' },
    quantityDesc: { l1: 'Cannabis Oil', l2: '30 mL bottle' },
    tgaName: 'Circle THC 20 Oil Drops 30mL',
    titration: 'Initial Dose: 0.2 ml/day | Maximal Dose: 2.0 ml/day',
    treatmentArea: 'Insomnia, CINV (Nausea), Multiple Sclerosis',
    price: { cost: 149, perMg: '(22 cents per mg)' },
    cmiSheet: 'https://www.montu.com.au/cmi-thc-20'
  },
  {
    imgUrl: 'https://circuit.montu.com.au/assets/images/products/Circle+THC+18+Flower.webp',
    title: 'Circle | THC 18%',
    dose: { l1: 'THC 18%', l2: 'CBD <1%' },
    quantityDesc: { l1: 'Dried Cannabis', l2: '10 gram jar' },
    tgaName: 'Circle THC18% Dried Flower 10g',
    titration: 'Initial Dose: 0.1 g/day | Maximal Dose: 3.0 g/day',
    treatmentArea: 'Insomnia, CINV (Nausea), Multiple Sclerosis',
    price: { cost: 119, perMg: '(8 cents per mg)' },
    cmiSheet: 'https://www.montu.com.au/cmi-thc-18'
  }
];

const Container = styled(Box)(({ theme }) => ({
  margin: theme.spacing(-12, -2, 0, -2),
  height: '100%'
}));

const ProductContainer = styled(Box)({
  flexDirection: 'column',
  alignItems: 'center',
  maxWidth: pxToRemConverter(1000),
  margin: '0 auto'
});

const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(8, 0)
}));

const FooterContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(5),
  paddingTop: theme.spacing(4),
  border: 0,
  display: 'flex',
  justifyContent: 'center',
  marginBottom: theme.spacing(-10),
  backgroundColor: theme.palette.action.hover
}));

const FooterText = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.spacing(3),
  fontWeight: theme.typography.fontWeightRegular,
  lineHeight: theme.spacing(5),
  letterSpacing: 0,
  [theme.breakpoints.up('md')]: {
    fontSize: theme.spacing(4)
  }
}));

function Products() {
  const shippingParts = useShippingPrice();

  return (
    <BrandThemeProvider variant="montu">
      <Container>
        <TopBanner h1Text="Circle Product Information" pText="" />
        <CustomBox>
          <ProductContainer>
            <ProductSection title="CBD-dominant Products" products={CBDDominantProducts} />
            <ProductSection title="Balanced Products (CBD:THC)" products={BalancedProducts} />
            <ProductSection title="THC-dominant Products" products={THCDominantProducts} />
          </ProductContainer>
        </CustomBox>

        <FooterContainer>
          <Box>
            <Typography>
              <Typography component="span" fontWeight="bold">
                * Transparent Pricing Guarantee:
              </Typography>
            </Typography>
            <FooterText>
              The Circle product range is fairly priced with average patient costs of $150-300 per month. Each
              prescription is distributed to patients
            </FooterText>
            <FooterText>
              with a fixed pharmacy dispensing fee of {shippingParts.formattedShipping} (excl GST) through our trusted
              pharmacy network for patient price transparency.
            </FooterText>
          </Box>
        </FooterContainer>
      </Container>
    </BrandThemeProvider>
  );
}

export default Products;
