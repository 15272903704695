import type React from 'react';

import { getTsAdminClient } from '@/api/admin.client';
import { getTsCustomerPortalClient } from '@/api/customer-portal.client';
import { getTsPatientPortalClient } from '@/api/patient-portal.client';

const { ReactQueryProvider: TsrReactQueryCustomerProvider } = getTsCustomerPortalClient();
const { ReactQueryProvider: TsrReactQueryPatientProvider } = getTsPatientPortalClient();
const { ReactQueryProvider: TsrReactQueryAdminProvider } = getTsAdminClient();

export const TsrReactQueryProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <TsrReactQueryCustomerProvider>
      <TsrReactQueryPatientProvider>
        <TsrReactQueryAdminProvider>{children}</TsrReactQueryAdminProvider>
      </TsrReactQueryPatientProvider>
    </TsrReactQueryCustomerProvider>
  );
};

export default TsrReactQueryProvider;
