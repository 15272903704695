import axios, { isAxiosError } from 'axios';
import { useCallback, useEffect, useState } from 'react';

import settings from '@/constants/constants';
import { Logger } from '@/utils/logger';

import type { RequestError } from './types';

const logger = new Logger('useGetUserAgreement');

export function useGetUserAgreement() {
  const [hasAgreement, setHasAgreement] = useState<boolean | null>(null);
  const [error, setError] = useState<RequestError | null>(null);
  const [loading, setLoadingState] = useState(false);

  const makeRequest = useCallback(async () => {
    setLoadingState(true);

    axios
      .get(`${settings.url}/user/agreement`)
      .then(() => {
        setHasAgreement(true);
        setError(null);
      })
      .catch((err) => {
        if (isAxiosError(err)) {
          // If we get a 404, it's because the user doesn't have an agreement yet
          if (err?.response?.status === 404) {
            setHasAgreement(false);
            return;
          }
        }

        logger.error(err);
        setError(err);
      })
      .finally(() => {
        setLoadingState(false);
      });
  }, []);

  useEffect(() => {
    if (hasAgreement === null && !loading && !error) {
      makeRequest();
    }
  }, [hasAgreement, loading, error, makeRequest]);

  return {
    error,
    hasAgreement,
    loading,
    setHasAgreement
  };
}

export default useGetUserAgreement;
