import { Link, Typography } from '@mui/material';
import type { Dispatch, SetStateAction } from 'react';

import { buildIdFromEndpoint } from '@/utils/supportTasks';

import type { SupportTaskProps } from './types';

interface Props {
  tasks: SupportTaskProps[];
  setHighlightedTaskId: Dispatch<SetStateAction<string | null>>;
}

function SupportTaskSubmenu({ tasks, setHighlightedTaskId }: Props) {
  const highlightSupportTask = (id: string) => {
    setHighlightedTaskId(id);
    setTimeout(() => setHighlightedTaskId(null), 300);
  };

  const handleSubmenuClick = (id: string) => {
    const taskElement = document.getElementById(id);

    if (taskElement) {
      const headerElement = document.querySelector('.navbar.fixed-top');
      const offset = headerElement?.getBoundingClientRect().height || 0;

      highlightSupportTask(id);

      window.scrollTo({
        top: taskElement.getBoundingClientRect().top - offset,
        behavior: 'smooth'
      });
    }
  };

  return (
    <Typography component="nav" aria-label="support tasks sub menu" sx={{ px: 4, py: 2 }}>
      Jump to:
      {tasks.map((task, i) => {
        const id = buildIdFromEndpoint(task.endpoint);
        const borderStyle = i !== 0 ? { borderLeft: '1px solid #ccc' } : {};
        return (
          <Link
            key={`${task.title}${i}`}
            component="a"
            color="secondary"
            underline="hover"
            href={`#${id}`}
            onClick={(e) => {
              e.preventDefault();
              handleSubmenuClick(id);
            }}
            sx={{ px: 2, ...borderStyle }}
          >
            {task.title}
          </Link>
        );
      })}
    </Typography>
  );
}

export default SupportTaskSubmenu;
