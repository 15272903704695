import { Typography } from '@mui/material';

import Section from './Section';

export type ProductUsageProps = {
  product: {
    howToUseSteps: string[];
  };
};

export function ProductUsage({ product: { howToUseSteps } }: ProductUsageProps) {
  return (
    <Section>
      <Section.Title>Product usage</Section.Title>
      <Section.Content>
        {howToUseSteps.map((step) => (
          <Typography key={step} gutterBottom>
            {step}
          </Typography>
        ))}
      </Section.Content>
    </Section>
  );
}

export default ProductUsage;
