import styled from '@emotion/styled';
import {
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Typography
} from '@mui/material';
import { useCallback, useState } from 'react';

import type { OnAcceptanceFields } from '@/hooks/rest/types';
import { Button } from '@/ui-library';

const PolicyContainer = styled.div`
  padding: 1rem;
  height: 300px;
  scrollbar-color: auto;
  border: 1px solid rgba(0, 0, 0, 0.42);
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  overflow: scroll;
  margin-bottom: 2rem;
`;

const LabelContainer = styled.span`
  padding-top: 0.5rem;
  display: block;
`;

type Props = {
  open: boolean;
  onAcceptance: (data: OnAcceptanceFields) => Promise<void>;
};

export default function PrivacyPolicyDialog({ open, onAcceptance }: Props) {
  const [privacyChecked, setPrivacyChecked] = useState(false);
  const [marketingChecked, setMarketingChecked] = useState(true);

  const onContinue = useCallback(async () => {
    await onAcceptance({ marketingOptIn: marketingChecked });
  }, [onAcceptance, marketingChecked]);

  return (
    <Dialog open={open}>
      <DialogTitle>Privacy policy agreement</DialogTitle>
      <DialogContent>
        <PolicyContainer>
          <Typography>
            The information you are providing to Montu Group Pty Ltd (ABN 35 634 198 360) and it’s wholly owned
            subsidiary Alternaleaf Pty Ltd (ABN 67 649 693 005 ) may include personal information under the Privacy Act
            1988 (Cth). We collect, hold, use and disclose personal information you provide to us in the manner set out
            in our Privacy Policy. We use your personal information to provide medical care and services that you
            request, and to keep you informed about products and services that may be of interest to you. We may
            disclose your personal information to third parties, to enable us to provide you with products and services
            that you request and as required by law. If you do not provide the personal information requested, we may
            not be able to provide you with our products or services. Our Privacy Collection Notice contains information
            about how you may request access to or correction of your personal information. Our Privacy Policy also
            contains information about how you may complain about a breach of the Australian Privacy Principles and how
            we will deal with such a complaint. Your personal information may be accessed from, transferred to, and/or
            stored outside Australia by our service providers. The data protection laws in that country may be of a
            lower standard than those in Australia. We will, in all circumstances, safeguard the personal information as
            set out our Privacy Collection Notice.
          </Typography>
        </PolicyContainer>

        <FormControlLabel
          sx={{
            alignItems: 'self-start',
            marginBottom: '1rem'
          }}
          control={
            <Checkbox
              checked={privacyChecked}
              onChange={(event) => setPrivacyChecked(event.target.checked)}
              name="privacyChecked"
              color="default"
              sx={{
                marginRight: '1rem'
              }}
            />
          }
          label={
            <LabelContainer>
              I consent to the collection, storage and use of my personal information in accordance with the Privacy
              Collection Notice. I understand that I can withdraw my consent at any time.
            </LabelContainer>
          }
        />

        <FormControlLabel
          sx={{
            alignItems: 'self-start'
          }}
          control={
            <Checkbox
              checked={marketingChecked}
              onChange={(event) => setMarketingChecked(event.target.checked)}
              name="marketingChecked"
              color="default"
              sx={{
                marginRight: '1rem'
              }}
            />
          }
          label={
            <LabelContainer>
              Yes, please keep me informed about products and services that may be of interest to me.
            </LabelContainer>
          }
        />
      </DialogContent>
      <DialogActions>
        {/* Loading button state is still in labs */}
        <Button onClick={onContinue} disabled={!privacyChecked} color="primary" variant="contained" autoFocus>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}
