import { Box, Grid } from '@mui/material';

import FormulationFilterSkeleton from '@/components/products/components/FormulationFilter/FormulationFilterSkeleton';
import ProductTileSkeleton from '@/components/products/components/ProductTile/ProductTileSkeleton';

import CTASection from './CTASection';
import HeadingSection from './HeadingSection';

export function PageSkeleton() {
  const keyFrom = (skeletonIdx: number) => skeletonIdx;
  return (
    <Box maxWidth="xl">
      <HeadingSection />
      <CTASection />
      <Box marginBottom={4}>
        <FormulationFilterSkeleton />
      </Box>
      <Grid container spacing={2}>
        {Array.from(Array(3)).map((_, idx) => (
          <Grid item xs={12} sm={6} lg={4} xl={3} key={keyFrom(idx)}>
            <ProductTileSkeleton />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default PageSkeleton;
