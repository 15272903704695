import USER_ROLES from '@/constants/userRoles';

export interface MenuItem {
  id: number | string;
  name: string;
  link: string;
  roleId: number[];
}

const menus: MenuItem[] = [
  {
    id: 3,
    name: 'ORDERS',
    link: '/orders',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.doctor, USER_ROLES.ml, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 4,
    name: 'PHARMACIES',
    link: '/pharmacy',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 5,
    name: 'PATIENTS',
    link: '/patients',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 6,
    name: 'DOCTORS',
    link: '/doctors',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.ml, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 7,
    name: 'PATIENTS',
    link: '/gp/patients',
    roleId: [USER_ROLES.doctor]
  },
  {
    id: 10,
    name: 'ORDERS',
    link: '/pharmacist/orders',
    roleId: [USER_ROLES.pharmacist]
  },
  {
    id: 11,
    name: 'INVENTORY',
    link: '/pharmacist/inventory',
    roleId: [USER_ROLES.pharmacist]
  },
  {
    id: 12,
    name: 'Home',
    link: '/home',
    roleId: [USER_ROLES.patient]
  },
  {
    id: 13,
    name: 'Refill',
    link: '/patient/refill',
    roleId: [USER_ROLES.patient]
  },
  {
    id: 14,
    name: 'Products',
    link: '/product-catalog',
    roleId: [USER_ROLES.patient]
  },
  {
    id: 15,
    name: 'Orders',
    link: '/patient/orders',
    roleId: [USER_ROLES.patient]
  },
  {
    id: 16,
    name: 'PRODUCT CATALOG',
    link: '/product-catalog',
    roleId: [USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: '17',
    name: 'Consultations',
    link: '/patient/consultations',
    roleId: [USER_ROLES.patient]
  },
  {
    id: '18',
    name: 'Prescriptions',
    link: '/patient/prescription',
    roleId: [USER_ROLES.patient]
  },
  {
    id: '19',
    name: 'FAQs',
    link: '/faq',
    roleId: [USER_ROLES.patient]
  }
];

const moreMenu = [
  {
    id: 2,
    name: 'SUPPLIERS',
    link: '/suppliers',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 3,
    name: 'PRODUCTS',
    link: '/products',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 4,
    name: 'PRODUCT CATALOG',
    link: '/product-catalog',
    roleId: [USER_ROLES.superAdmin]
  },
  {
    id: 5,
    name: 'INVOICES',
    link: '/invoices',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 10,
    name: 'SUPPORT SELF SERVICE',
    link: '/support-self-service',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 11,
    name: 'ORDER ALLOCATION TOOL',
    link: '/order-allocation-tool',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 12,
    name: 'BETA PHARMACY INVENTORY',
    link: '/beta-pharmacy-inventory',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  },
  {
    id: 13,
    name: 'PHARMACY ALLOCATION SETTINGS',
    link: '/pharmacy-allocation-settings',
    roleId: [USER_ROLES.superAdmin, USER_ROLES.admin, USER_ROLES.ptxSupportPartner]
  }
];

export default { menus, moreMenu };
