import { styled, Tab, Tabs } from '@mui/material';

import { tabs } from '@/pages/pharmacist/PharmacistInventory';

const PharmacistInventoryTab = styled(Tab)(({ theme }) => ({
  textTransform: 'none',
  fontFamily: theme.typography.button.fontFamily,
  '&:focus': {
    outline: 'none'
  },
  '&:focus-visible': {
    outline: 'auto'
  }
}));

interface IPharmacistInventoryTabsProps {
  activeTab: string;
  toggleTab: (tab: string) => void;
}

function PharmacistInventoryTabs({ activeTab, toggleTab }: IPharmacistInventoryTabsProps) {
  return (
    <Tabs
      value={activeTab}
      onChange={(_event, newView) => toggleTab(newView)}
      textColor="secondary"
      indicatorColor="secondary"
    >
      <PharmacistInventoryTab label="Product Overview" value={tabs.PRODUCT_OVERVIEW} />
      <PharmacistInventoryTab label="Adjustment Overview" value={tabs.ADJUSTMENT_OVERVIEW} />
    </Tabs>
  );
}

export default PharmacistInventoryTabs;
