import React from 'react';
import logo from './montu.png';

const header = () => {
  return (
    <div className="header navbar-expand-lg fixed-top navbg" data-testid="header">
      <a href="https://www.montu.com.au" target="blank">
        <img src={logo} alt="montu" className="p-2 montu-logo" />
      </a>
    </div>
  );
};

export default header;
