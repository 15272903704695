import { Logger } from '@/utils/logger';

const logger = new Logger('Utils.User');

export function getAndFormatMoreUserData() {
  try {
    // @ts-expect-error
    const moreUserData = JSON.parse(localStorage.getItem('moreUserData'));
    const { patient, patientDiscounts, products, order } = moreUserData || {};

    const prescribedProducts = products
      ?.filter((product: any) => product?.prescription_id)
      .reduce((acc: any, product: any) => {
        acc[product?.id] = product?.name;
        return acc;
      }, {});

    const medicalConditions = order?.medical_condition?.split('\n');

    const isConcession = !!patientDiscounts?.some((discount: any) => discount?.is_concession);

    const propertiesToReturn = {
      clientCode: patient?.client_code,
      createdBy: patient?.created_by,
      createdDate: patient?.created_date,
      dateOfBirth: patient?.dob,
      id: patient?.id,
      isDischarged: patient?.is_discharge,
      isNewPatient: patient?.is_new_patient,
      patientCode: patient?.patient_code,
      patientPharmacyName: patient?.patient_pharmacy_name,
      patientPharmacyAddress: patient?.patient_pharmacy_address,
      pharmacyId: patient?.pharmacy_id,
      priority: patient?.priority,
      userId: patient?.user_id,
      xeroContactId: patient?.xero_contact_id,
      patientDiscounts: patientDiscounts,
      prescribedProducts: prescribedProducts,
      medicalConditions: medicalConditions,
      isConcession: isConcession
    };
    return propertiesToReturn;
  } catch {
    logger.error('more user data failed');
  }
  return {};
}
