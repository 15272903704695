import type { LDFlagSet } from 'launchdarkly-js-sdk-common';

import {
  FF_BRAND_THEME_NAV,
  FF_DISABLE_CONSULTATION_BUTTONS,
  FF_ENABLE_404_AND_500_ERROR_PAGES,
  FF_ENABLE_ADD_SHIPPIT_TOKEN,
  FF_ENABLE_ESCRIPT_PROTOTYPE,
  FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE,
  FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE_PT_PROFILE,
  FF_ENABLE_GOOGLE_PLACES_UMEDS,
  FF_ENABLE_INACTIVE_PHARMACY_FILTER,
  FF_ENABLE_MY_TREATMENT_PLAN,
  FF_ENABLE_NOTICE_ON_MODAL,
  FF_ENABLE_ORDER_ASSIGNMENT_TOOL,
  FF_ENABLE_ORDER_FILTER_BY_PHARMACY,
  FF_ENABLE_ORDER_TRACKING_STATUS_NOTIFICATION,
  FF_ENABLE_PATIENT_ORDERS_PAGE,
  FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES,
  FF_ENABLE_PATIENT_PROFILE_MERGE,
  FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS,
  FF_ENABLE_SAFESCRIPT_OUTAGE_BANNER,
  FF_ENABLE_SHOPIFY_CHECKOUT,
  FF_ENABLE_SNOWPLOW_CIRCUIT,
  FF_ENABLE_SUPER_ADMIN_INVENTORY,
  FF_ENABLE_WOM_CAMPAIGN,
  FF_FF_ENABLE_UMEDS_CHECKBOX,
  FF_GENERATIVE_SCRIPTING_TOGGLES,
  FF_ONLY_ALLOW_FULL_ORDER_ORDER_DISCOUNTS_FOR_ADMIN_ORDERS,
  FF_ORDER_FORM_DISABLE_EDIT_IF_PAID,
  FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT,
  FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT,
  FF_PHARMACY_FILTER_ORDERS_BY_STATUS,
  FF_POWERED_BY_UMEDS,
  FF_PREVENT_LOGOUT_ON_UNAUTHED_NAVIGATION,
  FF_PRODUCT_IMAGE_CAROUSEL,
  FF_REFUND_NON_REFUND_CREDIT,
  FF_SHIPPING_PRICE,
  FF_SHOPIFY_DISABLE_PRODUCT_EDIT,
  FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE,
  FF_SHOW_MAINTENANCE_SCREEN,
  FF_SHOW_PAYMENT_WARNING,
  FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE,
  FF_UPDATED_PATIENT_ORDER_STATUSES,
  FF_USE_CUSTOMER_HOOK,
  FF_USE_NEW_EDIT_ORDER_ENDPOINT,
  FF_USE_SHOPIFY_PRODUCT_IMAGES,
  FF_WOM_PRICE,
  FF_WOM_TNC_COPY
} from '@/constants/featureFlags';

const ffDefaults: LDFlagSet = {
  [FF_DISABLE_CONSULTATION_BUTTONS]: false,
  [FF_ENABLE_ORDER_TRACKING_STATUS_NOTIFICATION]: false,
  [FF_ENABLE_SHOPIFY_CHECKOUT]: true,
  [FF_GENERATIVE_SCRIPTING_TOGGLES]: false,
  [FF_ORDER_FORM_DISABLE_EDIT_IF_PAID]: false,
  [FF_REFUND_NON_REFUND_CREDIT]: false,
  [FF_SHIPPING_PRICE]: 13.64,
  [FF_SHOW_PAYMENT_WARNING]: 'OFF',
  [FF_USE_NEW_EDIT_ORDER_ENDPOINT]: false,
  [FF_SHOPIFY_DISABLE_PRODUCT_EDIT]: false,
  [FF_USE_SHOPIFY_PRODUCT_IMAGES]: false,
  [FF_PRODUCT_IMAGE_CAROUSEL]: false,
  [FF_SHOW_MAINTENANCE_SCREEN]: false,
  [FF_PHARMACY_FILTER_ORDERS_BY_STATUS]: false,
  [FF_ENABLE_WOM_CAMPAIGN]: false,
  [FF_ENABLE_NOTICE_ON_MODAL]: false,
  [FF_UPDATED_PATIENT_ORDER_STATUSES]: false,
  [FF_ENABLE_PATIENT_PROFILE_MERGE]: false,
  [FF_ENABLE_SUPER_ADMIN_INVENTORY]: false,
  [FF_ENABLE_ORDER_ASSIGNMENT_TOOL]: false,
  [FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE]: false,
  [FF_ENABLE_GOOGLE_PLACES_AUTOCOMPLETE_PT_PROFILE]: false,
  [FF_ENABLE_PHARMACY_INVENTORY_INACTIVE_PRODUCTS]: false,
  [FF_ENABLE_SAFESCRIPT_OUTAGE_BANNER]: false,
  [FF_SHOW_PRODUCT_DESCRIPTION_AND_USAGE]: false,
  [FF_ENABLE_INACTIVE_PHARMACY_FILTER]: false,
  [FF_ENABLE_ORDER_FILTER_BY_PHARMACY]: false,
  [FF_ENABLE_MY_TREATMENT_PLAN]: false,
  [FF_ENABLE_404_AND_500_ERROR_PAGES]: false,
  [FF_ENABLE_PATIENT_ORDERS_PAGE]: false,
  [FF_ENABLE_PATIENT_ORDERS_WHITE_LABEL_IMAGES]: false,
  [FF_ENABLE_GOOGLE_PLACES_UMEDS]: false,
  [FF_FF_ENABLE_UMEDS_CHECKBOX]: false,
  [FF_ENABLE_SNOWPLOW_CIRCUIT]: false,
  [FF_POWERED_BY_UMEDS]: false,
  [FF_PATIENT_DISCHARGE_CIRCUIT_CHECKOUT_DISABLED_ALERT]: false,
  [FF_SHOPIFY_REDIRECT_THANK_YOU_PAGE]: false,
  [FF_WOM_PRICE]: 20,
  [FF_WOM_TNC_COPY]: 'Offer is only valid for participants who are new customers.',
  [FF_BRAND_THEME_NAV]: false,
  [FF_PATIENT_REFILL_CHECKOUT_BANNER_TEXT]:
    'Please note: vaporiser devices and batteries are now no longer available due to a change in legislation. If impacted, book a follow-up appointment with your doctor to discuss alternatives.',
  [FF_ENABLE_ADD_SHIPPIT_TOKEN]: false,
  [FF_PREVENT_LOGOUT_ON_UNAUTHED_NAVIGATION]: false,
  [FF_ONLY_ALLOW_FULL_ORDER_ORDER_DISCOUNTS_FOR_ADMIN_ORDERS]: false,
  [FF_ENABLE_ESCRIPT_PROTOTYPE]: false,
  [FF_USE_CUSTOMER_HOOK]: false
};

export default ffDefaults;
