import { toast } from '@montugroup/design-system';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';

interface DownloadTemplateParams {
  fileName: string;
  urlLink: string;
}

const downloadFile = async (fileName: string, urlLink: string) => {
  const response = await axios({
    url: urlLink,
    method: 'GET',
    params: { name: fileName },
    responseType: 'blob'
  });

  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function useDownloadTemplate() {
  const mutation = useMutation({
    mutationFn: async ({ fileName, urlLink }: DownloadTemplateParams) => downloadFile(fileName, urlLink),
    onError: () => {
      toast.error('File download failed');
    },
    onSuccess: () => {
      toast.success('File downloaded successfully');
    }
  });
  return mutation;
}

export default useDownloadTemplate;
