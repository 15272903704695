/* eslint-disable camelcase */
import { toast } from '@montugroup/design-system';
import axios, { isAxiosError } from 'axios';

import type { LoginType } from '@/components/auth/login/types';
import { PaymentService } from '@/services/payment.service';
import type { LoginResponse, LoginUser } from '@/types';
import { Logger } from '@/utils/logger';

import type { User } from '@/hooks/user/useUser';
import getQueryClient from '@/utils/getQueryClient';
import settings from '../constants/constants';
import USER_ROLES from '../constants/userRoles';

const logger = new Logger('authentication.service');

const LOCAL_STORAGE_KEY = 'currentUser';

const DEFAULT_USER_ROLE = 'patient';

const dispatchUserChangeEvent = (user: LoginUser | null) => {
  const event = new CustomEvent('user', { detail: user });
  window.dispatchEvent(event);
};

const clearCurrentUserFromStorage = () => {
  localStorage.removeItem(LOCAL_STORAGE_KEY);
  sessionStorage.removeItem(LOCAL_STORAGE_KEY);
  dispatchUserChangeEvent(null);
};

const clearUserDataFromStorage = () => {
  // clean up payment tokens if they exist
  PaymentService.clearOrderCode();
  PaymentService.clearInvoiceToken();
  // Clean up user
  clearCurrentUserFromStorage();
  localStorage.removeItem('moreUserData');
  getQueryClient().clear();
};

const redirectToLoginByUserRole = (userRole = 'patient', expired = false) => {
  const roleOverrides: Record<string, LoginType> = {
    /**
     * PTX Support Partners are logging into the admin panel, but don't have the admin role.
     */
    ptxSupportPartner: 'admin'
  };
  const role = roleOverrides[userRole] || userRole;
  const redirectURL = expired ? `/login/${role}?expired=true` : `/login/${role}`;
  window.location.href = redirectURL;
};

const getUserFromLocalStorage = () =>
  localStorage.getItem(LOCAL_STORAGE_KEY) || sessionStorage.getItem(LOCAL_STORAGE_KEY);

const getUser = (): LoginResponse | null => {
  const user = getUserFromLocalStorage();

  if (!user) {
    return null;
  }

  try {
    return JSON.parse(user);
  } catch (error) {
    logger.error('Error parsing user data:', error);
    clearUserDataFromStorage();
    redirectToLoginByUserRole();
  }

  return null;
};

const setUser = (userObj: LoginResponse) => {
  const userStrObj = JSON.stringify(userObj);
  if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
    localStorage.setItem(LOCAL_STORAGE_KEY, userStrObj);
  } else {
    sessionStorage.setItem(LOCAL_STORAGE_KEY, userStrObj);
  }
  dispatchUserChangeEvent(userObj.user ?? null);
};

// We only check if we've saved an entry in storage, not if it's valid
const isLoggedIn = () => !!getUserFromLocalStorage();

const getUserID = () => getUser()?.user?.id;

Logger.getUserIdFunc = () => getUserID()?.toString();

const updatePhone = (phone: string) => {
  const user = getUser();

  if (!user) {
    return;
  }

  if (phone) {
    if (user?.user) {
      user.user.phone = phone;
      setUser(user);
    }
  }
};

const updateUser = (user: LoginUser): void => {
  const localStorageUser = getUser();

  if (!localStorageUser || !localStorageUser.user) {
    return;
  }

  localStorageUser.user = user;

  setUser(localStorageUser);
};

const logout = (expired = false) => {
  logger.info('logging out');

  const user = getUser();
  let userRole = 'patient';

  if (user?.user) {
    userRole = Object.keys(USER_ROLES).find((key) => USER_ROLES[key] === user.user?.role_id) || DEFAULT_USER_ROLE;

    if (userRole === 'superAdmin') {
      userRole = 'admin';
    }
  }

  clearUserDataFromStorage();
  redirectToLoginByUserRole(userRole, expired);
};

const login = async (
  email?: string,
  password?: string,
  remember?: boolean,
  loginType = DEFAULT_USER_ROLE,
  tokenId = '' // only a default as I wanted to add the above without reordering arguments initially
) => {
  let requestPayload: Record<string, unknown> | undefined;

  if (tokenId) {
    requestPayload = { tokenId, loginType: USER_ROLES[loginType] };
  } else if (email && password) {
    requestPayload = { email, password, loginType: USER_ROLES[loginType] };
  } else {
    const err = new Error('Username and password required');
    toast.error(err.message);
    throw err;
  }

  logger.info('sending login request: ', { email, loginType: USER_ROLES[loginType] });

  try {
    const { data } = await axios.post<LoginResponse>(`${settings.url}/user/login`, requestPayload);
    logger.info('Login succeeded');

    logger.info('setting local storage: ', { email, remember, loginType: USER_ROLES[loginType] });
    // store user details and jwt token
    // const storage = remember ? localStorage : sessionStorage;
    // storage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    // @todo: temporary fix as "remember me" is no longer passed in by default
    // ref: https://montugroup.atlassian.net/browse/MM-1590
    // @todo: revert change to commented out section above once we have resolved session synchronisation
    // ref: https://montugroup.atlassian.net/browse/MM-1613
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data));
    dispatchUserChangeEvent(data.user ?? null);
    axios.defaults.headers.common.Authorization = `Bearer ${data.token}`;

    if (data.user) {
      const useUserInitialData: User = {
        id: data.user.id,
        email: data.user.email,
        firstName: data.user.first_name,
        lastName: data.user.last_name || '',
        roleId: data.user.role_id,
        connections: {}
      };
      getQueryClient().setQueryData(['user'], useUserInitialData);
      getQueryClient().invalidateQueries({ queryKey: ['user'] });
    }

    return data;
  } catch (err) {
    // eslint-disable-next-line no-undefined
    const response = isAxiosError(err) ? err.response : undefined;
    const status = response?.status;
    if (status === 403 && response?.headers['content-type']?.includes('text/html')) {
      const errorMessage = 'An unexpected error occurred.';
      logger.debug('Login failed due to WAF. Received HTML response.', {
        email,
        status: response.status,
        data: response.data
      });
      throw errorMessage;
    }
    const errorMessage = (response && response.statusText) || (err instanceof Error && err.message) || 'Login Failed';
    const targetedErrorMessage =
      status != null && [401, 403].includes(status) ? 'Unable to login with the credentials provided.' : errorMessage;

    // We pass the original error message through to the logger
    logger.info('Login failed with', { email, status, message: errorMessage });
    throw targetedErrorMessage;
  }
};

const resetPassword = (email: string) =>
  axios.post(`${settings.url}/user/forgotpassword`, { body: JSON.stringify({ email }) }).catch((err) => err);

const confirmUser = (email: string) =>
  axios.post(`${settings.url}/user/confirmuser`, { body: JSON.stringify({ email }) }).catch((err) => err);

const patient = (
  firstName: string,
  lastName: string,
  email: string,
  phone: string,
  address: string,
  city: string,
  state: number | null,
  country: string,
  zip_code: string,
  general_practitioner_id: number
) =>
  axios.post(`${settings.url}/patient`, {
    data: JSON.stringify({
      firstName,
      lastName,
      email,
      phone,
      address,
      city,
      state,
      country,
      zip_code,
      general_practitioner_id
    })
  });

const clinic = (
  name: string,
  email: string,
  phone: string,
  address: string,
  city: string,
  state: string,
  zip_code: string,
  country?: unknown,
  clinic_priority?: unknown,
  active?: unknown,
  gp_id?: unknown
) =>
  axios
    .post(`${settings.url}/clinic`, {
      data: JSON.stringify({
        name,
        email,
        phone,
        address,
        city,
        state,
        country,
        zip_code,
        clinic_priority,
        active,
        gp_id
      })
    })
    .then(async (data) => {
      if (data.status !== 200) {
        // This looks like it's expecting an error object, which doesn't match the type (hence the guard)
        if ('message' in data) {
          toast.error(`${data.message}`, {});
          logger.error('clinic then', `${data.message}`);
        } else {
          logger.error('clinic then', 'no message');
        }

        return null;
      }

      return data;
    })
    .catch((err) => {
      logger.error('/clinic', err?.response?.data?.message);
      toast.error(err?.response?.data?.message, {});
    });

const pharmacy = (
  companyName: string,
  ABN: string,
  address: string,
  city: string,
  state: string,
  country: number,
  zip_code: string,
  businessPersonName: string,
  businessPersonPosition: string,
  businessPersonPhone: string,
  businessPersonEmail: string,
  pharmacistName: string,
  pharmacistEmail: string,
  pharmacistPhone: string,
  pharmacistAhpraNumber: string,
  accountName: string,
  accountNo: string,
  bsb: string,
  bankNumber: string,
  authorised_firstName: string,
  authorised_lastName: string,
  script: number,
  date: Date,
  active: number,
  home_delivery: string,
  identifierName: string,
  pharmacistSecondaryEmail: string
) =>
  axios.post(`${settings.url}/pharmacy`, {
    data: JSON.stringify({
      companyName,
      ABN,
      address,
      city,
      state,
      country,
      zip_code,
      businessPersonName,
      businessPersonPosition,
      businessPersonPhone,
      businessPersonEmail,
      pharmacistName,
      pharmacistEmail,
      pharmacistPhone,
      pharmacistAhpraNumber,
      accountName,
      accountNo,
      bsb,
      bankNumber,
      authorised_firstName,
      authorised_lastName,
      script,
      date,
      active,
      home_delivery,
      identifierName,
      pharmacistSecondaryEmail
    })
  });

const isAdminOnly = () => getUser()?.user?.role_id === USER_ROLES.admin;

const isAdmin = () => {
  const roleId = getUser()?.user?.role_id;

  if (typeof roleId !== 'number') {
    return false;
  }

  return [
    USER_ROLES.superAdmin,
    USER_ROLES.admin,
    // we consider PTX support partner mostly the same as admin,
    // but they have less permissions on the backend
    USER_ROLES.ptxSupportPartner
  ].includes(roleId);
};

const isSuperAdmin = () => getUser()?.user?.role_id === USER_ROLES.superAdmin;

const isPatient = () => getUser()?.user?.role_id === USER_ROLES.patient;

const isDoc = () => getUser()?.user?.role_id === USER_ROLES.doctor;

const updateGPInfo = (id: string) => axios.post(`${settings.url}/user/status/${id}`);

const getUserRoles = () => USER_ROLES;

export const AuthService = {
  login,
  logout,
  resetPassword,
  confirmUser,
  patient,
  getUser,
  clinic,
  pharmacy,
  getUserID,
  updatePhone,
  updateUser,
  isAdmin,
  isSuperAdmin,
  updateGPInfo,
  isPatient,
  isDoc,
  isAdminOnly,
  isLoggedIn,
  getUserRoles,
  clearUserDataFromStorage,
  clearCurrentUserFromStorage,
  redirectToLoginByUserRole
};

export default AuthService;
