import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';

import AuthService from '@/services/authentication.service';
import { Logger } from '@/utils/logger';

const useSetDatadogUser = (prefix: string) => {
  const logger = new Logger(prefix);

  const user = AuthService.getUser()?.user;

  useEffect(() => {
    if (user && user.id) {
      try {
        datadogRum.setUser({
          id: user.id.toString()
        });
        logger.debug('DD RUM :: setUser was successful.', user.id);
      } catch (error) {
        logger.error('DD RUM :: Error setting user key', error);
      }
    }
  }, [user?.id]);
};

export default useSetDatadogUser;
