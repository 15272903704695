import { useQuery } from '@tanstack/react-query';

import { getProductOffering } from '@/services/product.service';
import { Logger } from '@/utils/logger';

const logger = new Logger('useProductDetails');

function useProductDetails(productId: string) {
  const { data, isError, isLoading, refetch } = useQuery({
    queryKey: ['productDetails', productId],
    queryFn: async () => {
      try {
        const res = await getProductOffering(productId);
        return res.data;
      } catch (error) {
        logger.error(error);
        throw new Error('Failed to fetch product details');
      }
    },
    retry: 1
  });

  return {
    product: data?.product,
    isError,
    isLoading,
    refetch
  };
}

export default useProductDetails;
