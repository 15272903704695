import { useContext } from 'react';

import { BrazeContentCardContext } from '@/App';

import ContentCard from './components/ContentCard';

export default function GlobalContentCard() {
  const brazeContentCards = useContext(BrazeContentCardContext);
  const globalContentCards = (brazeContentCards || []).filter((card) => card?.extras?.display_type === 'global');
  return (
    <>
      {globalContentCards.map((card) => (
        <ContentCard key={card.id} card={card} />
      ))}
    </>
  );
}
