import type { StackProps } from '@mui/material';
import { Box, Stack, styled, Typography } from '@mui/material';

import type { ProductFormulation, ProductStrain, ProductStrainKey } from '@/types';

import ConcessionChip from '../../components/ConcessionChip/ConcessionChip';
import FormulationChip from '../../components/FormulationChip/FormulationChip';
import KeyValueChip from '../../components/KeyValueChip/KeyValueChip';
import StrainChip from '../../components/StrainChip/StrainChip';

const Container = styled(Stack)(() => ({
  display: 'flex',
  flexDirection: 'column'
}));

const ChipsContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexWrap: 'wrap',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2)
}));

export type ProductSummaryProps = {
  product: {
    name: string;
    brandName: string | null;
    formulationName: ProductFormulation | null;
    strainName: ProductStrain | null;
    strainKey: ProductStrainKey | null;
    thcStrengthForDisplay: string | null;
    cbdStrengthForDisplay: string | null;
    isConcession: boolean;
  };
  sx?: StackProps['sx'];
};

export function ProductSummary({
  product: {
    name,
    brandName,
    formulationName,
    strainName,
    strainKey,
    thcStrengthForDisplay,
    cbdStrengthForDisplay,
    isConcession
  },
  sx
}: ProductSummaryProps) {
  return (
    <Container sx={sx}>
      <Typography variant="subtitle2" component="h1" color="primary" fontWeight="bold">
        {name}
      </Typography>

      {brandName ? (
        <Typography variant="body2" color="text.primary" sx={{ mt: 1 }}>
          {brandName}
        </Typography>
      ) : null}

      <ChipsContainer>
        {isConcession ? <ConcessionChip /> : null}
        {formulationName ? <FormulationChip formulation={formulationName} /> : null}
        {strainName && strainKey ? <StrainChip strainName={strainName} strainKey={strainKey} /> : null}
      </ChipsContainer>
      {(thcStrengthForDisplay || cbdStrengthForDisplay) && (
        <ChipsContainer>
          {thcStrengthForDisplay ? <KeyValueChip name="THC:" value={thcStrengthForDisplay} /> : null}
          {cbdStrengthForDisplay ? <KeyValueChip name="CBD:" value={cbdStrengthForDisplay} /> : null}
        </ChipsContainer>
      )}
    </Container>
  );
}

export default ProductSummary;
