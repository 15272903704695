import { searchDoctors } from '@/services/data.service';
import type { TableSortOrder } from '@/types';
import { useQuery } from '@tanstack/react-query';

export type UseGetDoctorsParams = {
  page: number;
  pageSize: number;
  sortingOrder: TableSortOrder;
  filter: string;
  gpFilter: string;
};

function useGetDoctors({ page, pageSize, sortingOrder, filter, gpFilter }: UseGetDoctorsParams) {
  return useQuery({
    queryKey: ['getDoctors', page, pageSize, sortingOrder, filter, gpFilter],
    queryFn: async ({ signal }) => {
      try {
        const response = await searchDoctors({ page, pageSize, sortingOrder, filter, gpFilter, signal });
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        return response.data;
      } catch (error) {
        throw new Error(`Failed to fetch doctors: ${(error as { message: string }).message}`);
      }
    }
  });
}

export default useGetDoctors;
