import { getUnableToDispenseInfo } from '@/services/pharmacist.service';
import { useQuery } from '@tanstack/react-query';

function useGetUnableToDispenseInfo({ orderId }: { orderId: number | null }) {
  return useQuery({
    queryKey: ['getUnableToDispenseInfo', orderId],
    enabled: Boolean(orderId),
    queryFn: async () => {
      try {
        if (!orderId) {
          return;
        }
        return await getUnableToDispenseInfo(orderId);
      } catch (e) {
        throw new Error(`Failed to fetch ordered products: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetUnableToDispenseInfo;
