import { Link } from '@mui/material';

type PatientIDTableCellProps = {
  patientId: string;
  patientUniqId: number;
};

function PatientIDTableCell({ patientId, patientUniqId }: PatientIDTableCellProps) {
  return (
    <Link href={`/onboard/${patientUniqId}`} target="_blank">
      {patientId}
    </Link>
  );
}

export default PatientIDTableCell;
