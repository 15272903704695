import { PaginationVariant, Table, toast } from '@montugroup/design-system';
import { Edit, Email } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import type { ColumnDef } from '@tanstack/react-table';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import TableSearchInput from '@/components/common/TableSearchInput';
import { useErrorManagement } from '@/context/ErrorManagement';
import useSearchPatients from '@/hooks/admin/useSearchPatients';
import useTablePaginationModel from '@/hooks/table/useTablePaginationModel';
import useTableSortingOrder from '@/hooks/table/useTableSortingOrder';
import type { SearchPatient } from '@/services/data.service';
import { UserService } from '@/services/user.service';

const sortFieldOverrides = {
  patient_name: 'PatientUser.first_name',
  phone: 'PatientUser.phone',
  email: 'PatientUser.email',
  state: 'PatientUser.State.name'
} as const;

const resendInvite = async (email: string) => {
  const response = await UserService.resendLoginInvite({ email });
  if (response.data.status === 200) {
    toast.success('Invite sent.');
  } else {
    toast.error('Failed to send invite.');
  }
};

const columns: ColumnDef<SearchPatient>[] = [
  {
    accessorKey: 'id',
    header: 'id',
    enableSorting: true
  },
  {
    accessorKey: 'patient_code',
    header: 'Patient ID',
    enableSorting: true,
    cell: ({ row }) => <Link to={`/onboard/${row.original.id}`}>{row.original.patient_code}</Link>
  },
  {
    accessorKey: 'patient_name',
    header: 'Name',
    enableSorting: true
  },
  {
    accessorKey: 'email',
    header: 'Email',
    enableSorting: true
  },
  {
    accessorKey: 'phone',
    header: 'Phone',
    enableSorting: true
  },
  {
    accessorKey: 'state',
    header: 'State',
    enableSorting: true
  },
  {
    accessorKey: 'gpname',
    header: 'Doctor',
    enableSorting: false
  },
  {
    accessorKey: 'approval',
    header: 'Approval',
    enableSorting: false
  },
  {
    accessorKey: 'total_prescriptions',
    header: 'Total prescriptions',
    enableSorting: false
  },
  {
    accessorKey: 'circuit_access',
    header: 'Patient access',
    enableSorting: false
  },
  {
    id: 'resend',
    header: 'Resend invite',
    enableSorting: false,
    cell: ({ row }) => {
      if (row.original.circuit_access === 'Non-PMS' || row.original.circuit_access === 'Not active') {
        return null;
      }
      return (
        <IconButton
          disabled={row.original.circuit_access === 'Active'}
          onClick={() => resendInvite(row.original.email)}
        >
          <Email aria-label="resend invite" />
        </IconButton>
      );
    }
  },
  {
    id: 'edit',
    header: 'Edit',
    cell: ({ row }) => (
      <Link to={`/onboard/${row.original.id}`}>
        <Edit aria-label="edit" color="action" sx={{ cursor: 'pointer' }} />
      </Link>
    )
  }
];

function ViewPatientsTable() {
  const [filter, setFilter] = useState<string>('');

  const { enqueueError } = useErrorManagement();
  const { page, pageSize, handlePaginationModelChange } = useTablePaginationModel();
  const { sortingOrder, handleSortingOrderChange } = useTableSortingOrder({ sortFieldOverrides });
  const { data, isLoading, error } = useSearchPatients({
    page,
    pageSize,
    sortingOrder,
    filter
  });

  useEffect(() => {
    if (error) {
      enqueueError({ title: 'Something went wrong', body: (error as { message: string }).message || '' });
    }
  }, [enqueueError, error]);

  return (
    <>
      <TableSearchInput value={filter} onChange={(value) => setFilter(value)} />
      <Table
        isLoading={isLoading}
        data={data?.patients ?? []}
        columns={columns}
        columnVisibility={{ id: false }}
        total={data?.count}
        showPagination
        manualPagination
        pageSize={pageSize}
        onPaginationModelChange={handlePaginationModelChange}
        paginationVariant={PaginationVariant.FIXED_PAGE_SIZE}
        onSortingChange={handleSortingOrderChange}
        hasRowBackgroundColor={false}
      />
    </>
  );
}

export default ViewPatientsTable;
