import type { LDClient } from 'launchdarkly-js-client-sdk';
import type { LDSingleKindContext } from 'launchdarkly-js-sdk-common';

import type { User } from '@/hooks/user/useUser';
import type { LoginUser } from '@/types';
import { Logger } from '@/utils/logger';

const logger = new Logger('identifyUserForLaunchDarkly');

export const makeUserIdentityContext = (user: LoginUser | User) => {
  if ('first_name' in user) {
    user = user as LoginUser;
    const ldUserName = `${user.first_name} ${user.last_name}`;

    const userContext: LDSingleKindContext = {
      kind: 'user',
      key: `${user.id}`,
      name: ldUserName,
      email: user.email,
      role: user.role_id
    };

    return userContext;
  }

  const ldUserName = `${user.firstName} ${user.lastName}`;

  const userContext: LDSingleKindContext = {
    kind: 'user',
    key: `${user.id}`,
    name: ldUserName,
    email: user.email,
    role: user.roleId
  };

  return userContext;
};

const identifyUserForLaunchDarkly = async (ldClient: LDClient, user: LoginUser | User) => {
  const userContext = makeUserIdentityContext(user);
  await ldClient.waitUntilReady();
  await ldClient.identify(userContext);
  logger.info('LD client identify success', { ...userContext });
};

export default identifyUserForLaunchDarkly;
