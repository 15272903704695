import { Box, styled, TextField, Typography } from '@mui/material';
import React from 'react';

const PrimaryProfileContainer = styled(Box)(({ theme }) => ({
  borderRadius: '6px',
  padding: '1rem',
  border: `1px solid ${theme.palette.primary.light}`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}));

interface IPrimaryProfile {
  patientCode: string;
  setPatientCode: (value: string) => void;
  setIsValidPatientCode: (value: boolean) => void;
}

function PrimaryProfile({ patientCode: primaryProfile, setPatientCode, setIsValidPatientCode }: IPrimaryProfile) {
  const showErrorMessage = (patientCode: string) =>
    !patientCode.toLowerCase().includes('mpat') && patientCode.length > 5;

  const handleOnChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPatientCode(event.target.value);
    setIsValidPatientCode(showErrorMessage(primaryProfile));
  };

  return (
    <PrimaryProfileContainer>
      <Typography variant="h6" component="h3" color="primary" marginBottom="1rem">
        Primary Profile
      </Typography>
      <TextField
        fullWidth
        label="Patient Code"
        variant="outlined"
        value={primaryProfile}
        size="small"
        error={showErrorMessage(primaryProfile)}
        helperText={showErrorMessage(primaryProfile) && 'Patient Code should begin with MPAT and be at least 8 chars'}
        onChange={handleOnChange}
      />
    </PrimaryProfileContainer>
  );
}

export default PrimaryProfile;
