import { useMutation, useQueryClient } from '@tanstack/react-query';

import { mergePatientProfiles } from '@/services/patientMerge.service';
import { Logger } from '@/utils/logger';

import { SECONDARY_PROFILE_QUERY_KEY } from './useGetSecondaryProfiles';

const logger = new Logger('useCreatePatientProfileMerge');

export const useCreatePatientProfileMerge = () => {
  const queryClient = useQueryClient();

  const createPatientProfileMergeMutation = useMutation({
    mutationFn: async ({ fromPatientCodes, toPatientCode }: { fromPatientCodes: string[]; toPatientCode: string }) => {
      try {
        return await mergePatientProfiles(fromPatientCodes, toPatientCode);
      } catch (error) {
        logger.error('Error creating profile', error);
        throw new Error(String(error));
      }
    },

    retry: false,

    onSuccess: () => {
      queryClient.setQueryData([SECONDARY_PROFILE_QUERY_KEY], null);
    }
  });

  return {
    createPatientProfileMerge: createPatientProfileMergeMutation.mutateAsync,
    isPending: createPatientProfileMergeMutation.isPending,
    isError: createPatientProfileMergeMutation.isError,
    isSuccess: createPatientProfileMergeMutation.isSuccess
  };
};

export default useCreatePatientProfileMerge;
