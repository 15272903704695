import { Box, Button, Skeleton, styled, Typography } from '@mui/material';

import { FF_ENABLE_ORDER_PHARMACY_ALLOCATION } from '@/constants/featureFlags';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { formatPrice } from '@/pages/umeds/order-medication/getCheckoutData';

type Discount = {
  id: string;
  display: string;
  amount: number | string;
  formattedAmount: string;
};

type Product = {
  name: string;
  id: number;
  price: number;
  quantity: number;
  supplier: string;
};

type CheckoutData = {
  totalNumberOfProducts: number;
  totalPrice: number;
  formattedTotalPrice: string;
  products: Product[];
  subTotalPrice?: number;
  discounts?: Discount[];

  shippingCalculationNote?: string;
};

const Wrapper = styled(Box)(({ theme }) => ({
  padding: theme.spacing(5),
  backgroundColor: theme.palette.action.focus,
  border: '1px solid',
  borderColor: theme.palette.grey[200],
  borderRadius: theme.shape.borderRadius * 2
}));

const OrderDetails = styled('dl')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: theme.spacing(3),
  paddingBottom: theme.spacing(3),
  gap: theme.spacing(3),
  borderTop: '1px solid',
  borderBottom: '1px solid',
  borderColor: '#B4BFC3'
}));

const LineItemRoot = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const OrderDisclaimer = styled(Typography)(({ theme }) => ({
  fontStyle: 'italic',
  color: theme.palette.grey[600]
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
  marginTop: theme.spacing(6)
}));

const DEFAULT_SHIPPING_CALCULATION_NOTE = 'Shipping fee calculated at checkout.';

interface LineItemProps {
  label: string;
  amount: number | string;
  isTotal?: boolean;
  isDiscount?: boolean;
}

interface ProductLineItemProps {
  quantity: number;
  supplier: string;
  label: string;
  amount: number | string;
}

function ProductLineItem(props: ProductLineItemProps) {
  const { label, amount, quantity, supplier } = props;

  return (
    <LineItemRoot>
      <Box>
        <Typography variant="body2">
          <strong>{label}</strong>
        </Typography>
        <Typography variant="body2">{supplier}</Typography>
        <Typography variant="body2">
          Qty: <strong>{quantity}</strong>
        </Typography>
      </Box>

      <Typography
        variant="body2"
        sx={(theme) => ({
          fontWeight: 600,
          color: theme.palette.text.primary,
          marginLeft: '1rem'
        })}
      >
        {formatPrice(Number(amount) * quantity)}
      </Typography>
    </LineItemRoot>
  );
}

export function LineItem(props: LineItemProps) {
  const { label, amount, isTotal, isDiscount } = props;

  const variant = isTotal ? 'subtitle2' : 'body1';

  return (
    <LineItemRoot>
      <Typography variant={variant}>{label}</Typography>
      <Typography
        variant={variant}
        sx={(theme) => ({
          fontWeight: 600,
          color: isDiscount ? theme.palette.success.main : theme.palette.text.primary,
          marginLeft: '1rem'
        })}
      >
        {amount}
      </Typography>
    </LineItemRoot>
  );
}

type OrderSummaryV2Props = {
  checkoutData: CheckoutData;
  handleCheckout: () => void;
  disabled?: boolean;
};

export function OrderSummaryV2(props: OrderSummaryV2Props) {
  const { flags } = useFeatureFlags();

  const {
    checkoutData: { formattedTotalPrice, totalNumberOfProducts, discounts = [], products },
    handleCheckout,
    disabled
  } = props;

  const hasAnyProducts = totalNumberOfProducts > 0;

  return (
    <Wrapper>
      <Typography variant="subtitle2" component="h3" sx={{ fontWeight: 600 }}>
        Summary
      </Typography>
      <OrderDetails>
        {hasAnyProducts
          ? products.map((product) => (
              <ProductLineItem
                key={product.id}
                label={product.name}
                amount={product.price}
                quantity={product.quantity}
                supplier={product.supplier}
              />
            ))
          : null}
        {discounts.map((discount) => (
          <LineItem key={discount.id} label={discount.display} amount={discount.formattedAmount} isDiscount />
        ))}
        <OrderDisclaimer variant="body2">{DEFAULT_SHIPPING_CALCULATION_NOTE}</OrderDisclaimer>
      </OrderDetails>
      <LineItem isTotal label="Total*" amount={formattedTotalPrice} />
      <ButtonContainer>
        <Button onClick={handleCheckout} color="primary" variant="contained" disabled={disabled}>
          {flags[FF_ENABLE_ORDER_PHARMACY_ALLOCATION] ? 'Proceed' : 'Proceed to checkout'}
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export function OrderSummaryV2Skeleton() {
  return (
    <Wrapper data-testid="order-summary-skeleton">
      <Typography variant="subtitle2" component="h3" sx={{ fontWeight: 600 }}>
        Summary
      </Typography>
      <OrderDetails>
        <LineItemRoot>
          <Skeleton variant="text" width="10rem" />
          <Skeleton variant="text" width="5rem" />
        </LineItemRoot>
        <LineItemRoot>
          <Skeleton variant="text" width="10rem" />
          <Skeleton variant="text" width="5rem" />
        </LineItemRoot>
        <LineItemRoot>
          <Skeleton variant="text" width="10rem" />
          <Skeleton variant="text" width="5rem" />
        </LineItemRoot>
        <OrderDisclaimer variant="body2">{DEFAULT_SHIPPING_CALCULATION_NOTE}</OrderDisclaimer>
      </OrderDetails>
      <LineItemRoot>
        <Typography variant="subtitle2">Total*</Typography>
        <Skeleton variant="text" width="5rem" />
      </LineItemRoot>
      <ButtonContainer>
        <Button color="primary" variant="contained" disabled>
          <Skeleton variant="rectangular" />
        </Button>
      </ButtonContainer>
    </Wrapper>
  );
}

export default OrderSummaryV2;
