import type { AxiosRequestConfig } from 'axios';
import axios from 'axios';
import { useState } from 'react';

// A trimmed down version of https://novajs.co/react-hook-download
export function useDownload() {
  const [error, setError] = useState<Error | unknown | null>(null);
  const [isDownloading, setIsDownloading] = useState<boolean>(false);

  const createBlobURL = (blob: Blob): string => window.URL.createObjectURL(blob);

  const handleDownload = (fileName: string, url: string, fileType: string) => {
    const link = document.createElement('a');

    link.href = url;
    link.setAttribute('download', `${fileName}${fileType}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  const downloadFile = async (fileName: string, fileUrl: string, config: AxiosRequestConfig = {}, fileType = '') => {
    setIsDownloading(true);
    setError(null);

    const finalConfig = config;

    if (!finalConfig?.responseType) {
      finalConfig.responseType = 'blob';
    }

    try {
      const response = await axios(fileUrl, finalConfig);

      const url = createBlobURL(response.data);

      handleDownload(fileName, url, fileType);
    } catch (e) {
      setError(e);
    } finally {
      setIsDownloading(false);
    }
  };

  return {
    isDownloading,
    downloadFile,
    error
  };
}

export default useDownload;
