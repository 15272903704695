import { DateTime } from 'luxon';
import { useMemo } from 'react';

const useGetGreeting = () => {
  const greeting = useMemo(() => {
    const currentHour = DateTime.local().hour;
    if (currentHour < 12) {
      return 'Good morning';
    }
    if (currentHour >= 18) {
      return 'Good evening';
    }
    if (currentHour >= 12) {
      return 'Good afternoon';
    }
    return 'Hello';
  }, []);

  return greeting;
};

export default useGetGreeting;
