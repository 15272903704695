import axios from 'axios';
import qs from 'qs';

import settings from '@/constants/constants';

const API_URL = `${settings.url}/inventory`;

const getAllInventory = () => axios.get(API_URL);

const getInventoryStock = (
  products: unknown[],
  orderId: string | number,
  pharmacyId: string | number,
  refill: boolean
) =>
  axios.get(API_URL, {
    params: {
      products,
      orderId,
      pharmacyId,
      refill: refill ? 'Yes' : 'No'
    },
    paramsSerializer: (params) => qs.stringify(params)
  });

// eslint-disable-next-line import/prefer-default-export
export const InventoryService = {
  getAllInventory,
  getInventoryStock
};
