import { Skeleton, Stack } from '@mui/material';

import { ConsultationContainer } from './UpcomingConsultationCard';

function UpcomingConsultationCardSkeleton() {
  return (
    <ConsultationContainer direction="column" spacing={3} alignItems="flex-start">
      <Skeleton variant="text" width={250} height={32} />
      <Stack direction="row" alignItems="center" gap={1} width="100%">
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={200} height={24} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} width="100%">
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={150} height={24} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} width="100%">
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={150} height={24} />
      </Stack>
      <Stack direction="row" alignItems="center" gap={1} width="100%">
        <Skeleton variant="circular" width={24} height={24} />
        <Skeleton variant="text" width={200} height={24} />
      </Stack>
      <Skeleton variant="text" width={150} height={24} />
      <Stack direction="column" alignItems="center" alignSelf="stretch">
        <Skeleton variant="rectangular" width="100%" height={40} />
        <Skeleton variant="text" width={250} height={24} style={{ marginTop: 8 }} />
      </Stack>
    </ConsultationContainer>
  );
}

export default UpcomingConsultationCardSkeleton;
