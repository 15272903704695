import type { LogsInitConfiguration } from '@datadog/browser-logs';
import type { RumInitConfiguration } from '@datadog/browser-rum';

import { viteAppBaseUrl, viteAppEnv } from './env';

const APP_BASE_URL = viteAppBaseUrl();

type CalendarConfig = {
  calOrigin: string;
  doctorTeamPath: string;
  embedJsUrl: string;
};

export type CommonDataDogConfig = Pick<LogsInitConfiguration, 'clientToken' | 'site'>;
export type EnvSpecificDataDogConfig = Pick<LogsInitConfiguration, 'env'> &
  Pick<
    RumInitConfiguration,
    'sessionSampleRate' | 'sessionReplaySampleRate' | 'allowedTracingUrls' | 'defaultPrivacyLevel'
  >;
export type DataDogConfig = Partial<CommonDataDogConfig> & EnvSpecificDataDogConfig;

type CommonSettings = {
  assetsUrl: string;
  ausPostTrackingUrl: string;
  followUpGoogleDoc: string;
  GoogleAuthId: string;
  googleTagManagerId: string;
  ZENDESK_CHAT_KEY: string;
  paymentGatwayEnable: number;
  recaptchaSiteKey: string;
  calendarConfig: Pick<CalendarConfig, 'doctorTeamPath'>;
  dataDog: CommonDataDogConfig;
  creditType: {
    OrderIssue: string;
    ProductDefect: string;
    ReScriptCredit: string;
    Other: string;
    Promotion: string;
    Referral: string;
  };
  discountReason: {
    EXTENDED_OUT_OF_STOCK: number;
    POOR_SUPPORT_EXPERIENCE: number;
    DELAYED_SHIPPING: number;
    MATCH_ANOTHER_SUPPLIER: number;
    PROMOTION: number;
    OTHER: number;
    CONCESSION_CARD_HOLDER: number;
  };
  discountType: {
    FULL_ORDER: number;
    DISPENSING_FEE: number;
    MEDICATION_SPECIFIC: number;
  };
  inventoryStatus: {
    INCREMENT: string;
    DECREMENT: string;
  };
  orderStatus: {
    AWAITING_PAYMENT: number;
    PAID_NOT_APPROVED: number;
    APPROVED_NOT_PAID: number;
    AWAITING_SHIPMENT: number;
    SHIPPED: number;
    COMPLETED: number;
    CANCELLED: number;
    PENDING: number;
    TEST: number;
    ERROR: number;
    EXPIRED: number;
    PMS_DELETED: number;
    OUT_OF_REPEATS: number;
    AWAITING_DISPENSE: number;
    PHARMACY_DISPENSED: number;
    PHARMACY_OUT_OF_STOCK: number;
    PHARMACY_COMPLETED: number;
    PHARMACY_PENDING: number;
    PHARMACY_TEST: number;
    INTERVAL_TIME: number;
    NO_PHARMACY: number;
    PHARMACY_PARTIALLY_OUT_OF_STOCK: number;
    OUT_OF_STOCK_REASONING: number;
    RE_SCRIPT: number;
    OUT_OF_STOCK: number;
    PARTIALLY_OUT_OF_STOCK: number;
    AWAITING_TOKEN: number;
    UNABLE_TO_DISPENSE: number;
    PROCESSING_DISPENSE: number;
    PMS_PATIENT_PROFILE_INCOMPLETE: number;
    REFUNDED: number;
  };
  screenResolution: {
    small: number;
    medium: number;
    large: number;
    extralarge: number;
    tablet: number;
  };
  shipmentCarrier: {
    ausPost: string;
    shippit: string;
  };
  transactionType: {
    CREDIT_CARD: number;
    BANK: number;
  };
  bankAccount: {
    name: string;
    bsb: string;
    accountNumber: string;
  };
  support: {
    phone: {
      raw: string;
      display: string;
    };
    site: {
      url: string;
    };
  };
  productStatus: {
    active: string;
    inactive: string;
  };
};

type EnvSpecificSettings = {
  brazeApiKey: string;
  brazeBaseUrl: string;
  calendarConfig: CalendarConfig;
  datadog?: DataDogConfig;
  FeatureFlagSDKKey: string;
  googleTagManagerAuth: string;
  googlePlacesApiKey: string;
  productImageUrl: string;
  Referal?: string;
  url: string;
  adminApiUrl: string;
  customerPortalApiUrl: string;
  launchDarklyConfig: {
    clientSideId: string;
  };
  shippitTrackingUrl: string;
  shopifyDiscountsURL: string;
  shopifyOrderDiscountAppURL: string;
  shopifyProductDiscountAppURL: string;
  shopifyShippingDiscountAppURL: string;
};

export type EnvSettings = CommonSettings & EnvSpecificSettings;

const common: CommonSettings = {
  screenResolution: {
    small: 576,
    medium: 720,
    tablet: 820,
    large: 960,
    extralarge: 1140
  },
  recaptchaSiteKey: '6LfxAoMhAAAAADy4Bv_Lz6AhzsTTzf7N2cjlICeZ',
  GoogleAuthId: '413997005731-dicel6reedvvm83fscbgvvhccbevfiib.apps.googleusercontent.com',
  creditType: {
    OrderIssue: '1',
    ProductDefect: '2',
    ReScriptCredit: '3',
    Other: '4',
    Promotion: '5',
    Referral: '6'
  },
  discountType: {
    FULL_ORDER: 1,
    DISPENSING_FEE: 2,
    MEDICATION_SPECIFIC: 3
  },
  discountReason: {
    EXTENDED_OUT_OF_STOCK: 1,
    POOR_SUPPORT_EXPERIENCE: 2,
    DELAYED_SHIPPING: 3,
    MATCH_ANOTHER_SUPPLIER: 4,
    PROMOTION: 5,
    OTHER: 6,
    CONCESSION_CARD_HOLDER: 7
  },
  transactionType: {
    CREDIT_CARD: 1,
    BANK: 2
  },
  bankAccount: {
    name: 'Montu Group',
    bsb: '063 097',
    accountNumber: '3229 8127'
  },
  inventoryStatus: {
    INCREMENT: '1',
    DECREMENT: '2'
  },
  orderStatus: {
    AWAITING_PAYMENT: 1,
    PAID_NOT_APPROVED: 2,
    APPROVED_NOT_PAID: 3,
    AWAITING_SHIPMENT: 4,
    SHIPPED: 5,
    /** @deprecated */
    COMPLETED: 6,
    CANCELLED: 7,
    /** @deprecated */
    PENDING: 8,
    TEST: 9,
    ERROR: 10,
    EXPIRED: 11,
    PMS_DELETED: 12,
    OUT_OF_REPEATS: 13,
    AWAITING_DISPENSE: 14,
    PHARMACY_DISPENSED: 15,
    PHARMACY_OUT_OF_STOCK: 16,
    /** @deprecated */
    PHARMACY_COMPLETED: 17,
    /** @deprecated */
    PHARMACY_PENDING: 18,
    PHARMACY_TEST: 19,
    INTERVAL_TIME: 20,
    /** @deprecated */
    NO_PHARMACY: 21,
    PHARMACY_PARTIALLY_OUT_OF_STOCK: 22,
    OUT_OF_STOCK_REASONING: 23,
    RE_SCRIPT: 24,
    /** @deprecated */
    OUT_OF_STOCK: 25,
    PARTIALLY_OUT_OF_STOCK: 26,
    AWAITING_TOKEN: 27,
    UNABLE_TO_DISPENSE: 28,
    PROCESSING_DISPENSE: 29,
    PMS_PATIENT_PROFILE_INCOMPLETE: 30,
    REFUNDED: 31
  },
  calendarConfig: {
    doctorTeamPath: 'team/alternaleaf-doctors'
  },
  dataDog: {
    clientToken: 'pubbc48eee0cbeb1f3ac5db6d8bd99c0f3b',
    site: 'us3.datadoghq.com'
  },
  paymentGatwayEnable: 1,
  shipmentCarrier: {
    ausPost: 'AUSPOST',
    shippit: 'SHIPPIT'
  },
  ausPostTrackingUrl: 'https://auspost.com.au/mypost/track/#/details/',
  assetsUrl: 'https://circuit.staging.montu.com.au/assets/images/UI/',
  ZENDESK_CHAT_KEY: 'f7f8f2af-9fd1-44d4-8c7a-5de7b20930f2',
  googleTagManagerId: 'GTM-ND4KXHRK',
  followUpGoogleDoc:
    'https://docs.google.com/forms/d/e/1FAIpQLSdpOvjYLOGjAu8AmSBnoftrI3AC6zDdIg4ifbTapw-yjqCrpg/viewform',
  support: {
    phone: {
      raw: '1800864878',
      display: '1800 864 878'
    },
    site: {
      url: 'https://support.montu.com.au'
    }
  },
  productStatus: {
    active: 'Active',
    inactive: 'Inactive'
  }
};

const development: EnvSettings = {
  url: `${APP_BASE_URL}/api/v1`,
  adminApiUrl: `${APP_BASE_URL}/api/admin/v1`,
  customerPortalApiUrl: `${APP_BASE_URL}/api/customer-portal/v1`,
  productImageUrl: 'https://circuit.staging.montu.com.au/assets/images/products/',
  ...common,
  Referal: 'https://circuit.staging.montu.com.au',
  FeatureFlagSDKKey: 'be49d7b2-589d-4bab-a85b-cca03b9e2f40',
  brazeApiKey: '58efb523-0f51-4b1e-988f-cac946b97dfe',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  datadog: {
    ...common.dataDog,
    env: 'local',
    sessionSampleRate: 0, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 0, // percentage of sessions capturing RUM, AND session replays
    /*
     * If PII is discovered during a session replay, adding the data attribute data-dd-privacy="mask" will override specific elements
     * See: https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#override-an-html-element
     */
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`${APP_BASE_URL}/api/v1`]
  },
  calendarConfig: {
    ...common.calendarConfig,
    calOrigin: 'https://bookings.staging.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
  },
  launchDarklyConfig: {
    clientSideId: '64867d27a3414f12de1f7416'
  },
  googleTagManagerAuth: '',
  googlePlacesApiKey: 'AIzaSyBbGoHlNxg-31cc-IV1yXQySQRp69TqtBA',
  shippitTrackingUrl: 'https://app.staging.shippit.com/tracking/',
  shopifyDiscountsURL: 'https://admin.shopify.com/store/b2cteststore',
  shopifyOrderDiscountAppURL:
    'apps/circuit-discounts-dev-for-wx/app/order-discount/bd55ebfe-c451-410a-8919-bd17b494be15',
  shopifyProductDiscountAppURL:
    'apps/circuit-discounts-dev-for-wx/app/product-discount/9b704a7d-23b9-44d2-8085-a9d5ed015f2f',
  shopifyShippingDiscountAppURL:
    'apps/circuit-discounts-dev-for-wx/app/shipping-discount/ebd0f507-b5ad-4df0-9c3a-dcebbd14bbc5'
};

const staging: EnvSettings = {
  url: `${APP_BASE_URL}/api/v1`,
  adminApiUrl: `${APP_BASE_URL}/api/admin/v1`,
  customerPortalApiUrl: `${APP_BASE_URL}/api/customer-portal/v1`,
  productImageUrl: `${APP_BASE_URL}/assets/images/products/`,
  ...common,
  assetsUrl: `${APP_BASE_URL}/assets/images/UI/`,
  Referal: APP_BASE_URL,
  FeatureFlagSDKKey: '28a87403-6fa4-4df4-8bcf-aaae0beae867',
  brazeApiKey: '58efb523-0f51-4b1e-988f-cac946b97dfe',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  datadog: {
    ...common.dataDog,
    env: 'staging',
    sessionSampleRate: 100, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 0, // percentage of sessions capturing RUM, AND session replays
    /*
     * If PII is discovered during a session replay, adding the data attribute data-dd-privacy="mask" will override specific elements
     * See: https://docs.datadoghq.com/real_user_monitoring/session_replay/privacy_options/#override-an-html-element
     */
    defaultPrivacyLevel: 'mask-user-input',
    allowedTracingUrls: [`${APP_BASE_URL}/api/v1`]
  },
  calendarConfig: {
    ...common.calendarConfig,
    calOrigin: 'https://bookings.staging.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.staging.alternaleaf.com.au/embed/embed.js'
  },
  launchDarklyConfig: {
    clientSideId: '64867d1f3e893d129b01ff08'
  },
  googleTagManagerAuth: '',
  googlePlacesApiKey: 'AIzaSyBbGoHlNxg-31cc-IV1yXQySQRp69TqtBA',
  shippitTrackingUrl: 'https://app.staging.shippit.com/tracking/',
  shopifyDiscountsURL: 'https://admin.shopify.com/store/b2cstaging',
  shopifyOrderDiscountAppURL: 'apps/circuit-discounts-staging/app/order-discount/d08a4444-efe1-49e5-ab26-860bb6ba0ba9',
  shopifyProductDiscountAppURL:
    'apps/circuit-discounts-staging/app/product-discount/bb0c61a3-370c-464f-acbb-0610671d3500',
  shopifyShippingDiscountAppURL:
    'apps/circuit-discounts-staging/app/shipping-discount/e7535004-3491-42c5-8755-bc0ed760fb55'
};

const prod: EnvSettings = {
  url: `${APP_BASE_URL}/api/v1`,
  adminApiUrl: `${APP_BASE_URL}/api/admin/v1`,
  customerPortalApiUrl: `${APP_BASE_URL}/api/customer-portal/v1`,
  productImageUrl: `${APP_BASE_URL}/assets/images/products/`,
  ...common,
  assetsUrl: `${APP_BASE_URL}/assets/images/UI/`,
  recaptchaSiteKey: '6LfxpKcaAAAAAMsdrsz9rEZQbk90e4oR1n4y11fe',
  GoogleAuthId: '178327000471-q4dg3ac4j6qudsu1oo277440e8sivter.apps.googleusercontent.com',
  Referal: 'https://www.altleaf.au',
  FeatureFlagSDKKey: 'ec1fd7a8-a339-4c56-8ef7-8ae75e30fd97',
  brazeApiKey: 'a1ea623c-bcc7-46e8-a862-36eb5b837fa9',
  brazeBaseUrl: 'sdk.iad-02.braze.com',
  datadog: {
    ...common.dataDog,
    env: 'production',
    sessionSampleRate: 100, // percentage of sessions capturing RUM
    sessionReplaySampleRate: 50, // percentage of sessions capturing RUM, AND session replays. currently bumped from 25% to 50% to better identify a few known issues
    /**
     * If you need to unmask a field, you can use the `data-dd-privacy` attribute
     * data-dd-privacy="allow" will unmask the field if needed for RUM, and isn't PII
     */
    defaultPrivacyLevel: 'mask',
    allowedTracingUrls: [`${APP_BASE_URL}/api/v1`]
  },
  calendarConfig: {
    ...common.calendarConfig,
    calOrigin: 'https://bookings.alternaleaf.com.au',
    embedJsUrl: 'https://bookings.alternaleaf.com.au/embed/embed.js'
  },
  launchDarklyConfig: {
    clientSideId: '64867d18418ca9139db03055'
  },
  googleTagManagerAuth: 'cFM07ZbzfdCf3JdRCJxojQ',
  googlePlacesApiKey: 'AIzaSyAhUsEHrULAYUbYq08eYB7asYYBcBHIMIg',
  shippitTrackingUrl: 'https://app.shippit.com/tracking/',
  shopifyDiscountsURL: 'https://admin.shopify.com/store/b2cprod',
  shopifyOrderDiscountAppURL: 'apps/circuit-discounts-prod/app/order-discount/e8ee532e-1bef-46f8-816c-6c6b5b80a08d',
  shopifyProductDiscountAppURL: 'apps/circuit-discounts-prod/app/product-discount/c3ee05e5-4dc5-4169-8264-1c9b9c446b48',
  shopifyShippingDiscountAppURL:
    'apps/circuit-discounts-prod/app/shipping-discount/7210f810-03ad-465d-b3b7-239b5c3ccb95'
};

function getEnvironmentSettings(): EnvSettings {
  switch (viteAppEnv()) {
    case 'test':
    case 'development':
      return development;
    case 'staging':
      return staging;
    case 'production':
      return prod;
    default:
      return development;
  }
}

export const EMAIL_PATTERN =
  // eslint-disable-next-line no-useless-escape
  /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const settings = getEnvironmentSettings();
export default settings;
