import { OrderService } from '@/services/order.service';
import { useQuery } from '@tanstack/react-query';

function useGetOrderedProducts({ orderId, onSuccess }: { orderId: number | null; onSuccess?: () => void }) {
  return useQuery({
    queryKey: ['getOrderedProducts', orderId],
    enabled: Boolean(orderId),
    queryFn: async () => {
      try {
        if (!orderId) {
          return;
        }

        const response = await OrderService.getOrderedProducts(orderId);
        if (response.status !== 200) {
          throw new Error(`Request failed with status code ${response.status}`);
        }
        if (onSuccess) {
          onSuccess();
        }
        return response.data;
      } catch (e) {
        throw new Error(`Failed to fetch ordered products: ${(e as { message: string }).message}`);
      }
    }
  });
}

export default useGetOrderedProducts;
