import { Box, styled } from '@mui/material';

import { APP_BAR_HEIGHT } from './navbar/navbar.config';

export const MainContentBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  minHeight: '100vh',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    minHeight: `calc(100dvh - ${APP_BAR_HEIGHT})`,
    marginTop: APP_BAR_HEIGHT
  }
}));

export const ContentWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'removePadding' && prop !== 'removeExtraBottomPadding'
})<{
  removePadding?: boolean;
  removeExtraBottomPadding?: boolean;
}>(({ theme, removePadding, removeExtraBottomPadding }) => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: removePadding ? 0 : theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    minHeight: '100vh',
    padding: removePadding ? 0 : theme.spacing(27, 16)
  },
  [theme.breakpoints.down('sm')]: {
    paddingBottom: removeExtraBottomPadding ? 0 : theme.spacing(30)
  }
}));

export const MainContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(6, 3),
  [theme.breakpoints.up('md')]: {
    padding: theme.spacing(27, 16, 20, 16)
  }
}));
