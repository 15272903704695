import { Box, styled } from '@mui/material';

export const Papaya = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;

  height: 25%;
  width: 15%;

  background-image: url('/assets/images/papaya.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
`;

export const IcebergPapaya = styled(Box)`
  position: fixed;
  bottom: 0;
  right: 0;

  height: 25%;
  width: 15%;

  background-image: url('/assets/images/iceberg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
`;

export const PapayaContained = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;

  height: 50%;
  width: 30%;

  background-image: url('/assets/images/papaya.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
`;

export const IcebergPapayaContained = styled(Box)`
  position: absolute;
  bottom: 0;
  right: 0;

  height: 50%;
  width: 30%;

  background-image: url('/assets/images/iceberg.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom right;
  pointer-events: none;
`;
export default Papaya;
