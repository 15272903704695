import Select from 'react-select';
import settings from '../../constants/constants';

function SplitOrderModal(props) {
  const {
    menuPortalTarget,
    hideSplitOrderModal,
    selectedProducts,
    splitOrders,
    handleOrderSelect,
    saveSplitOrders,
    selectedOrder,
    inactiveProducts,
    disabled,
  } = props;

  const getOrderValue = (id) => {
    const order = splitOrders.find((itm) => itm.order === id);
    return { label: order.orderName, value: order.order };
  };

  const inactiveOrderIds = inactiveProducts.map((p) => p.orderId);
  const orderOptions = splitOrders
    .filter((p) => !inactiveOrderIds.includes(p.order))
    .map((p) => getOrderValue(p.order));

  return (
    <div
      className="modal"
      id="splitOrderModal"
      tabIndex="-1"
      role="dialog"
      aria-labelledby="splitOrdertModal"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-lg modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Split Order - {selectedOrder ? selectedOrder.order_code : null}</h5>
            <button type="button" className="close" onClick={() => hideSplitOrderModal()} disabled={disabled}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <table className="table table-bordered table-hover">
              <thead>
                <tr>
                  <th>Product</th>
                  <th className="custom-align-center">Status</th>
                  <th className="custom-align-center">Quantity</th>
                  <th className="custom-align-center">Split</th>
                </tr>
              </thead>
              <tbody>
                {selectedProducts.map((product) => (
                  <tr key={product.product_id} role="row">
                    <td style={{ verticalAlign: 'middle' }}>{product.product_name}</td>
                    <td className="custom-align-center">{product.status}</td>
                    <td className="custom-align-center">{product.quantity}</td>
                    <td className="custom-align-center">
                      <Select
                        value={getOrderValue(product.orderId)}
                        name="Split Orders"
                        menuPlacement="auto"
                        menuPortalTarget={menuPortalTarget}
                        menuPosition="fixed"
                        isSearchable
                        options={orderOptions}
                        className="orderSelectField"
                        onChange={(e) => handleOrderSelect(e, product)}
                        isDisabled={disabled || product.status === settings.productStatus.inactive}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            <small>
              {inactiveProducts.length > 0 ? '*Inactive products will be split into a separate order.' : ''}
            </small>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              disabled={disabled}
              className="btn btn-primary btn-md d-block font-weight-bold"
              onClick={() => hideSplitOrderModal()}
            >
              Cancel
            </button>
            <button
              type="button"
              disabled={disabled}
              className="btn btn-primary btn-md d-block font-weight-bold"
              onClick={() => saveSplitOrders()}
            >
              {disabled && <i class="fa-solid fa-spinner fa-spin" data-testid="loading-spinner"></i>} Split Order
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SplitOrderModal;
