import React from 'react';
import { NavLink } from 'react-router-dom';

const NavbarMenu = (props) => {
  const [data, setData] = React.useState({
    isExpanded: false
  });

  const handleRedirect = () => {
    if (window.outerWidth < 992) {
      props.handleMenu();
      setData({ isExpanded: !data.isExpanded });
    }
  };

  const navList = ({ name, activeReferal }) => {
    if (name === 'Referral' && activeReferal) {
      return <button className="btn text-white refer-button">REFER A FRIEND</button>;
    }

    return name;
  };

  return (
    <React.Fragment>
      <li
        className="nav-item ml-5 nav-response"
        key={`main-menu-${props.id}`}
        data-placement="center"
        title={props.name}
      >
        <NavLink
          to={props.link}
          onClick={handleRedirect}
          className={({ isActive }) => 'nav-link' + (isActive ? ' navbar__link--active' : '')}
        >
          <span
            data-testid="spanID"
            className={props.name === 'Referral' && !props.activeReferal ? 'd-none' : 'nav-link-text'}
          >
            {navList(props)}
          </span>
        </NavLink>
      </li>
    </React.Fragment>
  );
};

export default NavbarMenu;
