import type { UseMutationResult } from '@tanstack/react-query';
import { useMutation } from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import { UserService } from '@/services/user.service';

interface UserProfilePayload {
  patient?: {
    dob?: Date;
  };
  user?: {
    first_name?: string;
    last_name?: string;
    email?: string;
    phone?: string;
    address?: string;
    city?: string;
    state_id?: number;
    zip_code?: string;
    country_id?: number;
  };
}

const useUpdateUserProfile = (): UseMutationResult<AxiosResponse, unknown, UserProfilePayload> =>
  useMutation({
    mutationFn: (data: UserProfilePayload) => UserService.updateUserProfile(data)
  });

export default useUpdateUserProfile;
