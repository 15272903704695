import { noOp } from '@/utils/noOp';
import type { PropsWithChildren, ReactNode } from 'react';
import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

export interface ErrorDisplay {
  title: string;
  body: string | ReactNode;
  persist?: boolean;
}

type ErrorListener = (errorDisplay: ErrorDisplay) => void;

export interface ErrorManagementData {
  addErrorToTop: (error: ErrorDisplay) => void;
  enqueueError: (error: ErrorDisplay) => void;
  removeErrorAtIndex: (index: number) => void;
  removeAllErrors: () => void;
  getErrorAtIndex: (index: number) => ErrorDisplay | null;
  getAllErrors: () => ErrorDisplay[];
  addOnErrorAddedListener: (id: string, callback: ErrorListener) => void;
  removeOnErrorAddedListener: (id: string) => void;
}

export const ErrorManagementContext = createContext<ErrorManagementData>({
  addErrorToTop: noOp,
  enqueueError: noOp,
  removeErrorAtIndex: noOp,
  removeAllErrors: noOp,
  getErrorAtIndex: () => null,
  getAllErrors: () => [],
  addOnErrorAddedListener: noOp,
  removeOnErrorAddedListener: noOp
});

interface ErrorManagementProviderProps {
  initialErrors: ErrorDisplay[] | undefined;
}

export function ErrorManagementProvider(props: PropsWithChildren<ErrorManagementProviderProps>) {
  const { children, initialErrors } = props;
  const [errors, setErrors] = useState<ErrorDisplay[]>(initialErrors || []);
  const [errorListeners, setErrorListeners] = useState<Map<string, ErrorListener>>(new Map());

  const addErrorToTop = useCallback(
    (error: ErrorDisplay) => {
      setErrors((errorDisplays) => [error, ...errorDisplays]);
      errorListeners.forEach((errorListener) => errorListener(error));
    },
    [setErrors, errorListeners]
  );

  const enqueueError = useCallback(
    (error: ErrorDisplay) => {
      setErrors((errorDisplays) => [...errorDisplays, error]);
      errorListeners.forEach((errorListener) => errorListener(error));
    },
    [setErrors, errorListeners]
  );

  const removeErrorAtIndex = useCallback(
    (index = 0) => {
      setErrors((errorDisplays) => {
        const newErrors = [...errorDisplays];
        newErrors.splice(index, 1);
        return newErrors;
      });
    },
    [setErrors]
  );

  const removeAllErrors = useCallback(() => setErrors([]), [setErrors]);

  const getErrorAtIndex = useCallback((index = 0) => errors[index] || null, [errors]);

  const getAllErrors = useCallback(() => errors, [errors]);

  const addOnErrorAddedListener = useCallback(
    (id: string, callback: ErrorListener) => {
      errorListeners.set(id, callback);
      setErrorListeners(new Map(errorListeners));
    },
    [setErrorListeners, errorListeners]
  );

  const removeOnErrorAddedListener = useCallback(
    (id: string) => {
      if (errorListeners.has(id)) {
        errorListeners.delete(id);
        setErrorListeners(new Map(errorListeners));
      }
    },
    [setErrorListeners, errorListeners]
  );

  const value: ErrorManagementData = useMemo(
    () => ({
      addErrorToTop,
      enqueueError,
      removeErrorAtIndex,
      removeAllErrors,
      getErrorAtIndex,
      getAllErrors,
      addOnErrorAddedListener,
      removeOnErrorAddedListener
    }),
    [
      addErrorToTop,
      enqueueError,
      removeErrorAtIndex,
      removeAllErrors,
      getErrorAtIndex,
      getAllErrors,
      addOnErrorAddedListener,
      removeOnErrorAddedListener
    ]
  );

  return <ErrorManagementContext.Provider value={value}>{children}</ErrorManagementContext.Provider>;
}

export interface ErrorManagementOptions {
  keepFromPrevious?: boolean;
}

export const useErrorManagement = (options: ErrorManagementOptions = {}) => {
  const errorManagement: ErrorManagementData = useContext(ErrorManagementContext);

  // Clear previous error stack on page mount
  useEffect(() => {
    if (!options.keepFromPrevious) {
      errorManagement.removeAllErrors();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return errorManagement;
};
