import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Stack, styled, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

type CardButtonProps = {
  title: string;
  subtitle?: string;
  datetime?: string;
  icon: React.ReactNode;
  accentColor: 'primary' | 'secondary';
  link: string;
};

const CardButtonContainer = styled(Link)(({ theme }) => ({
  display: 'flex',
  paddingRight: theme.spacing(4),
  alignItems: 'center',
  borderRadius: theme.spacing(2),
  borderTop: '1px solid rgba(0, 0, 0, 0.05)',
  gap: '1rem',
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[1],
  cursor: 'pointer',
  textDecoration: 'none',
  color: 'inherit',
  '&:hover': {
    textDecoration: 'none',
    backgroundColor: theme.palette.action.hover
  }
}));

const IconContainer = styled(Stack)<{ accentcolor: 'primary' | 'secondary' }>(({ theme, accentcolor }) => ({
  width: theme.spacing(11),
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: `${theme.spacing(2)} 0 0 ${theme.spacing(2)}`,
  backgroundColor: theme.palette[accentcolor].main,
  color: theme.palette.common.white,
  paddingTop: theme.spacing(12),
  paddingBottom: theme.spacing(12),
  [theme.breakpoints.between('md', 'lg')]: {
    paddingTop: theme.spacing(15),
    paddingBottom: theme.spacing(15)
  }
}));

const Title = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.spacing(5),
  fontWeight: theme.typography.fontWeightBold,
  lineHeight: theme.spacing(6),
  letterSpacing: 0,
  [theme.breakpoints.up('md')]: {
    lineHeight: theme.spacing(8)
  }
}));

const Subtitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: theme.spacing(4),
  lineHeight: theme.spacing(6)
}));

function CardButton({ title, subtitle, datetime, icon, accentColor, link }: CardButtonProps) {
  return (
    <CardButtonContainer to={link}>
      <IconContainer accentcolor={accentColor}>{icon}</IconContainer>
      <Stack flex={1}>
        <Title>{title}</Title>
        {subtitle && <Subtitle>{subtitle}</Subtitle>}
        {datetime && <Subtitle>{datetime}</Subtitle>}
      </Stack>
      <ArrowForwardIosIcon fontSize="inherit" />
    </CardButtonContainer>
  );
}

export default CardButton;
